import { Card, HTMLTable, Intent } from '@blueprintjs/core'
import React from 'react'
import { orderBy } from 'lodash'
import { Link } from 'react-router-dom'
import { GET_JOB_RESPONSE_TYPE } from './gql/job'
import { Explanation } from '../../atomics/Explanation'

export const AssignmentHistory = ({
  job,
}: {
  job: GET_JOB_RESPONSE_TYPE['job']
}) => {
  if (!job) return null
  const assignments = job.assignmentHistory || []

  return (
    <Card
      style={{
        maxHeight: '20vh',
        overflowY: 'auto',
        padding: '0px',
      }}
    >
      {assignments.length === 0 ? (
        <p>No assignment history</p>
      ) : (
        <HTMLTable compact striped>
          <thead>
            <tr>
              <th>Created at</th>
              <th>Assignment Status</th>
              <th>Driver</th>
            </tr>
          </thead>
          <tbody>
            {orderBy(assignments, 'createdAt', 'desc').map(assignment => {
              return (
                assignment && (
                  <tr key={assignment.id}>
                    <td>
                      {new Date(
                        assignment.createdAt as string
                      ).toLocaleString()}
                    </td>
                    <td>
                      {assignment.status}
                      {assignment.autoRejectAt && (
                        <>
                          {' '}
                          <Explanation
                            sup
                            intent={Intent.WARNING}
                            content="Autorejected. The driver neither approved or rejected the job"
                            icon="warning-sign"
                          />
                        </>
                      )}
                    </td>
                    <td>
                      <Link to={`/drivers/${assignment.driverId}`}>
                        {assignment.driverId}
                      </Link>
                    </td>
                  </tr>
                )
              )
            })}
          </tbody>
        </HTMLTable>
      )}
    </Card>
  )
}
