import React, { FC } from 'react'
import { Tooltip } from '@blueprintjs/core'
import { JobStatus, ModelTypes } from '../../zeus'
import { QUERY_PARAMS } from '../../utils/queryParamsNames'

export const NON_DRIVER_ROLES = ['ADMIN', 'PARTNER_USER', 'FLEET_USER']

type DriverProps = Pick<
  ModelTypes['Driver'],
  'id' | 'phoneNumber' | 'firstName' | 'lastName'
>

export const DriverSection: FC<{
  driver: DriverProps | undefined
  status: JobStatus
  completedByDriverId?: string
  completedByDriverFullName?: string
}> = ({ driver, status, completedByDriverId, completedByDriverFullName }) => {
  if (status === JobStatus.COMPLETE) {
    return (
      <strong>
        Completed
        {!!completedByDriverFullName && (
          <>
            {' by '}
            <a
              href={
                NON_DRIVER_ROLES.includes(completedByDriverFullName)
                  ? `/admin/users?${QUERY_PARAMS.userId}=${completedByDriverId}`
                  : `/drivers?${QUERY_PARAMS.driverId}=${completedByDriverId}`
              }
            >
              {completedByDriverFullName}
            </a>
          </>
        )}
      </strong>
    )
  }

  if (!driver) {
    return <strong>No assigned driver</strong>
  }

  return (
    <div>
      <Tooltip
        content={<h5>{driver.phoneNumber}</h5>}
        openOnTargetFocus={false}
        placement="left"
        usePortal={false}
      >
        <strong>
          Assigned driver is{' '}
          <a href={`/drivers/${driver.id}`}>
            {driver.firstName
              ? driver.firstName.concat(' ', driver.lastName || '')
              : driver.phoneNumber}
          </a>
        </strong>
      </Tooltip>
    </div>
  )
}
