import { useMutation } from '@apollo/client'
import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core'
import { DialogBody, DialogFooter } from '@blueprintjs/core'
import { useFormik } from 'formik'
import React, { useContext } from 'react'
import { ToastType, useToaster } from '../../hooks/useToaster'
import { RESET_PASSWORD } from '../Admin/Users/gql/resetPassword'
import { CurrentUserContext } from '../../providers/CurrentUserProvider'
import { UserActionType } from '../../providers/currentUserState'
import { PasswordField } from '../../components/FormElements/PasswordField'
import { useParams, useHistory } from 'react-router-dom'
import { SessionTokenContext } from '../../providers/SessionTokenProvider'

export const ResetPasswordForm = ({
  closeContainer,
}: {
  closeContainer?: Function
}) => {
  const { key } = useParams<{ key?: string }>()
  const history = useHistory()
  const { deleteStoredTokens } = useContext(SessionTokenContext)
  const openErrorToast = useToaster({ type: ToastType.ERROR })
  const openSuccessToast = useToaster({ type: ToastType.SUCCESS })
  const { dispatch } = useContext(CurrentUserContext)

  const [resetPassword, { loading }] = useMutation(RESET_PASSWORD, {
    onError: error => {
      openErrorToast(error.message)
    },
    onCompleted: data => {
      if (data.resetPassword) {
        openSuccessToast(
          'Password sucessfully updated. Please login using the new credentials'
        )
        deleteStoredTokens()
        dispatch({ type: UserActionType.LOGOUT })
      } else {
        openErrorToast(
          'There was an error updating the password. Please try again later'
        )
      }
    },
  })

  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      key: key || '',
      newPassword: '',
    },
    onSubmit: async ({ key, newPassword }) => {
      await resetPassword({
        variables: {
          input: {
            key,
            newPassword,
          },
        },
      })
    },
  })

  return (
    <DialogBody>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <InputGroup
            large
            minLength={7}
            name="key"
            value={values.key}
            intent={errors.key ? Intent.DANGER : Intent.NONE}
            onChange={handleChange}
            placeholder="The reset code received"
          />
        </FormGroup>

        <FormGroup helperText="New password">
          <PasswordField
            large
            name="newPassword"
            value={values.newPassword}
            intent={errors.newPassword ? Intent.DANGER : Intent.NONE}
            onChange={handleChange}
          />
        </FormGroup>

        <DialogFooter>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              minimal
              onClick={() => {
                if (closeContainer) {
                  closeContainer()
                } else if (key) {
                  history.push('/login')
                } else {
                  dispatch({ type: UserActionType.LOGOUT })
                }
              }}
            >
              Cancel
            </Button>
            <Button intent={Intent.PRIMARY} type="submit" disabled={loading}>
              Update
            </Button>
          </div>
        </DialogFooter>
      </form>
    </DialogBody>
  )
}
