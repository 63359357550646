import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const LIST_JOBS = typedGql('query')({
  paginatedJobs: [
    {
      where: $('where', 'JobWhereInput'),
      orderBy: $('orderBy', 'JobOrderByInput'),
      skip: $('skip', 'Int'),
      take: $('take', 'Int'),
    },
    {
      count: true,
      jobs: {
        id: true,
        jobReference: true,
        stackId: true,
        createdAt: true,
        updatedAt: true,
        estimatedPickupTime: true,
        estimatedDurationMinutes: true,
        status: true,
        stackStatus: true,
        onHold: true,
        fleetId: true,
        orderContents: true,
        orderReference: true,
        orderId: true,
        orderIsAsap: true,
        finishedAssignment: true,
        pickupTime: true,
        completionTime: true,
        completedByDriverId: true,
        completedByDriverFullName: true,
        canceledBy: true,
        reasonForCancellation: true,
        driver: {
          id: true,
          firstName: true,
          lastName: true,
          phoneNumber: true,
        },
        fleet: {
          id: true,
          name: true,
        },
      },
    },
  ],
})
