import { Icon, InputGroup } from '@blueprintjs/core'
import React, { VFC } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'

type SearchByProps = {
  paramName: string
  minChars?: number
  placeholder: string
  showIcon?: boolean
  disabled?: boolean
}

/**
 * Generic search by component. Note that it
 * uses the `useQueryParam` hook to propagate changes,
 * thus the parent or a HOC is in charge of consuming them,
 * using the same hook.
 */
export const SearchBy: VFC<SearchByProps> = ({
  paramName,
  placeholder,
  minChars = 3,
  showIcon = true,
  disabled = false,
}) => {
  const [value, setValue] = useQueryParam(paramName, StringParam)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(
      e.target.value && e.target.value.length >= minChars
        ? e.target.value
        : undefined
    )
  }
  return (
    <InputGroup
      leftIcon={showIcon ? <Icon icon="search" /> : undefined}
      placeholder={placeholder}
      onChange={handleChange}
      disabled={disabled}
      value={value ?? undefined}
      autoFocus
    />
  )
}
