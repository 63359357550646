import React, { FunctionComponent, createContext, useState } from 'react'

type SessionTokenType = {
  token: string | undefined | null
  setStoredToken: (_token: string) => void
  deleteStoredTokens: () => void
}

export const SessionTokenContext = createContext<SessionTokenType>({
  token: undefined,
  setStoredToken: () => {},
  deleteStoredTokens: () => {},
})

export const TOKEN_KEY_NAME = 'wayboxJwtToken'

export const SessionTokenProvider: FunctionComponent = ({ children }) => {
  const [token, setToken] = useState<string | undefined | null>(
    localStorage.getItem(TOKEN_KEY_NAME)
  )

  const setStoredToken = (newToken: string) => {
    localStorage.setItem(TOKEN_KEY_NAME, newToken)
    setToken(newToken)
  }

  const deleteStoredTokens = () => {
    localStorage.clear()
  }

  return (
    <SessionTokenContext.Provider
      value={{ token, setStoredToken, deleteStoredTokens }}
    >
      {children}
    </SessionTokenContext.Provider>
  )
}
