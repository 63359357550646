import { NonIdealState, Spinner } from '@blueprintjs/core'
import React, { VFC } from 'react'
import { useQuery } from '@apollo/client'
import { SUPPLEMENTARY_DATA } from '../gql/supplementaryData'
import { DriversTable } from './DriversTable'
import { LIST_DRIVERS_RESPONSE } from '../gql/listDrivers'
import { EmptyListState } from '../../../components/NonIdealState/NonIdealState'

export const DriversDetails: VFC<{
  drivers: LIST_DRIVERS_RESPONSE['drivers']
}> = ({ drivers }) => {
  if (!drivers || !drivers.length)
    return <EmptyListState entityName="drivers" />

  const { loading, error, data } = useQuery(SUPPLEMENTARY_DATA, {
    variables: {
      driverIds: drivers.map(driver => driver.id) as never,
    },
    fetchPolicy: 'network-only',
    pollInterval: 5000,
  })

  if (loading) return <Spinner />
  if (error)
    return (
      <NonIdealState
        icon="error"
        description="There was an error while getting supplementary data"
        title="Error"
      />
    )

  const jobs = data?.jobs

  if (!jobs)
    return (
      <NonIdealState
        icon="panel-table"
        title="No Data"
        description="No supplimentary data found for the selected categories"
      />
    )

  return <DriversTable drivers={drivers} jobs={jobs} />
}
