import React, { useContext } from 'react'
import { TableContainer, Table } from './Table.styles'
import { PageLayoutContext } from '../../providers/PageLayoutProvider'

interface IBaseTableViewProps {
  children?: JSX.Element | JSX.Element[]
  interactive?: boolean
}

export const TableView = ({
  children,
  interactive = true,
}: IBaseTableViewProps) => {
  const { darkMode } = useContext(PageLayoutContext)
  return (
    <TableContainer>
      <Table darkMode={darkMode} interactive={interactive}>
        {children}
      </Table>
    </TableContainer>
  )
}
