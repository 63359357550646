import { Spinner } from '@blueprintjs/core'
import React from 'react'
import { ModelTypes } from '../../zeus'
import { StringParam, useQueryParam } from 'use-query-params'
import { QUERY_PARAMS } from '../../utils/queryParamsNames'
import { BOOKED_AND_AVAILABLE_OPTIONS_FOR_DRIVER_STATUS } from '../../pages/Drivers/gql/bookedAndAvailableOptionsForDriverStatus'
import { useMutation, useQuery } from '@apollo/client'
import { ToastType, useToaster } from '../../hooks/useToaster'
import { OptionsForStatus } from '../OptionsForStatus'
import { SET_DRIVER_STATUS_FROM_OPTION } from '../../pages/Drivers/gql/setDriverStatusFromOption'
import { LIST_DRIVERS } from '../../pages/Drivers/gql/drivers'
import { DRIVER_BY_ID } from '../../pages/Drivers/gql/driverById'
import { REMOVE_DRIVER_STATUS_OPTION } from '../../pages/Drivers/gql/removeDriverStatusOption'

export const DriverStatusOptionsSelect = () => {
  const [driverId] = useQueryParam(QUERY_PARAMS.driverId, StringParam)
  const showSuccessToast = useToaster({ type: ToastType.SUCCESS })
  const showErrorToast = useToaster({ type: ToastType.ERROR })

  const openErrorToast = useToaster({ type: ToastType.ERROR })

  const { data, loading, error } = useQuery(
    BOOKED_AND_AVAILABLE_OPTIONS_FOR_DRIVER_STATUS,
    {
      variables: {
        driverId: driverId!,
      },
      fetchPolicy: 'network-only',
      skip: !driverId,
    }
  )

  const [setOption] = useMutation(SET_DRIVER_STATUS_FROM_OPTION, {
    refetchQueries: [
      LIST_DRIVERS,
      {
        query: DRIVER_BY_ID,
        variables: { driverId },
      },
      {
        query: BOOKED_AND_AVAILABLE_OPTIONS_FOR_DRIVER_STATUS,
        variables: { driverId },
      },
    ],
  })

  const [removeOption] = useMutation(REMOVE_DRIVER_STATUS_OPTION, {
    refetchQueries: [
      LIST_DRIVERS,
      {
        query: DRIVER_BY_ID,
        variables: { driverId },
      },
      {
        query: BOOKED_AND_AVAILABLE_OPTIONS_FOR_DRIVER_STATUS,
        variables: { driverId },
      },
    ],
  })

  const handleClick = (option: ModelTypes['OptionForDriverStatus']) => {
    if (option.booked) {
      // remove it
      removeOption({
        variables: {
          driverId: driverId!,
          option,
        },
        onError: err => {
          showErrorToast(err.message ?? 'Could not remove option')
        },
        onCompleted: response => {
          if (response?.removeDriverStatusOption) {
            // all good
            showSuccessToast('Option removed')
          } else {
            // mosty likely the option expired
            showErrorToast('Could not remove option, please try again later')
          }
        },
      })
    } else {
      // add it
      setOption({
        variables: {
          driverId: driverId!,
          option,
        },
        onError: err => {
          showErrorToast(err.message ?? 'Could not add option')
        },
        onCompleted: response => {
          if (response?.setDriverStatusFromOption) {
            // all good
            showSuccessToast('Option added')
          } else {
            // mosty likely the option expired
            showErrorToast(
              'Could not add option, it most likely expired. Please try again.'
            )
          }
        },
      })
    }
  }

  if (loading) {
    return <Spinner />
  }

  if (error) {
    openErrorToast(error.message ?? 'Error loading driver status options')
    return null
  }

  if (!data?.bookedAndAvailableOptionsForDriverStatus) {
    return null
  }

  return (
    <div style={{ padding: '10px' }}>
      <OptionsForStatus
        flexDirection="column"
        options={data.bookedAndAvailableOptionsForDriverStatus}
        showActiveOption
        showInactiveOption
        handleClick={handleClick}
        rightIcon="tick"
      />
    </div>
  )
}
