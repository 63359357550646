import { HTMLSelect } from '@blueprintjs/core'
import React from 'react'
import { JobStatus } from '../../zeus'
import { BaseSelectProps } from './types'

export const JobStatusSelect = (props: BaseSelectProps) => {
  const statuses: JobStatus[] = [
    JobStatus.ALMOST_AT_DROP_OFF,
    JobStatus.ALMOST_AT_PICKUP,
    JobStatus.ASSIGNED,
    JobStatus.AWAITING_ASSIGNMENT,
    JobStatus.CANCELLED,
    JobStatus.COMPLETE,
    JobStatus.EXCEPTION,
    JobStatus.PENDING,
    JobStatus.TRAVELLING_TO_DROP_OFF,
    JobStatus.TRAVELLING_TO_PICKUP,
    JobStatus.WAITING_AT_DROP_OFF,
    JobStatus.WAITING_AT_PICKUP,
  ]
  const statusesEntries = statuses.map(status => ({
    value: status,
    label: status,
  }))

  return (
    <HTMLSelect
      {...props}
      options={[{ value: '', label: 'All' }, ...statusesEntries]}
    />
  )
}
