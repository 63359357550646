import { typedGql } from '../../../../zeus/typedDocumentNode'
import { $ } from '../../../../zeus'

export const UPDATE_PASSWORD = typedGql('mutation')({
  updatePassword: [
    {
      input: $('input', 'UserUpdatePasswordInput!'),
    },
    true,
  ],
})
