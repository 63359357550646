import { useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import { IBaseDialogProps } from './types'
import React from 'react'
import {
  FormGroup,
  InputGroup,
  Intent,
  Button,
  Spinner,
  Classes,
} from '@blueprintjs/core'
import { DialogBody, DialogFooter } from '@blueprintjs/core'
import { API_IMPLEMENTATIONS_BY_FLEET } from '../pages/Fleets/gql/apiImplementationsByFleet'
import { CREATE_API_IMPLEMENTATION } from '../pages/Fleets/gql/createApiImplementation'

export const GenerateApiKeyDialog: React.FC<
  {
    fleetId: string
  } & IBaseDialogProps
> = ({ closeDialog, fleetId }) => {
  const [createApiImplementation] = useMutation(CREATE_API_IMPLEMENTATION, {
    refetchQueries: [API_IMPLEMENTATIONS_BY_FLEET],
  })

  const { values, handleChange, handleSubmit, errors, isSubmitting } =
    useFormik({
      initialValues: {
        name: '',
        apiKey: '',
      },
      onSubmit: async values => {
        await createApiImplementation({
          variables: {
            name: values.name,
            apiKey: values.apiKey,
            fleetId,
          },
        })

        closeDialog()
      },
    })

  return (
    <>
      <DialogBody>
        <form onSubmit={handleSubmit}>
          <FormGroup label="Name">
            <InputGroup
              name="name"
              onChange={handleChange}
              intent={errors.name ? Intent.DANGER : Intent.NONE}
              value={values.name}
              data-testid="name-input"
              large
            />
          </FormGroup>
          <FormGroup label="API Key">
            <InputGroup
              name="apiKey"
              onChange={handleChange}
              intent={errors.apiKey ? Intent.DANGER : Intent.NONE}
              value={values.apiKey}
              data-testid="api-key-input"
              large
            />
          </FormGroup>
          <DialogFooter>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={closeDialog}>Cancel</Button>
              <Button
                intent={Intent.PRIMARY}
                type="submit"
                icon={isSubmitting && <Spinner size={20} />}
              >
                Save
              </Button>
            </div>
          </DialogFooter>
        </form>
      </DialogBody>
    </>
  )
}
