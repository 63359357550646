import { Popover, Icon, Intent, Divider } from '@blueprintjs/core'
import { useDraggable } from '@dnd-kit/core'
import { format, addMinutes } from 'date-fns'
import React from 'react'
import { Link } from 'react-router-dom'
import { ModelTypes } from '../../../zeus'
import { PostponeJob } from './PostponeJob'
import {
  JobActions,
  JobAndDraggableWrapper,
  JobContainer,
  PopoverContentContainer,
  PopoverContentContainerRow,
  UnstyledParagraph,
} from './styles'
import { SCALING_FACTOR } from './Timeline'

export type JobBlockProps = Pick<
  ModelTypes['Job'],
  'id' | 'estimatedPickupTime' | 'estimatedDurationMinutes' | 'price'
> & {
  startingTimeInMillisec?: number
  isExceptionJob?: boolean
  isLastInQueue?: boolean
  stacked?: boolean
  additionalLeftShift?: number
}

export const JobBlock = ({
  id,
  estimatedDurationMinutes,
  estimatedPickupTime,
  price,
  startingTimeInMillisec,
  isExceptionJob,
  isLastInQueue,
  stacked = false,
  additionalLeftShift = 0,
}: JobBlockProps) => {
  const isTheJobInThePast =
    new Date(estimatedPickupTime).getTime() < new Date().getTime()
  const { setNodeRef, transform, attributes, listeners } = useDraggable({
    id,
  })
  // size based on duration
  const width = estimatedDurationMinutes * SCALING_FACTOR

  let left: number | undefined = undefined
  let pickupInMillisec = 0
  if (startingTimeInMillisec) {
    // conditionally position based on start time
    // a negative diff -> pickup was in the past
    pickupInMillisec = new Date(estimatedPickupTime || 0).getTime()
    const diffInMillisec = pickupInMillisec - startingTimeInMillisec
    left = Math.floor(diffInMillisec / 1000 / 60) * SCALING_FACTOR
  }

  // apply additional left shift
  if (left) {
    left = left - Math.floor(additionalLeftShift) * SCALING_FACTOR
  }

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined

  return (
    <JobAndDraggableWrapper left={stacked ? undefined : left}>
      <div id={id} ref={isTheJobInThePast ? null : setNodeRef} style={style}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <JobContainer width={width} isTheJobInThePast={isTheJobInThePast}>
            <Popover
              children={<Icon icon="info-sign" style={{ cursor: 'pointer' }} />}
              content={
                <PopoverContentContainer>
                  {isTheJobInThePast && (
                    <>
                      <PopoverContentContainerRow>
                        <strong>
                          Job is in the past! To assign it you need to postpone
                          it
                        </strong>
                      </PopoverContentContainerRow>
                      <Divider />
                    </>
                  )}
                  <PopoverContentContainerRow>
                    {format(new Date(estimatedPickupTime), 'HH:mm')}
                    <Icon icon="direction-right" intent={Intent.PRIMARY} />
                    {format(
                      addMinutes(
                        new Date(estimatedPickupTime),
                        estimatedDurationMinutes
                      ),
                      'HH:mm'
                    )}
                  </PopoverContentContainerRow>
                  <Divider />
                  <PopoverContentContainerRow>
                    <strong>Price £{price / 100}</strong>
                    <Link to={`/jobs?jobId=${id}`}>
                      <Icon icon="link" intent={Intent.PRIMARY} />
                    </Link>
                  </PopoverContentContainerRow>
                </PopoverContentContainer>
              }
            />
            <UnstyledParagraph>
              {Math.round(estimatedDurationMinutes)} min
            </UnstyledParagraph>
            <JobActions>
              <Icon
                icon="double-caret-vertical"
                {...listeners}
                {...attributes}
                style={{ cursor: 'grab' }}
              />
              <PostponeJob
                id={id}
                estimatedPickupTime={estimatedPickupTime}
                isExceptionJob={isExceptionJob}
                isLastInQueue={isLastInQueue}
              />
            </JobActions>
          </JobContainer>
        </div>
      </div>
    </JobAndDraggableWrapper>
  )
}
