import { typedGql } from '../../../zeus/typedDocumentNode'
import { $ } from '../../../zeus'

export const JOBS_WITH_EXCEPTIONS_BY_FLEET = typedGql('query')({
  exceptionQueue: [
    {
      fleetId: $('fleetId', 'String!'),
    },
    {
        id: true,
        jobReference: true,
        stackId: true,
        createdAt: true,
        updatedAt: true,
        estimatedPickupTime: true,
        pickupTime: true,
        status: true,
        onHold: true,
        orderReference: true,
        orderId: true,
        orderIsAsap: true,
        driver: {id: true, firstName: true, lastName: true},
        fleet: {name: true},

    },
  ],
})