import { format, addMinutes } from 'date-fns'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { EllipsisText } from '../../../atomics/EllipsisText'
import { EmptyListState } from '../../../components/NonIdealState/NonIdealState'
import { TableView } from '../../../components/Views/Table'
import {
  DRIVERS_AND_THEIR_JOBS,
  DRIVERS_AND_THEIR_JOBS_RESPONSE,
} from '../gql/listDriversWithJobs'
import useResizeObserver from 'use-resize-observer'
import { range } from 'lodash'
import { Explanation } from '../../../atomics/Explanation'
import { IconSize, Intent } from '@blueprintjs/core'
import { DriverStatus, JobStatus, ModelTypes } from '../../../zeus'
import {
  JobBlockSpacer,
  TDContainer,
  TimeBlock,
  UnassignedJobsContainer,
} from './styles'
import {
  DndContext,
  DragEndEvent,
  rectIntersection,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core'
import { DroppableTR } from '../../../components/Dnd/DroppableTR'
import { JobBlock } from './JobBlock'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { EXCEPTION_QUEUE_AND_ASSIGNED_JOBS } from '../gql/exceptionQueueAndAssignedJobs'
import { ShadowJob } from './ShadowJob'
import { ToastType, useToaster } from '../../../hooks/useToaster'
import { ASSIGN_JOB_TO_DRIVER } from '../../Jobs/gql/assignJobToDriver'
import { COLLISIONS_OR_STACKABILITY } from '../gql/collisions'
import { JobsRow } from './JobsRow'

export const SCALING_FACTOR = 20 // -> 1 min = 20px
const GUIDE_SIZE_IN_MINUTES = 15
const BLOCK_SIZE = GUIDE_SIZE_IN_MINUTES * SCALING_FACTOR

type TimelineProps = {
  drivers?: DRIVERS_AND_THEIR_JOBS_RESPONSE['drivers']
  leadTime?: number
  fleetId?: string
}

export const Timeline = ({ drivers, leadTime, fleetId }: TimelineProps) => {
  if (!leadTime || !fleetId) {
    return <EmptyListState entityName="fleet" />
  }

  const openSuccessToast = useToaster({ type: ToastType.SUCCESS })
  const openErrorToast = useToaster({ type: ToastType.ERROR })

  const [draggedJobId, setDraggedJobId] = useState<UniqueIdentifier | null>(
    null
  )
  const [possibleParentId, setPossibleParentId] =
    useState<UniqueIdentifier | null>(null)

  const { data } = useQuery(EXCEPTION_QUEUE_AND_ASSIGNED_JOBS, {
    variables: {
      fleetId,
      jobsWhere: {
        AND: [
          { fleetId: { equals: fleetId } },
          { status: { in: [JobStatus.ASSIGNED] } },
        ],
      },
    },
    pollInterval: 1000 * 30, // 30 sec
    fetchPolicy: 'network-only',
  })

  const [getCollisionsOrStackability, { data: colissionsOrStackabilityData }] =
    useLazyQuery(COLLISIONS_OR_STACKABILITY)
  const [assignJob] = useMutation(ASSIGN_JOB_TO_DRIVER)

  // used to prevent re-assigning the same job to the same driver
  const jobIdToCurrentDriverIdMap =
    data?.jobs.reduce<Record<string, string>>((acc, job) => {
      if (job.driverId) {
        acc[job.id] = job.driverId
      }

      return acc
    }, {}) || {}

  const draggedJob = draggedJobId
    ? data?.exceptionQueue.find(job => job.id === draggedJobId) ||
      data?.jobs.find(job => job.id === draggedJobId)
    : undefined

  const { ref: tDataRef, width = 0 } = useResizeObserver<HTMLElement>()

  const getStartingTime = (): Date => {
    const now = new Date()
    // we use GUIDE_SIZE_IN_MINUTES (15 min) as visual guides
    const remainder = now.getMinutes() % GUIDE_SIZE_IN_MINUTES
    if (remainder !== 0) {
      now.setMinutes(now.getMinutes() - remainder)
      return now
    }

    return now
  }

  const renderTimeLegend = () => {
    if (!width) return null

    const startingTime = getStartingTime()
    // plus one to allow overflow
    const intervalsLength = Math.ceil(width / BLOCK_SIZE)

    return (
      <div style={{ display: 'flex', maxWidth: `${width}px` }}>
        {range(intervalsLength).map(position => (
          <TimeBlock
            minWidth={intervalsLength - 1 === position ? 0 : BLOCK_SIZE}
            key={position}
          >
            {format(
              addMinutes(startingTime, position * GUIDE_SIZE_IN_MINUTES),
              'HH:mm'
            )}
          </TimeBlock>
        ))}
      </div>
    )
  }

  const renderLeadTimeGuides = () => {
    // TODO - implement, this should be altering the style of the TDContainer
    // https://korelogic.atlassian.net/browse/DIS-147
    return null
  }

  const attemptAssignJobToDriver = async ({
    driverId,
    jobId,
  }: {
    driverId: UniqueIdentifier | null
    jobId: UniqueIdentifier | null
  }) => {
    if (
      !jobId ||
      !driverId ||
      !colissionsOrStackabilityData?.collisionsOrStackability
    )
      return

    const { jobsOverlap, isPlausible, isStackable } =
      colissionsOrStackabilityData.collisionsOrStackability
    if (!jobsOverlap && isPlausible) {
      await assignJob({
        variables: {
          input: {
            jobId: jobId as string,
            driverId: driverId as string,
          },
        },
        onError: err =>
          openErrorToast(`Could not assign job to driver, ${err.message}`),
        onCompleted: () => {
          openSuccessToast(
            'Job assigned. If the driver rejects the job it will be put in the exception queue.'
          )
        },
        refetchQueries: [
          EXCEPTION_QUEUE_AND_ASSIGNED_JOBS,
          DRIVERS_AND_THEIR_JOBS,
        ],
      })
    }
  }

  const handleDragStart = (event: DragStartEvent) => {
    setDraggedJobId(event.active.id)
  }

  const handleDragOver = (event: DragEndEvent) => {
    const { over } = event
    const newJob = draggedJob
    const currentParent = jobIdToCurrentDriverIdMap[newJob?.id as string]

    if (!over?.id) {
      // so parent doesn't remain set when moving away
      setPossibleParentId(null)
    }

    if (over?.id && newJob && over.id !== currentParent) {
      setPossibleParentId(over.id)

      getCollisionsOrStackability({
        variables: {
          destinationDriverId: over.id as string,
          jobId: newJob.id as string,
        },
      })
    }
  }

  const handleDragEnd = async () => {
    await attemptAssignJobToDriver({
      driverId: possibleParentId,
      jobId: draggedJobId,
    })
    setDraggedJobId(null)
    setPossibleParentId(null)
  }

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      collisionDetection={rectIntersection}
    >
      <>
        {data?.exceptionQueue.length ? (
          <UnassignedJobsContainer>
            {data.exceptionQueue.map(unassignedJob => (
              <JobBlockSpacer key={unassignedJob.id}>
                <JobBlock {...unassignedJob} isExceptionJob />
              </JobBlockSpacer>
            ))}
          </UnassignedJobsContainer>
        ) : null}
        <TableView interactive={false}>
          <thead>
            <tr>
              <th style={{ width: '10%' }}>Driver</th>
              <th>Jobs queue, lead time is {leadTime}min</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td />
              <TDContainer ref={tDataRef} blockSize={BLOCK_SIZE}>
                {renderTimeLegend()}
              </TDContainer>
            </tr>
            {drivers?.map(
              ({
                id: driverId,
                firstName,
                lastName,
                phoneNumber,
                allowJobRejection,
                jobs,
                status,
                breaks,
              }) => (
                <DroppableTR id={driverId} key={driverId}>
                  <td>
                    <Link to={`/drivers?driverId=${driverId}`}>
                      <EllipsisText>
                        {status ===
                          DriverStatus.NO_LONGER_RECEIVING_NEW_JOBS && (
                          <>
                            <Explanation
                              icon="warning-sign"
                              intent={Intent.WARNING}
                              size={IconSize.STANDARD}
                              content="Driver is on his last job"
                            />{' '}
                          </>
                        )}
                        {allowJobRejection ? (
                          <>
                            <Explanation
                              icon="pivot"
                              intent={Intent.DANGER}
                              size={IconSize.STANDARD}
                              content="Driver can accept / reject jobs"
                            />{' '}
                          </>
                        ) : null}

                        {firstName && lastName
                          ? firstName.concat(' ', lastName)
                          : phoneNumber}
                      </EllipsisText>
                    </Link>
                  </td>
                  <TDContainer blockSize={BLOCK_SIZE} clip>
                    <JobsRow
                      jobs={jobs}
                      startingTime={getStartingTime()}
                      key={driverId}
                      breaks={
                        breaks as
                          | ModelTypes['OptionForDriverStatus'][]
                          | undefined
                          | null
                      }
                    />
                    {draggedJob && possibleParentId === driverId && (
                      <ShadowJob
                        {...draggedJob}
                        startingTime={getStartingTime()}
                        onlyChild={!jobs.length}
                        collisions={
                          colissionsOrStackabilityData?.collisionsOrStackability
                        }
                      />
                    )}
                    {renderLeadTimeGuides()}
                  </TDContainer>
                </DroppableTR>
              )
            )}
          </tbody>
        </TableView>
      </>
    </DndContext>
  )
}
