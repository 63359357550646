import React from 'react'
import { Button, Popover, Position } from '@blueprintjs/core'
import { DatePicker } from '@blueprintjs/datetime'
import { add, endOfDay, sub } from 'date-fns'
import { isFuture } from 'date-fns/esm'
import { formatToCustomDayBoundary, YEARLESS_FORMAT } from './datetime'

type DaySelectorProps = {
  date: Date
  setDate: (date: Date) => void
  minimal?: boolean
  disabled?: boolean
  offsetHours?: number
}
export const DaySelector = ({
  date,
  setDate,
  minimal = false,
  disabled = false,
  offsetHours = 0,
}: DaySelectorProps) => {
  return (
    <>
      <Button
        rightIcon="caret-left"
        disabled={disabled}
        minimal={minimal}
        onClick={() => {
          setDate(sub(date, { days: 1 }))
        }}
      />
      <Popover
        position={Position.BOTTOM_LEFT}
        boundary={document.body}
        content={
          <DatePicker
            value={date}
            maxDate={endOfDay(new Date())}
            highlightCurrentDay
            onChange={(selectedDate, isUserChange) => {
              if (selectedDate && isUserChange) {
                setDate(selectedDate)
              }
            }}
          />
        }
      >
        <Button
          disabled={disabled}
          minimal={minimal}
          rightIcon="double-caret-vertical"
        >
          {formatToCustomDayBoundary({
            date,
            formatStr: YEARLESS_FORMAT,
            offsetHours,
          })}
          {'  '}
          <span className="bp5-text-disabled">to</span>
          {'  '}
          {formatToCustomDayBoundary({
            date: endOfDay(date),
            formatStr: YEARLESS_FORMAT,
            offsetHours,
          })}
        </Button>
      </Popover>
      <Button
        rightIcon="caret-right"
        minimal={minimal}
        onClick={() => {
          setDate(add(date, { days: 1 }))
        }}
        disabled={disabled || isFuture(add(date, { days: 1 }))}
      />
    </>
  )
}
