import { $ } from '../../../../zeus'
import { typedGql } from '../../../../zeus/typedDocumentNode'

export const INVITE_USER = typedGql('mutation')({
  inviteUser: [
    {
      input: $('input', 'UserInviteInput!'),
    },
    true,
  ],
})
