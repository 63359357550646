import React, { useContext } from 'react'
import {
  ButtonProps,
  Icon,
  IconName,
  Navbar,
  NavbarGroup,
} from '@blueprintjs/core'
import { PageLayoutTab } from './types'
import { TabBar } from './TabBar'
import {
  TopHeader,
  TopLeftLogo,
  TopRightButton,
  BreadcrumbsNav,
} from './Header.styles'
import { useLocation } from 'react-router-dom'
import { ReactSVG } from 'react-svg'
import wayboxLogo from '../../assets/waybox-logo-mark.svg'
import { startCase } from 'lodash'

interface IHeaderProps {
  tabs?: PageLayoutTab[]
  button?: ButtonProps
}

/// TODO dynamic breadcrumbs
export const Header = ({ tabs = [], button }: IHeaderProps) => {
  const location2 = useLocation() as any

  const { pathname = null, location } = location2

  return (
    <header>
      <TopHeader fixedToTop>
        <BreadcrumbsNav
          items={[
            {
              href: '/',
              icon: null,
              text: 'Waybox',
            },
            ...(pathname || location.pathname)
              .split('/')
              // @ts-ignore
              .map((path, i, arr) => {
                if (path === '') return false
                return {
                  // @ts-ignore
                  href: arr.filter((_, ii) => ii < i).join('/'),
                  icon: null,
                  text: startCase(path),
                }
              })
              .filter(Boolean),
          ]}
        />

        <NavbarGroup>
          {button && (
            <TopRightButton
              minimal
              icon={<Icon icon="add" color="black" />}
              {...button}
            />
          )}
        </NavbarGroup>
      </TopHeader>
      <TabBar tabs={tabs} />

      <div>
        <TopLeftLogo>
          <ReactSVG src={wayboxLogo} alt="waybox logo" />
        </TopLeftLogo>
      </div>
    </header>
  )
}
