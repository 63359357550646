import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const UPDATE_JOB_STATUS = typedGql('mutation')({
  updateJobStatus: [
    {
      input: $('input', 'UpdateJobStatusInput!'),
    },
    {
      id: true,
      status: true,
      stackStatus: true,
    },
  ],
})
