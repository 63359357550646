export type CurrentUser = {
  id: string
  firstName: string
  lastName: string
  role: string //keyof typeof UserRole
  email: string
  phoneNumber: string
  enabled: boolean
  userStatus: string //keyof typeof UserStatus
}

export enum UserActionType {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  // COMPLETE_SIGN_UP = 'COMPLETE_SIGN_UP',
  // VALIDATE_EMAIL = 'VALIDATE_EMAIL',
}

export interface CurrentUserState {
  currentUser: CurrentUser | null
  // needsConfirmation: boolean
  requestResetPassword: boolean // user has requested reset, a code is created
  resetPassword: boolean // use the generated reset password code
  // completeSignUp: boolean // new user added, password reset forced
  loggedIn: boolean
  loading: boolean // used on first add load before checking if user is logged in
  dispatch: (action: Action) => void
}

interface Action {
  type: UserActionType
  newState?: Partial<CurrentUserState>
}

export const defaultState: CurrentUserState = {
  currentUser: null,
  // needsConfirmation: false,
  requestResetPassword: false,
  resetPassword: false,
  // completeSignUp: false,
  loggedIn: false,
  loading: true,
  dispatch: () => {},
}

type ReducerFunc = (state: CurrentUserState, action: Action) => CurrentUserState

const reducers: {
  [key in UserActionType]: ReducerFunc
} = {
  LOGIN: (state, { newState }) => ({
    ...state,
    ...newState,
    loggedIn: true,
    loading: false,
    requestResetPassword: false,
    resetPassword: false,
    // needsConfirmation: false,
    // completeSignUp: false,
  }),

  LOGOUT: () => ({ ...defaultState }),

  REQUEST_RESET_PASSWORD: (state, { newState }) => ({
    ...state,
    ...newState,
    loggedIn: false,
    requestResetPassword: true,
    resetPassword: false,
    // needsConfirmation: false,
    // completeSignUp: false,
  }),
  RESET_PASSWORD: () => ({
    ...defaultState,
    requestResetPassword: false,
    resetPassword: true,
    // needsConfirmation: false,
    // completeSignUp: false,
  }),
  // COMPLETE_SIGN_UP: (state, { newState }) => ({
  //   ...state,
  //   ...newState,
  //   loggedIn: false,
  //   resetPassword: false,
  //   needsConfirmation: false,
  //   completeSignUp: true,
  // }),
  // VALIDATE_EMAIL: (state, { newState }) => ({
  //   ...state,
  //   ...newState,
  //   loggedIn: false,
  //   resetPassword: false,
  //   needsConfirmation: true,
  //   completeSignUp: false,
  // }),
}

export const reducerFunction: ReducerFunc = (state, action) => {
  // console.debug(
  //   `DEBUG reducers[${action.type}](${JSON.stringify({ type: action.type })})`,
  //   action
  // )

  return reducers[action.type] ? reducers[action.type](state, action) : state
}
