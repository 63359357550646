import { DriverStatus, ModelTypes } from '../../zeus'
import React from 'react'
import { OptionContainer, OptionsContainer } from './styles'
import { Icon, IconName, Tooltip } from '@blueprintjs/core'
import { BreaksTag, driverStatusColors } from '../../styles/status'
import { addMinutes, format } from 'date-fns'

type OptionsProps = {
  options?: ModelTypes['OptionForDriverStatus'][]
  flexDirection?: 'row' | 'column'
  showActiveOption?: boolean
  showInactiveOption?: boolean
  showMinimalLabel?: boolean
  handleClick?: Function
  rightIcon?: IconName
}

export const OptionsForStatus = ({
  options,
  flexDirection = 'row',
  showActiveOption = false,
  showInactiveOption = false,
  showMinimalLabel = false,
  handleClick,
  rightIcon,
}: OptionsProps) => (
  <OptionsContainer flexDirection={flexDirection}>
    {options
      ? options.map(option => (
          <OptionForDriverStatus
            key={option.id}
            option={option}
            showActiveOption={showActiveOption}
            showInactiveOption={showInactiveOption}
            showMinimalLabel={showMinimalLabel}
            flexDirection={flexDirection}
            handleClick={handleClick}
            rightIcon={rightIcon}
          />
        ))
      : null}
  </OptionsContainer>
)

export const OptionForDriverStatus = ({
  option,
  showActiveOption,
  showInactiveOption,
  showMinimalLabel,
  flexDirection,
  handleClick,
  rightIcon,
}: {
  option: ModelTypes['OptionForDriverStatus']
  showActiveOption: boolean
  showInactiveOption: boolean
  showMinimalLabel: boolean
  flexDirection?: 'row' | 'column'
  handleClick?: Function
  rightIcon?: IconName
}) => {
  if (
    (option.status === DriverStatus.ACTIVE && showActiveOption) ||
    (option.status === DriverStatus.INACTIVE && showInactiveOption)
  ) {
    return (
      <OptionContainer flexDirection={flexDirection}>
        {showMinimalLabel ? (
          <Tooltip
            content={`Starting at ${format(
              new Date(option.minStartTime || new Date()),
              'HH:mm'
            )}`}
          >
            <BreaksTag
              intent={driverStatusColors[option.status as DriverStatus].intent}
              onClick={() => handleClick?.(option)}
            >
              <span>{option.minimalLabel}</span>
              {rightIcon && option.booked && (
                <span>
                  {' '}
                  <Icon
                    icon={rightIcon}
                    onClick={() => handleClick?.(option)}
                  />
                </span>
              )}
            </BreaksTag>
          </Tooltip>
        ) : (
          <BreaksTag
            intent={driverStatusColors[option.status as DriverStatus].intent}
            onClick={() => handleClick?.(option)}
          >
            <span>{option.label}</span>
            {rightIcon &&
              option.status === DriverStatus.INACTIVE &&
              option.booked && (
                <span>
                  {' '}
                  <Icon
                    icon={rightIcon}
                    onClick={() => handleClick?.(option)}
                  />
                </span>
              )}
          </BreaksTag>
        )}
      </OptionContainer>
    )
  }

  if (!option.minStartTime || !option.durationInMinutes) {
    console.error(
      'Breaks.tsx: minStartTime or durationInMinutes is null',
      option
    )
    return null
  }

  return (
    <OptionContainer flexDirection={flexDirection}>
      {showMinimalLabel ? (
        <Tooltip
          content={`${format(
            new Date(option.minStartTime),
            'HH:mm'
          )} - ${format(
            addMinutes(new Date(option.minStartTime), option.durationInMinutes),
            'HH:mm'
          )}`}
        >
          <BreaksTag
            intent={driverStatusColors[option.status as DriverStatus].intent}
            onClick={() => handleClick?.(option)}
          >
            <span>{option.minimalLabel}</span>
            {rightIcon && option.booked && (
              <span>
                {' '}
                <Icon icon={rightIcon} onClick={() => handleClick?.(option)} />
              </span>
            )}
          </BreaksTag>
        </Tooltip>
      ) : (
        <BreaksTag
          intent={driverStatusColors[option.status as DriverStatus].intent}
          onClick={() => handleClick?.(option)}
        >
          <span>{option.label}</span>
          {rightIcon && option.booked && (
            <span>
              {' '}
              <Icon icon={rightIcon} onClick={() => handleClick?.(option)} />
            </span>
          )}
        </BreaksTag>
      )}
    </OptionContainer>
  )
}
