import { typedGql } from '../../../zeus/typedDocumentNode'
import { $ } from '../../../zeus'

export const REMOVE_DRIVER_STATUS_OPTION = typedGql('mutation')({
  removeDriverStatusOption: [
    {
      driverId: $('driverId', 'String!'),
      option: $('option', 'JSON!'),
    },
    true,
  ],
})
