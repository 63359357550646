import { useMutation } from '@apollo/client'
import { Button, Card, Classes, H4, Intent } from '@blueprintjs/core'
import { DialogFooter } from '@blueprintjs/core'
import React, { useContext } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { ToastType, useToaster } from '../hooks/useToaster'
import { DELETE_USER } from '../pages/Admin/Users/gql/deleteUser'
import { LIST_USERS } from '../pages/Admin/Users/gql/users'
import { QUERY_PARAMS } from '../utils/queryParamsNames'
import { CurrentUserContext } from '../providers/CurrentUserProvider'
import { SessionTokenContext } from '../providers/SessionTokenProvider'

export const DeleteUserDialog = ({
  closeContainer,
}: {
  closeContainer: Function
}) => {
  const openSuccessToast = useToaster({ type: ToastType.SUCCESS })
  const openErrorToast = useToaster({ type: ToastType.ERROR })

  const { currentUser } = useContext(CurrentUserContext)
  const { deleteStoredTokens } = useContext(SessionTokenContext)

  const [userId, setUserId] = useQueryParam(QUERY_PARAMS.userId, StringParam)
  const [deleteUser, { loading }] = useMutation(DELETE_USER, {
    refetchQueries: [LIST_USERS],
  })

  return (
    <Card style={{ width: '300px' }}>
      <H4>Delete User</H4>
      <p>
        Are you sure you want to delete the user? This <strong>cannot</strong>{' '}
        be undone.
      </p>
      <DialogFooter>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Cancel" minimal onClick={() => closeContainer()} />
          <Button
            text="Delete"
            intent={Intent.DANGER}
            disabled={loading}
            onClick={async () => {
              if (userId) {
                await deleteUser({
                  variables: { userId },
                  onError: err => {
                    openErrorToast(err.message)
                  },
                  onCompleted: response => {
                    openSuccessToast('User deleted')

                    if (response.deleteUser?.id === currentUser?.id) {
                      // user deleted himself, logout
                      deleteStoredTokens()
                      location.pathname = '/login'
                    } else {
                      closeContainer()
                      setUserId(null)
                    }
                  },
                })
              }
            }}
          />
        </div>
      </DialogFooter>
    </Card>
  )
}
