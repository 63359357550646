import React, { useEffect } from 'react'
import { FilterBar } from '../../../components/Filters/FilterBar'
import { FilterByFleet } from '../../../components/Filters/FilterByFleet'
import { FilterByDriverStatus } from '../../../components/Filters/FilterByDriverStatus'
import { HorizontalContainer, RightPanel, VerticalContainer } from '../styles'
import { useQuery } from '@apollo/client'
import { Spinner } from '@blueprintjs/core'
import { JobStatus, SortOrder } from '../../../zeus'
import { ErrorState } from '../../../components/NonIdealState/NonIdealState'
import { DASHBOARD_JOBS, DASHBOARD_JOBS_SUBSCRIPTION } from '../gql/jobs'
import { JobsMapSubsection } from './JobsMapSubsection'
import { JobsDetails } from './JobsDetails'

export const JobsDashboard = () => {
  const { loading, error, data, subscribeToMore } = useQuery(DASHBOARD_JOBS, {
    fetchPolicy: 'network-only',
    variables: {
      orderBy: {
        estimatedPickupTime: SortOrder.asc,
      },
      where: {
        status: {
          notIn: [JobStatus.COMPLETE, JobStatus.CANCELLED],
        },
      },
    },
  })

  useEffect(
    () =>
      subscribeToMore({
        document: DASHBOARD_JOBS_SUBSCRIPTION,
        variables: {
          orderBy: {
            estimatedPickupTime: SortOrder.asc,
          },
          where: {
            status: {
              notIn: [JobStatus.COMPLETE, JobStatus.CANCELLED],
            },
          },
        },
        updateQuery: (prevData, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prevData
          }

          const { jobs } = subscriptionData.data
          if (!jobs) {
            return prevData
          }

          // TODO I think it's safe to return new data directly
          return {
            ...prevData,
            ...subscriptionData.data,
          }
        },
      }),
    [subscribeToMore]
  )

  if (loading) return <Spinner />
  if (error) return <ErrorState entityName="jobs" />

  return (
    <VerticalContainer>
      {/* <FilterBar marginBottom="10px">
        <FilterByFleet />
        <FilterByStatus />
      </FilterBar> */}
      <HorizontalContainer>
        <JobsMapSubsection jobs={data?.jobs || []} />
        <RightPanel>
          <JobsDetails jobs={data?.jobs || []} />
        </RightPanel>
      </HorizontalContainer>
    </VerticalContainer>
  )
}
