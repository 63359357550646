import { useMutation, useQuery } from '@apollo/client'
import { Button, Card, Classes, Intent, Popover } from '@blueprintjs/core'
import { DialogFooter } from '@blueprintjs/core'
import { Map } from 'maplibre-gl'
import React, { FC, useContext, useState } from 'react'
import { MapSection } from '../../components/MapSection'
import {
  ErrorState,
  LoadingState,
} from '../../components/NonIdealState/NonIdealState'
import { ButtonsFooterContainer } from './styles'
import { createTasksAndRouteToBeDrawn } from '../../components/MapSection/createTasksToBeDrawn'
import { StatusAndDriver } from './StatusAndDriver'
import { DetailsSection } from './DetailsSection'
import { LIST_JOBS } from './gql/jobs'
import { GET_JOB } from './gql/job'
import { DELETE_JOB_AND_TASKS } from './gql/deleteJob'
import {
  PartialDropOffTask,
  PartialPickupTask,
} from '../../components/MapSection/popupUtils'
import { CurrentUserContext } from '../../providers/CurrentUserProvider'
import { UserRole } from '../../zeus'
import { UpdateJobStatusDialog } from '../../dialogs/UpdateJobStatus'
import { createRoute } from '../../components/MapSection/createRoute'
import { createStackedTasksToBeDrawn } from '../../components/MapSection/createStackedTasksToBeDrawn'
import { createLegRoute } from '../../components/MapSection/createLegRoute'
import { colors } from '../../styles/colors'
import { StringParam, useQueryParam } from 'use-query-params'
import { QUERY_PARAMS } from '../../utils/queryParamsNames'

export const JobDetails: FC<{
  jobId: string
  closeDrawer: () => void
}> = ({ jobId, closeDrawer }) => {
  const [stackId] = useQueryParam(QUERY_PARAMS.stackId, StringParam)
  const { currentUser } = useContext(CurrentUserContext)
  const [isOpen, setIsOpen] = useState(false)
  const [_idealRouteVisible, setIdealRouteVisible] = useState(false)
  const [deleteJobFn, { loading: deleteJobLoading, error: deleteJobError }] =
    useMutation(DELETE_JOB_AND_TASKS, {
      refetchQueries: [LIST_JOBS],
    })

  const handleDeleteJob = async (jobId: string) =>
    await deleteJobFn({
      variables: {
        jobId,
      },
    })

  const { data, loading, error } = useQuery(GET_JOB, {
    variables: {
      jobId,
      jobsWhere: {
        AND: [
          { id: { not: { equals: jobId } } },
          {
            AND: [
              { stackId: { equals: stackId } },
              { stackId: { not: { equals: null } } },
            ],
          },
        ],
      },
    },
    fetchPolicy: 'network-only',
  })

  if (loading) return <LoadingState entityName="job" />
  if (error || !data?.job) {
    return <ErrorState entityName="job" />
  }

  const { job, jobs: remainingJobsInTheStack } = data

  const drawTasks = (map: Map) =>
    map.on('load', () => {
      const { tasksMarkers, estimatedRoute } = createTasksAndRouteToBeDrawn({
        pickupTasks: job.pickupTasks as PartialPickupTask[],
        dropOffTasks: job.dropOffTasks as PartialDropOffTask[],
        route: job.route,
      })

      // draw tasks on map
      if (!tasksMarkers) {
        console.error('Attempted to draw a job with no tasks', job)
        return
      }
      tasksMarkers.forEach(marker => marker.addTo(map))

      if (remainingJobsInTheStack && remainingJobsInTheStack.length > 0) {
        // draw stacked tasks
        const stackedTasks = remainingJobsInTheStack.map(
          job => job.dropOffTasks[0]
        )
        const { tasksMarkers: stackedTasksMarkers } =
          createStackedTasksToBeDrawn({
            dropOffTasks: stackedTasks as PartialDropOffTask[],
          })
        if (stackedTasksMarkers) {
          stackedTasksMarkers.forEach(marker => marker.addTo(map))
        }
      }

      // draw route
      let driverRouteLayer: maplibregl.AnyLayer | undefined = undefined
      if (estimatedRoute) {
        const { id, source, layer } = estimatedRoute
        driverRouteLayer = layer

        map.addSource(id, source)
        map.addLayer(layer)
      }

      const {
        id: idealRouteId,
        source: idealRouteSource,
        layer: idealRouteLayer,
      } = createRoute({
        routeJSON: job.idealRoute as string,
        paint: {
          'line-color': colors.idealRouteColor,
          'line-width': colors.routeWidth,
        },
      })

      // ideal route
      map.addSource(idealRouteId, idealRouteSource)

      let legLayer: maplibregl.AnyLayer | undefined = undefined
      if (remainingJobsInTheStack && remainingJobsInTheStack.length > 0) {
        // higlight the current leg
        const {
          id: currentLegId,
          source: currentLegSource,
          layer: currentLegLayer,
        } = createLegRoute({
          routeJSON: job.route as string,
          legPosition: job.dropOffTasks[0].orderInStack,
        })
        legLayer = currentLegLayer
        map.addSource(currentLegId, currentLegSource)
        map.addLayer(currentLegLayer)
      }

      // add controls for routes
      map.addControl(
        {
          onAdd: () => {
            const container = document.createElement('button')
            container.id = 'route-custom-control'
            container.className = 'maplibregl-ctrl'
            container.type = 'button'
            // crate a span for the icon
            const icon = document.createElement('span')
            icon.className = 'bp5-icon-standard bp5-icon-eye-off'
            icon.id = 'idealRouteIcon'

            // add the icon to the button
            container.appendChild(icon)
            container.appendChild(document.createTextNode(' Ideal Route'))

            container.onclick = () => {
              setIdealRouteVisible(prevVal => {
                if (prevVal) {
                  icon.classList.remove('bp5-icon-eye-open')
                  icon.classList.add('bp5-icon-eye-off')
                  // the order in which the layers are removed and added is important
                  map.removeLayer(idealRouteLayer.id)
                  if (driverRouteLayer) map.addLayer(driverRouteLayer)
                  if (legLayer) map.addLayer(legLayer)
                } else {
                  icon.classList.remove('bp5-icon-eye-off')
                  icon.classList.add('bp5-icon-eye-open')
                  // the order in which the layers are removed and added is important
                  if (driverRouteLayer) map.removeLayer(driverRouteLayer.id)
                  if (legLayer) map.removeLayer(legLayer.id)
                  map.addLayer(idealRouteLayer)
                }
                return !prevVal
              })
            }

            return container
          },
          onRemove: () => {
            document.getElementById('route-custom-controls')?.remove()
          },
        },
        'top-left'
      )
    })

  return (
    <div
      style={{
        overflowY: 'auto',
      }}
    >
      <Card>
        <StatusAndDriver job={job} closeDrawer={closeDrawer} />
        <MapSection
          mapFunctionCallback={drawTasks}
          mapSize={{ height: '50vh', width: '100%' }}
          zoom={12}
        />
        <DetailsSection job={job} />
        {currentUser?.role === UserRole.ADMIN && (
          <ButtonsFooterContainer>
            <DialogFooter>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Popover
                  interactionKind="click"
                  isOpen={isOpen}
                  canEscapeKeyClose
                  onInteraction={state => setIsOpen(state)}
                  content={
                    <UpdateJobStatusDialog
                      job={job}
                      closeDialog={() => setIsOpen(false)}
                    />
                  }
                >
                  <Button
                    text="Update Status"
                    intent={Intent.WARNING}
                    minimal
                    onClick={() => setIsOpen(true)}
                  />
                </Popover>
                <Button
                  text="Delete"
                  intent={Intent.DANGER}
                  disabled={deleteJobLoading}
                  onClick={async () => {
                    await handleDeleteJob(job.id)
                    if (!deleteJobLoading && !deleteJobError) closeDrawer()
                  }}
                />
              </div>
            </DialogFooter>
          </ButtonsFooterContainer>
        )}
      </Card>
    </div>
  )
}

export const RoutesController: FC = () => {
  return null
}
