import { useLazyQuery } from '@apollo/client'
import React from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { FilterBar } from '../../../components/Filters/FilterBar'
import {
  EmptyListAwaitingUserInput,
  ErrorState,
  LoadingState,
} from '../../../components/NonIdealState/NonIdealState'
import { QUERY_PARAMS } from '../../../utils/queryParamsNames'
import { DriverStatus } from '../../../zeus'
import { FLEET_DETAILS } from '../gql/fleetDetails'
import { DRIVERS_AND_THEIR_JOBS } from '../gql/listDriversWithJobs'
import { Timeline } from './Timeline'
import { FleetSelect } from '../../../components/Select/FleetSelect'

export const JobsTimelineDashboard = () => {
  const [fleetId, setFleetId] = useQueryParam(QUERY_PARAMS.fleetId, StringParam)
  // fleet details
  const [
    fleetDetails,
    { loading: fleetDetailsLoading, error: fleetDetailsError, data: fleetData },
  ] = useLazyQuery(FLEET_DETAILS)

  // get active, onBreak, onLast delivery drivers, with jobs
  const [drivers, { loading, error, data }] = useLazyQuery(
    DRIVERS_AND_THEIR_JOBS,
    {
      pollInterval: 1000 * 30, // 30sec
      fetchPolicy: 'network-only',
      variables: {
        where: {
          AND: [
            {
              fleetDrivers: {
                some: {
                  fleetId: { equals: fleetId },
                },
              },
            },
            {
              OR: [
                { status: DriverStatus.ACTIVE },
                { status: DriverStatus.ON_BREAK },
                { status: DriverStatus.NO_LONGER_RECEIVING_NEW_JOBS },
              ],
            },
          ],
        },
      },
    }
  )

  if (loading || fleetDetailsLoading)
    return <LoadingState entityName={loading ? 'drivers' : 'fleet'} />

  if (error || fleetDetailsError)
    return <ErrorState entityName={error ? 'drivers' : 'fleet'} />

  return (
    <>
      <FilterBar>
        <FleetSelect
          onChange={e => {
            const id = e.target.value
            setFleetId(id)
            drivers()
            if (id) {
              fleetDetails({ variables: { id: e.target.value } })
            }
          }}
        />
        {/* <LeadTimeSwitch /> */}
      </FilterBar>
      {!fleetId ? (
        <EmptyListAwaitingUserInput message="Please select a fleet" />
      ) : (
        <Timeline
          drivers={data?.drivers}
          leadTime={fleetData?.fleet?.leadTime}
          fleetId={fleetData?.fleet?.id}
        />
      )}
    </>
  )
}
