import { useQuery } from '@apollo/client'
import { Button, MenuItem, Spinner } from '@blueprintjs/core'
import React from 'react'
import { ModelTypes, SortOrder } from '../../zeus'
import { LIST_FLEETS } from '../../pages/Fleets/gql/fleets'
import { StringParam, useQueryParam } from 'use-query-params'
import { QUERY_PARAMS } from '../../utils/queryParamsNames'
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select'

export interface Fleet {
  value: string
  label: string
}

interface FleetSelectProps {
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
}

const filterFleets: ItemPredicate<Fleet> = (
  query,
  fleet,
  _index,
  exactMatch
) => {
  const normalizedTitle = fleet.label.toLowerCase()
  const normalizedQuery = query.toLowerCase()

  if (exactMatch) {
    return normalizedTitle === normalizedQuery
  } else {
    return `${normalizedTitle} ${fleet.label}`.includes(normalizedQuery)
  }
}

const renderFleets: ItemRenderer<Fleet> = (
  fleet,
  { handleClick, handleFocus, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      onClick={handleClick}
      key={fleet.value}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={fleet.label}
    />
  )
}

export const FleetSelect: React.FC<FleetSelectProps> = ({ onChange }) => {
  const [fleetId, setFleetId] = useQueryParam(QUERY_PARAMS.fleetId, StringParam)
  const { data, loading } = useQuery(LIST_FLEETS, {
    variables: {
      OrderFleetsByNameDirecton: SortOrder.asc,
    },
  })

  const fleets =
    data?.fleets.map(
      ({ id, name }: Pick<ModelTypes['Fleet'], 'id' | 'name'>) => ({
        value: id,
        label: name,
      })
    ) || []

  const selectedFleet = fleets.find(fleet => fleet.value === fleetId)

  if (loading) {
    return <Spinner />
  }

  return (
    <Select
      onItemSelect={item => {
        setFleetId(item.value ? item.value : undefined)
        onChange({
          target: { value: item.value },
        } as React.ChangeEvent<HTMLSelectElement>)
      }}
      items={[{ value: '', label: 'Filter by fleet...' }, ...fleets]}
      itemPredicate={filterFleets}
      itemRenderer={renderFleets}
      noResults={
        <MenuItem
          disabled={true}
          text="No results."
          roleStructure="listoption"
        />
      }
    >
      <Button
        text={selectedFleet?.label ?? 'Filter by fleet'}
        rightIcon="double-caret-vertical"
      />
    </Select>
  )
}
