import React from 'react'
import { Button, MenuItem } from '@blueprintjs/core'
import { useQueryParam } from 'use-query-params'
import { SortOrder, ValueTypes } from '../../zeus'
import { QUERY_PARAMS } from '../../utils/queryParamsNames'
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select'

export interface OrderByJob {
  value: string
  label: string
}

const filterOrder: ItemPredicate<OrderByJob> = (
  query,
  status,
  _index,
  exactMatch
) => {
  const normalizedTitle = status.label.toLowerCase()
  const normalizedQuery = query.toLowerCase()

  if (exactMatch) {
    return normalizedTitle === normalizedQuery
  } else {
    return `${normalizedTitle} ${status.label}`.includes(normalizedQuery)
  }
}

const renderOrder: ItemRenderer<OrderByJob> = (
  orderBy,
  { handleClick, handleFocus, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      onClick={handleClick}
      key={orderBy.value}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={orderBy.label}
    />
  )
}

export const JOBS_ORDER_BY_VALUE_MAP: Record<
  string,
  ValueTypes['JobOrderByInput']
> = {
  created_desc: {
    createdAt: SortOrder.desc,
  },
  created_asc: {
    createdAt: SortOrder.asc,
  },
  pickup_desc: {
    estimatedPickupTime: SortOrder.desc,
  },
  pickup_asc: {
    estimatedPickupTime: SortOrder.asc,
  },
}

export const JOBS_ORDER_BY_MAP: Record<string, string> = {
  'Created - desc': 'created_desc',
  'Created - asc': 'created_asc',
  'Pickup Time - desc': 'pickup_desc',
  'Pickup Time - asc': 'pickup_asc',
}

export const JobsOrderBy = () => {
  const [orderBy, setOrderBy] = useQueryParam<undefined | any>(
    QUERY_PARAMS.jobsOrderby
  )

  const handleChange = (item: OrderByJob) => {
    setOrderBy(item.value ? item.value : undefined)
  }

  const statuses = Object.keys(JOBS_ORDER_BY_MAP).map(key => ({
    value: JOBS_ORDER_BY_MAP[key],
    label: key,
    selected: orderBy === key,
  }))

  const selectedStatus = statuses.find(item => item.value === orderBy)

  return (
    <Select
      filterable={false}
      onItemSelect={handleChange}
      items={[{ value: '', label: 'Order by' }, ...statuses]}
      itemPredicate={filterOrder}
      itemRenderer={renderOrder}
      noResults={
        <MenuItem
          disabled={true}
          text="No results."
          roleStructure="listoption"
        />
      }
    >
      <Button
        text={selectedStatus?.label ?? 'Order by'}
        rightIcon="double-caret-vertical"
      />
    </Select>
  )
}
