import React, { useEffect, useRef, useState } from 'react'
import { MapSection } from '../../../components/MapSection'
import { createDriversToBeDrawn } from '../../../components/MapSection/createDriversToBeDrawn'
import { ToastType, useToaster } from '../../../hooks/useToaster'
import { Map, Marker } from 'maplibre-gl'
import { StringParam, useQueryParam } from 'use-query-params'
import { useLazyQuery } from '@apollo/client'
import { createTasksAndRouteToBeDrawn } from '../../../components/MapSection/createTasksToBeDrawn'
import { JOBS_BY_DRIVER_ID } from '../gql/jobsByDriverId'
import { LIST_DRIVERS_RESPONSE } from '../gql/listDrivers'
import { JobStatus, ModelTypes } from '../../../zeus'
import {
  PartialDropOffTask,
  PartialPickupTask,
} from '../../../components/MapSection/popupUtils'
import { QUERY_PARAMS } from '../../../utils/queryParamsNames'
import { createStackedTasksToBeDrawn } from '../../../components/MapSection/createStackedTasksToBeDrawn'
import { CALCULATE_ROUTE } from '../gql/calculateRoute'
import { Location } from '../../../utils/location'
import {
  RouteType,
  createRoute,
} from '../../../components/MapSection/createRoute'
import { colors } from '../../../styles/colors'
import * as turf from '@turf/turf'

type ExtendedMarker = Marker & {
  setRotation?: (heading: number) => void
}

type PartialLocation = Pick<Location, 'longitude' | 'latitude'>

const DISTANCE_BETWEEN_RELATIVELY_DISTANT_POINTS_IN_MILES = 0.2

export const DriversMapSubsection = ({
  drivers,
}: {
  drivers: LIST_DRIVERS_RESPONSE['drivers']
}) => {
  if (!drivers) return null

  const openErrorToast = useToaster({ type: ToastType.ERROR })
  const [markers, setMarkers] = useState<ExtendedMarker[]>([])

  const [selectedDriver, setSelectedDriver] = useQueryParam(
    QUERY_PARAMS.selectedDriver,
    StringParam
  )
  const mapRef = useRef<Map | undefined>(undefined)
  const tasksMarkersRef = useRef<ExtendedMarker[] | undefined>(undefined)

  const [executeCalculateRoute, { data: additionalRouteData }] = useLazyQuery(
    CALCULATE_ROUTE,
    {
      fetchPolicy: 'network-only',
      onError: error => {
        openErrorToast(
          error.message ??
            'There was an error while calculating the additional route'
        )
      },
      onCompleted: ({ calculateRoute }) => {
        if (calculateRoute) {
          const { id, source, layer } = createRoute({
            routeJSON: calculateRoute,
            multiLayer: true,
            preferredId: 'additional-route',
            paint: {
              'line-color': colors.idealRouteColor,
              'line-width': colors.routeWidth,
            },
          })

          if (mapRef.current && !mapRef.current.getLayer('additional-route')) {
            mapRef.current.addSource(id, source)
            mapRef.current.addLayer(layer)
          }
        }
      },
    }
  )

  const [executeGetJobsByDriverId, { data }] = useLazyQuery(JOBS_BY_DRIVER_ID, {
    fetchPolicy: 'network-only',
    onError: error => {
      openErrorToast(error.message ?? 'There was an error while fetching jobs')
    },
    onCompleted: data => {
      const [mostRecentJob] = data.jobs

      // if JobStatus.TRAVELLING_TO_PICKUP, draw route towards pickup
      if (
        mostRecentJob &&
        mostRecentJob.driverId === selectedDriver &&
        mostRecentJob.status === JobStatus.TRAVELLING_TO_PICKUP
      ) {
        const currentDriver = drivers.find(
          driver => driver.id === selectedDriver
        )
        const { longitude: driverLongitude, latitude: driverLatitude } =
          currentDriver
            ? (currentDriver.currentLocation as PartialLocation)
            : {
                longitude: 0,
                latitude: 0,
              }

        const { longitude: pickupLongitude, latitude: pickupLatitude } =
          mostRecentJob.pickupTasks[0].location as PartialLocation

        // only do this expensive operation if the driver is relatively far away from the pickup
        const distanceBetweenDriverAndPickup = turf.distance(
          [driverLongitude, driverLatitude],
          [pickupLongitude, pickupLatitude],
          { units: 'miles' }
        )
        if (
          distanceBetweenDriverAndPickup >
          DISTANCE_BETWEEN_RELATIVELY_DISTANT_POINTS_IN_MILES
        ) {
          executeCalculateRoute({
            nextFetchPolicy: 'network-only',
            variables: {
              input: {
                pointA: {
                  longitude: driverLongitude,
                  latitude: driverLatitude,
                },
                pointB: {
                  longitude: pickupLongitude,
                  latitude: pickupLatitude,
                },
              },
            },
          })
        }
      }
    },
  })

  // moves the drivers on the map
  useEffect(() => {
    if (markers.length) {
      markers.forEach(marker => {
        const driverId = marker.getElement().id

        const { longitude, latitude, heading } =
          extractLngLatAndHeadingForDriver(driverId, drivers)

        if (longitude && latitude) {
          marker.setLngLat([longitude, latitude])
          marker.setRotation?.(heading)
        }
      })
    }

    // if JobStatus.TRAVELLING_TO_DROPOFF || ALMOST_AT_DROP_OFF
    // draw only the section of route towards dropoff and grey out the rest
    if (selectedDriver && data?.jobs.length) {
      const [latestJob] = data?.jobs || []
      if (
        latestJob &&
        (latestJob.status === JobStatus.TRAVELLING_TO_DROP_OFF ||
          latestJob.status === JobStatus.ALMOST_AT_DROP_OFF)
      ) {
        // first remove the already-done-route
        if (mapRef.current?.getLayer('already-done-route')) {
          mapRef.current?.removeLayer('already-done-route')
        }

        // current position of driver
        const currentDriver = drivers.find(
          driver => driver.id === selectedDriver
        )
        const { longitude: driverLongitude, latitude: driverLatitude } =
          currentDriver?.currentLocation as PartialLocation
        const route = JSON.parse(latestJob.route as string) as RouteType
        const line = route?.Legs[0].Geometry.LineString

        const lineString = turf.lineString(line)

        const snapped = turf.nearestPointOnLine(lineString, [
          driverLongitude,
          driverLatitude,
        ])
        const pointOnLine = snapped.geometry

        // snap the driver marker on that point if is under 0.1 miles away
        const markerDriver = markers.find(
          marker => marker.getElement().id === selectedDriver
        )
        if (markerDriver) {
          const markerCoordinates: maplibregl.LngLat = markerDriver.getLngLat()
          const distanceBetweenMarkerAndPointOnLine = turf.distance(
            [markerCoordinates.lng, markerCoordinates.lat],
            pointOnLine.coordinates as [number, number],
            { units: 'miles' }
          )

          if (
            distanceBetweenMarkerAndPointOnLine <
            DISTANCE_BETWEEN_RELATIVELY_DISTANT_POINTS_IN_MILES / 2
          ) {
            markerDriver.setLngLat(pointOnLine.coordinates as [number, number])
          }
        }

        // find the smallest distance between the pointOnLine and the line
        if (pointOnLine) {
          const closestPointIndex = line.reduce(
            (acc, curr, index) => {
              const distance = turf.distance(pointOnLine, curr)
              if (distance < acc.distance) {
                return { index, distance }
              }
              return acc
            },
            { index: 0, distance: Infinity }
          )

          const slicedLine = line.slice(0, closestPointIndex.index + 1)

          const { id, source, layer } = createRoute({
            arrayOfCoordinates: slicedLine,
            multiLayer: true,
            preferredId: 'already-done-route',
            paint: {
              'line-color': colors.alreadyDoneRouteColor,
              'line-width': colors.alreadyDoneRouteWidth,
            },
          })

          if (
            mapRef.current &&
            !mapRef.current.getLayer('already-done-route') &&
            id
          ) {
            mapRef.current.addSource(id, source)
            mapRef.current.addLayer(layer)
          }
        }
      }
    }
  }, [drivers, markers, data?.jobs, selectedDriver])

  // center map on the selected driver
  useEffect(() => {
    if (selectedDriver) {
      const { longitude, latitude } = extractLngLatAndHeadingForDriver(
        selectedDriver,
        drivers
      )

      if (longitude && latitude) {
        mapRef.current?.easeTo({
          center: [longitude, latitude],
          zoom: 14,
        })
      }
    }
  }, [selectedDriver])

  //  fetch active jobs for the selected driver
  useEffect(() => {
    if (selectedDriver) {
      executeGetJobsByDriverId({
        nextFetchPolicy: 'network-only',
        variables: {
          where: {
            driverId: {
              equals: selectedDriver,
            },
            status: {
              notIn: [
                JobStatus.COMPLETE,
                JobStatus.CANCELLED,
                JobStatus.PENDING,
              ],
            },
          },
        },
      })
    }
  }, [selectedDriver])

  if (data) {
    const [latestJob] = data.jobs

    if (latestJob) {
      const { tasksMarkers, estimatedRoute } = createTasksAndRouteToBeDrawn({
        pickupTasks: latestJob.pickupTasks as PartialPickupTask[],
        dropOffTasks: latestJob.dropOffTasks as PartialDropOffTask[],
        route: latestJob.route,
      })
      if (tasksMarkers && selectedDriver) {
        tasksMarkers.forEach(marker => {
          if (mapRef.current) {
            marker.addTo(mapRef.current)
          }
        })

        // update refs
        const oldMarkers = tasksMarkersRef.current ?? []
        tasksMarkersRef.current = [...oldMarkers, ...tasksMarkers]
      }

      if (estimatedRoute) {
        const { id, source, layer } = estimatedRoute
        if (mapRef.current && !mapRef.current.getLayer('route')) {
          mapRef.current.addSource(id, source)
          mapRef.current.addLayer(layer)
        }
      }

      if (latestJob.stackId) {
        // we should draw the entire stack
        const remainingDropOffTasksInTheStack = data.jobs
          .filter(
            job => job.stackId === latestJob.stackId && job.id !== latestJob.id
          )
          .map(job => job.dropOffTasks[0] as PartialDropOffTask)

        const { tasksMarkers } = createStackedTasksToBeDrawn({
          dropOffTasks: remainingDropOffTasksInTheStack,
          preserveMarkerOriginalSize: true,
        })
        if (mapRef.current && tasksMarkers) {
          tasksMarkers.forEach(marker => marker.addTo(mapRef.current!))

          // update refs
          const oldMarkers = tasksMarkersRef.current ?? []
          tasksMarkersRef.current = [...oldMarkers, ...tasksMarkers]
        }
      }

      // if JobStatus.WAITING_AT_PICKUP or JobStatus.WAITING_AT_DROP_OFF, add pulse effect to the marker
      if (
        latestJob &&
        (latestJob.status === JobStatus.WAITING_AT_PICKUP ||
          latestJob.status === JobStatus.WAITING_AT_DROP_OFF)
      ) {
        // find driver marker
        const driverMarker = markers.find(
          marker => marker.getElement().id === selectedDriver
        )
        const el = driverMarker?.getElement()
        el?.classList.add('pulse')
      } else {
        // remove the pulse effect
        const driverMarker = markers.find(
          marker => marker.getElement().id === selectedDriver
        )
        const el = driverMarker?.getElement()
        el?.classList.remove('pulse')
      }
    }
  } else {
    // no jobs
    tasksMarkersRef.current?.forEach(marker => {
      marker.remove()
    })
    tasksMarkersRef.current = undefined

    if (mapRef.current?.getLayer('route')) {
      mapRef.current?.removeLayer('route')
    }

    if (mapRef.current?.getLayer('additional-route')) {
      mapRef.current?.removeLayer('additional-route')
    }

    if (mapRef.current?.getLayer('already-done-route')) {
      mapRef.current?.removeLayer('already-done-route')
    }
  }

  const extractLngLatAndHeadingForDriver = (
    id: string,
    drivers: Pick<ModelTypes['Driver'], 'id' | 'currentLocation' | 'heading'>[]
  ) => {
    const driver = drivers.find(d => d.id === id)

    return {
      longitude: driver?.currentLocation?.longitude,
      latitude: driver?.currentLocation?.latitude,
      heading: driver?.heading || 0,
    }
  }

  const handleMarkerClick = (event: MouseEvent) => {
    const driverId = (event.target as HTMLElement).id
    setSelectedDriver(driverId)
  }

  const handlePopupClose = () => {
    setSelectedDriver(undefined)
    mapRef.current?.easeTo({ zoom: 12 })
  }

  const addCustomControl = () => {
    if (mapRef.current) {
      mapRef.current.addControl(
        {
          onAdd: () => {
            const container = document.createElement('button')
            container.id = 'custom-control'
            container.className = 'maplibregl-ctrl'
            container.type = 'button'
            container.textContent = 'Clear all tasks'
            container.onclick = () => {
              // remove tasks
              tasksMarkersRef.current?.forEach(marker => {
                marker.remove()
              })
              tasksMarkersRef.current = undefined

              if (mapRef.current?.getLayer('route')) {
                mapRef.current?.removeLayer('route')
              }

              if (mapRef.current?.getLayer('additional-route')) {
                mapRef.current?.removeLayer('additional-route')
              }

              if (mapRef.current?.getLayer('already-done-route')) {
                mapRef.current?.removeLayer('already-done-route')
              }
            }
            return container
          },
          onRemove: () => {
            document.getElementById('custom-control')?.remove()
          },
        },
        'bottom-right'
      )
    }
  }

  const drawDrivers = (map: Map) =>
    map.on('load', () => {
      mapRef.current = map
      const { driverMarkers } = createDriversToBeDrawn(drivers)
      if (!driverMarkers) {
        if (drivers.length > 0) {
          openErrorToast('There was an error while drawing drivers on the map')
        }
        return
      }

      driverMarkers.forEach(marker => {
        marker.getElement().addEventListener('click', handleMarkerClick)
        marker.getPopup().on('close', handlePopupClose)
        // the map can also rotate so we need to rotate the marker in relation to the map + the heading
        marker.setRotationAlignment('map')

        marker.addTo(map)
      })

      setMarkers(driverMarkers)
      addCustomControl()
    })

  return (
    <MapSection
      mapFunctionCallback={drawDrivers}
      zoom={12}
      mapSize={{
        height: 'calc(100vh - 96px)',
        width: 'calc(100vw)',
      }}
    />
  )
}
