import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { CurrentUserContext } from '../../providers/CurrentUserProvider'
import { PageLayoutContext } from '../../providers/PageLayoutProvider'
import { Nav } from '../Nav/Nav'
import { ErrorBoundary } from './ErrorBoundry'
import { Drawer, DrawerSize } from '@blueprintjs/core'
import { JobDetails } from '../../pages/Jobs/JobDetails'
import { useQueryParam, StringParam } from 'use-query-params'
import { QUERY_PARAMS } from '../../utils/queryParamsNames'

const MainBodyContainer = styled.section<{ darkMode?: boolean }>`
  position: relative;
  margin-left: 48px;
  top: 96px;
  height: 100vh;
  background-color: ${props => (props.darkMode ? '#2f343c' : '#ffffff')};
`

export const PageLayout = ({
  children,
}: {
  children: JSX.Element | JSX.Element[]
}) => {
  const [jobId, setJobId] = useQueryParam(QUERY_PARAMS.jobId, StringParam)
  const [_stackId, setStackId] = useQueryParam(
    QUERY_PARAMS.stackId,
    StringParam
  )

  const { currentUser } = useContext(CurrentUserContext)
  const { darkMode } = useContext(PageLayoutContext)

  if (!currentUser) {
    return <Redirect to="/login" />
  }

  return (
    <>
      <Nav />
      <main>
        <MainBodyContainer darkMode={darkMode}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </MainBodyContainer>

        <Drawer
          isOpen={!!jobId}
          title="Job Details"
          onClose={() => {
            setStackId(null)
            setJobId(null)
          }}
          size={DrawerSize.LARGE}
        >
          {jobId && (
            <JobDetails
              key={jobId}
              jobId={jobId}
              closeDrawer={() => setJobId(null)}
            />
          )}
        </Drawer>
      </main>
    </>
  )
}
