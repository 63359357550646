import { add, format, set, startOfDay } from 'date-fns'

export const YEARLESS_FORMAT = 'EEE dd MMM, HH:mm'
export const YEAR_FORMAT = 'EEE dd MMM yyyy, HH:mm'
export const ISO_FORMAT = `yyyy-MM-dd'T'HH:mm:ss'Z'`

type IsoWeekDayType = {
  [key: string]: 0 | 1 | 2 | 3 | 4 | 5 | 6
}
export const ISO_WEEK_DAY: IsoWeekDayType = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
}

type FormatToCustomDayBoundaryProps = {
  date: Date | number
  formatStr: string
  offsetHours?: number
}

/**
 * @description Some businesses treat different hours as the boundary between days instead of midnight.
 *   This function taxes a date, a format and an optional offset, modifies the hours by the specified amount.
 * @param dateFormatOffset Holds the date, format and an optional offset
 * @param {Date} dateFormatOffset.date This should only be the start or end of a date (ie T00:00:00.000 or T23:59:59.999)
 * @param {String} dateFormatOffset.formatStr This should be a string that can be passed to format from date-fns
 * @param {Number} dateFormatOffset.offsetHours This should be the number of hours to offset the date by
 * @returns {String} formatted date
 * @example
 *  formatToCustomDayBoundary({date: new Date('2020-07-01T00:00:00.000Z'), formatStr: YEARLESS_FORMAT, offsetHours: 4})
 *  // => 'Wed 01 Jul, 04:00'
 *  // Note that the date has been moved forward 4 hours
 *  // and the time is being displayed as if the date was local time not utc
 *  format(add(new Date('2020-07-01T00:00:00.000Z'), { hours: 4 }), YEARLESS_FORMAT)
 *  // would return 'Wed 01 Jul, 03:00' as the date has been moved forward 4 hours
 *  // but the time is being displayed in the local timezone
 *  // i hate timezones...
 */
export const formatToCustomDayBoundary = ({
  date,
  formatStr,
  offsetHours = 0,
}: FormatToCustomDayBoundaryProps) =>
  format(adjustDayBoundaryByOffset({ date, offsetHours }), formatStr)

type AdjustByRedboxDayBoundaryProps = {
  date: Date | number
  offsetHours?: number
}
/**
 * @description Some businesses treat different hours the boundary between days instead of midnight.
 *  This function takes a date, moves the date forwards the number of hours specified and formats it as if the date was UTC.
 *  (e.g. Redbox treats 4am as the boundary between days instead of midnight)
 * @param dateOffset - Incoming object holding the start or end of a date and the number of hours to offset it by
 * @param {Date} dateOffset.date - This should only be the start or end of a date (ie T00:00:00.000 or T23:59:59.999)
 * @param {Number} dateOffset.offsetHours - This should be the number of hours to offset it by
 * @returns {Date} adjusted date
 * @example
 *  adjustDayBoundaryByOffset({date: new Date('2020-07-01T00:00:00.000Z'), offsetHours: 4} })
 *  // equivalent of: new Date('2020-07-01T04:00:00.000Z')
 */
export const adjustDayBoundaryByOffset = ({
  date,
  offsetHours = 0,
}: AdjustByRedboxDayBoundaryProps) => {
  if (offsetHours !== 0) {
    if (date.valueOf() === startOfDay(date).valueOf()) {
      return set(date, { hours: offsetHours })
    } else {
      return set(add(date, { days: 1 }), { hours: offsetHours - 1 })
    }
  } else {
    return date
  }
}
