import { Button, Card } from '@blueprintjs/core'
import styled from 'styled-components'

export const LoginContainerMobile = styled.div`
  display: flex;
  margin: '10%';
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-direction: column;
`

export const LoginCardMobile = styled(Card)`
  margin: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 300px;
  margin-bottom: 20px;
`

export const InviteButtonsContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  & > button {
    margin: 10px;
    font-weight: 500;
  }
`
