import { Card, Tab, TabId, Tabs } from '@blueprintjs/core'
import React, { FC, useState } from 'react'
import { DetailsContainer } from './styles'
import { AssignmentHistory } from './AssignmentHistory'
import { TasksPanel } from './TasksPanel'
import { GET_JOB_RESPONSE_TYPE } from './gql/job'
import { ToastType, useToaster } from '../../hooks/useToaster'
import { ProofOfDeliveryPanel } from './ProofOfDeliveryPanel'
import { Fees } from './Fees'
import pick from 'lodash/pick'
import { JobStatus } from '../../zeus'

export const DetailsSection: FC<{ job: GET_JOB_RESPONSE_TYPE['job'] }> = ({
  job,
}) => {
  if (!job) return null

  const openErrorToast = useToaster({ type: ToastType.ERROR })
  const [activeTab, setActiveTab] = useState<TabId>('orderDetails')

  // attempt to parse orderContents
  let parsedOrderContents
  try {
    parsedOrderContents = JSON.parse(job.orderContents as string)
  } catch (error) {
    const msg = 'Unable to parse order content'
    openErrorToast(msg)
    parsedOrderContents = msg
  }

  return (
    <DetailsContainer>
      <Tabs
        selectedTabId={activeTab}
        onChange={tabId => {
          setActiveTab(tabId)
        }}
      >
        <Tab
          id="orderDetails"
          title="Order details"
          panel={
            <Card>
              <div>
                {Array.isArray(parsedOrderContents)
                  ? parsedOrderContents.map((item, index) => (
                      <p key={index}>{item}</p>
                    ))
                  : parsedOrderContents}
              </div>
            </Card>
          }
        />
        <Tab
          id="fees"
          title="Fees"
          panel={
            <Fees
              {...pick(job, [
                'id',
                'price',
                'cost',
                'initialCost',
                'distanceUnit',
                'estimatedDistanceMiles',
                'estimatedDurationMinutes',
              ])}
            />
          }
        />
        <Tab
          id="assignmentHistory"
          title="Assignment History"
          panel={<AssignmentHistory job={job} />}
        />
        <Tab id="tasks" title="Tasks" panel={<TasksPanel job={job} />} />
        <Tab
          id="proofOfDelivery"
          title="Proof of Delivery"
          panel={<ProofOfDeliveryPanel job={job} />}
        />
        {job.status === JobStatus.CANCELLED && (
          <Tab
            id="cancellationDetails"
            title="Cancellation Details"
            panel={
              <Card>
                <p>
                  Cancelled by <strong>{job.canceledBy}</strong>
                </p>
                {job.reasonForCancellation && (
                  <p>Reason: {job.reasonForCancellation}</p>
                )}
              </Card>
            }
          />
        )}
      </Tabs>
    </DetailsContainer>
  )
}
