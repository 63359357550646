export const KM_TO_MILE_RATIO = 0.6213712
export const MILE_TO_KM_RATIO = 1.609344

/**
 * @param pricePerKm number
 * @returns number
 */
export const oneKmToOneMiPrice = ({ pricePerKm }: { pricePerKm: number }) =>
  pricePerKm * MILE_TO_KM_RATIO

/**
 * @param pricePerMi number
 * @returns number
 */
export const oneMiToOneKmPrice = ({ pricePerMi }: { pricePerMi: number }) =>
  pricePerMi * KM_TO_MILE_RATIO
