import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const CREATE_WEBHOOK = typedGql('mutation')({
  createWebhook: [
    {
      input: $('input', 'WebhookCreateInput!'),
    },
    {
      id: true,
      name: true,
      isRedboxApi: true,
      forJobStatus: true,
      url: true,
    },
  ],
})
