import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import { CurrentUserContext } from '../../providers/CurrentUserProvider'
import { LoginContentConditional } from './LoginContentConditional'
import {
  LoginBackground,
  LoginCard,
  LoginContainer,
  LoginLogo,
} from './Login.styles'
import wayboxLogo from '../../assets/waybox-logo.svg'
import { ReactSVG } from 'react-svg'

export const Login = () => {
  const { loggedIn } = useContext(CurrentUserContext)
  return loggedIn ? (
    <Redirect to="/" />
  ) : (
    <LoginBackground>
      <LoginContainer>
        <LoginCard elevation={2}>
          <LoginLogo>
            <ReactSVG src={wayboxLogo} alt="waybox logo" />
          </LoginLogo>
          <LoginContentConditional />
        </LoginCard>
      </LoginContainer>
    </LoginBackground>
  )
}
