import styled, { css } from 'styled-components'
import { Card } from '@blueprintjs/core'
import { DriverStatus } from '../../../zeus'
import { driverStatusColors } from '../../../styles/status'

export const TimeBlock = styled.div<{ minWidth: number }>`
  overflow-x: clip;
  min-width: ${props => props.minWidth}px;
`

export const TDContainer = styled.td<{ blockSize: number; clip?: boolean }>`
  background: linear-gradient(to right, black 1px, aliceblue 1px);
  background-size: ${props => props.blockSize}px;
  overflow-x: ${props => (props.clip ? 'clip' : 'auto')};
`

export const HorizontalContainer = styled.div`
  display: flex;
`

export const JobAndDraggableWrapper = styled.div<{ left?: number }>`
  position: relative;
  ${({ left }) =>
    left &&
    css`
      left: ${left}px;
    `}
`

export const JobContainer = styled.div<{
  width: number
  isTheJobInThePast: boolean
}>`
  min-width: 120px;
  width: ${props => props.width}px;
  background-color: ${props =>
    props.isTheJobInThePast ? '#c23030' : '#ffcd00'};
  display: flex;
  justify-content: space-around;
  padding: 5px;
  border-radius: 5px;
`

export const BreakContainer = styled.div<{
  width: number
  status: DriverStatus
  left?: number
}>`
  min-width: 120px;
  width: ${props => props.width}px;
  background: ${props => driverStatusColors[props.status].color};
  ${({ status }) =>
    status === DriverStatus.INACTIVE &&
    'background: linear-gradient(90deg, rgba(128, 128, 128, 1) 0%, rgba(128, 128, 128, 0) 100%)'};
  opacity: 0.7;
  display: flex;
  justify-content: space-around;
  padding: 5px;
  border-radius: 5px;
  position: relative;
  ${({ left }) =>
    left &&
    css`
      left: ${left}px;
    `}
`

export const Shadow = styled.div<{
  width: number
  cannotAssign: boolean
  left?: number
  onlyChild: boolean
}>`
  position: relative;
  z-index: 10000;
  ${({ left }) =>
    left &&
    css`
      left: ${left}px;
    `}
  width: ${props => props.width}px;
  background-color: ${props =>
    props.cannotAssign ? 'rgba(255, 0, 0, 0.5)' : 'rgba(40, 40, 40, 0.5)'};
  padding: 5px;
  border-radius: 5px;
  ${({ onlyChild }) =>
    !onlyChild &&
    css`
      top: -28px;
    `}
`

export const UnstyledParagraph = styled.p`
  margin-bottom: 0;
`

export const PopoverContentContainer = styled.div`
  padding: 5px;
  width: 150px;
`

export const PopoverContentContainerRow = styled.div`
  display: flex;
  justify-content: space-between;
`

export const UnassignedJobsContainer = styled(Card)`
  margin: 10px 10px 5px 10px;
  display: flex;
  flex-direction: row;
`

export const JobBlockSpacer = styled.div`
  margin-right: 5px;
`

export const JobActions = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StackedJobsContainer = styled.span<{
  left?: number
}>`
  display: flex;
  flex-direction: column;
  border: 2px solid red;
  padding: 1px;
  position: relative;
  ${({ left }) =>
    left &&
    css`
      left: ${left}px;
    `}
`
