import React from 'react'
import { ModelTypes, UserRole } from '../../zeus'
import { Link } from 'react-router-dom'
import { EllipsisText } from '../../atomics/EllipsisText'
import { QUERY_PARAMS } from '../../utils/queryParamsNames'
import { NON_DRIVER_ROLES } from './DriverSection'

type PartialJob = Pick<
  ModelTypes['Job'],
  | 'id'
  | 'estimatedPickupTime'
  | 'createdAt'
  | 'updatedAt'
  | 'status'
  | 'onHold'
  | 'fleetId'
  | 'orderContents'
  | 'orderReference'
  | 'finishedAssignment'
  | 'pickupTime'
  | 'completionTime'
  | 'completedByDriverId'
  | 'completedByDriverFullName'
> & {
  driver?: Pick<
    ModelTypes['Driver'],
    'id' | 'phoneNumber' | 'firstName' | 'lastName'
  >
}

export const DriverName = ({ job }: { job: PartialJob }) => {
  if (job.completedByDriverFullName) {
    return (
      <Link
        to={
          NON_DRIVER_ROLES.includes(job.completedByDriverFullName)
            ? `/admin/users?${QUERY_PARAMS.userId}=${job.completedByDriverId}`
            : `/drivers?${QUERY_PARAMS.driverId}=${job.completedByDriverId}`
        }
      >
        <EllipsisText>{job.completedByDriverFullName}</EllipsisText>
      </Link>
    )
  }

  return job.driver?.id ? (
    <Link to={`/drivers?${QUERY_PARAMS.driverId}=${job.driver?.id}`}>
      {job.driver?.firstName
        ? `${job.driver.firstName} ${job.driver.lastName || ''}`
        : job.driver.phoneNumber}
    </Link>
  ) : (
    <p>---</p>
  )
}
