import { Button, Navbar, Breadcrumbs } from '@blueprintjs/core'
import styled from 'styled-components'

export const TopHeader = styled(Navbar)`
  width: 100%;
  height: 48px;
  z-index: 2;
  padding-left: 60px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => (theme.colors.headerBgColor)};
`

export const TopLeftLogo = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 8px;
  z-index: 3;
  height: 48px;
  width: 48px;
`

export const BreadcrumbsNav = styled(Breadcrumbs)`
color: ${({ theme }) => theme.colors.headerTextColor};
a.bp5-breadcrumb {
  color: ${({ theme  }) => (theme.colors.headerTextHoverColor)};
  &:hover {
    color: ${({ theme }) => (theme.colors.headerTextHoverColor)};
  }
}
`

export const TopRightButton = styled(Button)`
  background-image: none !important;
  color: ${({ theme }) => theme.colors.primaryButtonText} !important;
  box-shadow: ${({ theme }) => theme.colors.primaryButtonShadow} !important;
  background-color: ${({ theme }) =>
    theme.colors.primaryButtonColor} !important;
  span: {
    color: green !important;
  }
`
