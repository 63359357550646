import { useQuery } from '@apollo/client'
import { HTMLSelect, Spinner, Tag } from '@blueprintjs/core'
import React from 'react'
import { ModelTypes, SortOrder } from '../../zeus'
import { LIST_FLEETS } from '../../pages/Fleets/gql/fleets'
import { BaseMultiSelectProps } from './types'
import { MultiSelect } from '@blueprintjs/select'

export const FleetMultiSelect = (props: BaseMultiSelectProps) => {
  const { data, loading } = useQuery(LIST_FLEETS, {
    variables: {
      OrderFleetsByNameDirecton: SortOrder.asc,
    },
  })

  if (loading) {
    return <Spinner />
  }

  const fleets =
    data?.fleets.map(
      ({ id, name }: Pick<ModelTypes['Fleet'], 'id' | 'name'>) => ({
        id,
        name,
      })
    ) || []

  return (
    <MultiSelect
      {...props}
      tagRenderer={node => node.name}
      items={fleets}
      selectedItems={props.selectedItems}
      itemRenderer={({ id, name }, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
          return null
        }

        return (
          <li
            key={id}
            onClick={e => {
              if (!props.selectedItems?.find(item => item.id === id)) {
                handleClick(e)
              }
            }}
            style={{ listStyle: 'none', margin: '5px' }}
          >
            <Tag
              intent={
                props.selectedItems?.find(item => item.id === id)
                  ? 'none'
                  : 'primary'
              }
            >
              {name}
            </Tag>
          </li>
        )
      }}
    />
  )
}
