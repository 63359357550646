import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import { Button, NonIdealState } from '@blueprintjs/core'

import { PlatformForm } from './PlatformForm'
import {
  ErrorState,
  LoadingState,
} from '../../components/NonIdealState/NonIdealState'
import { DialogContext } from '../../providers/DialogProvider'
import { AddPlatformDialog } from '../../dialogs/AddPlatformDialog'
import { LIST_PLATFORMS } from './gql/platforms'
import { SortOrder } from '../../zeus'

export const PlatformContent = () => {
  const { data, loading, error } = useQuery(LIST_PLATFORMS, {
    variables: {
      sortByNameDirection: SortOrder.asc,
    },
  })
  const { openDialog, closeDialog } = useContext(DialogContext)

  if (error) {
    return <ErrorState entityName="platform" />
  }

  if (loading || !data) {
    return <LoadingState entityName="platform" />
  }

  const platform = data.platforms[0]

  if (!platform) {
    return (
      <NonIdealState
        icon="warning-sign"
        title="No platform created"
        action={
          <Button
            icon="add"
            onClick={() =>
              openDialog({
                title: 'Add Platform',
                content: <AddPlatformDialog closeDialog={closeDialog} />,
              })
            }
          >
            Add platform
          </Button>
        }
      />
    )
  }

  return <PlatformForm platform={platform} />
}
