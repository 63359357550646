import React from 'react'
import { Popup } from 'maplibre-gl'
import { renderToString } from 'react-dom/server'
import { FC } from 'react'
import { PopupInsideContainer } from '../styles'
import { Tag, HTMLTable } from '@blueprintjs/core'
import { ContactDetails, Location } from '../../../utils/location'
import { GraphQLTypes } from '../../../zeus'

export type PartialPickupTask = Pick<
  GraphQLTypes['PickupTask'],
  | 'id'
  | 'location'
  | 'locationName'
  | 'pickupNotes'
  | '__typename'
  | 'pickupContact'
>
export type PartialDropOffTask = Pick<
  GraphQLTypes['DropOffTask'],
  | 'id'
  | 'location'
  | 'locationName'
  | 'dropOffNotes'
  | 'orderInStack'
  | '__typename'
  | 'dropOffContact'
  | 'allocatedTime'
>

const isTaskOfTypePickup = (
  task: PartialPickupTask | PartialDropOffTask
): task is PartialPickupTask => task.__typename === 'PickupTask'

export const createTaskPopup = (
  task: PartialPickupTask | PartialDropOffTask
): Popup => {
  const html = renderToString(<PopupContent task={task} />)

  const popup = new Popup({
    offset: 20,
    anchor: 'right',
  }).setHTML(html)
  popup.setMaxWidth('300px')

  return popup
}

export const PopupContent: FC<{
  task: PartialPickupTask | PartialDropOffTask
}> = ({ task }) => {
  const location = task.location as unknown as Location
  const { firstLine, secondLine, thirdLine, postcode, city } = location.address

  const contactDetailsRaw = isTaskOfTypePickup(task)
    ? task.pickupContact
    : task.dropOffContact
  const contact = contactDetailsRaw as unknown as ContactDetails

  return (
    <PopupInsideContainer>
      <div>
        <Tag>{isTaskOfTypePickup(task) ? 'Pickup' : 'DropOff'}</Tag>
        <h3>{task.locationName}</h3>
      </div>
      <HTMLTable compact>
        <tr>
          <th>Name</th>
          <td>{contact.name || task.locationName}</td>
        </tr>

        {location.address && (
          <tr>
            <th>Address</th>
            <td>
              <p>{firstLine}</p>
              <p>{secondLine}</p>
              <p>{thirdLine}</p>
              <p>{postcode}</p>
              <p>{city}</p>
            </td>
          </tr>
        )}

        <tr>
          <th>Phone</th>
          <td>{contact.phone}</td>
        </tr>

        <tr>
          <th>Notes</th>
          <td>
            {isTaskOfTypePickup(task) ? task.pickupNotes : task.dropOffNotes}
          </td>
        </tr>

        {!isTaskOfTypePickup(task) && (
          <tr>
            <th>Allocated Time</th>
            <td>{task.allocatedTime} sec</td>
          </tr>
        )}
      </HTMLTable>
    </PopupInsideContainer>
  )
}
