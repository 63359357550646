import React from 'react'
import {
  ButtonGroup,
  Tag,
  Button,
  Popover,
  Position,
  NumericInput,
} from '@blueprintjs/core'
import { clamp } from 'lodash'

type PagerProps = {
  dataName: string
  goToPrevious: (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLElement, MouseEvent>
  ) => void
  goToNext: (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLElement, MouseEvent>,
    _outcomeLength: number
  ) => void
  goToPage: Function
  defaultNmbRecords: number
  skip: number
  total: number
  outcomeLength: number
  totalCount: number
}

export const Pager = ({
  dataName,
  goToPrevious,
  goToNext,
  goToPage,
  defaultNmbRecords,
  skip,
  total,
  outcomeLength,
  totalCount,
}: PagerProps) => {
  const minimum = 1
  const maximum = Math.ceil(total / defaultNmbRecords) || 1

  return (
    <div
      style={{
        marginLeft: 'auto',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      {totalCount !== undefined && (
        <Tag minimal={true} large={true} style={{ marginRight: '10px' }}>
          {totalCount || 0} {dataName || 'Records'}
        </Tag>
      )}
      <ButtonGroup id="navigationGroup">
        <Button
          disabled={skip === 0}
          icon="chevron-left"
          onClick={goToPrevious}
        />
        <Popover
          content={
            <div style={{ padding: 10 }}>
              <NumericInput
                autoFocus
                onValueChange={val => {
                  goToPage(clamp(val, 1, maximum))
                }}
                buttonPosition={Position.RIGHT}
                stepSize={1}
                min={minimum}
                max={maximum}
                placeholder="Page..."
                style={{ width: '100px' }}
              />
            </div>
          }
          disabled={maximum <= 1}
          position={Position.BOTTOM}
        >
          <Button
            disabled={totalCount <= 1}
            style={{
              width: '90px',
            }}
          >
            {skip / defaultNmbRecords + 1}
            &nbsp;of&nbsp;
            {maximum}
          </Button>
        </Popover>

        <Button
          disabled={
            skip / defaultNmbRecords + 1 < total / defaultNmbRecords === false
          }
          rightIcon="chevron-right"
          onClick={e => goToNext(e, outcomeLength)}
        />
      </ButtonGroup>
    </div>
  )
}
