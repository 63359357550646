import React from 'react';
import { Route, Switch } from 'react-router';
import { Fleet } from './Fleet/Fleet';
import { FleetsContent } from './FleetsContent';

export const Fleets = () => {
  return (
    <Switch>
      <Route path="/fleets" exact>
        <FleetsContent />
      </Route>
      <Route path="/fleets/:id">
        <Fleet />
      </Route>
    </Switch>
  );
};
