import React, { useContext, useState } from 'react'
import { useQuery } from '@apollo/client'
import {
  ErrorState,
  LoadingState,
} from '../../components/NonIdealState/NonIdealState'
import { DialogContext } from '../../providers/DialogProvider'
import {
  PageLayoutContext,
  configurePageLayout,
} from '../../providers/PageLayoutProvider'
import { CreateJobDialog } from '../../dialogs/CreateJobDialog'
import { DriverName } from './DriverName'
import { LIST_JOBS } from './gql/jobs'
import { StringParam, useQueryParam } from 'use-query-params'
import { JobStatus } from '../../zeus'
import { QUERY_PARAMS } from '../../utils/queryParamsNames'
import { SearchBy } from '../../components/Filters/SearchBy'
import { FilterByFleet } from '../../components/Filters/FilterByFleet'
import { FilterByDriver } from '../../components/Filters/FilterByDriver'
import { FilterByJobStatus } from '../../components/Filters/FilterByJobStatus'
import { TableView } from '../../components/Views/Table'
import { Explanation } from '../../atomics/Explanation'
import {
  ButtonGroup,
  ControlGroup,
  Drawer,
  DrawerSize,
  IconSize,
  Intent,
} from '@blueprintjs/core'
import { EllipsisText } from '../../atomics/EllipsisText'
import { JobStatusSection } from './JobStatusSection'
import isNil from 'lodash/isNil'
import { format } from 'date-fns'
import { JobDetails } from './JobDetails'
import { Pager } from '../../components/Filters/Pager'
import { Divider } from '@blueprintjs/core'
import {
  JobsOrderBy,
  JOBS_ORDER_BY_VALUE_MAP,
} from '../../components/Filters/JobsOrderBy'
import { ClearFilters } from '../../components/Filters/ClearFilters'
import { DateRangeFilter } from '../../components/Filters/DateRangeFilter'
import { translateDateRangeFilterToGraphQLVars } from '../../components/Filters/DateRangeFilter/translateDateRangeFilterToGraphQLVars'
import {
  EstimatedDropOffTimeActualAndDelta,
  EstimatedPickupTimeActualAndDelta,
} from '../../atomics/EstimatedTimeActualAndDelta'
import { FilterBarPager } from '../../components/Filters/styles'
import { FilterBar } from '../../components/Filters/FilterBar'
import { DialogWrapper } from '../../dialogs/styles'

export const DATE_TIME_FORMAT = 'dd LLL HH:mm'
const DEFAULT_RECORDS = 20

export const Jobs = () => {
  const { darkMode } = useContext(PageLayoutContext)
  const { openDialog, closeDialog } = useContext(DialogContext)
  const [status] = useQueryParam<undefined | JobStatus>(QUERY_PARAMS.jobStatus)
  const [jobOrOrderLike] = useQueryParam(
    QUERY_PARAMS.jobOrOrderLike,
    StringParam
  )
  const [fleetId] = useQueryParam<string | undefined>(QUERY_PARAMS.fleetId)
  const [driverId] = useQueryParam<string | undefined>(QUERY_PARAMS.driverId)
  const [jobId, setJobId] = useQueryParam(QUERY_PARAMS.jobId, StringParam)
  const [_stackId, setStackId] = useQueryParam(
    QUERY_PARAMS.stackId,
    StringParam
  )
  const [jobsOrderBy] = useQueryParam<string | undefined>(
    QUERY_PARAMS.jobsOrderby
  )
  const [dateFilterTypeKey, setDateFilterTypeKey] = useQueryParam<
    string | undefined
  >(QUERY_PARAMS.dateFilterTypeKey)
  const [startOfRangeDate, setStartOfRangeDate] = useQueryParam<
    string | undefined
  >(QUERY_PARAMS.startOfRangeDate)
  const [endOfRangeDate, setEndOfRangeDate] = useQueryParam<string | undefined>(
    QUERY_PARAMS.endOfRangeDate
  )

  const [ordersPagination, setOrdersPagination] = useState({
    skip: 0,
    take: DEFAULT_RECORDS,
    defaultNmbRecords: DEFAULT_RECORDS,
    outcomeLength: null,
    navigationDisabled: false,
  })

  const goToNext = (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLElement, MouseEvent>,
    limit: number
  ) => {
    e.preventDefault()
    setOrdersPagination({
      ...ordersPagination,
      skip: limitNext(ordersPagination.skip, ordersPagination.take, limit),
      take: DEFAULT_RECORDS,
    })
  }

  const limitNext = (currentCursor: number, amount: number, limit: number) => {
    let skip = currentCursor + amount
    return limit < ordersPagination.defaultNmbRecords ? currentCursor : skip
  }

  const goToPrevious = (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    e.preventDefault()
    setOrdersPagination({
      ...ordersPagination,
      skip: limitPrevious(ordersPagination.skip, ordersPagination.take),
    })
  }

  const limitPrevious = (currentCursor: number, amount: number) => {
    let skip = currentCursor - amount
    return skip >= 0 ? skip : 0
  }

  const goToPage = (value: number) => {
    const numberToSkip = DEFAULT_RECORDS * (value - 1)
    setOrdersPagination({
      ...ordersPagination,
      skip: numberToSkip,
    })
  }

  configurePageLayout({
    tabs: [],
    button:
      process.env.NODE_ENV !== 'prod' // TODO update accordingly
        ? {
            onClick: () =>
              openDialog({
                title: 'Add Job',
                content: <CreateJobDialog closeDialog={closeDialog} />,
              }),
            text: 'Add Job',
          }
        : null,
  })

  const { data, loading, error } = useQuery(LIST_JOBS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        status: { equals: status },
        ...(jobOrOrderLike && {
          OR: [
            { id: { contains: jobOrOrderLike } },
            { jobReference: { contains: jobOrOrderLike } },
            { orderId: { contains: jobOrOrderLike } },
            { orderReference: { contains: jobOrOrderLike } },
            { stackId: { contains: jobOrOrderLike } },
          ],
        }),
        fleetId: { equals: fleetId },
        ...(driverId && {
          OR: [
            { driverId: { equals: driverId } },
            { completedByDriverId: { equals: driverId } },
          ],
        }),
        ...(dateFilterTypeKey &&
          translateDateRangeFilterToGraphQLVars({
            dateFilterTypeKey,
            startOfRangeDate,
            endOfRangeDate,
            jobsOrderBy,
          })),
      },
      ...(jobsOrderBy && {
        orderBy: JOBS_ORDER_BY_VALUE_MAP[jobsOrderBy],
      }),
      skip: ordersPagination.skip,
      take: ordersPagination.take,
    },
  })

  return (
    <>
      {loading && <LoadingState entityName="jobs" />}
      {error && <ErrorState entityName="jobs" />}
      {data?.paginatedJobs?.jobs && (
        <>
          <FilterBar>
            <ControlGroup>
              <DateRangeFilter defaultStartOfRangeDate={new Date()} />
              <Divider style={{ marginRight: 7 }} />
              <ButtonGroup>
                <SearchBy
                  placeholder="Search by job / order / stack"
                  paramName={QUERY_PARAMS.jobOrOrderLike}
                />
                <FilterByFleet />
                <FilterByDriver />
                <JobsOrderBy />
                <FilterByJobStatus />
                <ClearFilters />
              </ButtonGroup>
              <Divider style={{ marginRight: 15 }} />
              <FilterBarPager>
                <Pager
                  goToPrevious={goToPrevious}
                  goToNext={goToNext}
                  goToPage={goToPage}
                  defaultNmbRecords={DEFAULT_RECORDS}
                  skip={ordersPagination.skip}
                  total={data.paginatedJobs.count}
                  outcomeLength={data.paginatedJobs.jobs.length}
                  totalCount={data.paginatedJobs.count}
                  dataName="Jobs"
                />
              </FilterBarPager>
            </ControlGroup>
          </FilterBar>
          <TableView>
            <thead>
              <tr>
                <th></th>
                <th>Job</th>
                <th>Stack</th>
                <th>Order</th>
                <th>Job / Stack Status</th>
                <th>Driver</th>
                <th>Fleet</th>
                <th>Created at</th>
                <th>Pickup Time Est. / Actual / &Delta;</th>
                <th>Drop Off Time Est. / Actual / &Delta;</th>
              </tr>
            </thead>
            <tbody>
              {data.paginatedJobs.jobs.map(job => (
                <tr key={job.id}>
                  <td style={{ maxWidth: '20px' }}>
                    {!job.finishedAssignment && (
                      <Explanation
                        icon="social-media"
                        intent={Intent.PRIMARY}
                        size={IconSize.STANDARD}
                        content="Searching for a courier"
                      />
                    )}
                  </td>
                  <td
                    onClick={() => {
                      setStackId(job.stackId)
                      setJobId(job.id)
                    }}
                  >
                    <a>{job.jobReference}</a>
                  </td>
                  <td>
                    <EllipsisText dir="rtl">
                      {job.stackId || '---'}
                    </EllipsisText>
                  </td>
                  <td style={{ minWidth: '150px' }}>
                    {job.orderIsAsap && (
                      <>
                        <Explanation
                          icon="time"
                          intent={Intent.PRIMARY}
                          size={IconSize.STANDARD}
                          content="Order is ASAP"
                        />
                      </>
                    )}
                    &nbsp;&nbsp;
                    {job.orderId}
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <JobStatusSection
                        status={job.status}
                        onHold={job.onHold}
                      />
                      {job.stackStatus && (
                        <>
                          &nbsp;/&nbsp;
                          <JobStatusSection
                            status={job.stackStatus}
                            onHold={job.onHold}
                          />
                        </>
                      )}
                    </div>
                  </td>
                  <td>
                    <DriverName job={job} />
                  </td>
                  <td>{job.fleet?.name}</td>
                  <td style={{ minWidth: 110 }}>
                    {format(
                      new Date(job.createdAt as string),
                      DATE_TIME_FORMAT
                    )}
                  </td>
                  {/* pickup time - estimated / actial / delta */}
                  <td>
                    <EstimatedPickupTimeActualAndDelta
                      estimatedPickupTime={job.estimatedPickupTime as string}
                      pickupTime={job.pickupTime as string}
                    />
                  </td>
                  {/* dropOff time - estimated / actual / delta */}
                  <td>
                    <EstimatedDropOffTimeActualAndDelta
                      pickupTime={job.pickupTime as string}
                      estimatedPickupTime={job.estimatedPickupTime as string}
                      estimatedDurationMinutes={job.estimatedDurationMinutes}
                      completionTime={job.completionTime as string}
                    />
                  </td>

                  <Drawer
                    isOpen={!!jobId}
                    title="Job Details"
                    onClose={() => {
                      setStackId(null)
                      setJobId(null)
                    }}
                    size={DrawerSize.LARGE}
                    className={darkMode ? 'bp5-dark' : ''}
                  >
                    {!!jobId && (
                      <DialogWrapper
                        className={darkMode ? 'bp5-dark' : ''}
                        darkMode={darkMode}
                      >
                        <JobDetails
                          key={jobId}
                          jobId={jobId}
                          closeDrawer={() => setJobId(null)}
                        />
                      </DialogWrapper>
                    )}
                  </Drawer>
                </tr>
              ))}
            </tbody>
          </TableView>
        </>
      )}
    </>
  )
}
