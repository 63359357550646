import { typedGql } from '../../../zeus/typedDocumentNode'

export const LIST_DRIVERS = typedGql('query')({
  drivers: [
    {},
    {
      createdAt: true,
      id: true,
      phoneNumber: true,
      title: true,
      firstName: true,
      middleNames: true,
      lastName: true,
      email: true,
      completedSignUp: true,
      allowJobRejection: true,
      allowStackingOrders: true,
      status: true,
      breaks: true,
      fleetDrivers: { fleet: { name: true } },
    },
  ],
})
