import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router'
import { InviteUserDialog } from '../../dialogs/InviteUserDialog'
import { DialogContext } from '../../providers/DialogProvider'
import { configurePageLayout } from '../../providers/PageLayoutProvider'
import { Playground } from './Playground/Playground'
import { Users } from './Users/Users'
import { CurrentUserContext } from '../../providers/CurrentUserProvider'

export const Admin = () => {
  const { openDialog, closeDialog } = useContext(DialogContext)
  const { currentUser } = useContext(CurrentUserContext)

  const isAdmin = currentUser?.role === 'ADMIN'

  configurePageLayout({
    tabs: [
      { path: 'users', title: 'Users' },
      { path: 'playground', title: 'Playground', hide: !isAdmin },
    ],

    button: {
      onClick: () =>
        openDialog({
          title: 'Invite User',
          content: <InviteUserDialog closeDialog={closeDialog} />,
        }),
      text: 'Invite User',
    },
  })

  return (
    <Switch>
      <Route path="/admin" exact>
        <Redirect to="/admin/users" />
      </Route>
      <Route path="/admin/users">
        <Users />
      </Route>
      <Route path="/admin/playground">
        <Playground />
      </Route>
    </Switch>
  )
}
