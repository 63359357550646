import React from 'react'
import { useMutation } from '@apollo/client'
import {
  Button,
  Classes,
  DialogFooter,
  FormGroup,
  Intent,
  Spinner,
} from '@blueprintjs/core'
import { useFormik } from 'formik'
import get from 'lodash/get'
import { IBaseDialogProps } from './types'
import { ToastType, useToaster } from '../hooks/useToaster'
import { LIST_JOBS } from '../pages/Jobs/gql/jobs'
import { GET_JOB_RESPONSE_TYPE } from '../pages/Jobs/gql/job'
import { UPDATE_JOB_STATUS } from '../pages/Jobs/gql/updateJobStatus'
import { JobStatusSelect } from '../components/Select/JobStatusSelect'
import { DialogBody } from '@blueprintjs/core'

type UpdateJobStatusProps = IBaseDialogProps & {
  job: GET_JOB_RESPONSE_TYPE['job']
}

export const UpdateJobStatusDialog = ({
  closeDialog,
  job,
}: UpdateJobStatusProps) => {
  if (!job) return null
  const openErrorToast = useToaster({ type: ToastType.ERROR })
  const [updateJobStatus] = useMutation(UPDATE_JOB_STATUS, {
    refetchQueries: [LIST_JOBS],
  })

  const { values, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      status: job.status || '',
    },
    onSubmit: async values => {
      try {
        await updateJobStatus({
          variables: {
            input: {
              id: job.id,
              status: values.status,
            },
          },
        })
      } catch (error) {
        openErrorToast(get(error, 'message', 'Error assigning job to driver'))
      }

      closeDialog()
    },
  })

  return (
    <DialogBody>
      <form onSubmit={handleSubmit}>
        <FormGroup label="Choose Status">
          <JobStatusSelect
            name="status"
            onChange={handleChange}
            value={values.status}
          />
        </FormGroup>

        <DialogFooter>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button onClick={closeDialog} minimal>
              Cancel
            </Button>

            <Button
              intent={Intent.PRIMARY}
              type="submit"
              minimal
              outlined
              icon={isSubmitting && <Spinner size={10} />}
            >
              Update
            </Button>
          </div>
        </DialogFooter>
      </form>
    </DialogBody>
  )
}
