import { useQuery } from '@apollo/client'
import { Drawer, DrawerSize, HTMLTable } from '@blueprintjs/core'
import React from 'react'
import { useParams } from 'react-router-dom'
import { StringParam, useQueryParam } from 'use-query-params'
import {
  EmptyListState,
  ErrorState,
  LoadingState,
} from '../../../../components/NonIdealState/NonIdealState'
import { QUERY_PARAMS } from '../../../../utils/queryParamsNames'
import { WEBHOOKS_BY_FLEET } from '../../gql/fleetWithWebhooks'
import { WebhookDetails } from './WebhookDetails'
import redboxIcon from '../../../../assets/icons/redbox.svg'
import { ExplanationSVG } from '../../../../atomics/ExplanationSVG'

type RouteParams = {
  id: string
}

export const Webhooks = () => {
  const { id } = useParams<RouteParams>()
  const [webhookId, setWebhookId] = useQueryParam(
    QUERY_PARAMS.webhookId,
    StringParam
  )

  const { data, loading, error } = useQuery(WEBHOOKS_BY_FLEET, {
    variables: { id },
  })

  if (loading) {
    return <LoadingState entityName="webhooks" />
  }

  if (error) {
    return <ErrorState entityName="webhooks" />
  }

  const webhooks = data?.fleet?.webhooks

  if (!webhooks?.length) {
    return <EmptyListState entityName="webhooks" />
  }

  return (
    <>
      <HTMLTable striped>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>URL</th>
            <th>For Status</th>
            <th>Created At</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {webhooks.map(webhook => (
            <tr key={webhook.id}>
              <td style={{ maxWidth: '20px' }}>
                {webhook.isRedboxApi && (
                  <ExplanationSVG
                    content="This is a Redbox API webhook"
                    reactSVG={redboxIcon}
                    size={16}
                  />
                )}
              </td>
              <td>
                <a onClick={() => setWebhookId(webhook.id)}>
                  {webhook.name || 'N.A'}
                </a>
              </td>
              <td>{webhook.url}</td>
              <td>{webhook.forJobStatus || 'ALL'}</td>
              <td>{webhook.createdAt as string}</td>
              <td>{webhook.updatedAt as string}</td>
            </tr>
          ))}
        </tbody>
      </HTMLTable>
      <Drawer
        isOpen={!!webhookId}
        onClose={() => setWebhookId(null)}
        size={DrawerSize.STANDARD}
        title="Webhook Details"
      >
        {webhookId && (
          <WebhookDetails
            webhook={webhooks.find(w => w.id === webhookId)}
            fleetId={id}
          />
        )}
      </Drawer>
    </>
  )
}
