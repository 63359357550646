import React from 'react'
import { isMobile, isIOS, isAndroid } from 'react-device-detect'
import { Button } from '@blueprintjs/core'
import { LoginBackground, LoginLogo } from '../Login/Login.styles'
import { ReactSVG } from 'react-svg'
import wayboxLogo from '../../assets/waybox-logo.svg'
import {
  InviteButtonsContainer,
  LoginCardMobile,
  LoginContainerMobile,
} from './styles'
import { Redirect } from 'react-router-dom'

// TODO raised https://korelogic.atlassian.net/browse/DIS-112
// to improve this solution once  the apps are released
const WAYBOX_DEEPLINK =
  process.env.REACT_APP_WAYBOX_DEEPLINK || 'waybox://login'
const WAYBOX_APP_ANDROID =
  process.env.REACT_APP_WAYBOX_APP_ANDROID ||
  'https://play.google.com/store/apps'
const WAYBOX_APP_IOS =
  process.env.REACT_APP_WAYBOX_APP_IOS ||
  'https://apps.apple.com/us/app/waybox/id6443502033'

export const InviteDeepLinking = () => {
  // not visible on desktop
  if (!isMobile) {
    return <Redirect to="/" />
  }

  const openApp = () => {
    window.location.replace(WAYBOX_DEEPLINK)
  }

  const fallBackToStore = () => {
    if (isAndroid) {
      window.location.replace(WAYBOX_APP_ANDROID)
    } else if (isIOS) {
      window.location.replace(WAYBOX_APP_IOS)
    } else {
      window.location.replace('http://manage.waybox.systems')
    }
  }

  return (
    <LoginBackground>
      <LoginContainerMobile>
        <LoginCardMobile elevation={2}>
          <LoginLogo>
            <ReactSVG src={wayboxLogo} alt="waybox logo" />
          </LoginLogo>
          Please make sure you have installed the Waybox Driver App
          <InviteButtonsContainer>
            <Button
              minimal
              large
              outlined
              text="Install"
              onClick={fallBackToStore}
            />
            <Button minimal large outlined text="Launch" onClick={openApp} />
          </InviteButtonsContainer>
        </LoginCardMobile>
      </LoginContainerMobile>
    </LoginBackground>
  )
}
