import { typedGql } from '../../../zeus/typedDocumentNode'
import { $ } from '../../../zeus'

export const EXCEPTION_QUEUE_AND_ASSIGNED_JOBS = typedGql('query')({
  exceptionQueue: [
    {
      fleetId: $('fleetId', 'String!'),
    },
    {
      id: true,
      stackId: true,
      estimatedPickupTime: true,
      estimatedDurationMinutes: true,
      price: true,
    },
  ],
  jobs: [
    {
      where: $('jobsWhere', 'JobWhereInput!'),
    },
    {
      id: true,
      stackId: true,
      driverId: true,
      estimatedPickupTime: true,
      estimatedDurationMinutes: true,
      price: true,
    },
  ],
})
