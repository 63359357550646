import { ResultOf } from '@graphql-typed-document-node/core'
import { typedGql } from '../../../zeus/typedDocumentNode'
import { $, JobStatus } from '../../../zeus'

// TODO add date filter entry when we are routinely creating many jobs
export const SUPPLEMENTARY_DATA = typedGql('query')({
  jobs: [
    {
      where: {
        AND: [
          { status: { notIn: [JobStatus.COMPLETE, JobStatus.CANCELLED] } },
          { driverId: { in: $('driverIds', '[String!]!') } },
        ],
      },
    },
    {
      id: true,
      jobReference: true,
      stackId: true,
      driverId: true,
      updatedAt: true,
      createdAt: true,
      status: true,
      onHold: true,
      orderReference: true,
      orderContents: true,
      pickupTasks: {
        id: true,
        locationName: true,
        location: true,
        pickupNotes: true,
        pickupContact: true,
      },
      dropOffTasks: {
        id: true,
        locationName: true,
        location: true,
        dropOffContact: true,
        dropOffNotes: true,
        orderInStack: true,
      },
      estimatedPickupTime: true,
    },
  ],
})

export type SUPPLEMENTARY_DATA_RESPONSE = ResultOf<typeof SUPPLEMENTARY_DATA>
