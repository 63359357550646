import React, { useEffect, useRef } from 'react'
import { MapSection } from '../../../components/MapSection'
import { Map } from 'maplibre-gl'
import { StringParam, useQueryParam } from 'use-query-params'
import { createTasksAndRouteToBeDrawn } from '../../../components/MapSection/createTasksToBeDrawn'
import { DASHBOARD_JOBS_RESPONSE } from '../gql/jobs'
import {
  PartialDropOffTask,
  PartialPickupTask,
} from '../../../components/MapSection/popupUtils'
import { parseRoute } from '../helpers/parseRoute'
import { QUERY_PARAMS } from '../../../utils/queryParamsNames'
import { createLegRoute } from '../../../components/MapSection/createLegRoute'
import { AnyLayer } from 'maplibre-gl'

const BBOX_PADDING = 0.005

export const JobsMapSubsection = ({
  jobs,
}: {
  jobs: DASHBOARD_JOBS_RESPONSE['jobs']
}) => {
  const mapRef = useRef<Map | undefined>(undefined)
  const [selectedJob, setSelectedJob] = useQueryParam(
    QUERY_PARAMS.jobId,
    StringParam
  )
  const [stackId, setStackId] = useQueryParam(QUERY_PARAMS.stackId, StringParam)
  const legLayerRef = useRef<AnyLayer | undefined>(undefined)

  const jobToTasksAndRouteMapRef = useRef<
    Record<
      string,
      {
        tasksMarkers: maplibregl.Marker[]
        layer: AnyLayer
      }
    >
  >({})

  const drawTasksAndRoutes = () => {
    jobs.forEach(job => {
      const { tasksMarkers, estimatedRoute } = createTasksAndRouteToBeDrawn({
        pickupTasks: job.pickupTasks as PartialPickupTask[],
        dropOffTasks: job.dropOffTasks as PartialDropOffTask[],
        route: job.route,
        multiLayer: true,
      })

      if (tasksMarkers) {
        tasksMarkers.forEach(marker => {
          if (mapRef.current) {
            marker.addTo(mapRef.current)
          }
        })
      }

      if (estimatedRoute) {
        const { id, source, layer } = estimatedRoute

        if (mapRef.current) {
          mapRef.current.addSource(id, source)
          mapRef.current.addLayer(layer)
        }
      }

      jobToTasksAndRouteMapRef.current[job.id] = {
        tasksMarkers: tasksMarkers as maplibregl.Marker[],
        layer: estimatedRoute?.layer as AnyLayer,
      }
    })
  }

  const addResetControl = () => {
    if (mapRef.current) {
      mapRef.current.addControl(
        {
          onAdd: () => {
            const container = document.createElement('button')
            container.id = 'custom-control'
            container.className = 'maplibregl-ctrl'
            container.type = 'button'
            container.textContent = 'Redraw all'

            container.onclick = () => {
              setStackId(undefined)
              setSelectedJob(undefined)
            }

            return container
          },
          onRemove: () => {
            document.getElementById('custom-controls')?.remove()
          },
        },
        'top-left'
      )
    }
  }

  const hideTasksAndRoutesExceptSelectedJob = (jobId: string) => {
    jobs
      .filter(job => {
        if (job.id === jobId) return false
        if (job.stackId === stackId) return false
        return true
      })
      .forEach(job => {
        const { tasksMarkers, layer } = jobToTasksAndRouteMapRef.current[job.id]

        tasksMarkers.forEach(marker => {
          marker.remove()
        })

        if (mapRef.current) {
          mapRef.current.removeLayer(layer.id)
        }
      })
  }

  const redrawHiddenTasksAndRoutes = () => {
    Object.values(jobToTasksAndRouteMapRef.current).forEach(
      ({ tasksMarkers, layer }) => {
        tasksMarkers.forEach(marker => {
          if (mapRef.current) marker.addTo(mapRef.current)
        })

        if (mapRef.current) {
          mapRef.current.addLayer(layer)
        }
      }
    )
  }

  // ensure subsequent jobs are drawn
  useEffect(() => {
    drawTasksAndRoutes()
  }, [jobs])

  // center map on the selected job
  useEffect(() => {
    if (selectedJob) {
      const job = jobs.find(j => j.id === selectedJob)
      if (!job || !job.route) return

      const parsedRoute = parseRoute(job.route as string)
      const bbox = parsedRoute?.Summary.RouteBBox.map((lngLatLike, index) => {
        // left
        if (index === 0) {
          return lngLatLike > 0
            ? lngLatLike + BBOX_PADDING
            : lngLatLike - BBOX_PADDING
        }
        // right
        if (index === 2) {
          return lngLatLike > 0
            ? lngLatLike - BBOX_PADDING
            : lngLatLike + BBOX_PADDING
        }

        return lngLatLike
      }) as maplibregl.LngLatBoundsLike

      if (bbox) {
        mapRef.current?.fitBounds(bbox)
      }
    }
  }, [selectedJob])

  // colour the selected job's leg
  useEffect(() => {
    if (selectedJob) {
      const job = jobs.find(j => j.id === selectedJob)

      // is this a stacked job?
      if (job && job.stackId) {
        const legRoute = createLegRoute({
          routeJSON: job.route as string,
          legPosition: job.dropOffTasks[0].orderInStack,
        })

        if (legRoute) {
          const { id, source, layer } = legRoute

          if (legLayerRef.current) {
            //remove the previous leg route
            mapRef.current?.removeLayer(legLayerRef.current.id)
          }
          legLayerRef.current = layer

          if (mapRef.current) {
            mapRef.current.addSource(id, source)
            mapRef.current.addLayer(layer)
          }
        }
      }
    } else {
      // remove the leg route
      if (mapRef.current) {
        if (legLayerRef.current) {
          //remove the previous leg route
          mapRef.current?.removeLayer(legLayerRef.current.id)
        }
        legLayerRef.current = undefined
      }
    }
  }, [selectedJob])

  useEffect(() => {
    if (selectedJob) {
      // hide everything except the selected job
      hideTasksAndRoutesExceptSelectedJob(selectedJob)
    } else {
      // redraw everything
      redrawHiddenTasksAndRoutes()
    }
  }, [selectedJob])

  const updateMapRef = (map: Map) => {
    map.on('load', () => {
      mapRef.current = map
      // needed for the initial rendering
      drawTasksAndRoutes()
      addResetControl()
    })
  }

  return (
    <MapSection
      mapFunctionCallback={updateMapRef}
      zoom={12}
      mapSize={{
        height: 'calc(100vh - 96px)',
        width: 'calc(100vw)',
      }}
    />
  )
}
