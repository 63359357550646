import React, { useContext } from 'react'
import { useQuery } from '@apollo/client'
import {
  ErrorState,
  LoadingState,
} from '../../../components/NonIdealState/NonIdealState'
import { Drawer, DrawerSize, Icon, Intent } from '@blueprintjs/core'
import startCase from 'lodash.startcase'
import { LIST_USERS } from './gql/users'
import { TableView } from '../../../components/Views/Table'
import { StringParam, useQueryParam } from 'use-query-params'
import { UserDrawer } from './UserDrawer'
import { QUERY_PARAMS } from '../../../utils/queryParamsNames'
import { DialogWrapper } from '../../../dialogs/styles'
import { PageLayoutContext } from '../../../providers/PageLayoutProvider'

export const Users = () => {
  const { darkMode } = useContext(PageLayoutContext)
  const { data, loading, error } = useQuery(LIST_USERS)
  const [userId, setUserId] = useQueryParam(QUERY_PARAMS.userId, StringParam)

  if (error) {
    return <ErrorState entityName="users" />
  }

  if (loading || !data) {
    return <LoadingState entityName="users" />
  }

  const users = data.users

  return (
    <>
      <TableView>
        <thead>
          <tr>
            <th>Role</th>
            <th>Name</th>
            <th>Email</th>
            <th>Phone Number</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {users.map(
            ({
              id,
              firstName,
              lastName,
              email,
              phoneNumber,
              role,
              enabled,
              userStatus,
            }) => (
              <tr key={id} onClick={() => setUserId(id)}>
                <td>{role}</td>
                <td>
                  {firstName || 'n.a'} {lastName}{' '}
                  {!enabled && <Icon icon="disable" intent={Intent.DANGER} />}{' '}
                </td>
                <td>{email || 'n.a'}</td>
                <td>{phoneNumber || 'n.a'}</td>
                <td>{startCase(userStatus.toLowerCase())}</td>
              </tr>
            )
          )}
        </tbody>
      </TableView>
      <Drawer
        isOpen={!!userId}
        title="Edit User Details"
        onClose={() => {
          setUserId(null)
        }}
        size={DrawerSize.SMALL}
        className={darkMode ? 'bp5-dark' : ''}
      >
        {!!userId && (
          <DialogWrapper
            className={darkMode ? 'bp5-dark' : ''}
            darkMode={darkMode}
          >
            <UserDrawer userId={userId} />
          </DialogWrapper>
        )}
      </Drawer>
    </>
  )
}
