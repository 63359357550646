import { HTMLSelect } from '@blueprintjs/core'
import React from 'react'
import { DistanceUnit } from '../../zeus'
import { BaseSelectProps } from './types'
import capitalize from 'lodash/capitalize'

export const DistanceUnitSelect = (props: BaseSelectProps) => {
  const units: DistanceUnit[] = [DistanceUnit.MILES, DistanceUnit.KILOMETERS]

  const statusEntries = units.map(unit => ({
    value: unit,
    label: capitalize(unit.slice(0, -1)),
  }))

  return <HTMLSelect {...props} options={statusEntries} />
}
