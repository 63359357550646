import { Intent, Position, Toaster } from '@blueprintjs/core'

const errorToast = Toaster.create({ position: Position.TOP })

const successToast = Toaster.create({ position: Position.BOTTOM_RIGHT })

export enum ToastType {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

interface UseToasterArgs {
  type: ToastType
}

type ToastFunction = (message: string) => void

const toastMapping: { [key in ToastType]: ToastFunction } = {
  [ToastType.ERROR]: (message: string) =>
    errorToast.show({ message, intent: Intent.DANGER }),
  [ToastType.SUCCESS]: (message: string) =>
    successToast.show({ message, intent: Intent.SUCCESS }),
  [ToastType.WARNING]: (message: string) =>
    successToast.show({ message, intent: Intent.WARNING }),
}

export const useToaster = ({
  type,
}: UseToasterArgs): ToastFunction => message => toastMapping[type](message)
