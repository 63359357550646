import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'
import { ResultOf } from '@graphql-typed-document-node/core'

export const FLEET_BY_ID = typedGql('query')({
  fleet: [
    {
      id: $('id', 'String!'),
    },
    {
      id: true,
      name: true,
      leadTime: true,
      jobAcceptWaitTime: true,
      jobDropOffAllocatedTime: true,
      outrightRejectJob: true,
      distanceUnit: true,
      pricePerDistanceUnit: true,
      defaultCostPerDistanceUnit: true,
      contactFullName: true,
      contactEmail: true,
      contactPhone: true,
      contactFullAddress: true,
      partner: {
        id: true,
        name: true,
      },
    },
  ],
})

export type FLEET_BY_ID_RESPONSE = ResultOf<typeof FLEET_BY_ID>
