import { useQuery } from '@apollo/client'
import {
  HTMLTable,
  Card,
  Icon,
  IconSize,
  Tooltip,
  Intent,
} from '@blueprintjs/core'
import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import {
  EmptyListState,
  ErrorState,
  LoadingState,
} from '../../components/NonIdealState/NonIdealState'
import { AddFleetDialog } from '../../dialogs/AddFleetDialog'
import { DialogContext } from '../../providers/DialogProvider'
import { configurePageLayout } from '../../providers/PageLayoutProvider'
import { LIST_FLEETS } from './gql/fleets'
import { SortOrder } from '../../zeus'
import { TableView } from '../../components/Views/Table'
import { Explanation } from '../../atomics/Explanation'

export const FleetsContent = () => {
  const { openDialog, closeDialog } = useContext(DialogContext)

  configurePageLayout({
    tabs: [],
    button: {
      onClick: () =>
        openDialog({
          title: 'Add Fleet',
          content: <AddFleetDialog closeDialog={closeDialog} />,
        }),
      text: 'Add Fleet',
    },
  })
  const { data, loading, error } = useQuery(LIST_FLEETS, {
    variables: {
      OrderFleetsByNameDirecton: SortOrder.asc,
    },
  })

  if (loading) {
    return <LoadingState entityName="fleets" />
  }

  if (error) {
    return <ErrorState entityName="fleets" />
  }

  const fleets = data?.fleets || []

  if (!fleets.length) {
    return <EmptyListState entityName="fleets" />
  }

  return (
    <TableView>
      <>
        <thead>
          <tr>
            <th>Name</th>
            <th>Partner</th>
            <th>
              <span>Lead Time</span>&nbsp;
              <Explanation
                sup
                color="gray"
                content="
                Used for preorders.
                If the pickup time is inside this interval
                the job assignment logic will be triggered immediately"
              />
            </th>
            <th>
              <span>Wait Time</span>&nbsp;
              <Explanation
                sup
                color="gray"
                content="
                Used during the job assignment.
                It represents the amount
                of time a job will wait for the driver to accept
                a job before rejecting / searching for other driver"
              />
            </th>
            <th>
              <span>Drop Off Time</span>&nbsp;
              <Explanation
                sup
                color="gray"
                content="Represents the amount of times a driver has to fulfil a drop off"
              />
            </th>
            <th>
              <span>Cancel Jobs</span>&nbsp;
              <Explanation
                sup
                color="gray"
                content="
                Automatically cancels a job when no drivers can be assigned.
                The switch should be turned off if a human operator is required for this action."
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {fleets.map(
            ({
              id,
              name,
              partner,
              leadTime,
              jobAcceptWaitTime,
              jobDropOffAllocatedTime,
              outrightRejectJob,
              contactPhone,
            }) => (
              <tr key={id}>
                <td>
                  <Link to={`/fleets/${id}`}>{name}</Link>
                  {!contactPhone && (
                    <>
                      &nbsp;
                      <Explanation
                        sup
                        intent={Intent.WARNING}
                        icon="warning-sign"
                        data-testid="warning-icon"
                        content="No contact phone"
                      />
                    </>
                  )}
                </td>
                <td>{partner?.name ?? '---'}</td>
                <td>{leadTime} min</td>
                <td>{jobAcceptWaitTime} sec</td>
                <td>{jobDropOffAllocatedTime} sec</td>
                <td>
                  {outrightRejectJob && (
                    <Icon intent={Intent.WARNING} icon="pivot" />
                  )}
                </td>
              </tr>
            )
          )}
        </tbody>
      </>
    </TableView>
  )
}
