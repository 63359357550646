import React from 'react'
import { Icon, IconName, Intent, Tooltip } from '@blueprintjs/core'

type ExplanationProps = {
  content: string | JSX.Element
  size?: number
  icon?: IconName
  color?: string // overwrites intent
  intent?: Intent
  sup?: boolean
  style?: React.CSSProperties
}

export const Explanation = ({
  content,
  size = 10,
  icon = 'info-sign',
  color,
  intent,
  sup,
  style,
}: ExplanationProps) => (
  <Tooltip content={content}>
    {sup ? (
      <sup>
        <Icon
          size={size}
          style={style}
          icon={icon}
          color={color}
          intent={intent}
        />
      </sup>
    ) : (
      <Icon
        size={size}
        style={style}
        icon={icon}
        color={color}
        intent={intent}
      />
    )}
  </Tooltip>
)
