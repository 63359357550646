import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'
import { ResultOf } from '@graphql-typed-document-node/core'

export const WEBHOOKS_BY_FLEET = typedGql('query')({
  fleet: [
    {
      id: $('id', 'String!'),
    },
    {
      id: true,
      name: true,
      webhooks: {
        id: true,
        name: true,
        url: true,
        isRedboxApi: true,
        apiKeyHeader: true,
        apiKeyValue: true,
        forJobStatus: true,
        createdAt: true,
        updatedAt: true,
      },
    },
  ],
})

export type WEBHOOKS_BY_FLEET_RESPONSE = ResultOf<typeof WEBHOOKS_BY_FLEET>
