import { HTMLTable } from '@blueprintjs/core'
import styled from 'styled-components'

export const TableContainer = styled.div`
  background: #f4f8fa;
  width: 100%;
 
`

export const Table = styled(HTMLTable)<{ darkMode?: boolean }>`
  background-color: #ffffff;
  position: relative;
  top: 0px;
  border-collapse: collapse;

  th {
    position: sticky !important;
    z-index: 1;
    top: 96px !important;
    background-color: ${props => (props.darkMode ? '#2f343c' : '#ffffff')};
    box-shadow: 0 1px 0px 0px ${props => (props.darkMode ? 'rgba(78,92,104,255)' : 'rgba(223, 223, 224, 1)')};
    border: none;
  }

  tr {
    background-color: ${props => (props.darkMode && '#2f343c')};
  }
`

