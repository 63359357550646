import React from 'react'
import { Button, Popover, Position } from '@blueprintjs/core'
import { DatePicker } from '@blueprintjs/datetime'
import { add, clamp, differenceInDays, sub, endOfDay, Duration } from 'date-fns'
import { isFuture } from 'date-fns'
import { DatePickerButton } from './DatePickerButton'
import { formatToCustomDayBoundary, YEARLESS_FORMAT } from './datetime'

type CustomRangeSelectorProps = {
  startOfRangeDate: Date
  setStartOfRangeDate: (date: Date) => void
  endOfRangeDate: Date
  setEndOfRangeDate: (date: Date) => void
  maxCustomRangeInterval: Duration
  minimal?: boolean
  disabled?: boolean
  offsetHours?: number
}

export const CustomRangeSelector = ({
  startOfRangeDate,
  setStartOfRangeDate,
  endOfRangeDate,
  setEndOfRangeDate,
  maxCustomRangeInterval,
  minimal = false,
  disabled = false,
  offsetHours = 0,
}: CustomRangeSelectorProps) => {
  return (
    <>
      <Button
        rightIcon="caret-left"
        disabled={disabled}
        minimal={minimal}
        onClick={() => {
          setStartOfRangeDate(sub(startOfRangeDate, { days: 1 }))
          setEndOfRangeDate(sub(endOfRangeDate, { days: 1 }))
        }}
      />
      <Popover
        position={Position.BOTTOM_LEFT}
        boundary={document.body}
        content={
          <DatePicker
            value={startOfRangeDate}
            maxDate={endOfDay(new Date())}
            highlightCurrentDay
            onChange={(selectedDate, isUserChange) => {
              if (selectedDate && isUserChange) {
                // move startOfRangeDate to start of selectedDate
                setStartOfRangeDate(selectedDate)
                // move endOfRangeDate by the same amount of days
                setEndOfRangeDate(
                  clamp(
                    add(endOfRangeDate, {
                      days: differenceInDays(selectedDate, startOfRangeDate),
                    }),
                    {
                      start: selectedDate,
                      end: endOfDay(new Date()),
                    }
                  )
                )
              }
            }}
          />
        }
      >
        <DatePickerButton minimal={minimal} disabled={disabled}>
          {formatToCustomDayBoundary({
            date: startOfRangeDate,
            formatStr: YEARLESS_FORMAT,
            offsetHours,
          })}
        </DatePickerButton>
      </Popover>

      <Button disabled={true} minimal={minimal}>
        to
      </Button>

      {
        // endOfRange selector
      }
      <Popover
        position={Position.BOTTOM_LEFT}
        content={
          <DatePicker
            value={endOfRangeDate}
            minDate={endOfDay(startOfRangeDate)}
            highlightCurrentDay
            maxDate={clamp(
              add(endOfDay(startOfRangeDate), maxCustomRangeInterval),
              {
                start: startOfRangeDate,
                end: endOfDay(new Date()),
              }
            )}
            onChange={(selectedDate, isUserChange) => {
              if (selectedDate && isUserChange) {
                setEndOfRangeDate(endOfDay(selectedDate))
              }
            }}
          />
        }
      >
        <DatePickerButton minimal={minimal} disabled={disabled}>
          {formatToCustomDayBoundary({
            date: endOfRangeDate,
            formatStr: YEARLESS_FORMAT,
            offsetHours,
          })}
        </DatePickerButton>
      </Popover>

      <Button
        rightIcon="caret-right"
        minimal={minimal}
        onClick={() => {
          setStartOfRangeDate(add(startOfRangeDate, { days: 1 }))
          setEndOfRangeDate(
            clamp(add(endOfRangeDate, { days: 1 }), {
              start: endOfRangeDate,
              end: endOfDay(new Date()),
            })
          )
        }}
        disabled={disabled || isFuture(add(startOfRangeDate, { days: 1 }))}
      />
    </>
  )
}
