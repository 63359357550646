import React, { FC, useContext } from 'react'
import { FilterBarContainer } from './styles'
import omit from 'lodash/omit'
import { PageLayoutContext } from '../../providers/PageLayoutProvider'

type FilterBarProps = {
  flexDirection?: 'row' | 'column'
  marginBottom?: string
  justifyEnd?: boolean
  children: React.ReactNode
}

export const FilterBar: FC<FilterBarProps> = props => {
  const propsWithoutChildren = omit(props, 'children')
  const { darkMode } = useContext(PageLayoutContext)

  return (
    <FilterBarContainer darkMode={darkMode} {...propsWithoutChildren}>
      {props.children}
    </FilterBarContainer>
  )
}
