import { Intent, Tab, Tabs, Tag } from '@blueprintjs/core'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { TabBarContainer } from './TabBar.styles'
import { PageLayoutTab } from './types'

// Temporary hack to draw tags until configurePageLayout is updated
// ideally the Tab should receive children
export const TabBar = ({ tabs }: { tabs: PageLayoutTab[] }) => {
  const history = useHistory()
  const currentPath = history.location.pathname
  const currentTab = currentPath.split('/').pop()

  return (
    <TabBarContainer>
      <Tabs
        onChange={route => history.push(route.toString())}
        selectedTabId={currentTab}
      >
        {tabs.map(
          ({ path, title, highlightNumber, intent, hide = false }) =>
            !hide && (
              <Tab key={path} id={path}>
                {title}
                {highlightNumber ? (
                  <>
                    {'\xa0\xa0'}
                    <Tag
                      intent={intent || Intent.DANGER}
                    >{`${highlightNumber}`}</Tag>
                  </>
                ) : null}
              </Tab>
            )
        )}
      </Tabs>
    </TabBarContainer>
  )
}
