import React, { FunctionComponent } from 'react'
import {
  ApolloProvider as ApolloLibProvider,
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { TOKEN_KEY_NAME } from './SessionTokenProvider'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { getPayloadFromToken } from '../auth/getPayloadFromToken'
import { Toast } from '@blueprintjs/core'
import { OverlayToaster, Position } from '@blueprintjs/core'

export const GQLErrorToaster = OverlayToaster.create({
  position: Position.TOP,
})

// log all queries and mutations with their variables as they are sent to the server
const TRACE_GQL = true
// log all responses from the server (errors are always logged)
// set to false for less verbose output
const TRACE_GQL_RESPONSES = false

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(TOKEN_KEY_NAME)
  const tokenPayload = getPayloadFromToken(token || '')
  const tokenExpiryTimestampInSeconds = tokenPayload?.exp

  // handle expired token
  if (
    tokenExpiryTimestampInSeconds &&
    tokenExpiryTimestampInSeconds * 1000 < new Date().getTime()
  ) {
    location.assign('/expiredToken')
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const serverWs =
  process.env.REACT_APP_GRAPHQL_SERVER_WS || 'ws://localhost:8888'
const serverUri =
  process.env.REACT_APP_GRAPHQL_SERVER || 'http://localhost:8888'

const wsLink = new GraphQLWsLink(
  createClient({
    url: serverWs,
    async connectionParams() {
      const token = localStorage.getItem(TOKEN_KEY_NAME)
      return {
        authorization: token ? `Bearer ${token}` : '',
      }
    },
  })
)

const httpLink = new HttpLink({
  uri: serverUri,
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)

    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  httpLink
)

// Add error logging for all operations
const errorLink = new ApolloLink((operation, forward) => {
  // Called before operation is sent to server

  const start = new Date().valueOf()

  const { query, variables } = operation
  const type =
    (query as any)?.definitions?.[0]?.operation?.toUpperCase?.() || 'UNKNOWN'
  const resolver =
    (query as any)?.definitions?.[0]?.selectionSet?.selections[0]?.name
      ?.value || ''

  const observable = forward(operation)

  return observable.map(data => {
    // console.log('ob3');
    const time = new Date().valueOf() - start

    if (data.errors) {
      data.errors.forEach(err => {
        console.error(`[GQL ${type} ERROR] ${resolver}`, err.message)

        if (err.extensions?.code === 'CLIENT_ERROR') {
          // toast it!
          GQLErrorToaster.show({
            message: err.message,
            intent: 'danger',
            timeout: 3000,
          })
        } else {
          GQLErrorToaster.show({
            message: 'Something went wrong. Please try again later.',
            intent: 'danger',
            timeout: 3000,
          })
        }
      })
    }

    TRACE_GQL &&
      console.log(
        `[GQL ${type} @ ${time}ms] ${resolver}(${Object.entries(variables)
          .map(([k, v]) => `${k}: ${JSON.stringify(v)}`)
          .join(', ')})`
      )

    TRACE_GQL &&
      TRACE_GQL_RESPONSES &&
      console.log(`[GQL RESP ${resolver}]`, JSON.stringify(data, null, 2))

    return data
  })
})

const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, splitLink]),
  cache: new InMemoryCache(),
})

export const ApolloProvider: FunctionComponent = ({ children }) => {
  return <ApolloLibProvider client={client}>{children}</ApolloLibProvider>
}
