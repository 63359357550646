import { NonIdealState } from '@blueprintjs/core'
import React, { useContext } from 'react'

import { CurrentUserContext } from '../../providers/CurrentUserProvider'
import { LoginForm } from './LoginForm'
import { ResetPasswordForm } from './ResetPasswordForm'
import { RequestResetPasswordForm } from './RequestResetPasswordForm'
// import { ConfirmationForm } from './ConfirmationForm'
// import { CompleteSignUpForm } from './CompleteSignUpForm'

export const LoginContentConditional = () => {
  const {
    loggedIn,
    // currentUser,
    requestResetPassword,
    resetPassword,
    // needsConfirmation,
    // completeSignUp,
  } = useContext(CurrentUserContext)

  if (requestResetPassword) {
    return <RequestResetPasswordForm />
  }

  if (resetPassword) {
    return <ResetPasswordForm />
  }

  if (!loggedIn) {
    return <LoginForm />
  }

  // if (completeSignUp) {
  //   return <CompleteSignUpForm />
  // }

  // if (needsConfirmation) {
  //   return <ConfirmationForm />
  // }

  return (
    <NonIdealState
      icon="error"
      title="Unexpected error"
      description="There was an issue logging you in"
    />
  )
}
