import React from 'react'
import { useMutation } from '@apollo/client'
import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
  NumericInput,
  Spinner,
} from '@blueprintjs/core'
import { DialogBody, DialogFooter } from '@blueprintjs/core'
import { useFormik } from 'formik'
import { PartnerSelect } from '../components/Select/PartnerSelect'
import { IBaseDialogProps } from './types'
import { CREATE_FLEET } from '../pages/Fleets/gql/createFleet'
import { ToastType, useToaster } from '../hooks/useToaster'
import { LIST_FLEETS } from '../pages/Fleets/gql/fleets'

export const AddFleetDialog = ({ closeDialog }: IBaseDialogProps) => {
  const openToast = useToaster({ type: ToastType.SUCCESS })
  const [createFleet] = useMutation(CREATE_FLEET, {
    refetchQueries: [LIST_FLEETS],
  })

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: {
      name: '',
      partnerId: '',
      leadTime: 45,
    },
    onSubmit: async values => {
      await createFleet({
        variables: {
          name: values.name,
          partnerId: values.partnerId,
          leadTime: values.leadTime,
        },
      })

      openToast('Fleet successfully created')
      closeDialog()
    },
  })

  return (
    <>
      <DialogBody>
        <form onSubmit={handleSubmit}>
          <FormGroup label="Name">
            <InputGroup
              name="name"
              onChange={handleChange}
              intent={errors.name ? Intent.DANGER : Intent.NONE}
              value={values.name}
            />
          </FormGroup>

          <FormGroup label="Partner">
            <PartnerSelect
              name="partnerId"
              onChange={handleChange}
              value={values.partnerId}
              fill
            />
          </FormGroup>

          <FormGroup label="Lead Time minutes">
            <NumericInput
              name="leadTime"
              onValueChange={number => setFieldValue('leadTime', number)}
              intent={errors.name ? Intent.DANGER : Intent.NONE}
              value={values.leadTime}
              fill
              allowNumericCharactersOnly
              min={0}
            />
          </FormGroup>

          <DialogFooter>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={closeDialog}>Cancel</Button>
              <Button
                intent={Intent.PRIMARY}
                type="submit"
                icon={isSubmitting && <Spinner />}
              >
                Save
              </Button>
            </div>
          </DialogFooter>
        </form>
      </DialogBody>
    </>
  )
}
