import { Button, Intent, ProgressBar } from '@blueprintjs/core'
import React, { useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

const START_COUNTER = 5 // seconds

export const ExpiredTokenForm = () => {
  const [counter, setCounter] = useState<number>(START_COUNTER)
  const history = useHistory()

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(prevVal => (prevVal <= 0 ? 0 : (prevVal -= 1)))
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return counter <= 0 ? (
    <Redirect to="/" />
  ) : (
    <>
      <p>Your authentication token expired.</p>
      <p>
        You'll be automatically redirected to the login page in {counter}{' '}
        seconds
      </p>
      <ProgressBar intent={Intent.PRIMARY} value={counter / START_COUNTER} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginTop: '15px',
        }}
      >
        <Button
          text="Redirect Now"
          minimal
          outlined
          intent={Intent.PRIMARY}
          onClick={() => history.push('/login')}
        />
      </div>
    </>
  )
}
