import React from 'react'
import { ContactDetails, Location } from '../../utils/location'

export const LocationDetails = ({ location }: { location: Location }) => {
  if (!location || !location.address) return null

  return (
    <>
      <p>
        <small>{location.address.firstLine}</small>
      </p>
      <p>
        <small>{location.address.secondLine}</small>
      </p>
      <p>
        <small>{location.address.thirdLine}</small>
      </p>
      <p>
        <small>{location.address.postcode}</small>
      </p>
      <p>
        <small>{location.address.city}</small>
      </p>
    </>
  )
}

export const Contact = ({
  contact,
  notes,
}: {
  contact: ContactDetails
  notes?: string
}) => (
  <>
    <p>
      <small>{contact.phone}</small>
    </p>
    {!!contact.name && (
      <p>
        <small>{contact.name}</small>
      </p>
    )}
    {!!notes && (
      <p>
        <small>{notes}</small>
      </p>
    )}
  </>
)
