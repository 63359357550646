import { $ } from '../../../../zeus'
import { typedGql } from '../../../../zeus/typedDocumentNode'

export const AVAILABLE_NODES_BY_USER_AND_ROLE = typedGql('query')({
  availableNodesByUserAndDesiredRole: [
    {
      input: $('input', 'AvailableNodesByUserAndDesiredRoleInput!'),
    },
    {
      id: true,
      name: true,
    },
  ],
})
