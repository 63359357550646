import { Card } from '@blueprintjs/core'
import { isEmpty } from 'lodash'
import React from 'react'
import { ModelTypes } from '../../zeus'
import { GET_JOB_RESPONSE_TYPE } from './gql/job'
import { AllocatedTimeContainer } from './styles'

type Address = {
  firstLine: string
  secondLine: string
  thirdLine: string
  postcode: string
  city: string
}

type PartialTaskUnion = Pick<
  ModelTypes['PickupTask'],
  'id' | 'locationName' | 'location' | 'pickupNotes' | 'pickupContact'
> &
  Pick<
    ModelTypes['DropOffTask'],
    'id' | 'locationName' | 'location' | 'dropOffNotes' | 'dropOffContact'
  >

const Task = ({ task }: { task: PartialTaskUnion }) => {
  const {
    address,
    longitude,
    latitude,
  }: { address: Address; longitude: number; latitude: number } = task.location
  const notes = task.pickupNotes ?? task.dropOffNotes
  const contact: ModelTypes['ContactInput'] =
    task.pickupContact ?? task.dropOffContact

  return (
    <div>
      <strong>{task.locationName}</strong>
      {address && (
        <>
          <p>{address.firstLine}</p>
          <p>{address.secondLine}</p>
          <p>{address.thirdLine}</p>
          <p>{address.postcode}</p>
          <p>{address.city}</p>
        </>
      )}
      <p>Long/Lat</p>
      <p>{`{${longitude},${latitude}}`}</p>
      {notes && <p>Notes: {notes}</p>}
      {!isEmpty(contact) && (
        <>
          <p>Contact: {contact.phone}</p>
          {contact.name && <p>{contact.name}</p>}
        </>
      )}
    </div>
  )
}

export const TasksPanel = ({ job }: { job: GET_JOB_RESPONSE_TYPE['job'] }) => {
  if (!job) return null

  const { pickupTasks, dropOffTasks } = job
  return (
    <Card>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <div>
          <p>Pickup</p>
          {pickupTasks.map(task => task && <Task key={task.id} task={task} />)}
        </div>
        <div>
          <p>
            DropOff{' '}
            {
              <AllocatedTimeContainer>
                ({dropOffTasks[0].allocatedTime} sec)
              </AllocatedTimeContainer>
            }
          </p>
          {dropOffTasks.map(task => task && <Task key={task.id} task={task} />)}
        </div>
      </div>
    </Card>
  )
}
