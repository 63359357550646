import { typedGql } from '../../../zeus/typedDocumentNode'
import { $ } from '../../../zeus'

export const BOOKED_AND_AVAILABLE_OPTIONS_FOR_DRIVER_STATUS = typedGql('query')(
  {
    bookedAndAvailableOptionsForDriverStatus: [
      {
        driverId: $('driverId', 'String!'),
      },
      {
        id: true,
        status: true,
        label: true,
        minimalLabel: true,
        minStartTime: true,
        maxStartTime: true,
        durationInMinutes: true,
        booked: true,
      },
    ],
  }
)
