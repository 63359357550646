import { $ } from '../../../../zeus'
import { typedGql } from '../../../../zeus/typedDocumentNode'

export const UPDATE_USER = typedGql('mutation')({
  updateUser: [
    {
      input: $('input', 'UserUpdateInput!'),
    },
    { id: true },
  ],
})
