import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const LIST_FLEETS = typedGql('query')({
  fleets: [
    {
      orderBy: {
        name: $('OrderFleetsByNameDirecton', 'SortOrder!'),
      },
    },
    {
      id: true,
      name: true,
      leadTime: true,
      jobAcceptWaitTime: true,
      jobDropOffAllocatedTime: true,
      outrightRejectJob: true,
      distanceUnit: true,
      pricePerDistanceUnit: true,
      defaultCostPerDistanceUnit: true,
      contactPhone: true,
      partner: {
        id: true,
        name: true,
      },
    },
  ],
})
