import React from 'react'
import { Tooltip } from '@blueprintjs/core'
import { ReactSVG } from 'react-svg'

type ExplanationSVGProps = {
  content: string | JSX.Element
  size?: number
  reactSVG: string
}

export const ExplanationSVG = ({
  content,
  size = 16,
  reactSVG,
}: ExplanationSVGProps) => (
  <Tooltip content={content}>
    <ReactSVG
      src={reactSVG}
      beforeInjection={svg => {
        svg.setAttribute('style', `width: ${size}px; height: ${size}px;`)
      }}
    />
  </Tooltip>
)
