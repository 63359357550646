export const awsConfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_ID_POOL,
    region: 'eu-west-1',
  },
  geo: {
    AmazonLocationService: {
      maps: {
        items: {
          waybox_v2_map: {
            style: 'Default style',
          },
        },
        default: 'waybox_v2_map',
      },
      region: 'eu-west-1',
    },
  },
}
