import React, { Component, Fragment } from 'react'
import { Card } from '@blueprintjs/core'

export class ErrorBoundary extends Component<
  {},
  { hasError: boolean; errorLocation: string | null; error: Error | null }
> {
  constructor (props: never) {
    super(props)
    this.state = { hasError: false, errorLocation: null, error: null }
  }

  componentDidCatch (error: Error) {
    this.setState({
      hasError: true,
      errorLocation: window.location.pathname,
      error
    })
  }

  componentDidUpdate () {
    if (
      typeof this.state.errorLocation === 'string' &&
      this.state.errorLocation !== window.location.pathname
    ) {
      this.setState({ hasError: false, errorLocation: null, error: null })
    }
  }

  render () {
    if (this.state.hasError) {
      return (
        <Fragment>
          <h1>Something went wrong.</h1>
          {process.env.ENVIRONMENT_NAME !== 'PROD'
            ? (
            <Card>
              <pre>
                <code>
                  {this.state.error?.message}
                  <br />
                  {this.state.error?.stack}
                </code>
              </pre>
            </Card>
              )
            : null}
        </Fragment>
      )
    }

    return this.props.children
  }
}
