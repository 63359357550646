import { Collapse, Icon, IconSize, Intent, Tag } from '@blueprintjs/core'
import React, { VFC, Fragment, useEffect } from 'react'
import { SUPPLEMENTARY_DATA_RESPONSE } from '../gql/supplementaryData'
import { filter, orderBy, uniqueId } from 'lodash'
import { Link } from 'react-router-dom'
import { intervalToDuration, formatDuration } from 'date-fns'
import { StringParam, useQueryParam } from 'use-query-params'
import { LIST_DRIVERS_RESPONSE } from '../gql/listDrivers'
import { TableView } from '../../../components/Views/Table'
import { EllipsisText } from '../../../atomics/EllipsisText'
import { TasksTableEntry } from '../../../components/TasksTableEntry'
import omit from 'lodash/omit'
import { Explanation } from '../../../atomics/Explanation'
import { QUERY_PARAMS } from '../../../utils/queryParamsNames'
import {
  StatusTag,
  driverStatusColors,
  jobStatusColors,
} from '../../../styles/status'
import { shortenedDuration } from '../../../atomics/ShortenedDuration'

type DriversTableProps = {
  drivers: LIST_DRIVERS_RESPONSE['drivers']
  jobs: SUPPLEMENTARY_DATA_RESPONSE['jobs']
}
export const DriversTable: VFC<DriversTableProps> = ({ drivers, jobs }) => {
  const [selectedDriver, setSelectedDriver] = useQueryParam(
    QUERY_PARAMS.selectedDriver,
    StringParam
  )

  useEffect(() => {
    if (selectedDriver) {
      const el = document.getElementById(selectedDriver)
      el?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selectedDriver])

  // match drivers with recent jobs
  // TODO update once we have a model of current and future jobs per driver
  const driversWithJobs = drivers!.map(driver => {
    const recentJobs = filter(jobs, { driverId: driver.id })
    const [recentJob] = orderBy(recentJobs, 'estimatedPickupTime', 'asc')

    const stackOfJobs = recentJob?.stackId
      ? filter(jobs, { stackId: recentJob.stackId })
      : undefined

    return {
      driverId: driver.id,
      ...omit(driver, 'id'),
      ...omit(recentJob, 'status'), // don't overwrite the driver status
      jobStatus: recentJob?.status, // use a syntehtic field to display the job status
      stackOfJobs,
    }
  })

  const icon = <Icon size={12} icon="symbol-circle" />

  return (
    <TableView>
      <thead>
        <tr>
          <th></th>
          <th>Driver</th>
          <th>Status</th>
          <th>Job</th>
          <th>Status</th>
          <th>Age</th>
        </tr>
      </thead>
      <tbody>
        {driversWithJobs.map(entry => (
          <Fragment key={entry.driverId}>
            <tr>
              <td style={{ maxWidth: '1rem' }}>
                <Icon
                  style={{
                    cursor: 'pointer',
                  }}
                  icon={
                    selectedDriver === entry.driverId
                      ? 'chevron-up'
                      : 'chevron-down'
                  }
                  onClick={() => {
                    setSelectedDriver(
                      selectedDriver === entry.driverId
                        ? undefined
                        : entry.driverId
                    )
                  }}
                />
              </td>
              <td>
                <Link to={`/drivers/${entry.driverId}`}>
                  <EllipsisText>
                    <Explanation
                      icon="layers"
                      intent={
                        entry.allowStackingOrders ? Intent.PRIMARY : Intent.NONE
                      }
                      size={IconSize.STANDARD}
                      content={`Driver is ${
                        !entry.allowStackingOrders ? 'NOT' : ''
                      } suitable to stack orders`}
                      style={{
                        marginRight: 10,
                        opacity: entry.allowStackingOrders ? 1 : 0.2,
                      }}
                    />
                    <Explanation
                      icon="pivot"
                      intent={
                        entry.allowJobRejection ? Intent.DANGER : Intent.NONE
                      }
                      size={IconSize.STANDARD}
                      content={`Driver can ${
                        !entry.allowJobRejection ? 'NOT' : ''
                      } accept / reject jobs`}
                      style={{
                        marginRight: 10,
                        opacity: entry.allowJobRejection ? 1 : 0.2,
                      }}
                    />
                    {entry.firstName && entry.lastName
                      ? entry.firstName.concat(' ', entry.lastName)
                      : entry.phoneNumber}
                  </EllipsisText>
                </Link>
              </td>
              <td>
                <StatusTag
                  intent={driverStatusColors[entry.status].intent}
                  icon={icon}
                >
                  {entry.status.replaceAll('_', ' ')}
                </StatusTag>
              </td>
              <td>
                {' '}
                {entry.stackId ? (
                  <Link to={`/jobs?stackId=${entry.stackId}`}>
                    <EllipsisText>Stack {entry.stackId}</EllipsisText>
                  </Link>
                ) : entry.id ? (
                  <Link to={`/jobs/${entry.id}`}>{entry.jobReference}</Link>
                ) : (
                  '---'
                )}
              </td>
              <td>
                {entry.jobStatus ? (
                  <StatusTag
                    intent={jobStatusColors[entry.jobStatus].intent}
                    icon={
                      <Explanation
                        icon={entry.onHold ? 'pause' : 'play'}
                        size={12}
                        content={`Job is ${!entry.onHold ? 'NOT' : ''} on hold`}
                        style={{ marginBottom: 1.5 }}
                      />
                    }
                  >
                    {entry.jobStatus.replaceAll('_', ' ')}
                  </StatusTag>
                ) : (
                  '---'
                )}
              </td>
              <td>
                {entry.createdAt
                  ? shortenedDuration(
                      intervalToDuration({
                        start: new Date(entry.createdAt as string),
                        end: new Date(),
                      })
                    )
                  : '---'}
              </td>
            </tr>
            <tr id={entry.driverId || uniqueId()}>
              <td colSpan={8} style={{ padding: 0 }}>
                <Collapse isOpen={selectedDriver === entry.driverId}>
                  <TasksTableEntry
                    pickupTasks={entry.pickupTasks || []}
                    dropOffTasks={entry.dropOffTasks || []}
                    stackOfJobs={entry.stackOfJobs}
                  />
                </Collapse>
              </td>
            </tr>
          </Fragment>
        ))}
      </tbody>
    </TableView>
  )
}
