import { typedGql } from '../../../../zeus/typedDocumentNode'
import { $ } from '../../../../zeus'

export const COMPLETE_USER_PROFILE = typedGql('mutation')({
  completeUserProfile: [
    {
      input: $('input', 'CompleteUserProfileInput!'),
    },
    true,
  ],
})
