import React from 'react'
import { DriverStatus, ModelTypes } from '../../../zeus'
import { BreakContainer, UnstyledParagraph } from './styles'
import { SCALING_FACTOR } from './Timeline'

export const BreakBlock = ({
  block,
  startingTimeInMillisec,
  additionalLeftShift = 0,
}: {
  block: ModelTypes['OptionForDriverStatus']
  startingTimeInMillisec: number
  additionalLeftShift?: number
}) => {
  const blockStartingTime = new Date(block.minStartTime!) // filtered out in the parent
  // size based on duration
  const width = (block.durationInMinutes ?? 30) * SCALING_FACTOR

  let left: number | undefined = undefined
  let pickupInMillisec = 0
  if (startingTimeInMillisec) {
    // conditionally position based on start time
    // a negative diff -> pickup was in the past
    pickupInMillisec = blockStartingTime.getTime()
    const diffInMillisec = pickupInMillisec - startingTimeInMillisec
    left = Math.floor(diffInMillisec / 1000 / 60) * SCALING_FACTOR
  }

  // apply additional left shift
  if (left) {
    left = left - Math.floor(additionalLeftShift) * SCALING_FACTOR
  }

  return (
    <div
      key={block.id}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <BreakContainer
        width={width}
        status={block.status as DriverStatus}
        left={left}
      >
        <UnstyledParagraph>{block.label}</UnstyledParagraph>
      </BreakContainer>
    </div>
  )
}
