import React from 'react'
import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
  Spinner,
} from '@blueprintjs/core'
import { DialogBody, DialogFooter } from '@blueprintjs/core'
import { useFormik } from 'formik'

import { IBaseDialogProps } from './types'
import { useMutation } from '@apollo/client'
import { PlatformSelect } from '../components/Select/PlatformSelect'
import { ToastType, useToaster } from '../hooks/useToaster'
import { CREATE_PARTNER } from '../pages/Partners/gql/createPartner'
import { LIST_PARTNERS } from '../pages/Partners/gql/partners'

export const AddPartnerDialog = ({ closeDialog }: IBaseDialogProps) => {
  const [createPartner] = useMutation(CREATE_PARTNER, {
    refetchQueries: [LIST_PARTNERS],
  })

  const openToast = useToaster({ type: ToastType.SUCCESS })

  const { values, handleChange, handleSubmit, errors, isSubmitting } =
    useFormik({
      initialValues: {
        name: '',
        platformId: '',
      },
      onSubmit: async values => {
        await createPartner({
          variables: {
            name: values.name,
            platformId: values.platformId,
          },
        })

        openToast('Partner successfully created')

        closeDialog()
      },
    })

  return (
    <>
      <DialogBody>
        <form onSubmit={handleSubmit}>
          <FormGroup label="Name">
            <InputGroup
              name="name"
              onChange={handleChange}
              intent={errors.name ? Intent.DANGER : Intent.NONE}
              value={values.name}
              large
            />
          </FormGroup>

          <FormGroup label="Platform">
            <PlatformSelect
              name="platformId"
              onChange={handleChange}
              value={values.platformId}
              fill
            />
          </FormGroup>
          <DialogFooter>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={closeDialog} minimal disabled={isSubmitting}>
                Cancel
              </Button>
              <Button type="submit" loading={isSubmitting}>
                Save
              </Button>
            </div>
          </DialogFooter>
        </form>
      </DialogBody>
    </>
  )
}
