import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const DELETE_DRIVER = typedGql('mutation')({
  deleteDriver: [
    {
      driverId: $('driverId', 'String!'),
    },
    {
      id: true,
    },
  ],
})
