import { RouteType } from '../../../components/MapSection/createRoute'

export const parseRoute = (route: string): RouteType | undefined => {
  let parsedRoute: RouteType | undefined = undefined

  try {
    parsedRoute = JSON.parse(route)
  } catch (err) {
    console.warn('Error parsing route', route)
  }

  return parsedRoute
}
