import { Intent, Tag } from '@blueprintjs/core'
import { DriverStatus, JobStatus } from '../zeus'
import styled from 'styled-components';

// TODO update tags to use these colors
// bleuprintjs intent colors are mapped to primary colors + grey
export const jobStatusColors: Record<
  JobStatus,
  { color: string; intent: Intent }
> = {
  [JobStatus.PENDING]: {
    color: 'grey',
    intent: Intent.NONE,
  },
  [JobStatus.AWAITING_ASSIGNMENT]: {
    color: 'grey',
    intent: Intent.NONE,
  },
  [JobStatus.ASSIGNED]: {
    color: 'blue',
    intent: Intent.PRIMARY,
  },
  [JobStatus.TRAVELLING_TO_PICKUP]: {
    color: 'blue',
    intent: Intent.PRIMARY,
  },
  [JobStatus.ALMOST_AT_PICKUP]: {
    color: 'blue',
    intent: Intent.PRIMARY,
  },
  [JobStatus.WAITING_AT_PICKUP]: {
    color: 'blue',
    intent: Intent.WARNING,
  },
  [JobStatus.TRAVELLING_TO_DROP_OFF]: {
    color: 'blue',
    intent: Intent.PRIMARY,
  },
  [JobStatus.ALMOST_AT_DROP_OFF]: {
    color: 'blue',
    intent: Intent.PRIMARY,
  },
  [JobStatus.WAITING_AT_DROP_OFF]: {
    color: 'blue',
    intent: Intent.WARNING,
  },
  [JobStatus.CANCELLED]: {
    color: 'orange',
    intent: Intent.DANGER,
  },
  [JobStatus.EXCEPTION]: {
    color: 'red',
    intent: Intent.DANGER,
  },
  [JobStatus.COMPLETE]: {
    color: 'green',
    intent: Intent.SUCCESS,
  },
}

export const driverStatusColors: Record<
  DriverStatus,
  { color: string; intent: Intent }
> = {
  [DriverStatus.ACTIVE]: { color: 'blue', intent: Intent.PRIMARY },
  [DriverStatus.NO_LONGER_RECEIVING_NEW_JOBS]: {
    color: 'red',
    intent: Intent.DANGER,
  },
  [DriverStatus.ON_BREAK]: { color: 'orange', intent: Intent.WARNING },
  [DriverStatus.INACTIVE]: { color: 'grey', intent: Intent.NONE },
}

export const StatusTag = styled(Tag)`
  border-radius: 20px;
  padding-right: 10px;
  opacity: 0.9
`
export const BreaksTag = styled(Tag)`
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
  opacity: 0.9;
`


