import React from 'react'
import { ModelTypes } from '../../zeus'
import { TableView } from '../Views/Table'
import { TasksTableEntryContainer } from './styles'
import { Contact, LocationDetails } from './Details'
import { SUPPLEMENTARY_DATA_RESPONSE } from '../../pages/Dashboard/gql/supplementaryData'

type PartialPickupTask = Pick<
  ModelTypes['PickupTask'],
  'id' | 'locationName' | 'location' | 'pickupContact' | 'pickupNotes'
>
type PartialDropOffTask = Pick<
  ModelTypes['DropOffTask'],
  | 'id'
  | 'locationName'
  | 'location'
  | 'dropOffContact'
  | 'dropOffNotes'
  | 'orderInStack'
>

const OrderInStackToLetterMap: Record<number, string> = {
  0: 'B',
  1: 'C',
  2: 'D',
}

export const TasksTableEntry = ({
  pickupTasks,
  dropOffTasks,
  showAddress = false,
  showContact = false,
  stacked = false,
  stackOfJobs,
}: {
  pickupTasks: PartialPickupTask[]
  dropOffTasks: PartialDropOffTask[]
  showAddress?: boolean
  showContact?: boolean
  stacked?: boolean
  stackOfJobs?: SUPPLEMENTARY_DATA_RESPONSE['jobs']
}) => {
  const dropOffTaskOrderInSequence = dropOffTasks[0]?.orderInStack

  return (
    <TasksTableEntryContainer>
      {pickupTasks.length && dropOffTasks.length ? (
        <TableView>
          <thead>
            <tr>
              <th>Pickup</th>
              <th>
                {stacked
                  ? `DropOff (${OrderInStackToLetterMap[dropOffTaskOrderInSequence]})`
                  : 'DropOff'}
              </th>
            </tr>
          </thead>
          <tbody>
            {stackOfJobs && stackOfJobs.length ? (
              <>
                {stackOfJobs.map(jobInStack => (
                  <tr key={jobInStack.id}>
                    <td>{jobInStack.pickupTasks[0].locationName}</td>
                    <td>
                      {jobInStack.dropOffTasks[0].locationName +
                        ` (${
                          OrderInStackToLetterMap[
                            jobInStack.dropOffTasks[0].orderInStack
                          ]
                        })`}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <>
                <tr>
                  <td>{pickupTasks[0].locationName}</td>
                  <td>{dropOffTasks[0].locationName}</td>
                </tr>
                {showAddress && (
                  <>
                    <tr>
                      <td>
                        <LocationDetails location={pickupTasks[0].location} />
                      </td>
                      <td>
                        <LocationDetails location={dropOffTasks[0].location} />
                      </td>
                    </tr>
                  </>
                )}
                {showContact && (
                  <>
                    <tr>
                      <td>
                        <Contact
                          contact={pickupTasks[0].pickupContact}
                          notes={pickupTasks[0].pickupNotes}
                        />
                      </td>
                      <td>
                        <Contact
                          contact={dropOffTasks[0].dropOffContact}
                          notes={dropOffTasks[0].dropOffNotes}
                        />
                      </td>
                    </tr>
                  </>
                )}
              </>
            )}
          </tbody>
        </TableView>
      ) : null}
    </TasksTableEntryContainer>
  )
}
