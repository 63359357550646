import { Button, MenuItem, Position } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import { format, getYear, setMonth } from 'date-fns'
import React, { Fragment } from 'react'

const getMonthNameFromIndex = (index: number) =>
  format(setMonth(new Date(), index), 'MMMM')

type MonthType = {
  key: string
  name: string
  index: number
}
const MONTHS: MonthType[] = []

while (Object.values(MONTHS).length < 12) {
  const index = Object.values(MONTHS).length
  const name = getMonthNameFromIndex(index)
  const key = name.toUpperCase()
  MONTHS.push({
    key,
    name,
    index,
  })
}

const MonthSelect = Select.ofType<MonthType>()
const YearSelect = Select.ofType<number>()

type MonthAndYearSelectorProps = {
  monthIndex: number
  setChangeMonth: (monthIndex: number) => void
  year: number
  setChangeYear: (year: number) => void
  minimal?: boolean
  disabled?: boolean
}
export const MonthAndYearSelector = ({
  monthIndex,
  setChangeMonth,
  year,
  setChangeYear,
  minimal = false,
  disabled = false,
}: MonthAndYearSelectorProps) => {
  const currentSelectedMonth = MONTHS[monthIndex]
  const now = new Date()
  const lastFiveYears = [
    getYear(now),
    getYear(now) - 1,
    getYear(now) - 2,
    getYear(now) - 3,
    getYear(now) - 4,
  ]
  const currentSelectedYear = year

  return (
    <>
      <MonthSelect
        filterable={false}
        items={MONTHS}
        onItemSelect={_ => {}}
        itemRenderer={(month: MonthType) => {
          return (
            <MenuItem
              key={month.key}
              onClick={() => {
                setChangeMonth(month.index)
              }}
              text={month.name}
              icon={
                currentSelectedMonth.index === month.index ? 'tick' : 'blank'
              }
              shouldDismissPopover={false}
            />
          )
        }}
        popoverProps={{
          minimal: false,
          position: Position.BOTTOM_LEFT,
          boundary: document.body,
        }}
      >
        <Button
          rightIcon="double-caret-vertical"
          disabled={disabled}
          minimal={minimal}
        >
          {currentSelectedMonth.name}
        </Button>
      </MonthSelect>
      <YearSelect
        filterable={false}
        items={lastFiveYears}
        onItemSelect={_ => {}}
        itemRenderer={(year: number) => {
          return (
            <MenuItem
              key={year}
              onClick={() => {
                setChangeYear(year)
              }}
              text={year}
              icon={currentSelectedYear === year ? 'tick' : 'blank'}
              shouldDismissPopover={false}
            />
          )
        }}
        popoverProps={{
          minimal: false,
          position: Position.BOTTOM_LEFT,
          boundary: document.body,
        }}
      >
        <Button
          rightIcon="double-caret-vertical"
          disabled={disabled}
          minimal={minimal}
        >
          {currentSelectedYear}
        </Button>
      </YearSelect>
    </>
  )
}
