import { typedGql } from '../../../zeus/typedDocumentNode'
import { $ } from '../../../zeus'

export const FLEET_DETAILS = typedGql('query')({
  fleet: [
    {
      id: $('id', 'String!'),
    },
    {
      id: true,
      leadTime: true,
    },
  ],
})
