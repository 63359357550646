import { HTMLSelect } from '@blueprintjs/core'
import React from 'react'
import { UserStatus } from '../../zeus'
import { BaseSelectProps } from './types'
import capitalize from 'lodash/capitalize'

export const UserStatusSelect = (props: BaseSelectProps) => {
  const statuses: UserStatus[] = [
    UserStatus.ARCHIVED,
    UserStatus.COMPROMISED,
    UserStatus.CONFIRMED,
    UserStatus.FORCE_CHANGE_PASSWORD,
    UserStatus.RESET_REQUIRED,
    UserStatus.UNCONFIRMED,
    UserStatus.UNKNOWN,
  ]

  const statusEntries = statuses.map(status => ({
    value: status,
    label: capitalize(status.replaceAll('_', ' ')),
  }))

  return (
    <HTMLSelect
      {...props}
      options={[{ value: '', label: 'Select a status' }, ...statusEntries]}
    />
  )
}
