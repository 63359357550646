import React, { Fragment, useContext } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import {
  Navbar,
  NavBottomSection,
  NavContainer,
  NavMainSection,
} from './Nav.styles'
import userIcon from '../../assets/icons/user.svg'
import dashboardIcon from '../../assets/icons/dashboard.svg'
import platformIcon from '../../assets/icons/platform.svg'
import partnersIcon from '../../assets/icons/partners.svg'
import settingsIcon from '../../assets/icons/settings.svg'
import reportsIcon from '../../assets/icons/reports.svg'
import fleetsIcon from '../../assets/icons/fleet.svg'
import driverIcon from '../../assets/icons/driver.svg'
import jobsIcon from '../../assets/icons/journey.svg'

import supportIcon from '../../assets/icons/support.svg'
import { NavIcon } from './NavIcon'
import {
  Menu,
  MenuDivider,
  MenuItem,
  Position,
  Tag,
  Popover,
  Icon,
} from '@blueprintjs/core'
import { CurrentUserContext } from '../../providers/CurrentUserProvider'
import { UserActionType } from '../../providers/currentUserState'
import { SessionTokenContext } from '../../providers/SessionTokenProvider'
import { UserRole } from '../../zeus'
import { useApolloClient } from '@apollo/client'
import { PageLayoutContext } from '../../providers/PageLayoutProvider'

export const Nav = () => {
  const { dispatch, currentUser } = useContext(CurrentUserContext)
  const { deleteStoredTokens } = useContext(SessionTokenContext)
  const { setDarkMode, darkMode } = useContext(PageLayoutContext)
  const history = useHistory()
  const { resetStore } = useApolloClient()

  return (
    <NavContainer>
      <Navbar darkMode={darkMode}>
        <NavMainSection>
          <NavLink to={'/dashboard'}>
            <NavIcon imagePath={dashboardIcon} tooltipContent="Dashboard" />
          </NavLink>
          <NavLink to="/platform">
            <NavIcon
              imagePath={platformIcon}
              tooltipContent="Platform"
              restrictToRoles={[UserRole.ADMIN]}
            />
          </NavLink>
          <NavLink to="/partners">
            <NavIcon
              imagePath={partnersIcon}
              tooltipContent="Partners"
              restrictToRoles={[UserRole.ADMIN]}
            />
          </NavLink>
          <NavLink to="/fleets">
            <NavIcon
              imagePath={fleetsIcon}
              tooltipContent="Fleets"
              restrictToRoles={[UserRole.ADMIN, UserRole.PARTNER_USER]}
            />
          </NavLink>
          <NavLink to="/drivers">
            <NavIcon imagePath={driverIcon} tooltipContent="Drivers" />
          </NavLink>
          <NavLink to="/jobs">
            <NavIcon imagePath={jobsIcon} tooltipContent="Jobs" />
          </NavLink>
          {/* <NavLink to="/reports">
            <NavIcon imagePath={reportsIcon} tooltipContent="Reports" />
          </NavLink> */}
          <NavLink to="/admin">
            <NavIcon
              imagePath={settingsIcon}
              tooltipContent="Administrator Settings"
            />
          </NavLink>
        </NavMainSection>
        <NavBottomSection>
          <Popover
            content={
              <Menu>
                <MenuDivider />
                {process.env.ENVIRONMENT_NAME !== 'PROD' && (
                  <MenuItem
                    text="Environment"
                    onClick={() =>
                      window.open('http://status.redbox.systems', '_blank')
                    }
                  />
                )}
                <MenuItem
                  text="Status"
                  // ooo thats looks fun
                  // labelElement={
                  // <Fetch
                  //   url={
                  //     "https://w86zt0h66nqt.statuspage.io/api/v2/status.json"
                  //   }
                  // >
                  //   {({ loading, data }) =>
                  //     !loading && (
                  //       <Fragment>
                  //         {data.status.indicator == "none" && (
                  //           <Tag intent="success">OK</Tag>
                  //         )}
                  //         {data.status.indicator == "minor" && (
                  //           <Tag intent="warning">MINOR</Tag>
                  //         )}
                  //         {data.status.indicator == "major" && (
                  //           <Tag intent="danger">INCIDENT</Tag>
                  //         )}
                  //         {data.status.indicator == "critical" && (
                  //           <Tag intent="danger">CRITICAL</Tag>
                  //         )}
                  //       </Fragment>
                  //     )
                  //   }
                  // </Fetch>
                  // }
                  onClick={() =>
                    window.open('http://status.redbox.systems', '_blank')
                  }
                />
              </Menu>
            }
            position={Position.RIGHT}
          >
            <NavIcon imagePath={supportIcon} tooltipContent="Support" />
          </Popover>
          <Popover
            content={
              <Menu>
                {currentUser && (
                  <Fragment>
                    <MenuItem
                      icon={'person'}
                      text={`${currentUser?.firstName} ${currentUser?.lastName}`}
                      labelElement={
                        <Tag minimal>
                          {(currentUser?.role || '').toUpperCase()}
                        </Tag>
                      }
                      onClick={() =>
                        history.push(`/admin/users?userId=${currentUser?.id}`)
                      }
                    />
                    <MenuDivider />
                  </Fragment>
                )}
                <MenuItem
                  text={darkMode ? 'Light Mode' : 'Dark Mode'}
                  labelElement={<Icon icon={darkMode ? 'flash' : 'moon'} />}
                  onClick={() => setDarkMode(!darkMode)}
                />
                {currentUser?.id ? (
                  <MenuItem
                    text="Edit Profile"
                    onClick={() =>
                      history.push(`/admin/users?userId=${currentUser?.id}`)
                    }
                  />
                ) : null}
                <MenuItem
                  text="Logout"
                  onClick={() => {
                    deleteStoredTokens()
                    resetStore()
                    dispatch({ type: UserActionType.LOGOUT })
                  }}
                />
              </Menu>
            }
            position={Position.RIGHT}
          >
            <NavIcon imagePath={userIcon} tooltipContent="Profile" />
          </Popover>
        </NavBottomSection>
      </Navbar>
    </NavContainer>
  )
}
