import { ResultOf } from '@graphql-typed-document-node/core'
import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const DASHBOARD_JOBS = typedGql('query')({
  jobs: [
    {
      where: $('where', 'JobWhereInput!'),
      orderBy: $('orderBy', 'JobOrderByInput'),
    },
    {
      id: true,
      jobReference: true,
      stackId: true,
      createdAt: true,
      updatedAt: true,
      estimatedPickupTime: true,
      pickupTime: true,
      estimatedDurationMinutes: true,
      status: true,
      onHold: true,
      orderReference: true,
      orderId: true,
      orderIsAsap: true,
      fleet: {
        id: true,
        leadTime: true,
        name: true,
      },
      driver: {
        id: true,
        firstName: true,
        lastName: true,
        phoneNumber: true,
        status: true,
        allowJobRejection: true,
        allowStackingOrders: true,
      },
      assignmentHistory: {
        id: true,
      },
      route: true,
      pickupTasks: {
        id: true,
        __typename: true,
        location: true,
        locationName: true,
        pickupNotes: true,
        pickupContact: true,
      },
      dropOffTasks: {
        id: true,
        __typename: true,
        location: true,
        locationName: true,
        dropOffNotes: true,
        dropOffContact: true,
        orderInStack: true,
        allocatedTime: true,
      },
    },
  ],
})

export const DASHBOARD_JOBS_SUBSCRIPTION = typedGql('subscription')({
  jobs: [
    {
      where: $('where', 'JobWhereInput!'),
      orderBy: $('orderBy', 'JobOrderByInput'),
    },
    {
      id: true,
      jobReference: true,
      stackId: true,
      createdAt: true,
      updatedAt: true,
      estimatedPickupTime: true,
      pickupTime: true,
      estimatedDurationMinutes: true,
      status: true,
      onHold: true,
      orderReference: true,
      orderId: true,
      orderIsAsap: true,
      fleet: {
        id: true,
        leadTime: true,
        name: true,
      },
      driver: {
        id: true,
        firstName: true,
        lastName: true,
        phoneNumber: true,
        status: true,
        allowJobRejection: true,
        allowStackingOrders: true,
      },
      assignmentHistory: {
        id: true,
      },
      route: true,
      pickupTasks: {
        id: true,
        __typename: true,
        location: true,
        locationName: true,
        pickupNotes: true,
        pickupContact: true,
      },
      dropOffTasks: {
        id: true,
        __typename: true,
        location: true,
        locationName: true,
        dropOffNotes: true,
        dropOffContact: true,
        orderInStack: true,
        allocatedTime: true,
      },
    },
  ],
})

export type DASHBOARD_JOBS_RESPONSE = ResultOf<typeof DASHBOARD_JOBS>
