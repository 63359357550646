import React from 'react'
import { Button, Classes, Popover, Position } from '@blueprintjs/core'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import { DateRangePicker } from '@blueprintjs/datetime'
import {
  add,
  clamp,
  isFuture,
  sub,
  endOfDay,
  startOfWeek,
  endOfWeek,
} from 'date-fns'
import {
  formatToCustomDayBoundary,
  ISO_WEEK_DAY,
  YEARLESS_FORMAT,
} from './datetime'

type WeekSelectorProps = {
  startOfWeekDate: Date
  setStartOfWeekDate: (date: Date) => void
  minimal?: boolean
  disabled?: boolean
  offsetHours?: number
}
export const WeekSelector = ({
  startOfWeekDate,
  setStartOfWeekDate,
  minimal = false,
  disabled = false,
  offsetHours = 0,
}: WeekSelectorProps) => {
  return (
    <>
      <Button
        rightIcon="caret-left"
        disabled={disabled}
        minimal={minimal}
        onClick={() => {
          setStartOfWeekDate(sub(startOfWeekDate, { weeks: 1 }))
        }}
      />
      <Popover
        position={Position.BOTTOM_LEFT}
        content={
          <DateRangePicker
            value={[
              startOfWeekDate,
              clamp(
                endOfWeek(startOfWeekDate, {
                  weekStartsOn: ISO_WEEK_DAY.MONDAY,
                }),
                {
                  start: startOfWeekDate,
                  end: endOfDay(new Date()),
                }
              ),
            ]}
            maxDate={endOfDay(new Date())}
            highlightCurrentDay
            singleMonthOnly
            shortcuts={false}
            onChange={([selectedDate]) => {
              if (selectedDate) {
                setStartOfWeekDate(
                  startOfWeek(selectedDate, {
                    weekStartsOn: ISO_WEEK_DAY.MONDAY,
                  })
                )
              }
            }}
          />
        }
      >
        <Button
          disabled={disabled}
          minimal={minimal}
          rightIcon="double-caret-vertical"
        >
          {formatToCustomDayBoundary({
            date: startOfWeekDate,
            formatStr: YEARLESS_FORMAT,
            offsetHours,
          })}
          {'  '}
          <span className="bp5-text-disabled">to</span>
          {'  '}
          {formatToCustomDayBoundary({
            date: endOfWeek(startOfWeekDate, {
              weekStartsOn: ISO_WEEK_DAY.MONDAY,
            }),
            formatStr: YEARLESS_FORMAT,
            offsetHours,
          })}
        </Button>
      </Popover>
      <Button
        rightIcon="caret-right"
        minimal={minimal}
        onClick={() => {
          setStartOfWeekDate(add(startOfWeekDate, { weeks: 1 }))
        }}
        disabled={disabled || isFuture(add(startOfWeekDate, { weeks: 1 }))}
      />
    </>
  )
}
