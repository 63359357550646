import React, { useState } from 'react'
import { Button, InputGroup, Intent } from '@blueprintjs/core'

export const PasswordField = ({
  name,
  value,
  intent,
  large,
  onChange: handleChange,
}: {
  name: string
  value: string
  intent: Intent
  large?: boolean
  onChange?: React.ChangeEventHandler
}) => {
  const [passwordFieldType, setPasswordFieldType] = useState<
    'text' | 'password'
  >('password')

  return (
    <InputGroup
      large={!!large}
      name={name}
      type={passwordFieldType}
      onChange={handleChange}
      intent={intent}
      value={value}
      rightElement={
        <Button
          minimal
          icon={passwordFieldType === 'password' ? 'eye-off' : 'eye-open'}
          intent={Intent.NONE}
          onClick={() => {
            setPasswordFieldType(prevState =>
              prevState === 'password' ? 'text' : 'password'
            )
          }}
        />
      }
    />
  )
}
