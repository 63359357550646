import { useMutation } from '@apollo/client'
import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
  Spinner,
} from '@blueprintjs/core'
import { DialogBody, DialogFooter } from '@blueprintjs/core'
import { useFormik } from 'formik'
import React from 'react'
import { ToastType, useToaster } from '../hooks/useToaster'
import { IBaseDialogProps } from './types'
import { CREATE_PLATFORM } from '../pages/Platform/gql/createPlatform'
import { LIST_PLATFORMS } from '../pages/Platform/gql/platforms'

export const AddPlatformDialog = ({ closeDialog }: IBaseDialogProps) => {
  const [createPlatform, { loading, error }] = useMutation(CREATE_PLATFORM, {
    refetchQueries: [LIST_PLATFORMS],
  })

  const openErrorToast = useToaster({ type: ToastType.ERROR })

  const { values, handleChange, handleSubmit, errors, isSubmitting } =
    useFormik({
      initialValues: {
        name: '',
      },
      onSubmit: async values => {
        await createPlatform({ variables: { name: values.name } })

        closeDialog()
      },
    })

  if (loading) {
    return <Spinner />
  }

  if (error) {
    openErrorToast(error.message)
  }

  return (
    <>
      <DialogBody>
        <form onSubmit={handleSubmit}>
          <FormGroup label="name">
            <InputGroup
              name="name"
              onChange={handleChange}
              intent={errors.name ? Intent.DANGER : Intent.NONE}
              value={values.name}
              large
            />
          </FormGroup>

          <DialogFooter>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={closeDialog}>Cancel</Button>
              <Button
                intent={Intent.PRIMARY}
                type="submit"
                icon={isSubmitting && <Spinner />}
              >
                Save
              </Button>
            </div>
          </DialogFooter>
        </form>
      </DialogBody>
    </>
  )
}
