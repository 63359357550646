import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const INVITE_DRIVER = typedGql('mutation')({
  inviteDriver: [
    {
      input: $('input', 'DriverInviteInput!'),
    },
    { id: true },
  ],
})
