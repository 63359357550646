import React from 'react'
import { Card } from '@blueprintjs/core'
import { configurePageLayout } from '../../providers/PageLayoutProvider'
import { Redirect, Route, Switch } from 'react-router-dom'
import { DriversDashboard } from './DriversDashboard'
import { ExceptionsDashboard } from './ExceptionsDashboard/Exceptions'
import { JobsDashboard } from './Jobs/JobsDashboard'
import { useQuery } from '@apollo/client'
import { OLD_JOBS } from './gql/oldJobs'
import { PageLayoutTab } from '../../components/Header/types'
// import { AudioElement } from '../../components/AudioElement'
import { JobsTimelineDashboard } from './JobsTimelineDashboard'

export const Dashboard = () => {
  const { error, data } = useQuery(OLD_JOBS, { fetchPolicy: 'network-only' })
  if (error) {
    console.warn('Error getting old jobs', error)
  }

  const tabs: PageLayoutTab[] = [
    { path: 'drivers', title: 'Drivers' },
    { path: 'jobs', title: 'Jobs', highlightNumber: data?.jobs.length },
    { path: 'jobs-timeline', title: 'Jobs Timeline' },
    { path: 'exceptions', title: 'Exceptions' },
    // { path: 'orders', title: 'Orders' },
  ]

  configurePageLayout({
    tabs,
    button: null,
  })

  return (
    <>
      <Switch>
        <Redirect exact path="/dashboard" to="/dashboard/drivers" />
        <Route path="/dashboard/drivers">
          <DriversDashboard />
        </Route>
        <Route path="/dashboard/jobs">
          <JobsDashboard />
        </Route>
        <Route path="/dashboard/jobs-timeline">
          <JobsTimelineDashboard />
        </Route>
        <Route path="/dashboard/exceptions">
          <ExceptionsDashboard />
        </Route>
        {/* <Route path="/dashboard/orders">
          <Card>Orders</Card>
        </Route> */}
      </Switch>
      {/* {data?.jobs.length ? (
        <AudioElement
          audioFilePath={
            new URL('../../assets/sounds/alarm.mp3', import.meta.url).href
          }
        />
      ) : null} */}
    </>
  )
}
