import { typedGql } from '../../../zeus/typedDocumentNode'
import { $ } from '../../../zeus'
import { platformSelector } from './platformSelector'

export const CREATE_PLATFORM = typedGql('mutation')({
  createPlatform: [
    {
      input: {
        name: $('name', 'String!'),
      },
    },
    platformSelector,
  ],
})
