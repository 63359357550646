import { useMutation } from '@apollo/client'
import {
  Button,
  Card,
  Classes,
  FormGroup,
  H4,
  InputGroup,
  Intent,
} from '@blueprintjs/core'
import { DialogBody, DialogFooter } from '@blueprintjs/core'
import React from 'react'
import { ToastType, useToaster } from '../hooks/useToaster'
import { NOTIFY_DRIVER } from '../pages/Drivers/gql/notifyDriver'
import { useFormik } from 'formik'

export const NotifyDriverDialog = ({
  driverId,
  closeContainer,
}: {
  driverId: string
  closeContainer: Function
}) => {
  const openSuccessToast = useToaster({ type: ToastType.SUCCESS })
  const openErrorToast = useToaster({ type: ToastType.ERROR })
  const [notifyDriver, { loading }] = useMutation(NOTIFY_DRIVER, {
    onError: err => {
      openErrorToast(err.message || 'Failed to notify driver')
    },
    onCompleted: data => {
      if (data.notifyDriver) {
        openSuccessToast('Notification sent')
      } else {
        openErrorToast('Failed to notify driver')
      }
      closeContainer()
    },
  })

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      title: '',
      subtitle: '',
      body: '',
      data: '',
    },
    onSubmit: async values => {
      await notifyDriver({
        variables: {
          input: {
            driverId,
            ...values,
          },
        },
      })
    },
  })

  return (
    <Card style={{ width: '300px' }}>
      <H4>Notify Driver</H4>
      <DialogBody>
        <form onSubmit={handleSubmit}>
          <FormGroup label="Title">
            <InputGroup
              name="title"
              maxLength={30}
              onChange={handleChange}
              value={values.title}
              data-testid="title-input"
            />
          </FormGroup>

          <FormGroup label="Subtitle">
            <InputGroup
              name="subtitle"
              maxLength={30}
              onChange={handleChange}
              value={values.subtitle}
              data-testid="subtitle-input"
              placeholder="optional"
            />
          </FormGroup>

          <FormGroup label="Message">
            <InputGroup
              name="body"
              maxLength={50}
              onChange={handleChange}
              value={values.body}
              data-testid="message-input"
            />
          </FormGroup>

          <DialogFooter>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button minimal onClick={() => closeContainer()}>
                Cancel
              </Button>
              <Button minimal outlined intent={Intent.PRIMARY} type="submit">
                Notify
              </Button>
            </div>
          </DialogFooter>
        </form>
      </DialogBody>
    </Card>
  )
}
