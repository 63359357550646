import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const CREATE_API_IMPLEMENTATION = typedGql('mutation')({
  createApiImplementation: [
    {
      input: {
        apiKey: $('apiKey', 'String!'),
        name: $('name', 'String!'),
        fleet: {
          connect: {
            id: $('fleetId', 'String!'),
          },
        },
      },
    },
    {
      id: true,
      apiKey: true,
    },
  ],
})
