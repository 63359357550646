/* eslint-disable */

import { AllTypesProps, ReturnTypes, Ops } from './const';


export const HOST="Specify host"


export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json();
};

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  options?: OperationOptions;
  scalars?: ScalarDefinition;
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = [],
  ): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars,
      })(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false, vars);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const keyForDirectives = o.__directives ?? '';
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`;
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`;
    }
    const varsString = vars.map((v) => `${v.name}: ${v.graphQLType}`).join(', ');
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`;
  };
  return ibb;
};

export const Thunder =
  (fn: FetchFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: Record<string, unknown> }) =>
    fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
      ops?.variables,
    ).then((data) => {
      if (graphqlOptions?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: graphqlOptions.scalars,
          ops: Ops,
        });
      }
      return data;
    }) as Promise<InputType<GraphQLTypes[R], Z, SCLR>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
  (fn: SubscriptionFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: ExtractVariables<Z> }) => {
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], SCLR>;
    if (returnedFunction?.on && graphqlOptions?.scalars) {
      const wrapped = returnedFunction.on;
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, SCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, SCLR>) => {
          if (graphqlOptions?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: graphqlOptions.scalars,
                ops: Ops,
              }),
            );
          }
          return fnToCall(data);
        });
    }
    return returnedFunction;
  };

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: {
    operationOptions?: OperationOptions;
    scalars?: ScalarDefinition;
  },
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars,
  })(operation, o as VType);

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS,
  },
});

export const ZeusScalars = ZeusSelect<ScalarCoders>();

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp,
}: {
  ops: O;
  response: any;
  returns: ReturnTypesType;
  scalars?: Record<string, ScalarResolver | undefined>;
  initialOp: keyof O;
  initialZeusQuery: InputValueType | VType;
}) => {
  if (!scalars) {
    return response;
  }
  const builder = PrepareScalarPaths({
    ops,
    returns,
  });

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery);
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })('Query', response, ['Query']);
    return r;
  }
  return response;
};

export const traverseResponse = ({
  resolvers,
  scalarPaths,
}: {
  scalarPaths: { [x: string]: `scalar.${string}` };
  resolvers: {
    [x: string]: ScalarResolver | undefined;
  };
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map((eachO) => ibb(k, eachO, p));
    }
    const scalarPathString = p.join(SEPARATOR);
    const currentScalarString = scalarPaths[scalarPathString];
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode;
      if (currentDecoder) {
        return currentDecoder(o);
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o;
    }
    return Object.fromEntries(Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])]));
  };
  return ibb;
};

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | `scalar.${string}`
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions = {
  operationName?: string;
};

export type ScalarCoder = Record<string, (s: unknown) => string>;

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders;
};

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return;
  }
  const oKey = mappedParts[0];
  const returnP1 = returns[oKey];
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]];
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns);
    }
    return undefined;
  }
  return returnP1 as `scalar.${string}` | undefined;
};

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true,
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return;
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey];
      const isScalar = ExtractScalar(extractionArray, returns);
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar,
        };
        return partOfTree;
      }
      return {};
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false);
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(alias, operationName, operation, p, pOriginals, false);
        })
        .reduce((a, b) => ({
          ...a,
          ...b,
        }));
    }
    const keyName = root ? ops[k] : k;
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) =>
        ibb(k, k, v, [...p, purifyGraphQLKey(keyName || k)], [...pOriginals, purifyGraphQLKey(originalKey)], false),
      )
      .reduce((a, b) => ({
        ...a,
        ...b,
      }));
  };
  return ibb;
};

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1;
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not';
      }
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not';
        }
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not';
    }
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  scalars?: ScalarDefinition;
  vars: Array<{ name: string; graphQLType: string }>;
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR);
        const v = vars.find((v) => v.name === varName);
        if (!v) {
          vars.push({
            name: varName,
            graphQLType,
          });
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`,
            );
          }
        }
        return varName;
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p);
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.');
      const scalarKey = splittedScalar.join('.');
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a);
    }
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};

export const resolverFor = <X, T extends keyof ResolverInputTypes, Z extends keyof ResolverInputTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : any,
) => fn as (args?: any, source?: any) => any;

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
export type ScalarDefinition = Record<string, ScalarResolver>;

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S;
type IsArray<T, U, SCLR extends ScalarDefinition> = T extends Array<infer R>
  ? InputType<R, U, SCLR>[]
  : InputType<T, U, SCLR>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>;

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> = FlattenArray<SRC> extends
  | ZEUS_INTERFACES
  | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
          : Record<string, unknown>
        : never;
    }[keyof DST] & {
      [P in keyof Omit<
        Pick<
          SRC,
          {
            [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
          }[keyof DST]
        >,
        '__typename'
      >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>;
    }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
        ? IsScalar<SRC[P], SCLR>
        : IsArray<SRC[P], DST[P], SCLR>;
    };

export type MapType<SRC, DST, SCLR extends ScalarDefinition> = SRC extends DeepAnify<DST>
  ? IsInterfaced<SRC, DST, SCLR>
  : never;
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>];
    } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
  : MapType<SRC, IsPayLoad<DST>, SCLR>;
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void;
  open: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>;

export type ScalarResolver = {
  encode?: (s: unknown) => string;
  decode?: (s: unknown) => unknown;
};

export type SelectionFunction<V> = <T>(t: T | V) => T;

type BuiltInVariableTypes = {
  ['String']: string;
  ['Int']: number;
  ['Float']: number;
  ['ID']: unknown;
  ['Boolean']: boolean;
};
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES;
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`;
type VR<T extends string> = VariableRequired<VariableRequired<T>>;

export type GraphQLVariableType = VR<AllVariableTypes>;

type ExtractVariableTypeString<T extends string> = T extends VR<infer R1>
  ? R1 extends VR<infer R2>
    ? R2 extends VR<infer R3>
      ? R3 extends VR<infer R4>
        ? R4 extends VR<infer R5>
          ? R5
          : R4
        : R3
      : R2
    : R1
  : T;

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
  ? NonNullable<DecomposeType<R, Type>>
  : Type | undefined;

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
  ? BuiltInVariableTypes[T]
  : any;

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>;

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K;
}[keyof T];

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>;
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>;

type OptionalKeys<T> = {
  [P in keyof T]?: T[P];
};

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>;

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name;
  ' __zeus_type': T;
};

export type ExtractVariables<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends [infer Inputs, infer Outputs]
  ? ExtractVariables<Inputs> & ExtractVariables<Outputs>
  : Query extends string | number | boolean
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export const START_VAR_NAME = `$ZEUS_VAR`;
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`;

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>;
};
type ZEUS_INTERFACES = never
export type ScalarCoders = {
	DateTime?: ScalarResolver;
	JSON?: ScalarResolver;
}
type ZEUS_UNIONS = never

export type ValueTypes = {
    ["AddressInput"]: {
	city: string | Variable<any, string>,
	firstLine: string | Variable<any, string>,
	postcode: string | Variable<any, string>,
	secondLine: string | Variable<any, string>,
	thirdLine: string | Variable<any, string>
};
	["ApiImplementation"]: AliasType<{
	apiKey?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	fleet?:ValueTypes["Fleet"],
	fleetId?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ApiImplementationCreateInput"]: {
	apiKey: string | Variable<any, string>,
	fleet: ValueTypes["FleetConnectOneInput"] | Variable<any, string>,
	name: string | Variable<any, string>
};
	["ApiImplementationCreateNestedManyWithoutFleetInput"]: {
	connect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	create?: Array<ValueTypes["ApiImplementationCreateWithoutFleetInput"]> | undefined | null | Variable<any, string>
};
	["ApiImplementationCreateWithoutFleetInput"]: {
	apiKey: string | Variable<any, string>,
	name: string | Variable<any, string>
};
	["ApiImplementationListRelationFilter"]: {
	every?: ValueTypes["ApiImplementationWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["ApiImplementationWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["ApiImplementationWhereInput"] | undefined | null | Variable<any, string>
};
	["ApiImplementationOrderByInput"]: {
	apiKey?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fleet?: ValueTypes["FleetOrderByInput"] | undefined | null | Variable<any, string>,
	fleetId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["ApiImplementationOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["ApiImplementationUpdateInput"]: {
	apiKey?: string | undefined | null | Variable<any, string>,
	fleet?: ValueTypes["FleetConnectOneInput"] | undefined | null | Variable<any, string>,
	id: string | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>
};
	["ApiImplementationUpdateManyWithoutFleetInput"]: {
	connect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	create?: Array<ValueTypes["ApiImplementationCreateWithoutFleetInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>
};
	["ApiImplementationWhereInput"]: {
	AND?: Array<ValueTypes["ApiImplementationWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["ApiImplementationWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["ApiImplementationWhereInput"]> | undefined | null | Variable<any, string>,
	apiKey?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	fleet?: ValueTypes["FleetWhereInput"] | undefined | null | Variable<any, string>,
	fleetId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	model?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>
};
	["AssignJobToDriverInput"]: {
	driverId: string | Variable<any, string>,
	jobId: string | Variable<any, string>
};
	["AuthTokenType"]:AuthTokenType;
	["AvailableNodesByUserAndDesiredRoleInput"]: {
	desiredRole: ValueTypes["UserRole"] | Variable<any, string>
};
	["Base"]: AliasType<{
	address?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	fleet?:ValueTypes["Fleet"],
	fleetId?:boolean | `@${string}`,
	geo?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BaseCreateInput"]: {
	address: string | Variable<any, string>,
	fleet: ValueTypes["FleetConnectOneInput"] | Variable<any, string>,
	geo: string | Variable<any, string>,
	name: string | Variable<any, string>
};
	["BaseCreateNestedWithoutFleetInput"]: {
	connect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	create?: Array<ValueTypes["BaseCreateWithoutFleetInput"]> | undefined | null | Variable<any, string>
};
	["BaseCreateWithoutFleetInput"]: {
	address: string | Variable<any, string>,
	geo: string | Variable<any, string>,
	name: string | Variable<any, string>
};
	["BaseListRelationFilter"]: {
	every?: ValueTypes["BaseWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["BaseWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["BaseWhereInput"] | undefined | null | Variable<any, string>
};
	["BaseOrderByInput"]: {
	address?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fleet?: ValueTypes["FleetOrderByInput"] | undefined | null | Variable<any, string>,
	fleetId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	geo?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["BaseOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["BaseUpdateInput"]: {
	address?: string | undefined | null | Variable<any, string>,
	fleet?: ValueTypes["FleetConnectOneInput"] | undefined | null | Variable<any, string>,
	geo?: string | undefined | null | Variable<any, string>,
	id: string | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>
};
	["BaseUpdateManyWithoutFleetInput"]: {
	connect: Array<ValueTypes["WhereRequiredIdInput"]> | Variable<any, string>,
	create?: Array<ValueTypes["BaseCreateWithoutFleetInput"]> | undefined | null | Variable<any, string>,
	delete: Array<ValueTypes["WhereRequiredIdInput"]> | Variable<any, string>,
	disconnect: Array<ValueTypes["WhereRequiredIdInput"]> | Variable<any, string>,
	set: Array<ValueTypes["WhereRequiredIdInput"]> | Variable<any, string>
};
	["BaseWhereInput"]: {
	AND?: Array<ValueTypes["BaseWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["BaseWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["BaseWhereInput"]> | undefined | null | Variable<any, string>,
	address?: ValueTypes["JsonFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	fleet?: ValueTypes["FleetWhereInput"] | undefined | null | Variable<any, string>,
	fleetId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	geo?: ValueTypes["JsonFilter"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>
};
	["BooleanFilter"]: {
	equals?: boolean | undefined | null | Variable<any, string>,
	not?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>
};
	["CalculateRouteInput"]: {
	pointA: ValueTypes["CoordinatesInput"] | Variable<any, string>,
	pointB: ValueTypes["CoordinatesInput"] | Variable<any, string>
};
	["CancelJobInput"]: {
	canceledBy: ValueTypes["CanceledBy"] | Variable<any, string>,
	id: string | Variable<any, string>
};
	["CanceledBy"]:CanceledBy;
	["CollisionsOrStackability"]: AliasType<{
	isPlausible?:boolean | `@${string}`,
	isStackable?:boolean | `@${string}`,
	jobsOverlap?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CompleteUserProfileInput"]: {
	firstName: string | Variable<any, string>,
	key: string | Variable<any, string>,
	lastName: string | Variable<any, string>,
	password: string | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>
};
	["ContactInput"]: {
	name?: string | undefined | null | Variable<any, string>,
	phone: string | Variable<any, string>
};
	["Coordinates"]: AliasType<{
	latitude?:boolean | `@${string}`,
	longitude?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CoordinatesInput"]: {
	latitude: number | Variable<any, string>,
	longitude: number | Variable<any, string>
};
	["CreateUserResponse"]: AliasType<{
	id?:boolean | `@${string}`,
	jwtToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
["DateTime"]:unknown;
	["DateTimeFilter"]: {
	equals?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	gte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>,
	lt?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	lte?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	not?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["DateTime"]> | undefined | null | Variable<any, string>
};
	["DeliveryEstimate"]: AliasType<{
	costInPennies?:boolean | `@${string}`,
	dropoffCoordinates?:ValueTypes["Coordinates"],
	pickupCoordinates?:ValueTypes["Coordinates"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryEstimateInput"]: {
	dropoffCoordinates: ValueTypes["CoordinatesInput"] | Variable<any, string>,
	fleetId: string | Variable<any, string>,
	pickupCoordinates: ValueTypes["CoordinatesInput"] | Variable<any, string>
};
	["DistanceUnit"]:DistanceUnit;
	["DistanceUnitFilter"]: {
	equals?: ValueTypes["DistanceUnit"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["DistanceUnit"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["DistanceUnit"] | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["DistanceUnit"]> | undefined | null | Variable<any, string>
};
	["Driver"]: AliasType<{
	allowJobRejection?:boolean | `@${string}`,
	allowStackingOrders?:boolean | `@${string}`,
	breaks?:boolean | `@${string}`,
	completedSignUp?:boolean | `@${string}`,
	costPerDistanceUnit?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	currentLocation?:boolean | `@${string}`,
	distanceUnit?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	fleetContact?:ValueTypes["DriverFleetContact"],
	fleetDrivers?:ValueTypes["FleetDriver"],
	fleetGroups?:boolean | `@${string}`,
	heading?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	inactiveStartingAt?:boolean | `@${string}`,
	jobAssignments?:ValueTypes["JobAssignmentHistory"],
	jobs?:ValueTypes["Job"],
	lastName?:boolean | `@${string}`,
	middleNames?:boolean | `@${string}`,
	pauseNotifications?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	pushToken?:boolean | `@${string}`,
	/** Stores the associated sns endpoint ARN so the server can send push messages */
	snsEndpointARN?:boolean | `@${string}`,
	speed?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	/** Synthetic field that counts the number of completed jobs for the current shift */
	todaysJobsCounter?:boolean | `@${string}`,
	/** Synthetic field that counts the distance covered for the completed jobs for the current shift */
	todaysMilesCounter?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	vehicle?:ValueTypes["Vehicle"],
		__typename?: boolean | `@${string}`
}>;
	["DriverConnectOneInput"]: {
	connect: ValueTypes["WhereRequiredIdInput"] | Variable<any, string>
};
	["DriverConnectOrDisconnectInput"]: {
	connect?: ValueTypes["WhereRequiredIdInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>
};
	["DriverConnectOrDisconnectOneInput"]: {
	connect?: ValueTypes["WhereRequiredIdInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>
};
	["DriverCreateInput"]: {
	allowJobRejection?: boolean | undefined | null | Variable<any, string>,
	allowStackingOrders?: boolean | undefined | null | Variable<any, string>,
	completedSignUp?: boolean | undefined | null | Variable<any, string>,
	costPerDistanceUnit?: number | undefined | null | Variable<any, string>,
	currentLocation: string | Variable<any, string>,
	distanceUnit?: ValueTypes["DistanceUnit"] | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	firstName?: string | undefined | null | Variable<any, string>,
	fleetGroups: string | Variable<any, string>,
	fleetId: string | Variable<any, string>,
	lastName?: string | undefined | null | Variable<any, string>,
	middleNames?: string | undefined | null | Variable<any, string>,
	phoneNumber: string | Variable<any, string>,
	pushToken?: string | undefined | null | Variable<any, string>,
	status: ValueTypes["DriverStatus"] | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	vehicle?: ValueTypes["VehicleCreateNestedOneWithoutDriverInput"] | undefined | null | Variable<any, string>
};
	["DriverCreateNestedOneWithoutFleetDriversInput"]: {
	connect?: ValueTypes["WhereRequiredIdInput"] | undefined | null | Variable<any, string>,
	create?: ValueTypes["DriverCreateWithoutFleetDriversInput"] | undefined | null | Variable<any, string>
};
	["DriverCreateWithoutFleetDriversInput"]: {
	allowJobRejection?: boolean | undefined | null | Variable<any, string>,
	allowStackingOrders?: boolean | undefined | null | Variable<any, string>,
	completedSignUp?: boolean | undefined | null | Variable<any, string>,
	costPerDistanceUnit?: number | undefined | null | Variable<any, string>,
	currentLocation: string | Variable<any, string>,
	distanceUnit?: ValueTypes["DistanceUnit"] | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	firstName?: string | undefined | null | Variable<any, string>,
	fleetGroups: string | Variable<any, string>,
	lastName?: string | undefined | null | Variable<any, string>,
	middelNames?: string | undefined | null | Variable<any, string>,
	phoneNumber: string | Variable<any, string>,
	pushToken?: string | undefined | null | Variable<any, string>,
	status: ValueTypes["DriverStatus"] | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>,
	vehicle?: ValueTypes["VehicleCreateNestedOneWithoutDriverInput"] | undefined | null | Variable<any, string>
};
	["DriverFleetContact"]: AliasType<{
	fleetContactPhone?:boolean | `@${string}`,
	fleetName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DriverInviteInput"]: {
	email?: string | undefined | null | Variable<any, string>,
	firstName?: string | undefined | null | Variable<any, string>,
	fleetId: string | Variable<any, string>,
	lastName?: string | undefined | null | Variable<any, string>,
	phoneNumber: string | Variable<any, string>
};
	["DriverJobAssignmentStatus"]:DriverJobAssignmentStatus;
	/** Response from driver login */
["DriverLoginResponse"]: AliasType<{
	expireAt?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	token?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DriverOrderByInput"]: {
	allowJobRejection?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	allowStackingOrders?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	breaks?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	completedSignUp?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	costPerDistanceUnit?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	currentLocation?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	distanceUnit?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	firstName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fleetDrivers?: ValueTypes["FleetDriverOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	fleetGroups?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	inactiveStartingAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	jobAssignments?: ValueTypes["JobAssignmentHistoryOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	jobs?: ValueTypes["JobOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	lastName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	middleNames?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	phoneNumber?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pushToken?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	vehicle?: ValueTypes["VehicleOrderByInput"] | undefined | null | Variable<any, string>
};
	["DriverSetLocationInput"]: {
	currentLocation?: ValueTypes["LocationInput"] | undefined | null | Variable<any, string>,
	id: string | Variable<any, string>
};
	["DriverStatus"]:DriverStatus;
	["DriverUpdateDetailsInput"]: {
	allowJobRejection?: boolean | undefined | null | Variable<any, string>,
	allowStackingOrders?: boolean | undefined | null | Variable<any, string>,
	completedSignUp?: boolean | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	firstName?: string | undefined | null | Variable<any, string>,
	id: string | Variable<any, string>,
	lastName?: string | undefined | null | Variable<any, string>,
	middleNames?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>
};
	["DriverUpdateInput"]: {
	allowJobRejection?: boolean | undefined | null | Variable<any, string>,
	allowStackingOrders?: boolean | undefined | null | Variable<any, string>,
	completedSignUp?: boolean | undefined | null | Variable<any, string>,
	costPerDistanceUnit?: number | undefined | null | Variable<any, string>,
	currentLocation?: ValueTypes["LocationInput"] | undefined | null | Variable<any, string>,
	distanceUnit?: ValueTypes["DistanceUnit"] | undefined | null | Variable<any, string>,
	email?: string | undefined | null | Variable<any, string>,
	firstName?: string | undefined | null | Variable<any, string>,
	fleetGroups?: string | undefined | null | Variable<any, string>,
	fleetIds?: Array<string> | undefined | null | Variable<any, string>,
	heading?: number | undefined | null | Variable<any, string>,
	id: string | Variable<any, string>,
	lastName?: string | undefined | null | Variable<any, string>,
	middleNames?: string | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	pushToken?: string | undefined | null | Variable<any, string>,
	speed?: number | undefined | null | Variable<any, string>,
	status?: ValueTypes["DriverStatus"] | undefined | null | Variable<any, string>,
	title?: string | undefined | null | Variable<any, string>
};
	["DriverUpdatePushTokenInput"]: {
	id: string | Variable<any, string>,
	isAppleDevice: boolean | Variable<any, string>,
	pushToken: string | Variable<any, string>
};
	["DriverWhereInput"]: {
	AND?: Array<ValueTypes["DriverWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["DriverWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["DriverWhereInput"]> | undefined | null | Variable<any, string>,
	allowJobRejection?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,
	allowStackingOrders?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,
	breaks?: ValueTypes["JsonFilter"] | undefined | null | Variable<any, string>,
	completedSignUp?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,
	costPerDistanceUnit?: number | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	currentLocation?: ValueTypes["JsonFilter"] | undefined | null | Variable<any, string>,
	distanceUnit?: ValueTypes["DistanceUnit"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	firstName?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	fleetDrivers?: ValueTypes["FleetDriverListRelationFilter"] | undefined | null | Variable<any, string>,
	fleetGroups?: ValueTypes["JsonFilter"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	inactiveStartingAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	jobAssignments?: ValueTypes["JobAssignmentHistoryListRelationFilter"] | undefined | null | Variable<any, string>,
	jobs?: ValueTypes["JobListRelationFilter"] | undefined | null | Variable<any, string>,
	lastName?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	middleNames?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	pauseNotifications?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,
	phoneNumber?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	pushToken?: string | undefined | null | Variable<any, string>,
	status?: ValueTypes["DriverStatus"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	vehicle?: ValueTypes["VehicleWhereInput"] | undefined | null | Variable<any, string>
};
	["DropOffTask"]: AliasType<{
	ageVerification?:boolean | `@${string}`,
	/** Duration in seconds for a drop off to be completed */
	allocatedTime?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	dropOffContact?:boolean | `@${string}`,
	dropOffNotes?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	job?:ValueTypes["Job"],
	jobId?:boolean | `@${string}`,
	location?:boolean | `@${string}`,
	locationName?:boolean | `@${string}`,
	orderInStack?:boolean | `@${string}`,
	proofOfDelivery?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DropOffTaskCreateInput"]: {
	ageVerification?: boolean | undefined | null | Variable<any, string>,
	allocatedTime?: number | undefined | null | Variable<any, string>,
	dropOffContact?: ValueTypes["ContactInput"] | undefined | null | Variable<any, string>,
	dropOffNotes?: string | undefined | null | Variable<any, string>,
	job: ValueTypes["JobConnectOneInput"] | Variable<any, string>,
	location: ValueTypes["LocationCoordsInput"] | Variable<any, string>,
	locationName: string | Variable<any, string>,
	orderInStack?: number | undefined | null | Variable<any, string>,
	proofOfDelivery?: string | undefined | null | Variable<any, string>
};
	/** Input required by JobCreateDirectInput */
["DropOffTaskInput"]: {
	ageVerification?: boolean | undefined | null | Variable<any, string>,
	dropOffContact: ValueTypes["ContactInput"] | Variable<any, string>,
	dropOffNotes?: string | undefined | null | Variable<any, string>,
	location: ValueTypes["LocationCoordsInput"] | Variable<any, string>,
	locationName: string | Variable<any, string>,
	orderInStack?: number | undefined | null | Variable<any, string>,
	proofOfDelivery?: string | undefined | null | Variable<any, string>
};
	["DropOffTaskListRelationFilter"]: {
	every?: ValueTypes["DropOffTaskWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["DropOffTaskWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["DropOffTaskWhereInput"] | undefined | null | Variable<any, string>
};
	["DropOffTaskOrderByInput"]: {
	ageVerification?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	allocatedTime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	dropOffNotes?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	job?: ValueTypes["JobOrderByInput"] | undefined | null | Variable<any, string>,
	locationName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	orderInStack?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["DropOffTaskOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["DropOffTaskUpdateInput"]: {
	ageVerification?: boolean | undefined | null | Variable<any, string>,
	dropOffContact?: ValueTypes["ContactInput"] | undefined | null | Variable<any, string>,
	dropOffNotes?: string | undefined | null | Variable<any, string>,
	id: string | Variable<any, string>,
	job?: ValueTypes["JobConnectOneInput"] | undefined | null | Variable<any, string>,
	location?: ValueTypes["LocationCoordsInput"] | undefined | null | Variable<any, string>,
	locationName?: string | undefined | null | Variable<any, string>,
	orderInStack?: number | undefined | null | Variable<any, string>,
	proofOfDelivery?: string | undefined | null | Variable<any, string>
};
	["DropOffTaskUpdateManyWithoutJobInput"]: {
	connect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>
};
	["DropOffTaskUpdateProofOfDelivery"]: {
	id: string | Variable<any, string>,
	proofOfDelivery: string | Variable<any, string>
};
	["DropOffTaskWhereInput"]: {
	AND?: Array<ValueTypes["DropOffTaskWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["DropOffTaskWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["DropOffTaskWhereInput"]> | undefined | null | Variable<any, string>,
	ageVerification?: boolean | undefined | null | Variable<any, string>,
	allocatedTime?: number | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	dropOffNotes?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>,
	job?: ValueTypes["JobWhereInput"] | undefined | null | Variable<any, string>,
	jobId?: string | undefined | null | Variable<any, string>,
	locationName?: string | undefined | null | Variable<any, string>,
	orderInStack?: number | undefined | null | Variable<any, string>,
	proofOfDelivery?: ValueTypes["JsonFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>
};
	["EligibleDriversForPickup"]: AliasType<{
	driverId?:boolean | `@${string}`,
	driverLocation?:ValueTypes["Coordinates"],
	geographicalDistance?:boolean | `@${string}`,
	roadDistance?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EligibleDriversForPickupInput"]: {
	taskId: string | Variable<any, string>,
	/** If left to true will return the closest drivers based on the geographical distabce rather than the road one */
	useGeographicalDistance?: boolean | undefined | null | Variable<any, string>
};
	["Fleet"]: AliasType<{
	bases?:ValueTypes["Base"],
	broadcastJob?:boolean | `@${string}`,
	contactEmail?:boolean | `@${string}`,
	contactFullAddress?:boolean | `@${string}`,
	contactFullName?:boolean | `@${string}`,
	contactPhone?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	/** What we pay the drivers. Default value. Can be overwriten individually for each driver */
	defaultCostPerDistanceUnit?:boolean | `@${string}`,
	distanceUnit?:boolean | `@${string}`,
	fleetDrivers?:ValueTypes["FleetDriver"],
	geo?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	implementations?:ValueTypes["ApiImplementation"],
	/** Duration in seconds a job waits to be accepted by a driver */
	jobAcceptWaitTime?:boolean | `@${string}`,
	/** Estimated duration in seconds for a drop off to be completed */
	jobDropOffAllocatedTime?:boolean | `@${string}`,
	jobs?:ValueTypes["Job"],
	/** Duration in minutes a job waits before commencing searching for a driver */
	leadTime?:boolean | `@${string}`,
	legalCookies?:boolean | `@${string}`,
	legalPrivacy?:boolean | `@${string}`,
	legalTerms?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	outrightRejectJob?:boolean | `@${string}`,
	partner?:ValueTypes["Partner"],
	partnerId?:boolean | `@${string}`,
	/** What we charge the customer */
	pricePerDistanceUnit?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	users?:ValueTypes["User"],
	vehicles?:ValueTypes["Vehicle"],
	webhooks?:ValueTypes["Webhook"],
		__typename?: boolean | `@${string}`
}>;
	["FleetConnectOneInput"]: {
	connect: ValueTypes["WhereRequiredIdInput"] | Variable<any, string>
};
	["FleetConnectOrDisconnectOneInput"]: {
	connect?: ValueTypes["WhereRequiredIdInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>
};
	["FleetCreateInput"]: {
	bases?: ValueTypes["BaseCreateNestedWithoutFleetInput"] | undefined | null | Variable<any, string>,
	broadcastJob?: boolean | undefined | null | Variable<any, string>,
	contactEmail?: string | undefined | null | Variable<any, string>,
	contactFullAddress?: string | undefined | null | Variable<any, string>,
	contactFullName?: string | undefined | null | Variable<any, string>,
	contactPhone?: string | undefined | null | Variable<any, string>,
	defaultCostPerDistanceUnit?: number | undefined | null | Variable<any, string>,
	distanceUnit?: ValueTypes["DistanceUnit"] | undefined | null | Variable<any, string>,
	fleetDrivers?: ValueTypes["FleetDriverCreateNestedWithoutFleetInput"] | undefined | null | Variable<any, string>,
	geo?: string | undefined | null | Variable<any, string>,
	implementations?: ValueTypes["ApiImplementationCreateNestedManyWithoutFleetInput"] | undefined | null | Variable<any, string>,
	jobAcceptWaitTime?: number | undefined | null | Variable<any, string>,
	jobDropOffAllocatedTime?: number | undefined | null | Variable<any, string>,
	leadTime: number | Variable<any, string>,
	legalCookies?: string | undefined | null | Variable<any, string>,
	legalPrivacy?: string | undefined | null | Variable<any, string>,
	legalTerms?: string | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>,
	outrightRejectJob?: boolean | undefined | null | Variable<any, string>,
	partner: ValueTypes["PartnerCreateNestedOneWithoutFleetsInput"] | Variable<any, string>,
	pricePerDistanceUnit?: number | undefined | null | Variable<any, string>,
	vehicles?: ValueTypes["VehicleCreateNestedWithoutOwnerFleetInput"] | undefined | null | Variable<any, string>,
	webhooks?: ValueTypes["WebhookCreateNestedManyWithoutFleetInput"] | undefined | null | Variable<any, string>
};
	["FleetCreateNestedManyWithoutPartnerInput"]: {
	connect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	create?: Array<ValueTypes["FleetCreateWithoutPartnerInput"]> | undefined | null | Variable<any, string>
};
	["FleetCreateWithoutPartnerInput"]: {
	broadcastJob?: boolean | undefined | null | Variable<any, string>,
	contactEmail?: string | undefined | null | Variable<any, string>,
	contactFullAddress?: string | undefined | null | Variable<any, string>,
	contactFullName?: string | undefined | null | Variable<any, string>,
	contactPhone?: string | undefined | null | Variable<any, string>,
	defaultCostPerDistanceUnit?: number | undefined | null | Variable<any, string>,
	distanceUnit?: ValueTypes["DistanceUnit"] | undefined | null | Variable<any, string>,
	geo: string | Variable<any, string>,
	jobAcceptWaitTime?: number | undefined | null | Variable<any, string>,
	jobDropOffAllocatedTime?: number | undefined | null | Variable<any, string>,
	leadTime: number | Variable<any, string>,
	legalCookies: string | Variable<any, string>,
	legalPrivacy: string | Variable<any, string>,
	legalTerms: string | Variable<any, string>,
	name: string | Variable<any, string>,
	outrightRejectJob?: boolean | undefined | null | Variable<any, string>,
	pricePerDistanceUnit?: number | undefined | null | Variable<any, string>
};
	["FleetDriver"]: AliasType<{
	createdAt?:boolean | `@${string}`,
	driver?:ValueTypes["Driver"],
	driverId?:boolean | `@${string}`,
	fleet?:ValueTypes["Fleet"],
	fleetId?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FleetDriverCreateInput"]: {
	driver?: ValueTypes["DriverConnectOneInput"] | undefined | null | Variable<any, string>,
	fleet: ValueTypes["FleetConnectOneInput"] | Variable<any, string>
};
	["FleetDriverCreateNestedWithoutFleetInput"]: {
	connect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	create?: Array<ValueTypes["FleetDriverCreateWithoutFleetInput"]> | undefined | null | Variable<any, string>
};
	["FleetDriverCreateWithoutDriverInput"]: {
	fleet: ValueTypes["FleetConnectOneInput"] | Variable<any, string>
};
	["FleetDriverCreateWithoutFleetInput"]: {
	driver?: ValueTypes["DriverConnectOneInput"] | undefined | null | Variable<any, string>
};
	["FleetDriverListRelationFilter"]: {
	every?: ValueTypes["FleetDriverWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["FleetDriverWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["FleetDriverWhereInput"] | undefined | null | Variable<any, string>
};
	["FleetDriverOrderByInput"]: {
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	driver?: ValueTypes["DriverOrderByInput"] | undefined | null | Variable<any, string>,
	driverId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fleet: ValueTypes["FleetOrderByInput"] | Variable<any, string>,
	fleetId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["FleetDriverOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["FleetDriverUpdateInput"]: {
	driver?: ValueTypes["DriverConnectOrDisconnectOneInput"] | undefined | null | Variable<any, string>,
	fleet?: ValueTypes["FleetConnectOrDisconnectOneInput"] | undefined | null | Variable<any, string>,
	id: string | Variable<any, string>
};
	["FleetDriverUpdateManyWithoutDriverInput"]: {
	connect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	create?: Array<ValueTypes["FleetDriverCreateWithoutDriverInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>
};
	["FleetDriverUpdateManyWithoutFleetInput"]: {
	connect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	create?: Array<ValueTypes["FleetDriverCreateWithoutFleetInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>
};
	["FleetDriverWhereInput"]: {
	AND?: Array<ValueTypes["FleetDriverWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["FleetDriverWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["FleetDriverWhereInput"]> | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	driver?: ValueTypes["DriverWhereInput"] | undefined | null | Variable<any, string>,
	driverId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	fleet?: ValueTypes["FleetWhereInput"] | undefined | null | Variable<any, string>,
	fleetId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>
};
	["FleetListRelationFilter"]: {
	every?: ValueTypes["FleetWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["FleetWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["FleetWhereInput"] | undefined | null | Variable<any, string>
};
	["FleetOrderByInput"]: {
	bases?: ValueTypes["BaseOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	defaultCostPerDistanceUnit?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	distanceUnit?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fleetDrivers?: ValueTypes["FleetDriverOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	geo?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	implementations?: ValueTypes["ApiImplementationOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	jobAcceptWaitTime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	jobDropOffAllocatedTime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	jobs?: ValueTypes["JobOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	leadTime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	legalCookies?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	legalPrivacy?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	legalTerms?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	partner?: ValueTypes["PartnerOrderByInput"] | undefined | null | Variable<any, string>,
	partnerId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pricePerDistanceUnit?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	vehicles?: ValueTypes["VehicleOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	webhooks?: ValueTypes["WebhookOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>
};
	["FleetOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["FleetUpdateInput"]: {
	bases?: ValueTypes["BaseUpdateManyWithoutFleetInput"] | undefined | null | Variable<any, string>,
	broadcastJob?: boolean | undefined | null | Variable<any, string>,
	contactEmail?: string | undefined | null | Variable<any, string>,
	contactFullAddress?: string | undefined | null | Variable<any, string>,
	contactFullName?: string | undefined | null | Variable<any, string>,
	contactPhone?: string | undefined | null | Variable<any, string>,
	defaultCostPerDistanceUnit?: number | undefined | null | Variable<any, string>,
	distanceUnit?: ValueTypes["DistanceUnit"] | undefined | null | Variable<any, string>,
	fleetDrivers?: ValueTypes["FleetDriverUpdateManyWithoutFleetInput"] | undefined | null | Variable<any, string>,
	geo?: string | undefined | null | Variable<any, string>,
	id: string | Variable<any, string>,
	implementations?: ValueTypes["ApiImplementationUpdateManyWithoutFleetInput"] | undefined | null | Variable<any, string>,
	jobAcceptWaitTime?: number | undefined | null | Variable<any, string>,
	jobDropOffAllocatedTime?: number | undefined | null | Variable<any, string>,
	leadTime?: number | undefined | null | Variable<any, string>,
	legalCookies?: string | undefined | null | Variable<any, string>,
	legalPrivacy?: string | undefined | null | Variable<any, string>,
	legalTerms?: string | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	outrightRejectJob?: boolean | undefined | null | Variable<any, string>,
	pricePerDistanceUnit?: number | undefined | null | Variable<any, string>,
	vehicles?: ValueTypes["VehicleUpdateManyWithoutOwnerFleetInput"] | undefined | null | Variable<any, string>,
	webhooks?: ValueTypes["WebhookUpdateManyWithoutFleetInput"] | undefined | null | Variable<any, string>
};
	["FleetUpdateManyWithoutPartnerInput"]: {
	connect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	create?: Array<ValueTypes["FleetCreateWithoutPartnerInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>
};
	["FleetWhereInput"]: {
	AND?: ValueTypes["FleetWhereInput"] | undefined | null | Variable<any, string>,
	NOT?: ValueTypes["FleetWhereInput"] | undefined | null | Variable<any, string>,
	OR?: ValueTypes["FleetWhereInput"] | undefined | null | Variable<any, string>,
	bases?: ValueTypes["BaseListRelationFilter"] | undefined | null | Variable<any, string>,
	broadcastJob?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,
	contactEmail?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	contactFullAddress?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	contactFullName?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	contactPhone?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	defaultCostPerDistanceUnit?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	distanceUnit?: ValueTypes["DistanceUnitFilter"] | undefined | null | Variable<any, string>,
	fleetDrivers?: ValueTypes["FleetDriverListRelationFilter"] | undefined | null | Variable<any, string>,
	geo?: ValueTypes["JsonFilter"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	implementations?: ValueTypes["ApiImplementationListRelationFilter"] | undefined | null | Variable<any, string>,
	jobAcceptWaitTime?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	jobDropOffAllocatedTime?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	jobs?: ValueTypes["JobListRelationFilter"] | undefined | null | Variable<any, string>,
	leadTime?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	legalCookies?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	legalPrivacy?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	legalTerms?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	outrightRejectJob?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,
	partner?: ValueTypes["PartnerWhereInput"] | undefined | null | Variable<any, string>,
	partnerId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	pricePerDistanceUnit?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	vehicles?: ValueTypes["VehicleListRelationFilter"] | undefined | null | Variable<any, string>,
	webhooks?: ValueTypes["WebhookListRelationFilter"] | undefined | null | Variable<any, string>
};
	["IntFilter"]: {
	equals?: number | undefined | null | Variable<any, string>,
	gt?: number | undefined | null | Variable<any, string>,
	gte?: number | undefined | null | Variable<any, string>,
	in?: Array<number> | undefined | null | Variable<any, string>,
	lt?: number | undefined | null | Variable<any, string>,
	lte?: number | undefined | null | Variable<any, string>,
	not?: ValueTypes["IntFilter"] | undefined | null | Variable<any, string>,
	notIn?: Array<number> | undefined | null | Variable<any, string>
};
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:unknown;
	["Job"]: AliasType<{
	assignmentHistory?:ValueTypes["JobAssignmentHistory"],
	canceledBy?:boolean | `@${string}`,
	completedByDriverFullName?:boolean | `@${string}`,
	completedByDriverId?:boolean | `@${string}`,
	completionTime?:boolean | `@${string}`,
	/** Initially matches the initialCost but will change to reflect the cost of the journey's equivalent leg for stacked jobs */
	cost?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	/** Stored at creation time and cannot be updated */
	distanceUnit?:boolean | `@${string}`,
	driver?:ValueTypes["Driver"],
	driverId?:boolean | `@${string}`,
	dropOffTasks?:ValueTypes["DropOffTask"],
	estimatedDistanceMiles?:boolean | `@${string}`,
	estimatedDurationMinutes?:boolean | `@${string}`,
	estimatedPickupTime?:boolean | `@${string}`,
	/** Flag used by the scheduler to continue looking for drivers or cancel jobs under certain conditions. Set to true once a driver manually accepts or rejects a job, or once the assignment loop finishes. */
	finishedAssignment?:boolean | `@${string}`,
	fleet?:ValueTypes["Fleet"],
	fleetContactEmail?:boolean | `@${string}`,
	fleetContactFullAddress?:boolean | `@${string}`,
	fleetContactFullName?:boolean | `@${string}`,
	fleetContactPhone?:boolean | `@${string}`,
	fleetId?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	/** The ideal route, this is calculated at creation time and is what the customer is charged for. Never changes and is kept for reference */
	idealRoute?:boolean | `@${string}`,
	/** Stored at creation time and updates if the job is reassigned. This is what we might pay the driver */
	initialCost?:boolean | `@${string}`,
	/** The max amount of time until the job may be automatically rejected if the driver does not accept or reject manually */
	jobAcceptWaitTime?:boolean | `@${string}`,
	/** Holds notes related to the delivery completion */
	jobCompleteNotes?:boolean | `@${string}`,
	/** Creates on demand a presigned URL that points to an image in S3 */
	jobCompletePhotoProof?:boolean | `@${string}`,
	/** Flag that signals the existence of a pic. Internally, it spares a trip to the S3 bucket and generating a url */
	jobHasPhotoProof?:boolean | `@${string}`,
	/** Human readable reference for the job, used by drivers to contact dispatch */
	jobReference?:boolean | `@${string}`,
	onHold?:boolean | `@${string}`,
	orderContents?:boolean | `@${string}`,
	orderId?:boolean | `@${string}`,
	/** Flag that signals the order must assign a driver ASAP or reject the job */
	orderIsAsap?:boolean | `@${string}`,
	/** Human readable reference for the order, used by dispatch to contact the partner, e.g. Redbox */
	orderReference?:boolean | `@${string}`,
	pickupTasks?:ValueTypes["PickupTask"],
	pickupTime?:boolean | `@${string}`,
	/** Stored at creation time and cannot be updated. This is what we charge the customer */
	price?:boolean | `@${string}`,
	reasonForCancellation?:boolean | `@${string}`,
	/** The suggested route, might be simply a journey's leg when dealing with stacked jobs */
	route?:boolean | `@${string}`,
	stackId?:boolean | `@${string}`,
	/** The Lowest common denominator of the job's statuses in the stack */
	stackStatus?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["JobAssignmentHistory"]: AliasType<{
	autoRejectAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	driver?:ValueTypes["Driver"],
	driverId?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	job?:ValueTypes["Job"],
	jobId?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["JobAssignmentHistoryCreateInput"]: {
	autoRejectAt?: string | undefined | null | Variable<any, string>,
	driver: ValueTypes["DriverConnectOneInput"] | Variable<any, string>,
	job: ValueTypes["JobConnectOneInput"] | Variable<any, string>,
	status: ValueTypes["DriverJobAssignmentStatus"] | Variable<any, string>
};
	["JobAssignmentHistoryListRelationFilter"]: {
	every?: ValueTypes["JobAssignmentHistoryWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["JobAssignmentHistoryWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["JobAssignmentHistoryWhereInput"] | undefined | null | Variable<any, string>
};
	["JobAssignmentHistoryOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["JobAssignmentHistoryUpdateInput"]: {
	autoRejectAt?: string | undefined | null | Variable<any, string>,
	driver?: ValueTypes["DriverConnectOneInput"] | undefined | null | Variable<any, string>,
	id: string | Variable<any, string>,
	job?: ValueTypes["JobConnectOneInput"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["DriverJobAssignmentStatus"] | undefined | null | Variable<any, string>
};
	["JobAssignmentHistoryUpdateManyWithoutDriverInput"]: {
	connect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>
};
	["JobAssignmentHistoryUpdateManyWithoutJobInput"]: {
	connect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>
};
	["JobAssignmentHistoryWhereInput"]: {
	AND?: Array<ValueTypes["JobAssignmentHistoryWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["JobAssignmentHistoryWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["JobAssignmentHistoryWhereInput"]> | undefined | null | Variable<any, string>,
	autoRejectAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	driver?: ValueTypes["DriverWhereInput"] | undefined | null | Variable<any, string>,
	driverId?: string | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>,
	job?: ValueTypes["JobWhereInput"] | undefined | null | Variable<any, string>,
	jobId?: string | undefined | null | Variable<any, string>,
	status?: ValueTypes["DriverJobAssignmentStatus"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>
};
	["JobAssignmentOrderByInput"]: {
	autoRejectAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	driver?: ValueTypes["DriverOrderByInput"] | undefined | null | Variable<any, string>,
	job?: ValueTypes["JobOrderByInput"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["JobConnectOneInput"]: {
	connect: ValueTypes["WhereRequiredIdInput"] | Variable<any, string>
};
	["JobCreateDirectInput"]: {
	dropOffTask: ValueTypes["DropOffTaskInput"] | Variable<any, string>,
	estimatedPickupTime?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	fleetId: string | Variable<any, string>,
	orderContents: Array<string> | Variable<any, string>,
	orderId: string | Variable<any, string>,
	orderIsAsap: boolean | Variable<any, string>,
	orderReference: string | Variable<any, string>,
	pickupTask: ValueTypes["PickupTaskInput"] | Variable<any, string>,
	/** This will be calculated based on the route */
	price?: number | undefined | null | Variable<any, string>
};
	["JobListRelationFilter"]: {
	every?: ValueTypes["JobWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["JobWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["JobWhereInput"] | undefined | null | Variable<any, string>
};
	["JobOrderByInput"]: {
	assignmentHistory?: ValueTypes["JobAssignmentHistoryOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	canceledBy?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	completedByDriverFullName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	completedByDriverId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	completionTime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	cost?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	distanceUnit?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	driver?: ValueTypes["DriverOrderByInput"] | undefined | null | Variable<any, string>,
	dropOffTasks?: ValueTypes["DropOffTaskOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	estimatedDistanceMiles?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	estimatedDurationMinutes?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	estimatedPickupTime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	finishedAssignment?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fleet?: ValueTypes["FleetOrderByInput"] | undefined | null | Variable<any, string>,
	initialCost?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	jobAcceptWaitTime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	jobCompleteNotes?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	jobReference?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	onHold?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	orderContents?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	orderId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	orderIsAsap?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	orderReference?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pickupTasks?: ValueTypes["PickupTaskOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	pickupTime?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	price?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stackId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	stackStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["JobOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["JobStatus"]:JobStatus;
	["JobStatusFilter"]: {
	equals?: ValueTypes["JobStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["JobStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["JobStatus"] | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["JobStatus"]> | undefined | null | Variable<any, string>
};
	["JobUpdateInput"]: {
	assignmentHistory?: ValueTypes["JobAssignmentHistoryUpdateManyWithoutJobInput"] | undefined | null | Variable<any, string>,
	canceledBy?: ValueTypes["CanceledBy"] | undefined | null | Variable<any, string>,
	completionTime?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	cost?: number | undefined | null | Variable<any, string>,
	driver?: ValueTypes["DriverConnectOrDisconnectInput"] | undefined | null | Variable<any, string>,
	dropOffTasks?: ValueTypes["DropOffTaskUpdateManyWithoutJobInput"] | undefined | null | Variable<any, string>,
	estimatedDistanceMiles?: number | undefined | null | Variable<any, string>,
	estimatedDurationMinutes?: number | undefined | null | Variable<any, string>,
	estimatedPickupTime?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	finishedAssignment?: boolean | undefined | null | Variable<any, string>,
	fleet?: ValueTypes["FleetConnectOneInput"] | undefined | null | Variable<any, string>,
	id: string | Variable<any, string>,
	initialCost?: number | undefined | null | Variable<any, string>,
	initialRoute?: string | undefined | null | Variable<any, string>,
	jobAcceptWaitTime?: number | undefined | null | Variable<any, string>,
	onHold?: boolean | undefined | null | Variable<any, string>,
	orderContents?: Array<string> | undefined | null | Variable<any, string>,
	orderIsAsap?: boolean | undefined | null | Variable<any, string>,
	orderReference?: string | undefined | null | Variable<any, string>,
	pickupTasks?: ValueTypes["PickupTaskUpdateManyWithoutJobInput"] | undefined | null | Variable<any, string>,
	pickupTime?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	reasonForCancellation?: string | undefined | null | Variable<any, string>,
	route?: string | undefined | null | Variable<any, string>,
	stackId?: string | undefined | null | Variable<any, string>,
	status?: ValueTypes["JobStatus"] | undefined | null | Variable<any, string>
};
	["JobUpdateManyWithoutDriverInput"]: {
	connect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>
};
	["JobWhereInput"]: {
	AND?: Array<ValueTypes["JobWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["JobWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["JobWhereInput"]> | undefined | null | Variable<any, string>,
	assignmentHistory?: ValueTypes["JobAssignmentHistoryListRelationFilter"] | undefined | null | Variable<any, string>,
	canceledBy?: ValueTypes["CanceledBy"] | undefined | null | Variable<any, string>,
	completedByDriverFullName?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	completedByDriverId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	completionTime?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	cost?: number | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	distanceUnit?: ValueTypes["DistanceUnitFilter"] | undefined | null | Variable<any, string>,
	driver?: ValueTypes["DriverWhereInput"] | undefined | null | Variable<any, string>,
	driverId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	dropOffTasks?: ValueTypes["DropOffTaskListRelationFilter"] | undefined | null | Variable<any, string>,
	estimatedDistanceMiles?: number | undefined | null | Variable<any, string>,
	estimatedDurationMinutes?: number | undefined | null | Variable<any, string>,
	estimatedPickupTime?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	finishedAssignment?: boolean | undefined | null | Variable<any, string>,
	fleet?: ValueTypes["FleetWhereInput"] | undefined | null | Variable<any, string>,
	fleetId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	initialCost?: number | undefined | null | Variable<any, string>,
	jobAcceptWaitTime?: number | undefined | null | Variable<any, string>,
	jobCompleteNotes?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	jobReference?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	onHold?: boolean | undefined | null | Variable<any, string>,
	orderContents?: ValueTypes["JsonFilter"] | undefined | null | Variable<any, string>,
	orderId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	orderIsAsap?: boolean | undefined | null | Variable<any, string>,
	orderReference?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	pickupTasks?: ValueTypes["PickupTaskListRelationFilter"] | undefined | null | Variable<any, string>,
	pickupTime?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	price?: number | undefined | null | Variable<any, string>,
	stackId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	stackStatus?: ValueTypes["JobStatusFilter"] | undefined | null | Variable<any, string>,
	status?: ValueTypes["JobStatusFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>
};
	["JsonFilter"]: {
	equals?: string | undefined | null | Variable<any, string>,
	not?: string | undefined | null | Variable<any, string>
};
	["LocationCoordsInput"]: {
	address: ValueTypes["AddressInput"] | Variable<any, string>,
	latitude: number | Variable<any, string>,
	longitude: number | Variable<any, string>
};
	["LocationInput"]: {
	latitude: number | Variable<any, string>,
	longitude: number | Variable<any, string>
};
	["MinimalNode"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
acceptJob?: [{	id: string | Variable<any, string>},ValueTypes["Job"]],
assignJobToDriver?: [{	input: ValueTypes["AssignJobToDriverInput"] | Variable<any, string>},ValueTypes["Job"]],
	/** Starts the assignment loop. Runs periodically */
	assignJobsScheduler?:boolean | `@${string}`,
	/** Transitions drivers to the correct status based on their booked breaks if they are not already in the correct status. Runs periodically */
	autoTransitionDriversStatusScheduler?:boolean | `@${string}`,
	/** Transitions jobs to travelling-to-pickup status based on estimated pickup time and current driver position. Runs periodically */
	autoTravelToPickupScheduler?:boolean | `@${string}`,
cancelJob?: [{	input: ValueTypes["CancelJobInput"] | Variable<any, string>},ValueTypes["Job"]],
	/** Cancels jobs that are in the exception queue. It only does so for fleets that allow automated job cancelling. */
	cancelJobsScheduler?:boolean | `@${string}`,
completeJob?: [{	id: string | Variable<any, string>},ValueTypes["Job"]],
completeUserProfile?: [{	input: ValueTypes["CompleteUserProfileInput"] | Variable<any, string>},boolean | `@${string}`],
	/** Continues assigning jobs if the assignment loop did not finish. Runs periodically */
	continueJobsAssignmentScheduler?:boolean | `@${string}`,
createApiImplementation?: [{	input: ValueTypes["ApiImplementationCreateInput"] | Variable<any, string>},ValueTypes["ApiImplementation"]],
createBase?: [{	input: ValueTypes["BaseCreateInput"] | Variable<any, string>},ValueTypes["Base"]],
createDropOffTask?: [{	input: ValueTypes["DropOffTaskCreateInput"] | Variable<any, string>},ValueTypes["DropOffTask"]],
createFleet?: [{	input: ValueTypes["FleetCreateInput"] | Variable<any, string>},ValueTypes["Fleet"]],
createFleetDriver?: [{	input: ValueTypes["FleetDriverCreateInput"] | Variable<any, string>},ValueTypes["FleetDriver"]],
createJob?: [{	input: ValueTypes["JobCreateDirectInput"] | Variable<any, string>},ValueTypes["Job"]],
createJobAssignmentHistory?: [{	input: ValueTypes["JobAssignmentHistoryCreateInput"] | Variable<any, string>},ValueTypes["JobAssignmentHistory"]],
createPartner?: [{	input: ValueTypes["PartnerCreateInput"] | Variable<any, string>},ValueTypes["Partner"]],
createPickupTask?: [{	input: ValueTypes["PickupTaskCreateInput"] | Variable<any, string>},ValueTypes["PickupTask"]],
createPlatform?: [{	input: ValueTypes["PlatformCreateInput"] | Variable<any, string>},ValueTypes["Platform"]],
createUser?: [{	input: ValueTypes["UserCreateInput"] | Variable<any, string>},ValueTypes["CreateUserResponse"]],
createWebhook?: [{	input: ValueTypes["WebhookCreateInput"] | Variable<any, string>},ValueTypes["Webhook"]],
deleteApiImplementation?: [{	id: string | Variable<any, string>},ValueTypes["ApiImplementation"]],
deleteDriver?: [{	driverId: string | Variable<any, string>},ValueTypes["Driver"]],
deleteJob?: [{	id: string | Variable<any, string>},ValueTypes["Job"]],
deleteUser?: [{	userId: string | Variable<any, string>},ValueTypes["User"]],
deleteWebhook?: [{	id: string | Variable<any, string>},ValueTypes["Webhook"]],
driverLogin?: [{	phoneNumber: string | Variable<any, string>},ValueTypes["DriverLoginResponse"]],
	/** Dedicated mutation that is only triggered by the driver app when the driver logs out. Has a side effect of pausing notifications for the driver. */
	driverLogout?:boolean | `@${string}`,
inviteDriver?: [{	input: ValueTypes["DriverInviteInput"] | Variable<any, string>},ValueTypes["Driver"]],
inviteUser?: [{	input: ValueTypes["UserInviteInput"] | Variable<any, string>},boolean | `@${string}`],
login?: [{	input: ValueTypes["UserLoginInput"] | Variable<any, string>},boolean | `@${string}`],
notifyDriver?: [{	input: ValueTypes["NotifyDriverInput"] | Variable<any, string>},boolean | `@${string}`],
rejectJob?: [{	id: string | Variable<any, string>},ValueTypes["Job"]],
removeDriverStatusOption?: [{	driverId?: string | undefined | null | Variable<any, string>,	option: ValueTypes["JSON"] | Variable<any, string>},boolean | `@${string}`],
requestPasswordReset?: [{	email: string | Variable<any, string>},boolean | `@${string}`],
resetPassword?: [{	input: ValueTypes["UserResetPasswordInput"] | Variable<any, string>},boolean | `@${string}`],
setDriverStatusFromOption?: [{	driverId?: string | undefined | null | Variable<any, string>,	option: ValueTypes["JSON"] | Variable<any, string>},boolean | `@${string}`],
updateApiImplementation?: [{	input: ValueTypes["ApiImplementationUpdateInput"] | Variable<any, string>},ValueTypes["ApiImplementation"]],
updateBase?: [{	input: ValueTypes["BaseUpdateInput"] | Variable<any, string>},ValueTypes["Base"]],
updateDriver?: [{	input: ValueTypes["DriverUpdateInput"] | Variable<any, string>},ValueTypes["Driver"]],
updateDriverDetails?: [{	input: ValueTypes["DriverUpdateDetailsInput"] | Variable<any, string>},ValueTypes["Driver"]],
updateDriverLocation?: [{	input: ValueTypes["DriverSetLocationInput"] | Variable<any, string>},boolean | `@${string}`],
updateDropOffTask?: [{	input: ValueTypes["DropOffTaskUpdateInput"] | Variable<any, string>},ValueTypes["DropOffTask"]],
updateDropOffTaskProofOfDelivery?: [{	input: ValueTypes["DropOffTaskUpdateProofOfDelivery"] | Variable<any, string>},ValueTypes["DropOffTask"]],
updateFleet?: [{	input: ValueTypes["FleetUpdateInput"] | Variable<any, string>},ValueTypes["Fleet"]],
updateFleetDriver?: [{	input: ValueTypes["FleetDriverUpdateInput"] | Variable<any, string>},ValueTypes["FleetDriver"]],
updateJob?: [{	input: ValueTypes["JobUpdateInput"] | Variable<any, string>},ValueTypes["Job"]],
updateJobAssignmentHistory?: [{	input: ValueTypes["JobAssignmentHistoryUpdateInput"] | Variable<any, string>},ValueTypes["JobAssignmentHistory"]],
updateJobPickupTime?: [{	input: ValueTypes["UpdateJobPickupTimeInput"] | Variable<any, string>},ValueTypes["Job"]],
updateJobStatus?: [{	input: ValueTypes["UpdateJobStatusInput"] | Variable<any, string>},ValueTypes["Job"]],
updatePartner?: [{	input: ValueTypes["PartnerUpdateInput"] | Variable<any, string>},ValueTypes["Partner"]],
updatePassword?: [{	input: ValueTypes["UserUpdatePasswordInput"] | Variable<any, string>},boolean | `@${string}`],
updatePickupTask?: [{	input: ValueTypes["PickupTaskUpdateInput"] | Variable<any, string>},ValueTypes["PickupTask"]],
updatePlatform?: [{	input: ValueTypes["PlatformUpdateInput"] | Variable<any, string>},ValueTypes["Platform"]],
updatePushToken?: [{	input: ValueTypes["DriverUpdatePushTokenInput"] | Variable<any, string>},ValueTypes["Driver"]],
updateUser?: [{	input: ValueTypes["UserUpdateInput"] | Variable<any, string>},ValueTypes["User"]],
updateWebhook?: [{	input: ValueTypes["WebhookUpdateInput"] | Variable<any, string>},ValueTypes["Webhook"]],
uploadJobCompleteProof?: [{	input: ValueTypes["UploadJobCompleteProofInput"] | Variable<any, string>},boolean | `@${string}`],
verifyPhone?: [{	verificationCode: string | Variable<any, string>},ValueTypes["VerifyPhoneResponse"]],
		__typename?: boolean | `@${string}`
}>;
	["NotifyDriverInput"]: {
	body: string | Variable<any, string>,
	data: ValueTypes["JSON"] | Variable<any, string>,
	driverId: string | Variable<any, string>,
	subtitle?: string | undefined | null | Variable<any, string>,
	title: string | Variable<any, string>
};
	["OptionForDriverStatus"]: AliasType<{
	booked?:boolean | `@${string}`,
	durationInMinutes?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	maxStartTime?:boolean | `@${string}`,
	minStartTime?:boolean | `@${string}`,
	minimalLabel?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PaginatedJobs"]: AliasType<{
	count?:boolean | `@${string}`,
	jobs?:ValueTypes["Job"],
		__typename?: boolean | `@${string}`
}>;
	["Partner"]: AliasType<{
	createdAt?:boolean | `@${string}`,
	fleets?:ValueTypes["Fleet"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	platform?:ValueTypes["Platform"],
	platformId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	users?:ValueTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["PartnerConnectOrDisconnectInput"]: {
	connect?: ValueTypes["WhereRequiredIdInput"] | undefined | null | Variable<any, string>,
	disconnect?: ValueTypes["WhereRequiredIdInput"] | undefined | null | Variable<any, string>
};
	["PartnerCreateInput"]: {
	name: string | Variable<any, string>,
	platform: ValueTypes["PlatformConnectById"] | Variable<any, string>
};
	["PartnerCreateNestedOneWithoutFleetsInput"]: {
	connect?: ValueTypes["WhereRequiredIdInput"] | undefined | null | Variable<any, string>,
	create?: ValueTypes["PartnerCreateInput"] | undefined | null | Variable<any, string>
};
	["PartnerCreateWithoutPlatformInput"]: {
	fleets?: ValueTypes["FleetCreateNestedManyWithoutPartnerInput"] | undefined | null | Variable<any, string>,
	name: string | Variable<any, string>
};
	["PartnerListRelationFilter"]: {
	every?: ValueTypes["PartnerWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["PartnerWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["PartnerWhereInput"] | undefined | null | Variable<any, string>
};
	["PartnerOrderByInput"]: {
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fleets?: ValueTypes["FleetOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	partners?: ValueTypes["PartnerOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	platform?: ValueTypes["PlatformOrderByInput"] | undefined | null | Variable<any, string>,
	platformId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PartnerOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PartnerUpdateInput"]: {
	fleets?: ValueTypes["FleetUpdateManyWithoutPartnerInput"] | undefined | null | Variable<any, string>,
	id: string | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>
};
	["PartnerUpdatemanyWithoutPlatformInput"]: {
	connect?: Array<ValueTypes["PartnerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["PartnerWhereUniqueInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["PartnerWhereUniqueInput"]> | undefined | null | Variable<any, string>
};
	["PartnerWhereInput"]: {
	AND?: Array<ValueTypes["PartnerWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PartnerWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PartnerWhereInput"]> | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	fleets?: ValueTypes["FleetListRelationFilter"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	platform?: ValueTypes["PlatformWhereInput"] | undefined | null | Variable<any, string>,
	platformId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>
};
	["PartnerWhereUniqueInput"]: {
	id?: string | undefined | null | Variable<any, string>
};
	["PickupTask"]: AliasType<{
	createdAt?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	job?:ValueTypes["Job"],
	jobId?:boolean | `@${string}`,
	location?:boolean | `@${string}`,
	locationHashed?:boolean | `@${string}`,
	locationName?:boolean | `@${string}`,
	pickupContact?:boolean | `@${string}`,
	pickupNotes?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PickupTaskCreateInput"]: {
	job: ValueTypes["JobConnectOneInput"] | Variable<any, string>,
	location: ValueTypes["LocationCoordsInput"] | Variable<any, string>,
	locationName: string | Variable<any, string>,
	pickupContact?: ValueTypes["ContactInput"] | undefined | null | Variable<any, string>,
	pickupNotes?: string | undefined | null | Variable<any, string>
};
	/** Input required by JobCreateDirectInput */
["PickupTaskInput"]: {
	location: ValueTypes["LocationCoordsInput"] | Variable<any, string>,
	locationName: string | Variable<any, string>,
	pickupContact: ValueTypes["ContactInput"] | Variable<any, string>,
	pickupNotes?: string | undefined | null | Variable<any, string>
};
	["PickupTaskListRelationFilter"]: {
	every?: ValueTypes["PickupTaskWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["PickupTaskWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["PickupTaskWhereInput"] | undefined | null | Variable<any, string>
};
	["PickupTaskOrderByInput"]: {
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	job?: ValueTypes["JobOrderByInput"] | undefined | null | Variable<any, string>,
	locationHashed?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	locationName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	pickupNotes?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PickupTaskOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PickupTaskUpdateInput"]: {
	id: string | Variable<any, string>,
	job?: ValueTypes["JobConnectOneInput"] | undefined | null | Variable<any, string>,
	location?: ValueTypes["LocationCoordsInput"] | undefined | null | Variable<any, string>,
	locationName?: string | undefined | null | Variable<any, string>,
	pickupContact?: ValueTypes["ContactInput"] | undefined | null | Variable<any, string>,
	pickupNotes?: string | undefined | null | Variable<any, string>,
	proofOfDelivery?: string | undefined | null | Variable<any, string>
};
	["PickupTaskUpdateManyWithoutJobInput"]: {
	connect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>
};
	["PickupTaskWhereInput"]: {
	AND?: Array<ValueTypes["PickupTaskWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PickupTaskWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PickupTaskWhereInput"]> | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	id?: string | undefined | null | Variable<any, string>,
	job?: ValueTypes["JobWhereInput"] | undefined | null | Variable<any, string>,
	jobId?: string | undefined | null | Variable<any, string>,
	locationHashed?: string | undefined | null | Variable<any, string>,
	locationName?: string | undefined | null | Variable<any, string>,
	pickupNotes?: string | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>
};
	["Platform"]: AliasType<{
	createdAt?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	partners?:ValueTypes["Partner"],
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlatformConnectById"]: {
	connect: ValueTypes["WhereRequiredIdInput"] | Variable<any, string>
};
	["PlatformCreateInput"]: {
	name: string | Variable<any, string>,
	partners?: ValueTypes["PlatformCreateOrConnectPartnerInput"] | undefined | null | Variable<any, string>
};
	["PlatformCreateOrConnectPartnerInput"]: {
	connect?: Array<string> | undefined | null | Variable<any, string>,
	create?: Array<ValueTypes["PartnerCreateWithoutPlatformInput"]> | undefined | null | Variable<any, string>
};
	["PlatformOrderByInput"]: {
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	partners?: ValueTypes["PartnerOrderByRelationAggregateInput"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["PlatformUpdateInput"]: {
	id: string | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	partners?: ValueTypes["PartnerUpdatemanyWithoutPlatformInput"] | undefined | null | Variable<any, string>
};
	["PlatformWhereInput"]: {
	AND?: Array<ValueTypes["PlatformWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["PlatformWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["PlatformWhereInput"]> | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	partners?: ValueTypes["PartnerListRelationFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>
};
	["Query"]: AliasType<{
apiImplementation?: [{	id: string | Variable<any, string>},ValueTypes["ApiImplementation"]],
apiImplementations?: [{	orderBy?: ValueTypes["ApiImplementationOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["ApiImplementationWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["ApiImplementation"]],
	assignedJobs?:ValueTypes["Job"],
availableNodesByUserAndDesiredRole?: [{	input: ValueTypes["AvailableNodesByUserAndDesiredRoleInput"] | Variable<any, string>},ValueTypes["MinimalNode"]],
	/** Returns an array of available roles the current user can grant to new users */
	availableRolesByUser?:boolean | `@${string}`,
base?: [{	id: string | Variable<any, string>},ValueTypes["Base"]],
bases?: [{	orderBy?: ValueTypes["BaseOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["BaseWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Base"]],
bookedAndAvailableOptionsForDriverStatus?: [{	driverId?: string | undefined | null | Variable<any, string>},ValueTypes["OptionForDriverStatus"]],
calculateRoute?: [{	input: ValueTypes["CalculateRouteInput"] | Variable<any, string>},boolean | `@${string}`],
collisionsOrStackability?: [{	destinationDriverId: string | Variable<any, string>,	jobId: string | Variable<any, string>},ValueTypes["CollisionsOrStackability"]],
	currentDriver?:ValueTypes["Driver"],
	/** Returns an array of assigned, not on hold and en-route jobs */
	currentJobs?:ValueTypes["Job"],
	currentUser?:ValueTypes["User"],
deliveryEstimate?: [{	input: ValueTypes["DeliveryEstimateInput"] | Variable<any, string>},ValueTypes["DeliveryEstimate"]],
driver?: [{	id: string | Variable<any, string>},ValueTypes["Driver"]],
drivers?: [{	orderBy?: ValueTypes["DriverOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["DriverWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Driver"]],
dropOffTask?: [{	id: string | Variable<any, string>},ValueTypes["DropOffTask"]],
dropOffTasks?: [{	orderBy?: ValueTypes["DropOffTaskOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["DropOffTaskWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["DropOffTask"]],
eligibleDriversForPickup?: [{	input: ValueTypes["EligibleDriversForPickupInput"] | Variable<any, string>},ValueTypes["EligibleDriversForPickup"]],
exceptionQueue?: [{	fleetId: string | Variable<any, string>},ValueTypes["Job"]],
fleet?: [{	id: string | Variable<any, string>},ValueTypes["Fleet"]],
fleetDriver?: [{	id: string | Variable<any, string>},ValueTypes["FleetDriver"]],
fleetDrivers?: [{	orderBy?: ValueTypes["FleetDriverOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["FleetDriverWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["FleetDriver"]],
fleets?: [{	orderBy?: ValueTypes["FleetOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["FleetWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Fleet"]],
job?: [{	id: string | Variable<any, string>},ValueTypes["Job"]],
jobAssignmentHistories?: [{	orderBy?: ValueTypes["JobAssignmentOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["JobAssignmentHistoryWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["JobAssignmentHistory"]],
jobAssignmentHistory?: [{	id: string | Variable<any, string>},ValueTypes["JobAssignmentHistory"]],
jobs?: [{	orderBy?: ValueTypes["JobOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["JobWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Job"]],
paginatedJobs?: [{	orderBy?: ValueTypes["JobOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["JobWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["PaginatedJobs"]],
partner?: [{	id: string | Variable<any, string>},ValueTypes["Partner"]],
partners?: [{	orderBy?: ValueTypes["PartnerOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["PartnerWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Partner"]],
	/** Returns an array of jobs, the first one needs to be accepted/rejected while the rest are simply completing the stack */
	pendingJobAndStack?:ValueTypes["Job"],
pickupTask?: [{	id: string | Variable<any, string>},ValueTypes["PickupTask"]],
pickupTasks?: [{	orderBy?: ValueTypes["PickupTaskOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["PickupTaskWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["PickupTask"]],
platform?: [{	id: string | Variable<any, string>},ValueTypes["Platform"]],
platforms?: [{	orderBy?: ValueTypes["PlatformOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["PlatformWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Platform"]],
timeConnectedInMs?: [{	input?: ValueTypes["TimeConnectedInMsInput"] | undefined | null | Variable<any, string>},boolean | `@${string}`],
userByEmail?: [{	email: string | Variable<any, string>},ValueTypes["User"]],
userById?: [{	id: string | Variable<any, string>},ValueTypes["User"]],
users?: [{	orderBy?: ValueTypes["UserOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["UserWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["User"]],
vehicle?: [{	id: string | Variable<any, string>},ValueTypes["Vehicle"]],
vehicles?: [{	orderBy?: ValueTypes["VehicleOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["VehicleWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Vehicle"]],
webhook?: [{	id: string | Variable<any, string>},ValueTypes["Webhook"]],
webhooks?: [{	orderBy?: ValueTypes["WebhookOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["WebhookWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Webhook"]],
		__typename?: boolean | `@${string}`
}>;
	["SortOrder"]:SortOrder;
	["StringFilter"]: {
	contains?: string | undefined | null | Variable<any, string>,
	endsWith?: string | undefined | null | Variable<any, string>,
	equals?: string | undefined | null | Variable<any, string>,
	gt?: string | undefined | null | Variable<any, string>,
	gte?: string | undefined | null | Variable<any, string>,
	in?: Array<string> | undefined | null | Variable<any, string>,
	lt?: string | undefined | null | Variable<any, string>,
	lte?: string | undefined | null | Variable<any, string>,
	not?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	notIn?: Array<string> | undefined | null | Variable<any, string>,
	startsWith?: string | undefined | null | Variable<any, string>
};
	["Subscription"]: AliasType<{
	assignedJobs?:ValueTypes["Job"],
	currentDriver?:ValueTypes["Driver"],
	/** Returns an array of assigned, not on hold and en-route jobs */
	currentJobs?:ValueTypes["Job"],
driver?: [{	id: string | Variable<any, string>},ValueTypes["Driver"]],
drivers?: [{	orderBy?: ValueTypes["DriverOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["DriverWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Driver"]],
job?: [{	id: string | Variable<any, string>},ValueTypes["Job"]],
jobs?: [{	orderBy?: ValueTypes["JobOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["JobWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["Job"]],
paginatedJobs?: [{	orderBy?: ValueTypes["JobOrderByInput"] | undefined | null | Variable<any, string>,	skip?: number | undefined | null | Variable<any, string>,	take?: number | undefined | null | Variable<any, string>,	where?: ValueTypes["JobWhereInput"] | undefined | null | Variable<any, string>},ValueTypes["PaginatedJobs"]],
	/** Returns an array of jobs, the first one needs to be accepted/rejected while the rest are simply completing the stack */
	pendingJobAndStack?:ValueTypes["Job"],
		__typename?: boolean | `@${string}`
}>;
	["TimeConnectedInMsInput"]: {
	/** If not provided the query will default to the user on the context */
	driverId?: string | undefined | null | Variable<any, string>,
	/** Optional parameter that marks the start of day timestamp in ms for the driver. It helps with addressing time zone variants */
	startOfDayTimestamp?: number | undefined | null | Variable<any, string>
};
	["UpdateJobPickupTimeInput"]: {
	estimatedPickupTime?: ValueTypes["DateTime"] | undefined | null | Variable<any, string>,
	id: string | Variable<any, string>
};
	["UpdateJobStatusInput"]: {
	id: string | Variable<any, string>,
	status: ValueTypes["JobStatus"] | Variable<any, string>
};
	["UploadJobCompleteProofInput"]: {
	jobCompleteNotes?: string | undefined | null | Variable<any, string>,
	jobCompletePhotoProof?: string | undefined | null | Variable<any, string>,
	jobId: string | Variable<any, string>
};
	["User"]: AliasType<{
	createdAt?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	enabled?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	fleets?:ValueTypes["Fleet"],
	id?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	partners?:ValueTypes["Partner"],
	/** The hashed password */
	password?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	userStatus?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserConnectInput"]: {
	connect?: ValueTypes["WhereRequiredIdInput"] | undefined | null | Variable<any, string>
};
	["UserCreateInput"]: {
	email: string | Variable<any, string>,
	firstName: string | Variable<any, string>,
	lastName: string | Variable<any, string>,
	partners?: ValueTypes["PartnerConnectOrDisconnectInput"] | undefined | null | Variable<any, string>,
	password: string | Variable<any, string>,
	phoneNumber: string | Variable<any, string>,
	role: ValueTypes["UserRole"] | Variable<any, string>,
	userStatus: ValueTypes["UserStatus"] | Variable<any, string>
};
	["UserCreateNewPasswordInput"]: {
	password: string | Variable<any, string>,
	token: string | Variable<any, string>
};
	["UserInviteInput"]: {
	email: string | Variable<any, string>,
	/** The highest level ids of nodes the user has access to. E.g. - a partner-user should get a list of partnerIds to connect */
	nodes?: Array<string> | undefined | null | Variable<any, string>,
	role: ValueTypes["UserRole"] | Variable<any, string>
};
	["UserLoginInput"]: {
	email: string | Variable<any, string>,
	password: string | Variable<any, string>
};
	["UserOrderByInput"]: {
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	enabled?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	firstName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	lastName?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	phoneNumber?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	role?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	userStatus?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["UserResetPasswordInput"]: {
	key: string | Variable<any, string>,
	newPassword: string | Variable<any, string>
};
	["UserRole"]:UserRole;
	["UserRoleFilter"]: {
	equals?: ValueTypes["UserRole"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["UserRole"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["UserRoleFilter"] | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["UserRole"]> | undefined | null | Variable<any, string>
};
	["UserStatus"]:UserStatus;
	["UserStatusFilter"]: {
	equals?: ValueTypes["UserStatus"] | undefined | null | Variable<any, string>,
	in?: Array<ValueTypes["UserStatus"]> | undefined | null | Variable<any, string>,
	not?: ValueTypes["UserStatusFilter"] | undefined | null | Variable<any, string>,
	notIn?: Array<ValueTypes["UserStatus"]> | undefined | null | Variable<any, string>
};
	["UserUpdateInput"]: {
	email?: string | undefined | null | Variable<any, string>,
	enabled?: boolean | undefined | null | Variable<any, string>,
	firstName?: string | undefined | null | Variable<any, string>,
	id: string | Variable<any, string>,
	lastName?: string | undefined | null | Variable<any, string>,
	/** The highest level ids of nodes the user has access to. It connects and disconnects nodes according to this array if passed in. */
	nodes?: Array<string> | undefined | null | Variable<any, string>,
	phoneNumber?: string | undefined | null | Variable<any, string>,
	role?: ValueTypes["UserRole"] | undefined | null | Variable<any, string>,
	userStatus?: ValueTypes["UserStatus"] | undefined | null | Variable<any, string>
};
	["UserUpdatePasswordInput"]: {
	newPassword: string | Variable<any, string>,
	oldPassword: string | Variable<any, string>
};
	["UserWhereInput"]: {
	AND?: Array<ValueTypes["UserWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["UserWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["UserWhereInput"]> | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	email?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	enabled?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,
	firstName?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	lastName?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	phoneNumber?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	role?: ValueTypes["UserRoleFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	userStatus?: ValueTypes["UserStatusFilter"] | undefined | null | Variable<any, string>
};
	["Vehicle"]: AliasType<{
	createdAt?:boolean | `@${string}`,
	driver?:ValueTypes["Driver"],
	driverId?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	make?:boolean | `@${string}`,
	model?:boolean | `@${string}`,
	ownerDriver?:boolean | `@${string}`,
	ownerFleet?:ValueTypes["Fleet"],
	ownerFleetId?:boolean | `@${string}`,
	reg?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["VehicleConnectOrDisconnectOneInput"]: {
	connect?: ValueTypes["WhereRequiredIdInput"] | undefined | null | Variable<any, string>,
	disconnect?: boolean | undefined | null | Variable<any, string>
};
	["VehicleCreateInput"]: {
	driver?: ValueTypes["DriverConnectOneInput"] | undefined | null | Variable<any, string>,
	make?: string | undefined | null | Variable<any, string>,
	model?: string | undefined | null | Variable<any, string>,
	ownerDriver?: boolean | undefined | null | Variable<any, string>,
	ownerFleet?: ValueTypes["FleetConnectOneInput"] | undefined | null | Variable<any, string>,
	reg?: string | undefined | null | Variable<any, string>,
	type: ValueTypes["VehicleType"] | Variable<any, string>
};
	["VehicleCreateNestedOneWithoutDriverInput"]: {
	connect?: ValueTypes["WhereRequiredIdInput"] | undefined | null | Variable<any, string>,
	create?: ValueTypes["VehicleCreateInput"] | undefined | null | Variable<any, string>
};
	["VehicleCreateNestedWithoutOwnerFleetInput"]: {
	connect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	create?: Array<ValueTypes["VehicleCreateWithoutOwnerFleetInput"]> | undefined | null | Variable<any, string>
};
	["VehicleCreateWithoutOwnerFleetInput"]: {
	driver: ValueTypes["DriverConnectOneInput"] | Variable<any, string>,
	make?: string | undefined | null | Variable<any, string>,
	model?: string | undefined | null | Variable<any, string>,
	ownerDriver?: boolean | undefined | null | Variable<any, string>,
	reg?: string | undefined | null | Variable<any, string>,
	type: ValueTypes["VehicleType"] | Variable<any, string>
};
	["VehicleListRelationFilter"]: {
	every?: ValueTypes["VehicleWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["VehicleWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["VehicleWhereInput"] | undefined | null | Variable<any, string>
};
	["VehicleOrderByInput"]: {
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	driver?: ValueTypes["DriverOrderByInput"] | undefined | null | Variable<any, string>,
	driverId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	make?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	model?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	ownerDriver?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	ownerFleet?: ValueTypes["FleetOrderByInput"] | undefined | null | Variable<any, string>,
	ownerFleetId?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	reg?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["VehicleOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["VehicleType"]:VehicleType;
	["VehicleUpdateInput"]: {
	driver?: ValueTypes["DriverConnectOrDisconnectOneInput"] | undefined | null | Variable<any, string>,
	id: string | Variable<any, string>,
	make?: string | undefined | null | Variable<any, string>,
	model?: string | undefined | null | Variable<any, string>,
	ownerDriver?: boolean | undefined | null | Variable<any, string>,
	ownerFleet?: ValueTypes["FleetConnectOrDisconnectOneInput"] | undefined | null | Variable<any, string>,
	reg?: string | undefined | null | Variable<any, string>,
	type?: ValueTypes["VehicleType"] | undefined | null | Variable<any, string>
};
	["VehicleUpdateManyWithoutOwnerFleetInput"]: {
	connect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	create?: Array<ValueTypes["VehicleCreateWithoutOwnerFleetInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>
};
	["VehicleWhereInput"]: {
	AND?: Array<ValueTypes["VehicleWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["VehicleWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["VehicleWhereInput"]> | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	driver?: ValueTypes["DriverWhereInput"] | undefined | null | Variable<any, string>,
	driverId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	make?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	model?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	ownerDriver?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,
	ownerFleet?: ValueTypes["FleetWhereInput"] | undefined | null | Variable<any, string>,
	ownerFleetId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	reg?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	type?: ValueTypes["VehicleType"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>
};
	/** Response from verify phone */
["VerifyPhoneResponse"]: AliasType<{
	completedSignUp?:boolean | `@${string}`,
	token?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Webhook"]: AliasType<{
	apiKeyHeader?:boolean | `@${string}`,
	apiKeyValue?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	fleet?:ValueTypes["Fleet"],
	fleetId?:boolean | `@${string}`,
	forJobStatus?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	isRedboxApi?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebhookCreateInput"]: {
	apiKeyHeader?: string | undefined | null | Variable<any, string>,
	apiKeyValue?: string | undefined | null | Variable<any, string>,
	fleet: ValueTypes["FleetConnectOneInput"] | Variable<any, string>,
	forJobStatus?: ValueTypes["JobStatus"] | undefined | null | Variable<any, string>,
	isRedboxApi?: boolean | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	url: string | Variable<any, string>
};
	["WebhookCreateNestedManyWithoutFleetInput"]: {
	connect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	create?: Array<ValueTypes["WebhookCreateWithoutFleetInput"]> | undefined | null | Variable<any, string>
};
	["WebhookCreateWithoutFleetInput"]: {
	forJobStatus?: ValueTypes["JobStatus"] | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	url: string | Variable<any, string>
};
	["WebhookListRelationFilter"]: {
	every?: ValueTypes["WebhookWhereInput"] | undefined | null | Variable<any, string>,
	none?: ValueTypes["WebhookWhereInput"] | undefined | null | Variable<any, string>,
	some?: ValueTypes["WebhookWhereInput"] | undefined | null | Variable<any, string>
};
	["WebhookOrderByInput"]: {
	apiKeyHeader?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	fleet?: ValueTypes["FleetOrderByInput"] | undefined | null | Variable<any, string>,
	isRedboxApi?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["WebhookOrderByRelationAggregateInput"]: {
	_count?: ValueTypes["SortOrder"] | undefined | null | Variable<any, string>
};
	["WebhookUpdateInput"]: {
	apiKeyHeader?: string | undefined | null | Variable<any, string>,
	apiKeyValue?: string | undefined | null | Variable<any, string>,
	fleet?: ValueTypes["FleetConnectOneInput"] | undefined | null | Variable<any, string>,
	forJobStatus?: ValueTypes["JobStatus"] | undefined | null | Variable<any, string>,
	id: string | Variable<any, string>,
	isRedboxApi?: boolean | undefined | null | Variable<any, string>,
	name?: string | undefined | null | Variable<any, string>,
	url?: string | undefined | null | Variable<any, string>
};
	["WebhookUpdateManyWithoutFleetInput"]: {
	connect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	create?: Array<ValueTypes["WebhookCreateWithoutFleetInput"]> | undefined | null | Variable<any, string>,
	delete?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	disconnect?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>,
	set?: Array<ValueTypes["WhereRequiredIdInput"]> | undefined | null | Variable<any, string>
};
	["WebhookWhereInput"]: {
	AND?: Array<ValueTypes["WebhookWhereInput"]> | undefined | null | Variable<any, string>,
	NOT?: Array<ValueTypes["WebhookWhereInput"]> | undefined | null | Variable<any, string>,
	OR?: Array<ValueTypes["WebhookWhereInput"]> | undefined | null | Variable<any, string>,
	apiKeyHeader?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	createdAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	fleet?: ValueTypes["FleetWhereInput"] | undefined | null | Variable<any, string>,
	fleetId?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	forJobStatus?: ValueTypes["JobStatus"] | undefined | null | Variable<any, string>,
	id?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	isRedboxApi?: ValueTypes["BooleanFilter"] | undefined | null | Variable<any, string>,
	name?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>,
	updatedAt?: ValueTypes["DateTimeFilter"] | undefined | null | Variable<any, string>,
	url?: ValueTypes["StringFilter"] | undefined | null | Variable<any, string>
};
	["WhereRequiredIdInput"]: {
	id: string | Variable<any, string>
}
  }

export type ResolverInputTypes = {
    ["AddressInput"]: {
	city: string,
	firstLine: string,
	postcode: string,
	secondLine: string,
	thirdLine: string
};
	["ApiImplementation"]: AliasType<{
	apiKey?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	fleet?:ResolverInputTypes["Fleet"],
	fleetId?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ApiImplementationCreateInput"]: {
	apiKey: string,
	fleet: ResolverInputTypes["FleetConnectOneInput"],
	name: string
};
	["ApiImplementationCreateNestedManyWithoutFleetInput"]: {
	connect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	create?: Array<ResolverInputTypes["ApiImplementationCreateWithoutFleetInput"]> | undefined | null
};
	["ApiImplementationCreateWithoutFleetInput"]: {
	apiKey: string,
	name: string
};
	["ApiImplementationListRelationFilter"]: {
	every?: ResolverInputTypes["ApiImplementationWhereInput"] | undefined | null,
	none?: ResolverInputTypes["ApiImplementationWhereInput"] | undefined | null,
	some?: ResolverInputTypes["ApiImplementationWhereInput"] | undefined | null
};
	["ApiImplementationOrderByInput"]: {
	apiKey?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	fleet?: ResolverInputTypes["FleetOrderByInput"] | undefined | null,
	fleetId?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["ApiImplementationOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["ApiImplementationUpdateInput"]: {
	apiKey?: string | undefined | null,
	fleet?: ResolverInputTypes["FleetConnectOneInput"] | undefined | null,
	id: string,
	name?: string | undefined | null
};
	["ApiImplementationUpdateManyWithoutFleetInput"]: {
	connect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	create?: Array<ResolverInputTypes["ApiImplementationCreateWithoutFleetInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null
};
	["ApiImplementationWhereInput"]: {
	AND?: Array<ResolverInputTypes["ApiImplementationWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["ApiImplementationWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["ApiImplementationWhereInput"]> | undefined | null,
	apiKey?: ResolverInputTypes["StringFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	fleet?: ResolverInputTypes["FleetWhereInput"] | undefined | null,
	fleetId?: ResolverInputTypes["StringFilter"] | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	model?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null
};
	["AssignJobToDriverInput"]: {
	driverId: string,
	jobId: string
};
	["AuthTokenType"]:AuthTokenType;
	["AvailableNodesByUserAndDesiredRoleInput"]: {
	desiredRole: ResolverInputTypes["UserRole"]
};
	["Base"]: AliasType<{
	address?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	fleet?:ResolverInputTypes["Fleet"],
	fleetId?:boolean | `@${string}`,
	geo?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["BaseCreateInput"]: {
	address: string,
	fleet: ResolverInputTypes["FleetConnectOneInput"],
	geo: string,
	name: string
};
	["BaseCreateNestedWithoutFleetInput"]: {
	connect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	create?: Array<ResolverInputTypes["BaseCreateWithoutFleetInput"]> | undefined | null
};
	["BaseCreateWithoutFleetInput"]: {
	address: string,
	geo: string,
	name: string
};
	["BaseListRelationFilter"]: {
	every?: ResolverInputTypes["BaseWhereInput"] | undefined | null,
	none?: ResolverInputTypes["BaseWhereInput"] | undefined | null,
	some?: ResolverInputTypes["BaseWhereInput"] | undefined | null
};
	["BaseOrderByInput"]: {
	address?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	fleet?: ResolverInputTypes["FleetOrderByInput"] | undefined | null,
	fleetId?: ResolverInputTypes["SortOrder"] | undefined | null,
	geo?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["BaseOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["BaseUpdateInput"]: {
	address?: string | undefined | null,
	fleet?: ResolverInputTypes["FleetConnectOneInput"] | undefined | null,
	geo?: string | undefined | null,
	id: string,
	name?: string | undefined | null
};
	["BaseUpdateManyWithoutFleetInput"]: {
	connect: Array<ResolverInputTypes["WhereRequiredIdInput"]>,
	create?: Array<ResolverInputTypes["BaseCreateWithoutFleetInput"]> | undefined | null,
	delete: Array<ResolverInputTypes["WhereRequiredIdInput"]>,
	disconnect: Array<ResolverInputTypes["WhereRequiredIdInput"]>,
	set: Array<ResolverInputTypes["WhereRequiredIdInput"]>
};
	["BaseWhereInput"]: {
	AND?: Array<ResolverInputTypes["BaseWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["BaseWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["BaseWhereInput"]> | undefined | null,
	address?: ResolverInputTypes["JsonFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	fleet?: ResolverInputTypes["FleetWhereInput"] | undefined | null,
	fleetId?: ResolverInputTypes["StringFilter"] | undefined | null,
	geo?: ResolverInputTypes["JsonFilter"] | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null
};
	["BooleanFilter"]: {
	equals?: boolean | undefined | null,
	not?: ResolverInputTypes["BooleanFilter"] | undefined | null
};
	["CalculateRouteInput"]: {
	pointA: ResolverInputTypes["CoordinatesInput"],
	pointB: ResolverInputTypes["CoordinatesInput"]
};
	["CancelJobInput"]: {
	canceledBy: ResolverInputTypes["CanceledBy"],
	id: string
};
	["CanceledBy"]:CanceledBy;
	["CollisionsOrStackability"]: AliasType<{
	isPlausible?:boolean | `@${string}`,
	isStackable?:boolean | `@${string}`,
	jobsOverlap?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CompleteUserProfileInput"]: {
	firstName: string,
	key: string,
	lastName: string,
	password: string,
	phoneNumber?: string | undefined | null
};
	["ContactInput"]: {
	name?: string | undefined | null,
	phone: string
};
	["Coordinates"]: AliasType<{
	latitude?:boolean | `@${string}`,
	longitude?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CoordinatesInput"]: {
	latitude: number,
	longitude: number
};
	["CreateUserResponse"]: AliasType<{
	id?:boolean | `@${string}`,
	jwtToken?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	/** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
["DateTime"]:unknown;
	["DateTimeFilter"]: {
	equals?: ResolverInputTypes["DateTime"] | undefined | null,
	gt?: ResolverInputTypes["DateTime"] | undefined | null,
	gte?: ResolverInputTypes["DateTime"] | undefined | null,
	in?: Array<ResolverInputTypes["DateTime"]> | undefined | null,
	lt?: ResolverInputTypes["DateTime"] | undefined | null,
	lte?: ResolverInputTypes["DateTime"] | undefined | null,
	not?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	notIn?: Array<ResolverInputTypes["DateTime"]> | undefined | null
};
	["DeliveryEstimate"]: AliasType<{
	costInPennies?:boolean | `@${string}`,
	dropoffCoordinates?:ResolverInputTypes["Coordinates"],
	pickupCoordinates?:ResolverInputTypes["Coordinates"],
		__typename?: boolean | `@${string}`
}>;
	["DeliveryEstimateInput"]: {
	dropoffCoordinates: ResolverInputTypes["CoordinatesInput"],
	fleetId: string,
	pickupCoordinates: ResolverInputTypes["CoordinatesInput"]
};
	["DistanceUnit"]:DistanceUnit;
	["DistanceUnitFilter"]: {
	equals?: ResolverInputTypes["DistanceUnit"] | undefined | null,
	in?: Array<ResolverInputTypes["DistanceUnit"]> | undefined | null,
	not?: ResolverInputTypes["DistanceUnit"] | undefined | null,
	notIn?: Array<ResolverInputTypes["DistanceUnit"]> | undefined | null
};
	["Driver"]: AliasType<{
	allowJobRejection?:boolean | `@${string}`,
	allowStackingOrders?:boolean | `@${string}`,
	breaks?:boolean | `@${string}`,
	completedSignUp?:boolean | `@${string}`,
	costPerDistanceUnit?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	currentLocation?:boolean | `@${string}`,
	distanceUnit?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	fleetContact?:ResolverInputTypes["DriverFleetContact"],
	fleetDrivers?:ResolverInputTypes["FleetDriver"],
	fleetGroups?:boolean | `@${string}`,
	heading?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	inactiveStartingAt?:boolean | `@${string}`,
	jobAssignments?:ResolverInputTypes["JobAssignmentHistory"],
	jobs?:ResolverInputTypes["Job"],
	lastName?:boolean | `@${string}`,
	middleNames?:boolean | `@${string}`,
	pauseNotifications?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	pushToken?:boolean | `@${string}`,
	/** Stores the associated sns endpoint ARN so the server can send push messages */
	snsEndpointARN?:boolean | `@${string}`,
	speed?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	title?:boolean | `@${string}`,
	/** Synthetic field that counts the number of completed jobs for the current shift */
	todaysJobsCounter?:boolean | `@${string}`,
	/** Synthetic field that counts the distance covered for the completed jobs for the current shift */
	todaysMilesCounter?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	vehicle?:ResolverInputTypes["Vehicle"],
		__typename?: boolean | `@${string}`
}>;
	["DriverConnectOneInput"]: {
	connect: ResolverInputTypes["WhereRequiredIdInput"]
};
	["DriverConnectOrDisconnectInput"]: {
	connect?: ResolverInputTypes["WhereRequiredIdInput"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["DriverConnectOrDisconnectOneInput"]: {
	connect?: ResolverInputTypes["WhereRequiredIdInput"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["DriverCreateInput"]: {
	allowJobRejection?: boolean | undefined | null,
	allowStackingOrders?: boolean | undefined | null,
	completedSignUp?: boolean | undefined | null,
	costPerDistanceUnit?: number | undefined | null,
	currentLocation: string,
	distanceUnit?: ResolverInputTypes["DistanceUnit"] | undefined | null,
	email?: string | undefined | null,
	firstName?: string | undefined | null,
	fleetGroups: string,
	fleetId: string,
	lastName?: string | undefined | null,
	middleNames?: string | undefined | null,
	phoneNumber: string,
	pushToken?: string | undefined | null,
	status: ResolverInputTypes["DriverStatus"],
	title?: string | undefined | null,
	vehicle?: ResolverInputTypes["VehicleCreateNestedOneWithoutDriverInput"] | undefined | null
};
	["DriverCreateNestedOneWithoutFleetDriversInput"]: {
	connect?: ResolverInputTypes["WhereRequiredIdInput"] | undefined | null,
	create?: ResolverInputTypes["DriverCreateWithoutFleetDriversInput"] | undefined | null
};
	["DriverCreateWithoutFleetDriversInput"]: {
	allowJobRejection?: boolean | undefined | null,
	allowStackingOrders?: boolean | undefined | null,
	completedSignUp?: boolean | undefined | null,
	costPerDistanceUnit?: number | undefined | null,
	currentLocation: string,
	distanceUnit?: ResolverInputTypes["DistanceUnit"] | undefined | null,
	email?: string | undefined | null,
	firstName?: string | undefined | null,
	fleetGroups: string,
	lastName?: string | undefined | null,
	middelNames?: string | undefined | null,
	phoneNumber: string,
	pushToken?: string | undefined | null,
	status: ResolverInputTypes["DriverStatus"],
	title?: string | undefined | null,
	vehicle?: ResolverInputTypes["VehicleCreateNestedOneWithoutDriverInput"] | undefined | null
};
	["DriverFleetContact"]: AliasType<{
	fleetContactPhone?:boolean | `@${string}`,
	fleetName?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DriverInviteInput"]: {
	email?: string | undefined | null,
	firstName?: string | undefined | null,
	fleetId: string,
	lastName?: string | undefined | null,
	phoneNumber: string
};
	["DriverJobAssignmentStatus"]:DriverJobAssignmentStatus;
	/** Response from driver login */
["DriverLoginResponse"]: AliasType<{
	expireAt?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	token?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DriverOrderByInput"]: {
	allowJobRejection?: ResolverInputTypes["SortOrder"] | undefined | null,
	allowStackingOrders?: ResolverInputTypes["SortOrder"] | undefined | null,
	breaks?: ResolverInputTypes["SortOrder"] | undefined | null,
	completedSignUp?: ResolverInputTypes["SortOrder"] | undefined | null,
	costPerDistanceUnit?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	currentLocation?: ResolverInputTypes["SortOrder"] | undefined | null,
	distanceUnit?: ResolverInputTypes["SortOrder"] | undefined | null,
	email?: ResolverInputTypes["SortOrder"] | undefined | null,
	firstName?: ResolverInputTypes["SortOrder"] | undefined | null,
	fleetDrivers?: ResolverInputTypes["FleetDriverOrderByRelationAggregateInput"] | undefined | null,
	fleetGroups?: ResolverInputTypes["SortOrder"] | undefined | null,
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	inactiveStartingAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	jobAssignments?: ResolverInputTypes["JobAssignmentHistoryOrderByRelationAggregateInput"] | undefined | null,
	jobs?: ResolverInputTypes["JobOrderByRelationAggregateInput"] | undefined | null,
	lastName?: ResolverInputTypes["SortOrder"] | undefined | null,
	middleNames?: ResolverInputTypes["SortOrder"] | undefined | null,
	phoneNumber?: ResolverInputTypes["SortOrder"] | undefined | null,
	pushToken?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	vehicle?: ResolverInputTypes["VehicleOrderByInput"] | undefined | null
};
	["DriverSetLocationInput"]: {
	currentLocation?: ResolverInputTypes["LocationInput"] | undefined | null,
	id: string
};
	["DriverStatus"]:DriverStatus;
	["DriverUpdateDetailsInput"]: {
	allowJobRejection?: boolean | undefined | null,
	allowStackingOrders?: boolean | undefined | null,
	completedSignUp?: boolean | undefined | null,
	email?: string | undefined | null,
	firstName?: string | undefined | null,
	id: string,
	lastName?: string | undefined | null,
	middleNames?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	title?: string | undefined | null
};
	["DriverUpdateInput"]: {
	allowJobRejection?: boolean | undefined | null,
	allowStackingOrders?: boolean | undefined | null,
	completedSignUp?: boolean | undefined | null,
	costPerDistanceUnit?: number | undefined | null,
	currentLocation?: ResolverInputTypes["LocationInput"] | undefined | null,
	distanceUnit?: ResolverInputTypes["DistanceUnit"] | undefined | null,
	email?: string | undefined | null,
	firstName?: string | undefined | null,
	fleetGroups?: string | undefined | null,
	fleetIds?: Array<string> | undefined | null,
	heading?: number | undefined | null,
	id: string,
	lastName?: string | undefined | null,
	middleNames?: string | undefined | null,
	phoneNumber?: string | undefined | null,
	pushToken?: string | undefined | null,
	speed?: number | undefined | null,
	status?: ResolverInputTypes["DriverStatus"] | undefined | null,
	title?: string | undefined | null
};
	["DriverUpdatePushTokenInput"]: {
	id: string,
	isAppleDevice: boolean,
	pushToken: string
};
	["DriverWhereInput"]: {
	AND?: Array<ResolverInputTypes["DriverWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["DriverWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["DriverWhereInput"]> | undefined | null,
	allowJobRejection?: ResolverInputTypes["BooleanFilter"] | undefined | null,
	allowStackingOrders?: ResolverInputTypes["BooleanFilter"] | undefined | null,
	breaks?: ResolverInputTypes["JsonFilter"] | undefined | null,
	completedSignUp?: ResolverInputTypes["BooleanFilter"] | undefined | null,
	costPerDistanceUnit?: number | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	currentLocation?: ResolverInputTypes["JsonFilter"] | undefined | null,
	distanceUnit?: ResolverInputTypes["DistanceUnit"] | undefined | null,
	email?: ResolverInputTypes["StringFilter"] | undefined | null,
	firstName?: ResolverInputTypes["StringFilter"] | undefined | null,
	fleetDrivers?: ResolverInputTypes["FleetDriverListRelationFilter"] | undefined | null,
	fleetGroups?: ResolverInputTypes["JsonFilter"] | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	inactiveStartingAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	jobAssignments?: ResolverInputTypes["JobAssignmentHistoryListRelationFilter"] | undefined | null,
	jobs?: ResolverInputTypes["JobListRelationFilter"] | undefined | null,
	lastName?: ResolverInputTypes["StringFilter"] | undefined | null,
	middleNames?: ResolverInputTypes["StringFilter"] | undefined | null,
	pauseNotifications?: ResolverInputTypes["BooleanFilter"] | undefined | null,
	phoneNumber?: ResolverInputTypes["StringFilter"] | undefined | null,
	pushToken?: string | undefined | null,
	status?: ResolverInputTypes["DriverStatus"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	vehicle?: ResolverInputTypes["VehicleWhereInput"] | undefined | null
};
	["DropOffTask"]: AliasType<{
	ageVerification?:boolean | `@${string}`,
	/** Duration in seconds for a drop off to be completed */
	allocatedTime?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	dropOffContact?:boolean | `@${string}`,
	dropOffNotes?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	job?:ResolverInputTypes["Job"],
	jobId?:boolean | `@${string}`,
	location?:boolean | `@${string}`,
	locationName?:boolean | `@${string}`,
	orderInStack?:boolean | `@${string}`,
	proofOfDelivery?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["DropOffTaskCreateInput"]: {
	ageVerification?: boolean | undefined | null,
	allocatedTime?: number | undefined | null,
	dropOffContact?: ResolverInputTypes["ContactInput"] | undefined | null,
	dropOffNotes?: string | undefined | null,
	job: ResolverInputTypes["JobConnectOneInput"],
	location: ResolverInputTypes["LocationCoordsInput"],
	locationName: string,
	orderInStack?: number | undefined | null,
	proofOfDelivery?: string | undefined | null
};
	/** Input required by JobCreateDirectInput */
["DropOffTaskInput"]: {
	ageVerification?: boolean | undefined | null,
	dropOffContact: ResolverInputTypes["ContactInput"],
	dropOffNotes?: string | undefined | null,
	location: ResolverInputTypes["LocationCoordsInput"],
	locationName: string,
	orderInStack?: number | undefined | null,
	proofOfDelivery?: string | undefined | null
};
	["DropOffTaskListRelationFilter"]: {
	every?: ResolverInputTypes["DropOffTaskWhereInput"] | undefined | null,
	none?: ResolverInputTypes["DropOffTaskWhereInput"] | undefined | null,
	some?: ResolverInputTypes["DropOffTaskWhereInput"] | undefined | null
};
	["DropOffTaskOrderByInput"]: {
	ageVerification?: ResolverInputTypes["SortOrder"] | undefined | null,
	allocatedTime?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	dropOffNotes?: ResolverInputTypes["SortOrder"] | undefined | null,
	job?: ResolverInputTypes["JobOrderByInput"] | undefined | null,
	locationName?: ResolverInputTypes["SortOrder"] | undefined | null,
	orderInStack?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["DropOffTaskOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["DropOffTaskUpdateInput"]: {
	ageVerification?: boolean | undefined | null,
	dropOffContact?: ResolverInputTypes["ContactInput"] | undefined | null,
	dropOffNotes?: string | undefined | null,
	id: string,
	job?: ResolverInputTypes["JobConnectOneInput"] | undefined | null,
	location?: ResolverInputTypes["LocationCoordsInput"] | undefined | null,
	locationName?: string | undefined | null,
	orderInStack?: number | undefined | null,
	proofOfDelivery?: string | undefined | null
};
	["DropOffTaskUpdateManyWithoutJobInput"]: {
	connect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null
};
	["DropOffTaskUpdateProofOfDelivery"]: {
	id: string,
	proofOfDelivery: string
};
	["DropOffTaskWhereInput"]: {
	AND?: Array<ResolverInputTypes["DropOffTaskWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["DropOffTaskWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["DropOffTaskWhereInput"]> | undefined | null,
	ageVerification?: boolean | undefined | null,
	allocatedTime?: number | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	dropOffNotes?: string | undefined | null,
	id?: string | undefined | null,
	job?: ResolverInputTypes["JobWhereInput"] | undefined | null,
	jobId?: string | undefined | null,
	locationName?: string | undefined | null,
	orderInStack?: number | undefined | null,
	proofOfDelivery?: ResolverInputTypes["JsonFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null
};
	["EligibleDriversForPickup"]: AliasType<{
	driverId?:boolean | `@${string}`,
	driverLocation?:ResolverInputTypes["Coordinates"],
	geographicalDistance?:boolean | `@${string}`,
	roadDistance?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EligibleDriversForPickupInput"]: {
	taskId: string,
	/** If left to true will return the closest drivers based on the geographical distabce rather than the road one */
	useGeographicalDistance?: boolean | undefined | null
};
	["Fleet"]: AliasType<{
	bases?:ResolverInputTypes["Base"],
	broadcastJob?:boolean | `@${string}`,
	contactEmail?:boolean | `@${string}`,
	contactFullAddress?:boolean | `@${string}`,
	contactFullName?:boolean | `@${string}`,
	contactPhone?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	/** What we pay the drivers. Default value. Can be overwriten individually for each driver */
	defaultCostPerDistanceUnit?:boolean | `@${string}`,
	distanceUnit?:boolean | `@${string}`,
	fleetDrivers?:ResolverInputTypes["FleetDriver"],
	geo?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	implementations?:ResolverInputTypes["ApiImplementation"],
	/** Duration in seconds a job waits to be accepted by a driver */
	jobAcceptWaitTime?:boolean | `@${string}`,
	/** Estimated duration in seconds for a drop off to be completed */
	jobDropOffAllocatedTime?:boolean | `@${string}`,
	jobs?:ResolverInputTypes["Job"],
	/** Duration in minutes a job waits before commencing searching for a driver */
	leadTime?:boolean | `@${string}`,
	legalCookies?:boolean | `@${string}`,
	legalPrivacy?:boolean | `@${string}`,
	legalTerms?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	outrightRejectJob?:boolean | `@${string}`,
	partner?:ResolverInputTypes["Partner"],
	partnerId?:boolean | `@${string}`,
	/** What we charge the customer */
	pricePerDistanceUnit?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	users?:ResolverInputTypes["User"],
	vehicles?:ResolverInputTypes["Vehicle"],
	webhooks?:ResolverInputTypes["Webhook"],
		__typename?: boolean | `@${string}`
}>;
	["FleetConnectOneInput"]: {
	connect: ResolverInputTypes["WhereRequiredIdInput"]
};
	["FleetConnectOrDisconnectOneInput"]: {
	connect?: ResolverInputTypes["WhereRequiredIdInput"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["FleetCreateInput"]: {
	bases?: ResolverInputTypes["BaseCreateNestedWithoutFleetInput"] | undefined | null,
	broadcastJob?: boolean | undefined | null,
	contactEmail?: string | undefined | null,
	contactFullAddress?: string | undefined | null,
	contactFullName?: string | undefined | null,
	contactPhone?: string | undefined | null,
	defaultCostPerDistanceUnit?: number | undefined | null,
	distanceUnit?: ResolverInputTypes["DistanceUnit"] | undefined | null,
	fleetDrivers?: ResolverInputTypes["FleetDriverCreateNestedWithoutFleetInput"] | undefined | null,
	geo?: string | undefined | null,
	implementations?: ResolverInputTypes["ApiImplementationCreateNestedManyWithoutFleetInput"] | undefined | null,
	jobAcceptWaitTime?: number | undefined | null,
	jobDropOffAllocatedTime?: number | undefined | null,
	leadTime: number,
	legalCookies?: string | undefined | null,
	legalPrivacy?: string | undefined | null,
	legalTerms?: string | undefined | null,
	name: string,
	outrightRejectJob?: boolean | undefined | null,
	partner: ResolverInputTypes["PartnerCreateNestedOneWithoutFleetsInput"],
	pricePerDistanceUnit?: number | undefined | null,
	vehicles?: ResolverInputTypes["VehicleCreateNestedWithoutOwnerFleetInput"] | undefined | null,
	webhooks?: ResolverInputTypes["WebhookCreateNestedManyWithoutFleetInput"] | undefined | null
};
	["FleetCreateNestedManyWithoutPartnerInput"]: {
	connect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	create?: Array<ResolverInputTypes["FleetCreateWithoutPartnerInput"]> | undefined | null
};
	["FleetCreateWithoutPartnerInput"]: {
	broadcastJob?: boolean | undefined | null,
	contactEmail?: string | undefined | null,
	contactFullAddress?: string | undefined | null,
	contactFullName?: string | undefined | null,
	contactPhone?: string | undefined | null,
	defaultCostPerDistanceUnit?: number | undefined | null,
	distanceUnit?: ResolverInputTypes["DistanceUnit"] | undefined | null,
	geo: string,
	jobAcceptWaitTime?: number | undefined | null,
	jobDropOffAllocatedTime?: number | undefined | null,
	leadTime: number,
	legalCookies: string,
	legalPrivacy: string,
	legalTerms: string,
	name: string,
	outrightRejectJob?: boolean | undefined | null,
	pricePerDistanceUnit?: number | undefined | null
};
	["FleetDriver"]: AliasType<{
	createdAt?:boolean | `@${string}`,
	driver?:ResolverInputTypes["Driver"],
	driverId?:boolean | `@${string}`,
	fleet?:ResolverInputTypes["Fleet"],
	fleetId?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["FleetDriverCreateInput"]: {
	driver?: ResolverInputTypes["DriverConnectOneInput"] | undefined | null,
	fleet: ResolverInputTypes["FleetConnectOneInput"]
};
	["FleetDriverCreateNestedWithoutFleetInput"]: {
	connect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	create?: Array<ResolverInputTypes["FleetDriverCreateWithoutFleetInput"]> | undefined | null
};
	["FleetDriverCreateWithoutDriverInput"]: {
	fleet: ResolverInputTypes["FleetConnectOneInput"]
};
	["FleetDriverCreateWithoutFleetInput"]: {
	driver?: ResolverInputTypes["DriverConnectOneInput"] | undefined | null
};
	["FleetDriverListRelationFilter"]: {
	every?: ResolverInputTypes["FleetDriverWhereInput"] | undefined | null,
	none?: ResolverInputTypes["FleetDriverWhereInput"] | undefined | null,
	some?: ResolverInputTypes["FleetDriverWhereInput"] | undefined | null
};
	["FleetDriverOrderByInput"]: {
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	driver?: ResolverInputTypes["DriverOrderByInput"] | undefined | null,
	driverId?: ResolverInputTypes["SortOrder"] | undefined | null,
	fleet: ResolverInputTypes["FleetOrderByInput"],
	fleetId?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["FleetDriverOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["FleetDriverUpdateInput"]: {
	driver?: ResolverInputTypes["DriverConnectOrDisconnectOneInput"] | undefined | null,
	fleet?: ResolverInputTypes["FleetConnectOrDisconnectOneInput"] | undefined | null,
	id: string
};
	["FleetDriverUpdateManyWithoutDriverInput"]: {
	connect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	create?: Array<ResolverInputTypes["FleetDriverCreateWithoutDriverInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null
};
	["FleetDriverUpdateManyWithoutFleetInput"]: {
	connect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	create?: Array<ResolverInputTypes["FleetDriverCreateWithoutFleetInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null
};
	["FleetDriverWhereInput"]: {
	AND?: Array<ResolverInputTypes["FleetDriverWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["FleetDriverWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["FleetDriverWhereInput"]> | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	driver?: ResolverInputTypes["DriverWhereInput"] | undefined | null,
	driverId?: ResolverInputTypes["StringFilter"] | undefined | null,
	fleet?: ResolverInputTypes["FleetWhereInput"] | undefined | null,
	fleetId?: ResolverInputTypes["StringFilter"] | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null
};
	["FleetListRelationFilter"]: {
	every?: ResolverInputTypes["FleetWhereInput"] | undefined | null,
	none?: ResolverInputTypes["FleetWhereInput"] | undefined | null,
	some?: ResolverInputTypes["FleetWhereInput"] | undefined | null
};
	["FleetOrderByInput"]: {
	bases?: ResolverInputTypes["BaseOrderByRelationAggregateInput"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	defaultCostPerDistanceUnit?: ResolverInputTypes["SortOrder"] | undefined | null,
	distanceUnit?: ResolverInputTypes["SortOrder"] | undefined | null,
	fleetDrivers?: ResolverInputTypes["FleetDriverOrderByRelationAggregateInput"] | undefined | null,
	geo?: ResolverInputTypes["SortOrder"] | undefined | null,
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	implementations?: ResolverInputTypes["ApiImplementationOrderByRelationAggregateInput"] | undefined | null,
	jobAcceptWaitTime?: ResolverInputTypes["SortOrder"] | undefined | null,
	jobDropOffAllocatedTime?: ResolverInputTypes["SortOrder"] | undefined | null,
	jobs?: ResolverInputTypes["JobOrderByRelationAggregateInput"] | undefined | null,
	leadTime?: ResolverInputTypes["SortOrder"] | undefined | null,
	legalCookies?: ResolverInputTypes["SortOrder"] | undefined | null,
	legalPrivacy?: ResolverInputTypes["SortOrder"] | undefined | null,
	legalTerms?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	partner?: ResolverInputTypes["PartnerOrderByInput"] | undefined | null,
	partnerId?: ResolverInputTypes["SortOrder"] | undefined | null,
	pricePerDistanceUnit?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	vehicles?: ResolverInputTypes["VehicleOrderByRelationAggregateInput"] | undefined | null,
	webhooks?: ResolverInputTypes["WebhookOrderByRelationAggregateInput"] | undefined | null
};
	["FleetOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["FleetUpdateInput"]: {
	bases?: ResolverInputTypes["BaseUpdateManyWithoutFleetInput"] | undefined | null,
	broadcastJob?: boolean | undefined | null,
	contactEmail?: string | undefined | null,
	contactFullAddress?: string | undefined | null,
	contactFullName?: string | undefined | null,
	contactPhone?: string | undefined | null,
	defaultCostPerDistanceUnit?: number | undefined | null,
	distanceUnit?: ResolverInputTypes["DistanceUnit"] | undefined | null,
	fleetDrivers?: ResolverInputTypes["FleetDriverUpdateManyWithoutFleetInput"] | undefined | null,
	geo?: string | undefined | null,
	id: string,
	implementations?: ResolverInputTypes["ApiImplementationUpdateManyWithoutFleetInput"] | undefined | null,
	jobAcceptWaitTime?: number | undefined | null,
	jobDropOffAllocatedTime?: number | undefined | null,
	leadTime?: number | undefined | null,
	legalCookies?: string | undefined | null,
	legalPrivacy?: string | undefined | null,
	legalTerms?: string | undefined | null,
	name?: string | undefined | null,
	outrightRejectJob?: boolean | undefined | null,
	pricePerDistanceUnit?: number | undefined | null,
	vehicles?: ResolverInputTypes["VehicleUpdateManyWithoutOwnerFleetInput"] | undefined | null,
	webhooks?: ResolverInputTypes["WebhookUpdateManyWithoutFleetInput"] | undefined | null
};
	["FleetUpdateManyWithoutPartnerInput"]: {
	connect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	create?: Array<ResolverInputTypes["FleetCreateWithoutPartnerInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null
};
	["FleetWhereInput"]: {
	AND?: ResolverInputTypes["FleetWhereInput"] | undefined | null,
	NOT?: ResolverInputTypes["FleetWhereInput"] | undefined | null,
	OR?: ResolverInputTypes["FleetWhereInput"] | undefined | null,
	bases?: ResolverInputTypes["BaseListRelationFilter"] | undefined | null,
	broadcastJob?: ResolverInputTypes["BooleanFilter"] | undefined | null,
	contactEmail?: ResolverInputTypes["StringFilter"] | undefined | null,
	contactFullAddress?: ResolverInputTypes["StringFilter"] | undefined | null,
	contactFullName?: ResolverInputTypes["StringFilter"] | undefined | null,
	contactPhone?: ResolverInputTypes["StringFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	defaultCostPerDistanceUnit?: ResolverInputTypes["IntFilter"] | undefined | null,
	distanceUnit?: ResolverInputTypes["DistanceUnitFilter"] | undefined | null,
	fleetDrivers?: ResolverInputTypes["FleetDriverListRelationFilter"] | undefined | null,
	geo?: ResolverInputTypes["JsonFilter"] | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	implementations?: ResolverInputTypes["ApiImplementationListRelationFilter"] | undefined | null,
	jobAcceptWaitTime?: ResolverInputTypes["IntFilter"] | undefined | null,
	jobDropOffAllocatedTime?: ResolverInputTypes["IntFilter"] | undefined | null,
	jobs?: ResolverInputTypes["JobListRelationFilter"] | undefined | null,
	leadTime?: ResolverInputTypes["IntFilter"] | undefined | null,
	legalCookies?: ResolverInputTypes["StringFilter"] | undefined | null,
	legalPrivacy?: ResolverInputTypes["StringFilter"] | undefined | null,
	legalTerms?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	outrightRejectJob?: ResolverInputTypes["BooleanFilter"] | undefined | null,
	partner?: ResolverInputTypes["PartnerWhereInput"] | undefined | null,
	partnerId?: ResolverInputTypes["StringFilter"] | undefined | null,
	pricePerDistanceUnit?: ResolverInputTypes["IntFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	vehicles?: ResolverInputTypes["VehicleListRelationFilter"] | undefined | null,
	webhooks?: ResolverInputTypes["WebhookListRelationFilter"] | undefined | null
};
	["IntFilter"]: {
	equals?: number | undefined | null,
	gt?: number | undefined | null,
	gte?: number | undefined | null,
	in?: Array<number> | undefined | null,
	lt?: number | undefined | null,
	lte?: number | undefined | null,
	not?: ResolverInputTypes["IntFilter"] | undefined | null,
	notIn?: Array<number> | undefined | null
};
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:unknown;
	["Job"]: AliasType<{
	assignmentHistory?:ResolverInputTypes["JobAssignmentHistory"],
	canceledBy?:boolean | `@${string}`,
	completedByDriverFullName?:boolean | `@${string}`,
	completedByDriverId?:boolean | `@${string}`,
	completionTime?:boolean | `@${string}`,
	/** Initially matches the initialCost but will change to reflect the cost of the journey's equivalent leg for stacked jobs */
	cost?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	/** Stored at creation time and cannot be updated */
	distanceUnit?:boolean | `@${string}`,
	driver?:ResolverInputTypes["Driver"],
	driverId?:boolean | `@${string}`,
	dropOffTasks?:ResolverInputTypes["DropOffTask"],
	estimatedDistanceMiles?:boolean | `@${string}`,
	estimatedDurationMinutes?:boolean | `@${string}`,
	estimatedPickupTime?:boolean | `@${string}`,
	/** Flag used by the scheduler to continue looking for drivers or cancel jobs under certain conditions. Set to true once a driver manually accepts or rejects a job, or once the assignment loop finishes. */
	finishedAssignment?:boolean | `@${string}`,
	fleet?:ResolverInputTypes["Fleet"],
	fleetContactEmail?:boolean | `@${string}`,
	fleetContactFullAddress?:boolean | `@${string}`,
	fleetContactFullName?:boolean | `@${string}`,
	fleetContactPhone?:boolean | `@${string}`,
	fleetId?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	/** The ideal route, this is calculated at creation time and is what the customer is charged for. Never changes and is kept for reference */
	idealRoute?:boolean | `@${string}`,
	/** Stored at creation time and updates if the job is reassigned. This is what we might pay the driver */
	initialCost?:boolean | `@${string}`,
	/** The max amount of time until the job may be automatically rejected if the driver does not accept or reject manually */
	jobAcceptWaitTime?:boolean | `@${string}`,
	/** Holds notes related to the delivery completion */
	jobCompleteNotes?:boolean | `@${string}`,
	/** Creates on demand a presigned URL that points to an image in S3 */
	jobCompletePhotoProof?:boolean | `@${string}`,
	/** Flag that signals the existence of a pic. Internally, it spares a trip to the S3 bucket and generating a url */
	jobHasPhotoProof?:boolean | `@${string}`,
	/** Human readable reference for the job, used by drivers to contact dispatch */
	jobReference?:boolean | `@${string}`,
	onHold?:boolean | `@${string}`,
	orderContents?:boolean | `@${string}`,
	orderId?:boolean | `@${string}`,
	/** Flag that signals the order must assign a driver ASAP or reject the job */
	orderIsAsap?:boolean | `@${string}`,
	/** Human readable reference for the order, used by dispatch to contact the partner, e.g. Redbox */
	orderReference?:boolean | `@${string}`,
	pickupTasks?:ResolverInputTypes["PickupTask"],
	pickupTime?:boolean | `@${string}`,
	/** Stored at creation time and cannot be updated. This is what we charge the customer */
	price?:boolean | `@${string}`,
	reasonForCancellation?:boolean | `@${string}`,
	/** The suggested route, might be simply a journey's leg when dealing with stacked jobs */
	route?:boolean | `@${string}`,
	stackId?:boolean | `@${string}`,
	/** The Lowest common denominator of the job's statuses in the stack */
	stackStatus?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["JobAssignmentHistory"]: AliasType<{
	autoRejectAt?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	driver?:ResolverInputTypes["Driver"],
	driverId?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	job?:ResolverInputTypes["Job"],
	jobId?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["JobAssignmentHistoryCreateInput"]: {
	autoRejectAt?: string | undefined | null,
	driver: ResolverInputTypes["DriverConnectOneInput"],
	job: ResolverInputTypes["JobConnectOneInput"],
	status: ResolverInputTypes["DriverJobAssignmentStatus"]
};
	["JobAssignmentHistoryListRelationFilter"]: {
	every?: ResolverInputTypes["JobAssignmentHistoryWhereInput"] | undefined | null,
	none?: ResolverInputTypes["JobAssignmentHistoryWhereInput"] | undefined | null,
	some?: ResolverInputTypes["JobAssignmentHistoryWhereInput"] | undefined | null
};
	["JobAssignmentHistoryOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["JobAssignmentHistoryUpdateInput"]: {
	autoRejectAt?: string | undefined | null,
	driver?: ResolverInputTypes["DriverConnectOneInput"] | undefined | null,
	id: string,
	job?: ResolverInputTypes["JobConnectOneInput"] | undefined | null,
	status?: ResolverInputTypes["DriverJobAssignmentStatus"] | undefined | null
};
	["JobAssignmentHistoryUpdateManyWithoutDriverInput"]: {
	connect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null
};
	["JobAssignmentHistoryUpdateManyWithoutJobInput"]: {
	connect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null
};
	["JobAssignmentHistoryWhereInput"]: {
	AND?: Array<ResolverInputTypes["JobAssignmentHistoryWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["JobAssignmentHistoryWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["JobAssignmentHistoryWhereInput"]> | undefined | null,
	autoRejectAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	driver?: ResolverInputTypes["DriverWhereInput"] | undefined | null,
	driverId?: string | undefined | null,
	id?: string | undefined | null,
	job?: ResolverInputTypes["JobWhereInput"] | undefined | null,
	jobId?: string | undefined | null,
	status?: ResolverInputTypes["DriverJobAssignmentStatus"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null
};
	["JobAssignmentOrderByInput"]: {
	autoRejectAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	driver?: ResolverInputTypes["DriverOrderByInput"] | undefined | null,
	job?: ResolverInputTypes["JobOrderByInput"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["JobConnectOneInput"]: {
	connect: ResolverInputTypes["WhereRequiredIdInput"]
};
	["JobCreateDirectInput"]: {
	dropOffTask: ResolverInputTypes["DropOffTaskInput"],
	estimatedPickupTime?: ResolverInputTypes["DateTime"] | undefined | null,
	fleetId: string,
	orderContents: Array<string>,
	orderId: string,
	orderIsAsap: boolean,
	orderReference: string,
	pickupTask: ResolverInputTypes["PickupTaskInput"],
	/** This will be calculated based on the route */
	price?: number | undefined | null
};
	["JobListRelationFilter"]: {
	every?: ResolverInputTypes["JobWhereInput"] | undefined | null,
	none?: ResolverInputTypes["JobWhereInput"] | undefined | null,
	some?: ResolverInputTypes["JobWhereInput"] | undefined | null
};
	["JobOrderByInput"]: {
	assignmentHistory?: ResolverInputTypes["JobAssignmentHistoryOrderByRelationAggregateInput"] | undefined | null,
	canceledBy?: ResolverInputTypes["SortOrder"] | undefined | null,
	completedByDriverFullName?: ResolverInputTypes["SortOrder"] | undefined | null,
	completedByDriverId?: ResolverInputTypes["SortOrder"] | undefined | null,
	completionTime?: ResolverInputTypes["SortOrder"] | undefined | null,
	cost?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	distanceUnit?: ResolverInputTypes["SortOrder"] | undefined | null,
	driver?: ResolverInputTypes["DriverOrderByInput"] | undefined | null,
	dropOffTasks?: ResolverInputTypes["DropOffTaskOrderByRelationAggregateInput"] | undefined | null,
	estimatedDistanceMiles?: ResolverInputTypes["SortOrder"] | undefined | null,
	estimatedDurationMinutes?: ResolverInputTypes["SortOrder"] | undefined | null,
	estimatedPickupTime?: ResolverInputTypes["SortOrder"] | undefined | null,
	finishedAssignment?: ResolverInputTypes["SortOrder"] | undefined | null,
	fleet?: ResolverInputTypes["FleetOrderByInput"] | undefined | null,
	initialCost?: ResolverInputTypes["SortOrder"] | undefined | null,
	jobAcceptWaitTime?: ResolverInputTypes["SortOrder"] | undefined | null,
	jobCompleteNotes?: ResolverInputTypes["SortOrder"] | undefined | null,
	jobReference?: ResolverInputTypes["SortOrder"] | undefined | null,
	onHold?: ResolverInputTypes["SortOrder"] | undefined | null,
	orderContents?: ResolverInputTypes["SortOrder"] | undefined | null,
	orderId?: ResolverInputTypes["SortOrder"] | undefined | null,
	orderIsAsap?: ResolverInputTypes["SortOrder"] | undefined | null,
	orderReference?: ResolverInputTypes["SortOrder"] | undefined | null,
	pickupTasks?: ResolverInputTypes["PickupTaskOrderByRelationAggregateInput"] | undefined | null,
	pickupTime?: ResolverInputTypes["SortOrder"] | undefined | null,
	price?: ResolverInputTypes["SortOrder"] | undefined | null,
	stackId?: ResolverInputTypes["SortOrder"] | undefined | null,
	stackStatus?: ResolverInputTypes["SortOrder"] | undefined | null,
	status?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["JobOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["JobStatus"]:JobStatus;
	["JobStatusFilter"]: {
	equals?: ResolverInputTypes["JobStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["JobStatus"]> | undefined | null,
	not?: ResolverInputTypes["JobStatus"] | undefined | null,
	notIn?: Array<ResolverInputTypes["JobStatus"]> | undefined | null
};
	["JobUpdateInput"]: {
	assignmentHistory?: ResolverInputTypes["JobAssignmentHistoryUpdateManyWithoutJobInput"] | undefined | null,
	canceledBy?: ResolverInputTypes["CanceledBy"] | undefined | null,
	completionTime?: ResolverInputTypes["DateTime"] | undefined | null,
	cost?: number | undefined | null,
	driver?: ResolverInputTypes["DriverConnectOrDisconnectInput"] | undefined | null,
	dropOffTasks?: ResolverInputTypes["DropOffTaskUpdateManyWithoutJobInput"] | undefined | null,
	estimatedDistanceMiles?: number | undefined | null,
	estimatedDurationMinutes?: number | undefined | null,
	estimatedPickupTime?: ResolverInputTypes["DateTime"] | undefined | null,
	finishedAssignment?: boolean | undefined | null,
	fleet?: ResolverInputTypes["FleetConnectOneInput"] | undefined | null,
	id: string,
	initialCost?: number | undefined | null,
	initialRoute?: string | undefined | null,
	jobAcceptWaitTime?: number | undefined | null,
	onHold?: boolean | undefined | null,
	orderContents?: Array<string> | undefined | null,
	orderIsAsap?: boolean | undefined | null,
	orderReference?: string | undefined | null,
	pickupTasks?: ResolverInputTypes["PickupTaskUpdateManyWithoutJobInput"] | undefined | null,
	pickupTime?: ResolverInputTypes["DateTime"] | undefined | null,
	reasonForCancellation?: string | undefined | null,
	route?: string | undefined | null,
	stackId?: string | undefined | null,
	status?: ResolverInputTypes["JobStatus"] | undefined | null
};
	["JobUpdateManyWithoutDriverInput"]: {
	connect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null
};
	["JobWhereInput"]: {
	AND?: Array<ResolverInputTypes["JobWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["JobWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["JobWhereInput"]> | undefined | null,
	assignmentHistory?: ResolverInputTypes["JobAssignmentHistoryListRelationFilter"] | undefined | null,
	canceledBy?: ResolverInputTypes["CanceledBy"] | undefined | null,
	completedByDriverFullName?: ResolverInputTypes["StringFilter"] | undefined | null,
	completedByDriverId?: ResolverInputTypes["StringFilter"] | undefined | null,
	completionTime?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	cost?: number | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	distanceUnit?: ResolverInputTypes["DistanceUnitFilter"] | undefined | null,
	driver?: ResolverInputTypes["DriverWhereInput"] | undefined | null,
	driverId?: ResolverInputTypes["StringFilter"] | undefined | null,
	dropOffTasks?: ResolverInputTypes["DropOffTaskListRelationFilter"] | undefined | null,
	estimatedDistanceMiles?: number | undefined | null,
	estimatedDurationMinutes?: number | undefined | null,
	estimatedPickupTime?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	finishedAssignment?: boolean | undefined | null,
	fleet?: ResolverInputTypes["FleetWhereInput"] | undefined | null,
	fleetId?: ResolverInputTypes["StringFilter"] | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	initialCost?: number | undefined | null,
	jobAcceptWaitTime?: number | undefined | null,
	jobCompleteNotes?: ResolverInputTypes["StringFilter"] | undefined | null,
	jobReference?: ResolverInputTypes["StringFilter"] | undefined | null,
	onHold?: boolean | undefined | null,
	orderContents?: ResolverInputTypes["JsonFilter"] | undefined | null,
	orderId?: ResolverInputTypes["StringFilter"] | undefined | null,
	orderIsAsap?: boolean | undefined | null,
	orderReference?: ResolverInputTypes["StringFilter"] | undefined | null,
	pickupTasks?: ResolverInputTypes["PickupTaskListRelationFilter"] | undefined | null,
	pickupTime?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	price?: number | undefined | null,
	stackId?: ResolverInputTypes["StringFilter"] | undefined | null,
	stackStatus?: ResolverInputTypes["JobStatusFilter"] | undefined | null,
	status?: ResolverInputTypes["JobStatusFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null
};
	["JsonFilter"]: {
	equals?: string | undefined | null,
	not?: string | undefined | null
};
	["LocationCoordsInput"]: {
	address: ResolverInputTypes["AddressInput"],
	latitude: number,
	longitude: number
};
	["LocationInput"]: {
	latitude: number,
	longitude: number
};
	["MinimalNode"]: AliasType<{
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mutation"]: AliasType<{
acceptJob?: [{	id: string},ResolverInputTypes["Job"]],
assignJobToDriver?: [{	input: ResolverInputTypes["AssignJobToDriverInput"]},ResolverInputTypes["Job"]],
	/** Starts the assignment loop. Runs periodically */
	assignJobsScheduler?:boolean | `@${string}`,
	/** Transitions drivers to the correct status based on their booked breaks if they are not already in the correct status. Runs periodically */
	autoTransitionDriversStatusScheduler?:boolean | `@${string}`,
	/** Transitions jobs to travelling-to-pickup status based on estimated pickup time and current driver position. Runs periodically */
	autoTravelToPickupScheduler?:boolean | `@${string}`,
cancelJob?: [{	input: ResolverInputTypes["CancelJobInput"]},ResolverInputTypes["Job"]],
	/** Cancels jobs that are in the exception queue. It only does so for fleets that allow automated job cancelling. */
	cancelJobsScheduler?:boolean | `@${string}`,
completeJob?: [{	id: string},ResolverInputTypes["Job"]],
completeUserProfile?: [{	input: ResolverInputTypes["CompleteUserProfileInput"]},boolean | `@${string}`],
	/** Continues assigning jobs if the assignment loop did not finish. Runs periodically */
	continueJobsAssignmentScheduler?:boolean | `@${string}`,
createApiImplementation?: [{	input: ResolverInputTypes["ApiImplementationCreateInput"]},ResolverInputTypes["ApiImplementation"]],
createBase?: [{	input: ResolverInputTypes["BaseCreateInput"]},ResolverInputTypes["Base"]],
createDropOffTask?: [{	input: ResolverInputTypes["DropOffTaskCreateInput"]},ResolverInputTypes["DropOffTask"]],
createFleet?: [{	input: ResolverInputTypes["FleetCreateInput"]},ResolverInputTypes["Fleet"]],
createFleetDriver?: [{	input: ResolverInputTypes["FleetDriverCreateInput"]},ResolverInputTypes["FleetDriver"]],
createJob?: [{	input: ResolverInputTypes["JobCreateDirectInput"]},ResolverInputTypes["Job"]],
createJobAssignmentHistory?: [{	input: ResolverInputTypes["JobAssignmentHistoryCreateInput"]},ResolverInputTypes["JobAssignmentHistory"]],
createPartner?: [{	input: ResolverInputTypes["PartnerCreateInput"]},ResolverInputTypes["Partner"]],
createPickupTask?: [{	input: ResolverInputTypes["PickupTaskCreateInput"]},ResolverInputTypes["PickupTask"]],
createPlatform?: [{	input: ResolverInputTypes["PlatformCreateInput"]},ResolverInputTypes["Platform"]],
createUser?: [{	input: ResolverInputTypes["UserCreateInput"]},ResolverInputTypes["CreateUserResponse"]],
createWebhook?: [{	input: ResolverInputTypes["WebhookCreateInput"]},ResolverInputTypes["Webhook"]],
deleteApiImplementation?: [{	id: string},ResolverInputTypes["ApiImplementation"]],
deleteDriver?: [{	driverId: string},ResolverInputTypes["Driver"]],
deleteJob?: [{	id: string},ResolverInputTypes["Job"]],
deleteUser?: [{	userId: string},ResolverInputTypes["User"]],
deleteWebhook?: [{	id: string},ResolverInputTypes["Webhook"]],
driverLogin?: [{	phoneNumber: string},ResolverInputTypes["DriverLoginResponse"]],
	/** Dedicated mutation that is only triggered by the driver app when the driver logs out. Has a side effect of pausing notifications for the driver. */
	driverLogout?:boolean | `@${string}`,
inviteDriver?: [{	input: ResolverInputTypes["DriverInviteInput"]},ResolverInputTypes["Driver"]],
inviteUser?: [{	input: ResolverInputTypes["UserInviteInput"]},boolean | `@${string}`],
login?: [{	input: ResolverInputTypes["UserLoginInput"]},boolean | `@${string}`],
notifyDriver?: [{	input: ResolverInputTypes["NotifyDriverInput"]},boolean | `@${string}`],
rejectJob?: [{	id: string},ResolverInputTypes["Job"]],
removeDriverStatusOption?: [{	driverId?: string | undefined | null,	option: ResolverInputTypes["JSON"]},boolean | `@${string}`],
requestPasswordReset?: [{	email: string},boolean | `@${string}`],
resetPassword?: [{	input: ResolverInputTypes["UserResetPasswordInput"]},boolean | `@${string}`],
setDriverStatusFromOption?: [{	driverId?: string | undefined | null,	option: ResolverInputTypes["JSON"]},boolean | `@${string}`],
updateApiImplementation?: [{	input: ResolverInputTypes["ApiImplementationUpdateInput"]},ResolverInputTypes["ApiImplementation"]],
updateBase?: [{	input: ResolverInputTypes["BaseUpdateInput"]},ResolverInputTypes["Base"]],
updateDriver?: [{	input: ResolverInputTypes["DriverUpdateInput"]},ResolverInputTypes["Driver"]],
updateDriverDetails?: [{	input: ResolverInputTypes["DriverUpdateDetailsInput"]},ResolverInputTypes["Driver"]],
updateDriverLocation?: [{	input: ResolverInputTypes["DriverSetLocationInput"]},boolean | `@${string}`],
updateDropOffTask?: [{	input: ResolverInputTypes["DropOffTaskUpdateInput"]},ResolverInputTypes["DropOffTask"]],
updateDropOffTaskProofOfDelivery?: [{	input: ResolverInputTypes["DropOffTaskUpdateProofOfDelivery"]},ResolverInputTypes["DropOffTask"]],
updateFleet?: [{	input: ResolverInputTypes["FleetUpdateInput"]},ResolverInputTypes["Fleet"]],
updateFleetDriver?: [{	input: ResolverInputTypes["FleetDriverUpdateInput"]},ResolverInputTypes["FleetDriver"]],
updateJob?: [{	input: ResolverInputTypes["JobUpdateInput"]},ResolverInputTypes["Job"]],
updateJobAssignmentHistory?: [{	input: ResolverInputTypes["JobAssignmentHistoryUpdateInput"]},ResolverInputTypes["JobAssignmentHistory"]],
updateJobPickupTime?: [{	input: ResolverInputTypes["UpdateJobPickupTimeInput"]},ResolverInputTypes["Job"]],
updateJobStatus?: [{	input: ResolverInputTypes["UpdateJobStatusInput"]},ResolverInputTypes["Job"]],
updatePartner?: [{	input: ResolverInputTypes["PartnerUpdateInput"]},ResolverInputTypes["Partner"]],
updatePassword?: [{	input: ResolverInputTypes["UserUpdatePasswordInput"]},boolean | `@${string}`],
updatePickupTask?: [{	input: ResolverInputTypes["PickupTaskUpdateInput"]},ResolverInputTypes["PickupTask"]],
updatePlatform?: [{	input: ResolverInputTypes["PlatformUpdateInput"]},ResolverInputTypes["Platform"]],
updatePushToken?: [{	input: ResolverInputTypes["DriverUpdatePushTokenInput"]},ResolverInputTypes["Driver"]],
updateUser?: [{	input: ResolverInputTypes["UserUpdateInput"]},ResolverInputTypes["User"]],
updateWebhook?: [{	input: ResolverInputTypes["WebhookUpdateInput"]},ResolverInputTypes["Webhook"]],
uploadJobCompleteProof?: [{	input: ResolverInputTypes["UploadJobCompleteProofInput"]},boolean | `@${string}`],
verifyPhone?: [{	verificationCode: string},ResolverInputTypes["VerifyPhoneResponse"]],
		__typename?: boolean | `@${string}`
}>;
	["NotifyDriverInput"]: {
	body: string,
	data: ResolverInputTypes["JSON"],
	driverId: string,
	subtitle?: string | undefined | null,
	title: string
};
	["OptionForDriverStatus"]: AliasType<{
	booked?:boolean | `@${string}`,
	durationInMinutes?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	label?:boolean | `@${string}`,
	maxStartTime?:boolean | `@${string}`,
	minStartTime?:boolean | `@${string}`,
	minimalLabel?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PaginatedJobs"]: AliasType<{
	count?:boolean | `@${string}`,
	jobs?:ResolverInputTypes["Job"],
		__typename?: boolean | `@${string}`
}>;
	["Partner"]: AliasType<{
	createdAt?:boolean | `@${string}`,
	fleets?:ResolverInputTypes["Fleet"],
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	platform?:ResolverInputTypes["Platform"],
	platformId?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	users?:ResolverInputTypes["User"],
		__typename?: boolean | `@${string}`
}>;
	["PartnerConnectOrDisconnectInput"]: {
	connect?: ResolverInputTypes["WhereRequiredIdInput"] | undefined | null,
	disconnect?: ResolverInputTypes["WhereRequiredIdInput"] | undefined | null
};
	["PartnerCreateInput"]: {
	name: string,
	platform: ResolverInputTypes["PlatformConnectById"]
};
	["PartnerCreateNestedOneWithoutFleetsInput"]: {
	connect?: ResolverInputTypes["WhereRequiredIdInput"] | undefined | null,
	create?: ResolverInputTypes["PartnerCreateInput"] | undefined | null
};
	["PartnerCreateWithoutPlatformInput"]: {
	fleets?: ResolverInputTypes["FleetCreateNestedManyWithoutPartnerInput"] | undefined | null,
	name: string
};
	["PartnerListRelationFilter"]: {
	every?: ResolverInputTypes["PartnerWhereInput"] | undefined | null,
	none?: ResolverInputTypes["PartnerWhereInput"] | undefined | null,
	some?: ResolverInputTypes["PartnerWhereInput"] | undefined | null
};
	["PartnerOrderByInput"]: {
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	fleets?: ResolverInputTypes["FleetOrderByRelationAggregateInput"] | undefined | null,
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	partners?: ResolverInputTypes["PartnerOrderByRelationAggregateInput"] | undefined | null,
	platform?: ResolverInputTypes["PlatformOrderByInput"] | undefined | null,
	platformId?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PartnerOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PartnerUpdateInput"]: {
	fleets?: ResolverInputTypes["FleetUpdateManyWithoutPartnerInput"] | undefined | null,
	id: string,
	name?: string | undefined | null
};
	["PartnerUpdatemanyWithoutPlatformInput"]: {
	connect?: Array<ResolverInputTypes["PartnerWhereUniqueInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["PartnerWhereUniqueInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["PartnerWhereUniqueInput"]> | undefined | null
};
	["PartnerWhereInput"]: {
	AND?: Array<ResolverInputTypes["PartnerWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PartnerWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PartnerWhereInput"]> | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	fleets?: ResolverInputTypes["FleetListRelationFilter"] | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	platform?: ResolverInputTypes["PlatformWhereInput"] | undefined | null,
	platformId?: ResolverInputTypes["StringFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null
};
	["PartnerWhereUniqueInput"]: {
	id?: string | undefined | null
};
	["PickupTask"]: AliasType<{
	createdAt?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	job?:ResolverInputTypes["Job"],
	jobId?:boolean | `@${string}`,
	location?:boolean | `@${string}`,
	locationHashed?:boolean | `@${string}`,
	locationName?:boolean | `@${string}`,
	pickupContact?:boolean | `@${string}`,
	pickupNotes?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PickupTaskCreateInput"]: {
	job: ResolverInputTypes["JobConnectOneInput"],
	location: ResolverInputTypes["LocationCoordsInput"],
	locationName: string,
	pickupContact?: ResolverInputTypes["ContactInput"] | undefined | null,
	pickupNotes?: string | undefined | null
};
	/** Input required by JobCreateDirectInput */
["PickupTaskInput"]: {
	location: ResolverInputTypes["LocationCoordsInput"],
	locationName: string,
	pickupContact: ResolverInputTypes["ContactInput"],
	pickupNotes?: string | undefined | null
};
	["PickupTaskListRelationFilter"]: {
	every?: ResolverInputTypes["PickupTaskWhereInput"] | undefined | null,
	none?: ResolverInputTypes["PickupTaskWhereInput"] | undefined | null,
	some?: ResolverInputTypes["PickupTaskWhereInput"] | undefined | null
};
	["PickupTaskOrderByInput"]: {
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	job?: ResolverInputTypes["JobOrderByInput"] | undefined | null,
	locationHashed?: ResolverInputTypes["SortOrder"] | undefined | null,
	locationName?: ResolverInputTypes["SortOrder"] | undefined | null,
	pickupNotes?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PickupTaskOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PickupTaskUpdateInput"]: {
	id: string,
	job?: ResolverInputTypes["JobConnectOneInput"] | undefined | null,
	location?: ResolverInputTypes["LocationCoordsInput"] | undefined | null,
	locationName?: string | undefined | null,
	pickupContact?: ResolverInputTypes["ContactInput"] | undefined | null,
	pickupNotes?: string | undefined | null,
	proofOfDelivery?: string | undefined | null
};
	["PickupTaskUpdateManyWithoutJobInput"]: {
	connect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null
};
	["PickupTaskWhereInput"]: {
	AND?: Array<ResolverInputTypes["PickupTaskWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PickupTaskWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PickupTaskWhereInput"]> | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	id?: string | undefined | null,
	job?: ResolverInputTypes["JobWhereInput"] | undefined | null,
	jobId?: string | undefined | null,
	locationHashed?: string | undefined | null,
	locationName?: string | undefined | null,
	pickupNotes?: string | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null
};
	["Platform"]: AliasType<{
	createdAt?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	partners?:ResolverInputTypes["Partner"],
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["PlatformConnectById"]: {
	connect: ResolverInputTypes["WhereRequiredIdInput"]
};
	["PlatformCreateInput"]: {
	name: string,
	partners?: ResolverInputTypes["PlatformCreateOrConnectPartnerInput"] | undefined | null
};
	["PlatformCreateOrConnectPartnerInput"]: {
	connect?: Array<string> | undefined | null,
	create?: Array<ResolverInputTypes["PartnerCreateWithoutPlatformInput"]> | undefined | null
};
	["PlatformOrderByInput"]: {
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	partners?: ResolverInputTypes["PartnerOrderByRelationAggregateInput"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["PlatformUpdateInput"]: {
	id: string,
	name?: string | undefined | null,
	partners?: ResolverInputTypes["PartnerUpdatemanyWithoutPlatformInput"] | undefined | null
};
	["PlatformWhereInput"]: {
	AND?: Array<ResolverInputTypes["PlatformWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["PlatformWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["PlatformWhereInput"]> | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	partners?: ResolverInputTypes["PartnerListRelationFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null
};
	["Query"]: AliasType<{
apiImplementation?: [{	id: string},ResolverInputTypes["ApiImplementation"]],
apiImplementations?: [{	orderBy?: ResolverInputTypes["ApiImplementationOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["ApiImplementationWhereInput"] | undefined | null},ResolverInputTypes["ApiImplementation"]],
	assignedJobs?:ResolverInputTypes["Job"],
availableNodesByUserAndDesiredRole?: [{	input: ResolverInputTypes["AvailableNodesByUserAndDesiredRoleInput"]},ResolverInputTypes["MinimalNode"]],
	/** Returns an array of available roles the current user can grant to new users */
	availableRolesByUser?:boolean | `@${string}`,
base?: [{	id: string},ResolverInputTypes["Base"]],
bases?: [{	orderBy?: ResolverInputTypes["BaseOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["BaseWhereInput"] | undefined | null},ResolverInputTypes["Base"]],
bookedAndAvailableOptionsForDriverStatus?: [{	driverId?: string | undefined | null},ResolverInputTypes["OptionForDriverStatus"]],
calculateRoute?: [{	input: ResolverInputTypes["CalculateRouteInput"]},boolean | `@${string}`],
collisionsOrStackability?: [{	destinationDriverId: string,	jobId: string},ResolverInputTypes["CollisionsOrStackability"]],
	currentDriver?:ResolverInputTypes["Driver"],
	/** Returns an array of assigned, not on hold and en-route jobs */
	currentJobs?:ResolverInputTypes["Job"],
	currentUser?:ResolverInputTypes["User"],
deliveryEstimate?: [{	input: ResolverInputTypes["DeliveryEstimateInput"]},ResolverInputTypes["DeliveryEstimate"]],
driver?: [{	id: string},ResolverInputTypes["Driver"]],
drivers?: [{	orderBy?: ResolverInputTypes["DriverOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["DriverWhereInput"] | undefined | null},ResolverInputTypes["Driver"]],
dropOffTask?: [{	id: string},ResolverInputTypes["DropOffTask"]],
dropOffTasks?: [{	orderBy?: ResolverInputTypes["DropOffTaskOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["DropOffTaskWhereInput"] | undefined | null},ResolverInputTypes["DropOffTask"]],
eligibleDriversForPickup?: [{	input: ResolverInputTypes["EligibleDriversForPickupInput"]},ResolverInputTypes["EligibleDriversForPickup"]],
exceptionQueue?: [{	fleetId: string},ResolverInputTypes["Job"]],
fleet?: [{	id: string},ResolverInputTypes["Fleet"]],
fleetDriver?: [{	id: string},ResolverInputTypes["FleetDriver"]],
fleetDrivers?: [{	orderBy?: ResolverInputTypes["FleetDriverOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["FleetDriverWhereInput"] | undefined | null},ResolverInputTypes["FleetDriver"]],
fleets?: [{	orderBy?: ResolverInputTypes["FleetOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["FleetWhereInput"] | undefined | null},ResolverInputTypes["Fleet"]],
job?: [{	id: string},ResolverInputTypes["Job"]],
jobAssignmentHistories?: [{	orderBy?: ResolverInputTypes["JobAssignmentOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["JobAssignmentHistoryWhereInput"] | undefined | null},ResolverInputTypes["JobAssignmentHistory"]],
jobAssignmentHistory?: [{	id: string},ResolverInputTypes["JobAssignmentHistory"]],
jobs?: [{	orderBy?: ResolverInputTypes["JobOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["JobWhereInput"] | undefined | null},ResolverInputTypes["Job"]],
paginatedJobs?: [{	orderBy?: ResolverInputTypes["JobOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["JobWhereInput"] | undefined | null},ResolverInputTypes["PaginatedJobs"]],
partner?: [{	id: string},ResolverInputTypes["Partner"]],
partners?: [{	orderBy?: ResolverInputTypes["PartnerOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["PartnerWhereInput"] | undefined | null},ResolverInputTypes["Partner"]],
	/** Returns an array of jobs, the first one needs to be accepted/rejected while the rest are simply completing the stack */
	pendingJobAndStack?:ResolverInputTypes["Job"],
pickupTask?: [{	id: string},ResolverInputTypes["PickupTask"]],
pickupTasks?: [{	orderBy?: ResolverInputTypes["PickupTaskOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["PickupTaskWhereInput"] | undefined | null},ResolverInputTypes["PickupTask"]],
platform?: [{	id: string},ResolverInputTypes["Platform"]],
platforms?: [{	orderBy?: ResolverInputTypes["PlatformOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["PlatformWhereInput"] | undefined | null},ResolverInputTypes["Platform"]],
timeConnectedInMs?: [{	input?: ResolverInputTypes["TimeConnectedInMsInput"] | undefined | null},boolean | `@${string}`],
userByEmail?: [{	email: string},ResolverInputTypes["User"]],
userById?: [{	id: string},ResolverInputTypes["User"]],
users?: [{	orderBy?: ResolverInputTypes["UserOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["UserWhereInput"] | undefined | null},ResolverInputTypes["User"]],
vehicle?: [{	id: string},ResolverInputTypes["Vehicle"]],
vehicles?: [{	orderBy?: ResolverInputTypes["VehicleOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["VehicleWhereInput"] | undefined | null},ResolverInputTypes["Vehicle"]],
webhook?: [{	id: string},ResolverInputTypes["Webhook"]],
webhooks?: [{	orderBy?: ResolverInputTypes["WebhookOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["WebhookWhereInput"] | undefined | null},ResolverInputTypes["Webhook"]],
		__typename?: boolean | `@${string}`
}>;
	["SortOrder"]:SortOrder;
	["StringFilter"]: {
	contains?: string | undefined | null,
	endsWith?: string | undefined | null,
	equals?: string | undefined | null,
	gt?: string | undefined | null,
	gte?: string | undefined | null,
	in?: Array<string> | undefined | null,
	lt?: string | undefined | null,
	lte?: string | undefined | null,
	not?: ResolverInputTypes["StringFilter"] | undefined | null,
	notIn?: Array<string> | undefined | null,
	startsWith?: string | undefined | null
};
	["Subscription"]: AliasType<{
	assignedJobs?:ResolverInputTypes["Job"],
	currentDriver?:ResolverInputTypes["Driver"],
	/** Returns an array of assigned, not on hold and en-route jobs */
	currentJobs?:ResolverInputTypes["Job"],
driver?: [{	id: string},ResolverInputTypes["Driver"]],
drivers?: [{	orderBy?: ResolverInputTypes["DriverOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["DriverWhereInput"] | undefined | null},ResolverInputTypes["Driver"]],
job?: [{	id: string},ResolverInputTypes["Job"]],
jobs?: [{	orderBy?: ResolverInputTypes["JobOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["JobWhereInput"] | undefined | null},ResolverInputTypes["Job"]],
paginatedJobs?: [{	orderBy?: ResolverInputTypes["JobOrderByInput"] | undefined | null,	skip?: number | undefined | null,	take?: number | undefined | null,	where?: ResolverInputTypes["JobWhereInput"] | undefined | null},ResolverInputTypes["PaginatedJobs"]],
	/** Returns an array of jobs, the first one needs to be accepted/rejected while the rest are simply completing the stack */
	pendingJobAndStack?:ResolverInputTypes["Job"],
		__typename?: boolean | `@${string}`
}>;
	["TimeConnectedInMsInput"]: {
	/** If not provided the query will default to the user on the context */
	driverId?: string | undefined | null,
	/** Optional parameter that marks the start of day timestamp in ms for the driver. It helps with addressing time zone variants */
	startOfDayTimestamp?: number | undefined | null
};
	["UpdateJobPickupTimeInput"]: {
	estimatedPickupTime?: ResolverInputTypes["DateTime"] | undefined | null,
	id: string
};
	["UpdateJobStatusInput"]: {
	id: string,
	status: ResolverInputTypes["JobStatus"]
};
	["UploadJobCompleteProofInput"]: {
	jobCompleteNotes?: string | undefined | null,
	jobCompletePhotoProof?: string | undefined | null,
	jobId: string
};
	["User"]: AliasType<{
	createdAt?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	enabled?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	fleets?:ResolverInputTypes["Fleet"],
	id?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	partners?:ResolverInputTypes["Partner"],
	/** The hashed password */
	password?:boolean | `@${string}`,
	phoneNumber?:boolean | `@${string}`,
	role?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	userStatus?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["UserConnectInput"]: {
	connect?: ResolverInputTypes["WhereRequiredIdInput"] | undefined | null
};
	["UserCreateInput"]: {
	email: string,
	firstName: string,
	lastName: string,
	partners?: ResolverInputTypes["PartnerConnectOrDisconnectInput"] | undefined | null,
	password: string,
	phoneNumber: string,
	role: ResolverInputTypes["UserRole"],
	userStatus: ResolverInputTypes["UserStatus"]
};
	["UserCreateNewPasswordInput"]: {
	password: string,
	token: string
};
	["UserInviteInput"]: {
	email: string,
	/** The highest level ids of nodes the user has access to. E.g. - a partner-user should get a list of partnerIds to connect */
	nodes?: Array<string> | undefined | null,
	role: ResolverInputTypes["UserRole"]
};
	["UserLoginInput"]: {
	email: string,
	password: string
};
	["UserOrderByInput"]: {
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	email?: ResolverInputTypes["SortOrder"] | undefined | null,
	enabled?: ResolverInputTypes["SortOrder"] | undefined | null,
	firstName?: ResolverInputTypes["SortOrder"] | undefined | null,
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	lastName?: ResolverInputTypes["SortOrder"] | undefined | null,
	phoneNumber?: ResolverInputTypes["SortOrder"] | undefined | null,
	role?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	userStatus?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["UserResetPasswordInput"]: {
	key: string,
	newPassword: string
};
	["UserRole"]:UserRole;
	["UserRoleFilter"]: {
	equals?: ResolverInputTypes["UserRole"] | undefined | null,
	in?: Array<ResolverInputTypes["UserRole"]> | undefined | null,
	not?: ResolverInputTypes["UserRoleFilter"] | undefined | null,
	notIn?: Array<ResolverInputTypes["UserRole"]> | undefined | null
};
	["UserStatus"]:UserStatus;
	["UserStatusFilter"]: {
	equals?: ResolverInputTypes["UserStatus"] | undefined | null,
	in?: Array<ResolverInputTypes["UserStatus"]> | undefined | null,
	not?: ResolverInputTypes["UserStatusFilter"] | undefined | null,
	notIn?: Array<ResolverInputTypes["UserStatus"]> | undefined | null
};
	["UserUpdateInput"]: {
	email?: string | undefined | null,
	enabled?: boolean | undefined | null,
	firstName?: string | undefined | null,
	id: string,
	lastName?: string | undefined | null,
	/** The highest level ids of nodes the user has access to. It connects and disconnects nodes according to this array if passed in. */
	nodes?: Array<string> | undefined | null,
	phoneNumber?: string | undefined | null,
	role?: ResolverInputTypes["UserRole"] | undefined | null,
	userStatus?: ResolverInputTypes["UserStatus"] | undefined | null
};
	["UserUpdatePasswordInput"]: {
	newPassword: string,
	oldPassword: string
};
	["UserWhereInput"]: {
	AND?: Array<ResolverInputTypes["UserWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["UserWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["UserWhereInput"]> | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	email?: ResolverInputTypes["StringFilter"] | undefined | null,
	enabled?: ResolverInputTypes["BooleanFilter"] | undefined | null,
	firstName?: ResolverInputTypes["StringFilter"] | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	lastName?: ResolverInputTypes["StringFilter"] | undefined | null,
	phoneNumber?: ResolverInputTypes["StringFilter"] | undefined | null,
	role?: ResolverInputTypes["UserRoleFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	userStatus?: ResolverInputTypes["UserStatusFilter"] | undefined | null
};
	["Vehicle"]: AliasType<{
	createdAt?:boolean | `@${string}`,
	driver?:ResolverInputTypes["Driver"],
	driverId?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	make?:boolean | `@${string}`,
	model?:boolean | `@${string}`,
	ownerDriver?:boolean | `@${string}`,
	ownerFleet?:ResolverInputTypes["Fleet"],
	ownerFleetId?:boolean | `@${string}`,
	reg?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["VehicleConnectOrDisconnectOneInput"]: {
	connect?: ResolverInputTypes["WhereRequiredIdInput"] | undefined | null,
	disconnect?: boolean | undefined | null
};
	["VehicleCreateInput"]: {
	driver?: ResolverInputTypes["DriverConnectOneInput"] | undefined | null,
	make?: string | undefined | null,
	model?: string | undefined | null,
	ownerDriver?: boolean | undefined | null,
	ownerFleet?: ResolverInputTypes["FleetConnectOneInput"] | undefined | null,
	reg?: string | undefined | null,
	type: ResolverInputTypes["VehicleType"]
};
	["VehicleCreateNestedOneWithoutDriverInput"]: {
	connect?: ResolverInputTypes["WhereRequiredIdInput"] | undefined | null,
	create?: ResolverInputTypes["VehicleCreateInput"] | undefined | null
};
	["VehicleCreateNestedWithoutOwnerFleetInput"]: {
	connect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	create?: Array<ResolverInputTypes["VehicleCreateWithoutOwnerFleetInput"]> | undefined | null
};
	["VehicleCreateWithoutOwnerFleetInput"]: {
	driver: ResolverInputTypes["DriverConnectOneInput"],
	make?: string | undefined | null,
	model?: string | undefined | null,
	ownerDriver?: boolean | undefined | null,
	reg?: string | undefined | null,
	type: ResolverInputTypes["VehicleType"]
};
	["VehicleListRelationFilter"]: {
	every?: ResolverInputTypes["VehicleWhereInput"] | undefined | null,
	none?: ResolverInputTypes["VehicleWhereInput"] | undefined | null,
	some?: ResolverInputTypes["VehicleWhereInput"] | undefined | null
};
	["VehicleOrderByInput"]: {
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	driver?: ResolverInputTypes["DriverOrderByInput"] | undefined | null,
	driverId?: ResolverInputTypes["SortOrder"] | undefined | null,
	id?: ResolverInputTypes["SortOrder"] | undefined | null,
	make?: ResolverInputTypes["SortOrder"] | undefined | null,
	model?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	ownerDriver?: ResolverInputTypes["SortOrder"] | undefined | null,
	ownerFleet?: ResolverInputTypes["FleetOrderByInput"] | undefined | null,
	ownerFleetId?: ResolverInputTypes["SortOrder"] | undefined | null,
	reg?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["VehicleOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["VehicleType"]:VehicleType;
	["VehicleUpdateInput"]: {
	driver?: ResolverInputTypes["DriverConnectOrDisconnectOneInput"] | undefined | null,
	id: string,
	make?: string | undefined | null,
	model?: string | undefined | null,
	ownerDriver?: boolean | undefined | null,
	ownerFleet?: ResolverInputTypes["FleetConnectOrDisconnectOneInput"] | undefined | null,
	reg?: string | undefined | null,
	type?: ResolverInputTypes["VehicleType"] | undefined | null
};
	["VehicleUpdateManyWithoutOwnerFleetInput"]: {
	connect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	create?: Array<ResolverInputTypes["VehicleCreateWithoutOwnerFleetInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null
};
	["VehicleWhereInput"]: {
	AND?: Array<ResolverInputTypes["VehicleWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["VehicleWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["VehicleWhereInput"]> | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	driver?: ResolverInputTypes["DriverWhereInput"] | undefined | null,
	driverId?: ResolverInputTypes["StringFilter"] | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	make?: ResolverInputTypes["StringFilter"] | undefined | null,
	model?: ResolverInputTypes["StringFilter"] | undefined | null,
	ownerDriver?: ResolverInputTypes["BooleanFilter"] | undefined | null,
	ownerFleet?: ResolverInputTypes["FleetWhereInput"] | undefined | null,
	ownerFleetId?: ResolverInputTypes["StringFilter"] | undefined | null,
	reg?: ResolverInputTypes["StringFilter"] | undefined | null,
	type?: ResolverInputTypes["VehicleType"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null
};
	/** Response from verify phone */
["VerifyPhoneResponse"]: AliasType<{
	completedSignUp?:boolean | `@${string}`,
	token?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Webhook"]: AliasType<{
	apiKeyHeader?:boolean | `@${string}`,
	apiKeyValue?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	fleet?:ResolverInputTypes["Fleet"],
	fleetId?:boolean | `@${string}`,
	forJobStatus?:boolean | `@${string}`,
	id?:boolean | `@${string}`,
	isRedboxApi?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	url?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["WebhookCreateInput"]: {
	apiKeyHeader?: string | undefined | null,
	apiKeyValue?: string | undefined | null,
	fleet: ResolverInputTypes["FleetConnectOneInput"],
	forJobStatus?: ResolverInputTypes["JobStatus"] | undefined | null,
	isRedboxApi?: boolean | undefined | null,
	name?: string | undefined | null,
	url: string
};
	["WebhookCreateNestedManyWithoutFleetInput"]: {
	connect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	create?: Array<ResolverInputTypes["WebhookCreateWithoutFleetInput"]> | undefined | null
};
	["WebhookCreateWithoutFleetInput"]: {
	forJobStatus?: ResolverInputTypes["JobStatus"] | undefined | null,
	name?: string | undefined | null,
	url: string
};
	["WebhookListRelationFilter"]: {
	every?: ResolverInputTypes["WebhookWhereInput"] | undefined | null,
	none?: ResolverInputTypes["WebhookWhereInput"] | undefined | null,
	some?: ResolverInputTypes["WebhookWhereInput"] | undefined | null
};
	["WebhookOrderByInput"]: {
	apiKeyHeader?: ResolverInputTypes["SortOrder"] | undefined | null,
	createdAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	fleet?: ResolverInputTypes["FleetOrderByInput"] | undefined | null,
	isRedboxApi?: ResolverInputTypes["SortOrder"] | undefined | null,
	name?: ResolverInputTypes["SortOrder"] | undefined | null,
	updatedAt?: ResolverInputTypes["SortOrder"] | undefined | null,
	url?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["WebhookOrderByRelationAggregateInput"]: {
	_count?: ResolverInputTypes["SortOrder"] | undefined | null
};
	["WebhookUpdateInput"]: {
	apiKeyHeader?: string | undefined | null,
	apiKeyValue?: string | undefined | null,
	fleet?: ResolverInputTypes["FleetConnectOneInput"] | undefined | null,
	forJobStatus?: ResolverInputTypes["JobStatus"] | undefined | null,
	id: string,
	isRedboxApi?: boolean | undefined | null,
	name?: string | undefined | null,
	url?: string | undefined | null
};
	["WebhookUpdateManyWithoutFleetInput"]: {
	connect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	create?: Array<ResolverInputTypes["WebhookCreateWithoutFleetInput"]> | undefined | null,
	delete?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	disconnect?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null,
	set?: Array<ResolverInputTypes["WhereRequiredIdInput"]> | undefined | null
};
	["WebhookWhereInput"]: {
	AND?: Array<ResolverInputTypes["WebhookWhereInput"]> | undefined | null,
	NOT?: Array<ResolverInputTypes["WebhookWhereInput"]> | undefined | null,
	OR?: Array<ResolverInputTypes["WebhookWhereInput"]> | undefined | null,
	apiKeyHeader?: ResolverInputTypes["StringFilter"] | undefined | null,
	createdAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	fleet?: ResolverInputTypes["FleetWhereInput"] | undefined | null,
	fleetId?: ResolverInputTypes["StringFilter"] | undefined | null,
	forJobStatus?: ResolverInputTypes["JobStatus"] | undefined | null,
	id?: ResolverInputTypes["StringFilter"] | undefined | null,
	isRedboxApi?: ResolverInputTypes["BooleanFilter"] | undefined | null,
	name?: ResolverInputTypes["StringFilter"] | undefined | null,
	updatedAt?: ResolverInputTypes["DateTimeFilter"] | undefined | null,
	url?: ResolverInputTypes["StringFilter"] | undefined | null
};
	["WhereRequiredIdInput"]: {
	id: string
}
  }

export type ModelTypes = {
    ["AddressInput"]: {
	city: string,
	firstLine: string,
	postcode: string,
	secondLine: string,
	thirdLine: string
};
	["ApiImplementation"]: {
		apiKey: string,
	createdAt: ModelTypes["DateTime"],
	fleet: ModelTypes["Fleet"],
	fleetId: string,
	id: string,
	name: string,
	updatedAt: ModelTypes["DateTime"]
};
	["ApiImplementationCreateInput"]: {
	apiKey: string,
	fleet: ModelTypes["FleetConnectOneInput"],
	name: string
};
	["ApiImplementationCreateNestedManyWithoutFleetInput"]: {
	connect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<ModelTypes["ApiImplementationCreateWithoutFleetInput"]> | undefined
};
	["ApiImplementationCreateWithoutFleetInput"]: {
	apiKey: string,
	name: string
};
	["ApiImplementationListRelationFilter"]: {
	every?: ModelTypes["ApiImplementationWhereInput"] | undefined,
	none?: ModelTypes["ApiImplementationWhereInput"] | undefined,
	some?: ModelTypes["ApiImplementationWhereInput"] | undefined
};
	["ApiImplementationOrderByInput"]: {
	apiKey?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	fleet?: ModelTypes["FleetOrderByInput"] | undefined,
	fleetId?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["ApiImplementationOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["ApiImplementationUpdateInput"]: {
	apiKey?: string | undefined,
	fleet?: ModelTypes["FleetConnectOneInput"] | undefined,
	id: string,
	name?: string | undefined
};
	["ApiImplementationUpdateManyWithoutFleetInput"]: {
	connect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<ModelTypes["ApiImplementationCreateWithoutFleetInput"]> | undefined,
	delete?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined
};
	["ApiImplementationWhereInput"]: {
	AND?: Array<ModelTypes["ApiImplementationWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["ApiImplementationWhereInput"]> | undefined,
	OR?: Array<ModelTypes["ApiImplementationWhereInput"]> | undefined,
	apiKey?: ModelTypes["StringFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	fleet?: ModelTypes["FleetWhereInput"] | undefined,
	fleetId?: ModelTypes["StringFilter"] | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	model?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined
};
	["AssignJobToDriverInput"]: {
	driverId: string,
	jobId: string
};
	["AuthTokenType"]:AuthTokenType;
	["AvailableNodesByUserAndDesiredRoleInput"]: {
	desiredRole: ModelTypes["UserRole"]
};
	["Base"]: {
		address: ModelTypes["JSON"],
	createdAt: ModelTypes["DateTime"],
	fleet: ModelTypes["Fleet"],
	fleetId: string,
	geo: ModelTypes["JSON"],
	id: string,
	name: string,
	updatedAt: ModelTypes["DateTime"]
};
	["BaseCreateInput"]: {
	address: string,
	fleet: ModelTypes["FleetConnectOneInput"],
	geo: string,
	name: string
};
	["BaseCreateNestedWithoutFleetInput"]: {
	connect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<ModelTypes["BaseCreateWithoutFleetInput"]> | undefined
};
	["BaseCreateWithoutFleetInput"]: {
	address: string,
	geo: string,
	name: string
};
	["BaseListRelationFilter"]: {
	every?: ModelTypes["BaseWhereInput"] | undefined,
	none?: ModelTypes["BaseWhereInput"] | undefined,
	some?: ModelTypes["BaseWhereInput"] | undefined
};
	["BaseOrderByInput"]: {
	address?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	fleet?: ModelTypes["FleetOrderByInput"] | undefined,
	fleetId?: ModelTypes["SortOrder"] | undefined,
	geo?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["BaseOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["BaseUpdateInput"]: {
	address?: string | undefined,
	fleet?: ModelTypes["FleetConnectOneInput"] | undefined,
	geo?: string | undefined,
	id: string,
	name?: string | undefined
};
	["BaseUpdateManyWithoutFleetInput"]: {
	connect: Array<ModelTypes["WhereRequiredIdInput"]>,
	create?: Array<ModelTypes["BaseCreateWithoutFleetInput"]> | undefined,
	delete: Array<ModelTypes["WhereRequiredIdInput"]>,
	disconnect: Array<ModelTypes["WhereRequiredIdInput"]>,
	set: Array<ModelTypes["WhereRequiredIdInput"]>
};
	["BaseWhereInput"]: {
	AND?: Array<ModelTypes["BaseWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["BaseWhereInput"]> | undefined,
	OR?: Array<ModelTypes["BaseWhereInput"]> | undefined,
	address?: ModelTypes["JsonFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	fleet?: ModelTypes["FleetWhereInput"] | undefined,
	fleetId?: ModelTypes["StringFilter"] | undefined,
	geo?: ModelTypes["JsonFilter"] | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined
};
	["BooleanFilter"]: {
	equals?: boolean | undefined,
	not?: ModelTypes["BooleanFilter"] | undefined
};
	["CalculateRouteInput"]: {
	pointA: ModelTypes["CoordinatesInput"],
	pointB: ModelTypes["CoordinatesInput"]
};
	["CancelJobInput"]: {
	canceledBy: ModelTypes["CanceledBy"],
	id: string
};
	["CanceledBy"]:CanceledBy;
	["CollisionsOrStackability"]: {
		isPlausible: boolean,
	isStackable: boolean,
	jobsOverlap: boolean
};
	["CompleteUserProfileInput"]: {
	firstName: string,
	key: string,
	lastName: string,
	password: string,
	phoneNumber?: string | undefined
};
	["ContactInput"]: {
	name?: string | undefined,
	phone: string
};
	["Coordinates"]: {
		latitude: number,
	longitude: number
};
	["CoordinatesInput"]: {
	latitude: number,
	longitude: number
};
	["CreateUserResponse"]: {
		id: string,
	jwtToken: string
};
	/** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
["DateTime"]:any;
	["DateTimeFilter"]: {
	equals?: ModelTypes["DateTime"] | undefined,
	gt?: ModelTypes["DateTime"] | undefined,
	gte?: ModelTypes["DateTime"] | undefined,
	in?: Array<ModelTypes["DateTime"]> | undefined,
	lt?: ModelTypes["DateTime"] | undefined,
	lte?: ModelTypes["DateTime"] | undefined,
	not?: ModelTypes["DateTimeFilter"] | undefined,
	notIn?: Array<ModelTypes["DateTime"]> | undefined
};
	["DeliveryEstimate"]: {
		costInPennies: number,
	dropoffCoordinates: ModelTypes["Coordinates"],
	pickupCoordinates: ModelTypes["Coordinates"]
};
	["DeliveryEstimateInput"]: {
	dropoffCoordinates: ModelTypes["CoordinatesInput"],
	fleetId: string,
	pickupCoordinates: ModelTypes["CoordinatesInput"]
};
	["DistanceUnit"]:DistanceUnit;
	["DistanceUnitFilter"]: {
	equals?: ModelTypes["DistanceUnit"] | undefined,
	in?: Array<ModelTypes["DistanceUnit"]> | undefined,
	not?: ModelTypes["DistanceUnit"] | undefined,
	notIn?: Array<ModelTypes["DistanceUnit"]> | undefined
};
	["Driver"]: {
		allowJobRejection: boolean,
	allowStackingOrders: boolean,
	breaks?: ModelTypes["JSON"] | undefined,
	completedSignUp: boolean,
	costPerDistanceUnit: number,
	createdAt: ModelTypes["DateTime"],
	currentLocation?: ModelTypes["JSON"] | undefined,
	distanceUnit: ModelTypes["DistanceUnit"],
	email?: string | undefined,
	firstName?: string | undefined,
	fleetContact: ModelTypes["DriverFleetContact"],
	fleetDrivers?: Array<ModelTypes["FleetDriver"]> | undefined,
	fleetGroups: ModelTypes["JSON"],
	heading: number,
	id: string,
	inactiveStartingAt?: ModelTypes["DateTime"] | undefined,
	jobAssignments: Array<ModelTypes["JobAssignmentHistory"]>,
	jobs: Array<ModelTypes["Job"]>,
	lastName?: string | undefined,
	middleNames?: string | undefined,
	pauseNotifications: boolean,
	phoneNumber: string,
	pushToken?: string | undefined,
	/** Stores the associated sns endpoint ARN so the server can send push messages */
	snsEndpointARN?: string | undefined,
	speed: number,
	status: ModelTypes["DriverStatus"],
	title?: string | undefined,
	/** Synthetic field that counts the number of completed jobs for the current shift */
	todaysJobsCounter: number,
	/** Synthetic field that counts the distance covered for the completed jobs for the current shift */
	todaysMilesCounter: number,
	updatedAt: ModelTypes["DateTime"],
	vehicle?: ModelTypes["Vehicle"] | undefined
};
	["DriverConnectOneInput"]: {
	connect: ModelTypes["WhereRequiredIdInput"]
};
	["DriverConnectOrDisconnectInput"]: {
	connect?: ModelTypes["WhereRequiredIdInput"] | undefined,
	disconnect?: boolean | undefined
};
	["DriverConnectOrDisconnectOneInput"]: {
	connect?: ModelTypes["WhereRequiredIdInput"] | undefined,
	disconnect?: boolean | undefined
};
	["DriverCreateInput"]: {
	allowJobRejection?: boolean | undefined,
	allowStackingOrders?: boolean | undefined,
	completedSignUp?: boolean | undefined,
	costPerDistanceUnit?: number | undefined,
	currentLocation: string,
	distanceUnit?: ModelTypes["DistanceUnit"] | undefined,
	email?: string | undefined,
	firstName?: string | undefined,
	fleetGroups: string,
	fleetId: string,
	lastName?: string | undefined,
	middleNames?: string | undefined,
	phoneNumber: string,
	pushToken?: string | undefined,
	status: ModelTypes["DriverStatus"],
	title?: string | undefined,
	vehicle?: ModelTypes["VehicleCreateNestedOneWithoutDriverInput"] | undefined
};
	["DriverCreateNestedOneWithoutFleetDriversInput"]: {
	connect?: ModelTypes["WhereRequiredIdInput"] | undefined,
	create?: ModelTypes["DriverCreateWithoutFleetDriversInput"] | undefined
};
	["DriverCreateWithoutFleetDriversInput"]: {
	allowJobRejection?: boolean | undefined,
	allowStackingOrders?: boolean | undefined,
	completedSignUp?: boolean | undefined,
	costPerDistanceUnit?: number | undefined,
	currentLocation: string,
	distanceUnit?: ModelTypes["DistanceUnit"] | undefined,
	email?: string | undefined,
	firstName?: string | undefined,
	fleetGroups: string,
	lastName?: string | undefined,
	middelNames?: string | undefined,
	phoneNumber: string,
	pushToken?: string | undefined,
	status: ModelTypes["DriverStatus"],
	title?: string | undefined,
	vehicle?: ModelTypes["VehicleCreateNestedOneWithoutDriverInput"] | undefined
};
	["DriverFleetContact"]: {
		fleetContactPhone?: string | undefined,
	fleetName?: string | undefined
};
	["DriverInviteInput"]: {
	email?: string | undefined,
	firstName?: string | undefined,
	fleetId: string,
	lastName?: string | undefined,
	phoneNumber: string
};
	["DriverJobAssignmentStatus"]:DriverJobAssignmentStatus;
	/** Response from driver login */
["DriverLoginResponse"]: {
		expireAt: ModelTypes["DateTime"],
	phoneNumber: string,
	token?: string | undefined
};
	["DriverOrderByInput"]: {
	allowJobRejection?: ModelTypes["SortOrder"] | undefined,
	allowStackingOrders?: ModelTypes["SortOrder"] | undefined,
	breaks?: ModelTypes["SortOrder"] | undefined,
	completedSignUp?: ModelTypes["SortOrder"] | undefined,
	costPerDistanceUnit?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	currentLocation?: ModelTypes["SortOrder"] | undefined,
	distanceUnit?: ModelTypes["SortOrder"] | undefined,
	email?: ModelTypes["SortOrder"] | undefined,
	firstName?: ModelTypes["SortOrder"] | undefined,
	fleetDrivers?: ModelTypes["FleetDriverOrderByRelationAggregateInput"] | undefined,
	fleetGroups?: ModelTypes["SortOrder"] | undefined,
	id?: ModelTypes["SortOrder"] | undefined,
	inactiveStartingAt?: ModelTypes["SortOrder"] | undefined,
	jobAssignments?: ModelTypes["JobAssignmentHistoryOrderByRelationAggregateInput"] | undefined,
	jobs?: ModelTypes["JobOrderByRelationAggregateInput"] | undefined,
	lastName?: ModelTypes["SortOrder"] | undefined,
	middleNames?: ModelTypes["SortOrder"] | undefined,
	phoneNumber?: ModelTypes["SortOrder"] | undefined,
	pushToken?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	vehicle?: ModelTypes["VehicleOrderByInput"] | undefined
};
	["DriverSetLocationInput"]: {
	currentLocation?: ModelTypes["LocationInput"] | undefined,
	id: string
};
	["DriverStatus"]:DriverStatus;
	["DriverUpdateDetailsInput"]: {
	allowJobRejection?: boolean | undefined,
	allowStackingOrders?: boolean | undefined,
	completedSignUp?: boolean | undefined,
	email?: string | undefined,
	firstName?: string | undefined,
	id: string,
	lastName?: string | undefined,
	middleNames?: string | undefined,
	phoneNumber?: string | undefined,
	title?: string | undefined
};
	["DriverUpdateInput"]: {
	allowJobRejection?: boolean | undefined,
	allowStackingOrders?: boolean | undefined,
	completedSignUp?: boolean | undefined,
	costPerDistanceUnit?: number | undefined,
	currentLocation?: ModelTypes["LocationInput"] | undefined,
	distanceUnit?: ModelTypes["DistanceUnit"] | undefined,
	email?: string | undefined,
	firstName?: string | undefined,
	fleetGroups?: string | undefined,
	fleetIds?: Array<string> | undefined,
	heading?: number | undefined,
	id: string,
	lastName?: string | undefined,
	middleNames?: string | undefined,
	phoneNumber?: string | undefined,
	pushToken?: string | undefined,
	speed?: number | undefined,
	status?: ModelTypes["DriverStatus"] | undefined,
	title?: string | undefined
};
	["DriverUpdatePushTokenInput"]: {
	id: string,
	isAppleDevice: boolean,
	pushToken: string
};
	["DriverWhereInput"]: {
	AND?: Array<ModelTypes["DriverWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["DriverWhereInput"]> | undefined,
	OR?: Array<ModelTypes["DriverWhereInput"]> | undefined,
	allowJobRejection?: ModelTypes["BooleanFilter"] | undefined,
	allowStackingOrders?: ModelTypes["BooleanFilter"] | undefined,
	breaks?: ModelTypes["JsonFilter"] | undefined,
	completedSignUp?: ModelTypes["BooleanFilter"] | undefined,
	costPerDistanceUnit?: number | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	currentLocation?: ModelTypes["JsonFilter"] | undefined,
	distanceUnit?: ModelTypes["DistanceUnit"] | undefined,
	email?: ModelTypes["StringFilter"] | undefined,
	firstName?: ModelTypes["StringFilter"] | undefined,
	fleetDrivers?: ModelTypes["FleetDriverListRelationFilter"] | undefined,
	fleetGroups?: ModelTypes["JsonFilter"] | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	inactiveStartingAt?: ModelTypes["DateTimeFilter"] | undefined,
	jobAssignments?: ModelTypes["JobAssignmentHistoryListRelationFilter"] | undefined,
	jobs?: ModelTypes["JobListRelationFilter"] | undefined,
	lastName?: ModelTypes["StringFilter"] | undefined,
	middleNames?: ModelTypes["StringFilter"] | undefined,
	pauseNotifications?: ModelTypes["BooleanFilter"] | undefined,
	phoneNumber?: ModelTypes["StringFilter"] | undefined,
	pushToken?: string | undefined,
	status?: ModelTypes["DriverStatus"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	vehicle?: ModelTypes["VehicleWhereInput"] | undefined
};
	["DropOffTask"]: {
		ageVerification: boolean,
	/** Duration in seconds for a drop off to be completed */
	allocatedTime: number,
	createdAt: ModelTypes["DateTime"],
	dropOffContact?: ModelTypes["JSON"] | undefined,
	dropOffNotes?: string | undefined,
	id: string,
	job: ModelTypes["Job"],
	jobId: string,
	location: ModelTypes["JSON"],
	locationName: string,
	orderInStack: number,
	proofOfDelivery?: string | undefined,
	updatedAt: ModelTypes["DateTime"]
};
	["DropOffTaskCreateInput"]: {
	ageVerification?: boolean | undefined,
	allocatedTime?: number | undefined,
	dropOffContact?: ModelTypes["ContactInput"] | undefined,
	dropOffNotes?: string | undefined,
	job: ModelTypes["JobConnectOneInput"],
	location: ModelTypes["LocationCoordsInput"],
	locationName: string,
	orderInStack?: number | undefined,
	proofOfDelivery?: string | undefined
};
	/** Input required by JobCreateDirectInput */
["DropOffTaskInput"]: {
	ageVerification?: boolean | undefined,
	dropOffContact: ModelTypes["ContactInput"],
	dropOffNotes?: string | undefined,
	location: ModelTypes["LocationCoordsInput"],
	locationName: string,
	orderInStack?: number | undefined,
	proofOfDelivery?: string | undefined
};
	["DropOffTaskListRelationFilter"]: {
	every?: ModelTypes["DropOffTaskWhereInput"] | undefined,
	none?: ModelTypes["DropOffTaskWhereInput"] | undefined,
	some?: ModelTypes["DropOffTaskWhereInput"] | undefined
};
	["DropOffTaskOrderByInput"]: {
	ageVerification?: ModelTypes["SortOrder"] | undefined,
	allocatedTime?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	dropOffNotes?: ModelTypes["SortOrder"] | undefined,
	job?: ModelTypes["JobOrderByInput"] | undefined,
	locationName?: ModelTypes["SortOrder"] | undefined,
	orderInStack?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["DropOffTaskOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["DropOffTaskUpdateInput"]: {
	ageVerification?: boolean | undefined,
	dropOffContact?: ModelTypes["ContactInput"] | undefined,
	dropOffNotes?: string | undefined,
	id: string,
	job?: ModelTypes["JobConnectOneInput"] | undefined,
	location?: ModelTypes["LocationCoordsInput"] | undefined,
	locationName?: string | undefined,
	orderInStack?: number | undefined,
	proofOfDelivery?: string | undefined
};
	["DropOffTaskUpdateManyWithoutJobInput"]: {
	connect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	delete?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined
};
	["DropOffTaskUpdateProofOfDelivery"]: {
	id: string,
	proofOfDelivery: string
};
	["DropOffTaskWhereInput"]: {
	AND?: Array<ModelTypes["DropOffTaskWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["DropOffTaskWhereInput"]> | undefined,
	OR?: Array<ModelTypes["DropOffTaskWhereInput"]> | undefined,
	ageVerification?: boolean | undefined,
	allocatedTime?: number | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	dropOffNotes?: string | undefined,
	id?: string | undefined,
	job?: ModelTypes["JobWhereInput"] | undefined,
	jobId?: string | undefined,
	locationName?: string | undefined,
	orderInStack?: number | undefined,
	proofOfDelivery?: ModelTypes["JsonFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined
};
	["EligibleDriversForPickup"]: {
		driverId: string,
	driverLocation: ModelTypes["Coordinates"],
	geographicalDistance: number,
	roadDistance?: number | undefined
};
	["EligibleDriversForPickupInput"]: {
	taskId: string,
	/** If left to true will return the closest drivers based on the geographical distabce rather than the road one */
	useGeographicalDistance?: boolean | undefined
};
	["Fleet"]: {
		bases: Array<ModelTypes["Base"]>,
	broadcastJob: boolean,
	contactEmail?: string | undefined,
	contactFullAddress?: string | undefined,
	contactFullName?: string | undefined,
	contactPhone?: string | undefined,
	createdAt: ModelTypes["DateTime"],
	/** What we pay the drivers. Default value. Can be overwriten individually for each driver */
	defaultCostPerDistanceUnit: number,
	distanceUnit: ModelTypes["DistanceUnit"],
	fleetDrivers: Array<ModelTypes["FleetDriver"]>,
	geo: ModelTypes["JSON"],
	id: string,
	implementations: Array<ModelTypes["ApiImplementation"]>,
	/** Duration in seconds a job waits to be accepted by a driver */
	jobAcceptWaitTime: number,
	/** Estimated duration in seconds for a drop off to be completed */
	jobDropOffAllocatedTime: number,
	jobs: Array<ModelTypes["Job"]>,
	/** Duration in minutes a job waits before commencing searching for a driver */
	leadTime: number,
	legalCookies?: string | undefined,
	legalPrivacy?: string | undefined,
	legalTerms?: string | undefined,
	name: string,
	outrightRejectJob: boolean,
	partner?: ModelTypes["Partner"] | undefined,
	partnerId: string,
	/** What we charge the customer */
	pricePerDistanceUnit: number,
	updatedAt: ModelTypes["DateTime"],
	users: Array<ModelTypes["User"]>,
	vehicles: Array<ModelTypes["Vehicle"]>,
	webhooks: Array<ModelTypes["Webhook"]>
};
	["FleetConnectOneInput"]: {
	connect: ModelTypes["WhereRequiredIdInput"]
};
	["FleetConnectOrDisconnectOneInput"]: {
	connect?: ModelTypes["WhereRequiredIdInput"] | undefined,
	disconnect?: boolean | undefined
};
	["FleetCreateInput"]: {
	bases?: ModelTypes["BaseCreateNestedWithoutFleetInput"] | undefined,
	broadcastJob?: boolean | undefined,
	contactEmail?: string | undefined,
	contactFullAddress?: string | undefined,
	contactFullName?: string | undefined,
	contactPhone?: string | undefined,
	defaultCostPerDistanceUnit?: number | undefined,
	distanceUnit?: ModelTypes["DistanceUnit"] | undefined,
	fleetDrivers?: ModelTypes["FleetDriverCreateNestedWithoutFleetInput"] | undefined,
	geo?: string | undefined,
	implementations?: ModelTypes["ApiImplementationCreateNestedManyWithoutFleetInput"] | undefined,
	jobAcceptWaitTime?: number | undefined,
	jobDropOffAllocatedTime?: number | undefined,
	leadTime: number,
	legalCookies?: string | undefined,
	legalPrivacy?: string | undefined,
	legalTerms?: string | undefined,
	name: string,
	outrightRejectJob?: boolean | undefined,
	partner: ModelTypes["PartnerCreateNestedOneWithoutFleetsInput"],
	pricePerDistanceUnit?: number | undefined,
	vehicles?: ModelTypes["VehicleCreateNestedWithoutOwnerFleetInput"] | undefined,
	webhooks?: ModelTypes["WebhookCreateNestedManyWithoutFleetInput"] | undefined
};
	["FleetCreateNestedManyWithoutPartnerInput"]: {
	connect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<ModelTypes["FleetCreateWithoutPartnerInput"]> | undefined
};
	["FleetCreateWithoutPartnerInput"]: {
	broadcastJob?: boolean | undefined,
	contactEmail?: string | undefined,
	contactFullAddress?: string | undefined,
	contactFullName?: string | undefined,
	contactPhone?: string | undefined,
	defaultCostPerDistanceUnit?: number | undefined,
	distanceUnit?: ModelTypes["DistanceUnit"] | undefined,
	geo: string,
	jobAcceptWaitTime?: number | undefined,
	jobDropOffAllocatedTime?: number | undefined,
	leadTime: number,
	legalCookies: string,
	legalPrivacy: string,
	legalTerms: string,
	name: string,
	outrightRejectJob?: boolean | undefined,
	pricePerDistanceUnit?: number | undefined
};
	["FleetDriver"]: {
		createdAt: ModelTypes["DateTime"],
	driver: ModelTypes["Driver"],
	driverId: string,
	fleet: ModelTypes["Fleet"],
	fleetId: string,
	id: string,
	updatedAt: ModelTypes["DateTime"]
};
	["FleetDriverCreateInput"]: {
	driver?: ModelTypes["DriverConnectOneInput"] | undefined,
	fleet: ModelTypes["FleetConnectOneInput"]
};
	["FleetDriverCreateNestedWithoutFleetInput"]: {
	connect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<ModelTypes["FleetDriverCreateWithoutFleetInput"]> | undefined
};
	["FleetDriverCreateWithoutDriverInput"]: {
	fleet: ModelTypes["FleetConnectOneInput"]
};
	["FleetDriverCreateWithoutFleetInput"]: {
	driver?: ModelTypes["DriverConnectOneInput"] | undefined
};
	["FleetDriverListRelationFilter"]: {
	every?: ModelTypes["FleetDriverWhereInput"] | undefined,
	none?: ModelTypes["FleetDriverWhereInput"] | undefined,
	some?: ModelTypes["FleetDriverWhereInput"] | undefined
};
	["FleetDriverOrderByInput"]: {
	createdAt?: ModelTypes["SortOrder"] | undefined,
	driver?: ModelTypes["DriverOrderByInput"] | undefined,
	driverId?: ModelTypes["SortOrder"] | undefined,
	fleet: ModelTypes["FleetOrderByInput"],
	fleetId?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["FleetDriverOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["FleetDriverUpdateInput"]: {
	driver?: ModelTypes["DriverConnectOrDisconnectOneInput"] | undefined,
	fleet?: ModelTypes["FleetConnectOrDisconnectOneInput"] | undefined,
	id: string
};
	["FleetDriverUpdateManyWithoutDriverInput"]: {
	connect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<ModelTypes["FleetDriverCreateWithoutDriverInput"]> | undefined,
	delete?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined
};
	["FleetDriverUpdateManyWithoutFleetInput"]: {
	connect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<ModelTypes["FleetDriverCreateWithoutFleetInput"]> | undefined,
	delete?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined
};
	["FleetDriverWhereInput"]: {
	AND?: Array<ModelTypes["FleetDriverWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["FleetDriverWhereInput"]> | undefined,
	OR?: Array<ModelTypes["FleetDriverWhereInput"]> | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	driver?: ModelTypes["DriverWhereInput"] | undefined,
	driverId?: ModelTypes["StringFilter"] | undefined,
	fleet?: ModelTypes["FleetWhereInput"] | undefined,
	fleetId?: ModelTypes["StringFilter"] | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined
};
	["FleetListRelationFilter"]: {
	every?: ModelTypes["FleetWhereInput"] | undefined,
	none?: ModelTypes["FleetWhereInput"] | undefined,
	some?: ModelTypes["FleetWhereInput"] | undefined
};
	["FleetOrderByInput"]: {
	bases?: ModelTypes["BaseOrderByRelationAggregateInput"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	defaultCostPerDistanceUnit?: ModelTypes["SortOrder"] | undefined,
	distanceUnit?: ModelTypes["SortOrder"] | undefined,
	fleetDrivers?: ModelTypes["FleetDriverOrderByRelationAggregateInput"] | undefined,
	geo?: ModelTypes["SortOrder"] | undefined,
	id?: ModelTypes["SortOrder"] | undefined,
	implementations?: ModelTypes["ApiImplementationOrderByRelationAggregateInput"] | undefined,
	jobAcceptWaitTime?: ModelTypes["SortOrder"] | undefined,
	jobDropOffAllocatedTime?: ModelTypes["SortOrder"] | undefined,
	jobs?: ModelTypes["JobOrderByRelationAggregateInput"] | undefined,
	leadTime?: ModelTypes["SortOrder"] | undefined,
	legalCookies?: ModelTypes["SortOrder"] | undefined,
	legalPrivacy?: ModelTypes["SortOrder"] | undefined,
	legalTerms?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	partner?: ModelTypes["PartnerOrderByInput"] | undefined,
	partnerId?: ModelTypes["SortOrder"] | undefined,
	pricePerDistanceUnit?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	vehicles?: ModelTypes["VehicleOrderByRelationAggregateInput"] | undefined,
	webhooks?: ModelTypes["WebhookOrderByRelationAggregateInput"] | undefined
};
	["FleetOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["FleetUpdateInput"]: {
	bases?: ModelTypes["BaseUpdateManyWithoutFleetInput"] | undefined,
	broadcastJob?: boolean | undefined,
	contactEmail?: string | undefined,
	contactFullAddress?: string | undefined,
	contactFullName?: string | undefined,
	contactPhone?: string | undefined,
	defaultCostPerDistanceUnit?: number | undefined,
	distanceUnit?: ModelTypes["DistanceUnit"] | undefined,
	fleetDrivers?: ModelTypes["FleetDriverUpdateManyWithoutFleetInput"] | undefined,
	geo?: string | undefined,
	id: string,
	implementations?: ModelTypes["ApiImplementationUpdateManyWithoutFleetInput"] | undefined,
	jobAcceptWaitTime?: number | undefined,
	jobDropOffAllocatedTime?: number | undefined,
	leadTime?: number | undefined,
	legalCookies?: string | undefined,
	legalPrivacy?: string | undefined,
	legalTerms?: string | undefined,
	name?: string | undefined,
	outrightRejectJob?: boolean | undefined,
	pricePerDistanceUnit?: number | undefined,
	vehicles?: ModelTypes["VehicleUpdateManyWithoutOwnerFleetInput"] | undefined,
	webhooks?: ModelTypes["WebhookUpdateManyWithoutFleetInput"] | undefined
};
	["FleetUpdateManyWithoutPartnerInput"]: {
	connect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<ModelTypes["FleetCreateWithoutPartnerInput"]> | undefined,
	disconnect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined
};
	["FleetWhereInput"]: {
	AND?: ModelTypes["FleetWhereInput"] | undefined,
	NOT?: ModelTypes["FleetWhereInput"] | undefined,
	OR?: ModelTypes["FleetWhereInput"] | undefined,
	bases?: ModelTypes["BaseListRelationFilter"] | undefined,
	broadcastJob?: ModelTypes["BooleanFilter"] | undefined,
	contactEmail?: ModelTypes["StringFilter"] | undefined,
	contactFullAddress?: ModelTypes["StringFilter"] | undefined,
	contactFullName?: ModelTypes["StringFilter"] | undefined,
	contactPhone?: ModelTypes["StringFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	defaultCostPerDistanceUnit?: ModelTypes["IntFilter"] | undefined,
	distanceUnit?: ModelTypes["DistanceUnitFilter"] | undefined,
	fleetDrivers?: ModelTypes["FleetDriverListRelationFilter"] | undefined,
	geo?: ModelTypes["JsonFilter"] | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	implementations?: ModelTypes["ApiImplementationListRelationFilter"] | undefined,
	jobAcceptWaitTime?: ModelTypes["IntFilter"] | undefined,
	jobDropOffAllocatedTime?: ModelTypes["IntFilter"] | undefined,
	jobs?: ModelTypes["JobListRelationFilter"] | undefined,
	leadTime?: ModelTypes["IntFilter"] | undefined,
	legalCookies?: ModelTypes["StringFilter"] | undefined,
	legalPrivacy?: ModelTypes["StringFilter"] | undefined,
	legalTerms?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	outrightRejectJob?: ModelTypes["BooleanFilter"] | undefined,
	partner?: ModelTypes["PartnerWhereInput"] | undefined,
	partnerId?: ModelTypes["StringFilter"] | undefined,
	pricePerDistanceUnit?: ModelTypes["IntFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	vehicles?: ModelTypes["VehicleListRelationFilter"] | undefined,
	webhooks?: ModelTypes["WebhookListRelationFilter"] | undefined
};
	["IntFilter"]: {
	equals?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	in?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	not?: ModelTypes["IntFilter"] | undefined,
	notIn?: Array<number> | undefined
};
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]:any;
	["Job"]: {
		assignmentHistory: Array<ModelTypes["JobAssignmentHistory"]>,
	canceledBy?: ModelTypes["CanceledBy"] | undefined,
	completedByDriverFullName?: string | undefined,
	completedByDriverId?: string | undefined,
	completionTime?: ModelTypes["DateTime"] | undefined,
	/** Initially matches the initialCost but will change to reflect the cost of the journey's equivalent leg for stacked jobs */
	cost: number,
	createdAt: ModelTypes["DateTime"],
	/** Stored at creation time and cannot be updated */
	distanceUnit: ModelTypes["DistanceUnit"],
	driver?: ModelTypes["Driver"] | undefined,
	driverId?: string | undefined,
	dropOffTasks: Array<ModelTypes["DropOffTask"]>,
	estimatedDistanceMiles: number,
	estimatedDurationMinutes: number,
	estimatedPickupTime?: ModelTypes["DateTime"] | undefined,
	/** Flag used by the scheduler to continue looking for drivers or cancel jobs under certain conditions. Set to true once a driver manually accepts or rejects a job, or once the assignment loop finishes. */
	finishedAssignment: boolean,
	fleet: ModelTypes["Fleet"],
	fleetContactEmail?: string | undefined,
	fleetContactFullAddress?: string | undefined,
	fleetContactFullName?: string | undefined,
	fleetContactPhone?: string | undefined,
	fleetId: string,
	id: string,
	/** The ideal route, this is calculated at creation time and is what the customer is charged for. Never changes and is kept for reference */
	idealRoute?: ModelTypes["JSON"] | undefined,
	/** Stored at creation time and updates if the job is reassigned. This is what we might pay the driver */
	initialCost: number,
	/** The max amount of time until the job may be automatically rejected if the driver does not accept or reject manually */
	jobAcceptWaitTime: number,
	/** Holds notes related to the delivery completion */
	jobCompleteNotes?: string | undefined,
	/** Creates on demand a presigned URL that points to an image in S3 */
	jobCompletePhotoProof?: string | undefined,
	/** Flag that signals the existence of a pic. Internally, it spares a trip to the S3 bucket and generating a url */
	jobHasPhotoProof: boolean,
	/** Human readable reference for the job, used by drivers to contact dispatch */
	jobReference: string,
	onHold: boolean,
	orderContents: ModelTypes["JSON"],
	orderId: string,
	/** Flag that signals the order must assign a driver ASAP or reject the job */
	orderIsAsap: boolean,
	/** Human readable reference for the order, used by dispatch to contact the partner, e.g. Redbox */
	orderReference: string,
	pickupTasks: Array<ModelTypes["PickupTask"]>,
	pickupTime?: ModelTypes["DateTime"] | undefined,
	/** Stored at creation time and cannot be updated. This is what we charge the customer */
	price: number,
	reasonForCancellation?: string | undefined,
	/** The suggested route, might be simply a journey's leg when dealing with stacked jobs */
	route?: ModelTypes["JSON"] | undefined,
	stackId?: string | undefined,
	/** The Lowest common denominator of the job's statuses in the stack */
	stackStatus?: ModelTypes["JobStatus"] | undefined,
	status: ModelTypes["JobStatus"],
	updatedAt: ModelTypes["DateTime"]
};
	["JobAssignmentHistory"]: {
		autoRejectAt?: ModelTypes["DateTime"] | undefined,
	createdAt: ModelTypes["DateTime"],
	driver: ModelTypes["Driver"],
	driverId: string,
	id: string,
	job: ModelTypes["Job"],
	jobId: string,
	status: ModelTypes["DriverJobAssignmentStatus"],
	updatedAt: ModelTypes["DateTime"]
};
	["JobAssignmentHistoryCreateInput"]: {
	autoRejectAt?: string | undefined,
	driver: ModelTypes["DriverConnectOneInput"],
	job: ModelTypes["JobConnectOneInput"],
	status: ModelTypes["DriverJobAssignmentStatus"]
};
	["JobAssignmentHistoryListRelationFilter"]: {
	every?: ModelTypes["JobAssignmentHistoryWhereInput"] | undefined,
	none?: ModelTypes["JobAssignmentHistoryWhereInput"] | undefined,
	some?: ModelTypes["JobAssignmentHistoryWhereInput"] | undefined
};
	["JobAssignmentHistoryOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["JobAssignmentHistoryUpdateInput"]: {
	autoRejectAt?: string | undefined,
	driver?: ModelTypes["DriverConnectOneInput"] | undefined,
	id: string,
	job?: ModelTypes["JobConnectOneInput"] | undefined,
	status?: ModelTypes["DriverJobAssignmentStatus"] | undefined
};
	["JobAssignmentHistoryUpdateManyWithoutDriverInput"]: {
	connect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined
};
	["JobAssignmentHistoryUpdateManyWithoutJobInput"]: {
	connect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	delete?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined
};
	["JobAssignmentHistoryWhereInput"]: {
	AND?: Array<ModelTypes["JobAssignmentHistoryWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["JobAssignmentHistoryWhereInput"]> | undefined,
	OR?: Array<ModelTypes["JobAssignmentHistoryWhereInput"]> | undefined,
	autoRejectAt?: ModelTypes["DateTimeFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	driver?: ModelTypes["DriverWhereInput"] | undefined,
	driverId?: string | undefined,
	id?: string | undefined,
	job?: ModelTypes["JobWhereInput"] | undefined,
	jobId?: string | undefined,
	status?: ModelTypes["DriverJobAssignmentStatus"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined
};
	["JobAssignmentOrderByInput"]: {
	autoRejectAt?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	driver?: ModelTypes["DriverOrderByInput"] | undefined,
	job?: ModelTypes["JobOrderByInput"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["JobConnectOneInput"]: {
	connect: ModelTypes["WhereRequiredIdInput"]
};
	["JobCreateDirectInput"]: {
	dropOffTask: ModelTypes["DropOffTaskInput"],
	estimatedPickupTime?: ModelTypes["DateTime"] | undefined,
	fleetId: string,
	orderContents: Array<string>,
	orderId: string,
	orderIsAsap: boolean,
	orderReference: string,
	pickupTask: ModelTypes["PickupTaskInput"],
	/** This will be calculated based on the route */
	price?: number | undefined
};
	["JobListRelationFilter"]: {
	every?: ModelTypes["JobWhereInput"] | undefined,
	none?: ModelTypes["JobWhereInput"] | undefined,
	some?: ModelTypes["JobWhereInput"] | undefined
};
	["JobOrderByInput"]: {
	assignmentHistory?: ModelTypes["JobAssignmentHistoryOrderByRelationAggregateInput"] | undefined,
	canceledBy?: ModelTypes["SortOrder"] | undefined,
	completedByDriverFullName?: ModelTypes["SortOrder"] | undefined,
	completedByDriverId?: ModelTypes["SortOrder"] | undefined,
	completionTime?: ModelTypes["SortOrder"] | undefined,
	cost?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	distanceUnit?: ModelTypes["SortOrder"] | undefined,
	driver?: ModelTypes["DriverOrderByInput"] | undefined,
	dropOffTasks?: ModelTypes["DropOffTaskOrderByRelationAggregateInput"] | undefined,
	estimatedDistanceMiles?: ModelTypes["SortOrder"] | undefined,
	estimatedDurationMinutes?: ModelTypes["SortOrder"] | undefined,
	estimatedPickupTime?: ModelTypes["SortOrder"] | undefined,
	finishedAssignment?: ModelTypes["SortOrder"] | undefined,
	fleet?: ModelTypes["FleetOrderByInput"] | undefined,
	initialCost?: ModelTypes["SortOrder"] | undefined,
	jobAcceptWaitTime?: ModelTypes["SortOrder"] | undefined,
	jobCompleteNotes?: ModelTypes["SortOrder"] | undefined,
	jobReference?: ModelTypes["SortOrder"] | undefined,
	onHold?: ModelTypes["SortOrder"] | undefined,
	orderContents?: ModelTypes["SortOrder"] | undefined,
	orderId?: ModelTypes["SortOrder"] | undefined,
	orderIsAsap?: ModelTypes["SortOrder"] | undefined,
	orderReference?: ModelTypes["SortOrder"] | undefined,
	pickupTasks?: ModelTypes["PickupTaskOrderByRelationAggregateInput"] | undefined,
	pickupTime?: ModelTypes["SortOrder"] | undefined,
	price?: ModelTypes["SortOrder"] | undefined,
	stackId?: ModelTypes["SortOrder"] | undefined,
	stackStatus?: ModelTypes["SortOrder"] | undefined,
	status?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["JobOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["JobStatus"]:JobStatus;
	["JobStatusFilter"]: {
	equals?: ModelTypes["JobStatus"] | undefined,
	in?: Array<ModelTypes["JobStatus"]> | undefined,
	not?: ModelTypes["JobStatus"] | undefined,
	notIn?: Array<ModelTypes["JobStatus"]> | undefined
};
	["JobUpdateInput"]: {
	assignmentHistory?: ModelTypes["JobAssignmentHistoryUpdateManyWithoutJobInput"] | undefined,
	canceledBy?: ModelTypes["CanceledBy"] | undefined,
	completionTime?: ModelTypes["DateTime"] | undefined,
	cost?: number | undefined,
	driver?: ModelTypes["DriverConnectOrDisconnectInput"] | undefined,
	dropOffTasks?: ModelTypes["DropOffTaskUpdateManyWithoutJobInput"] | undefined,
	estimatedDistanceMiles?: number | undefined,
	estimatedDurationMinutes?: number | undefined,
	estimatedPickupTime?: ModelTypes["DateTime"] | undefined,
	finishedAssignment?: boolean | undefined,
	fleet?: ModelTypes["FleetConnectOneInput"] | undefined,
	id: string,
	initialCost?: number | undefined,
	initialRoute?: string | undefined,
	jobAcceptWaitTime?: number | undefined,
	onHold?: boolean | undefined,
	orderContents?: Array<string> | undefined,
	orderIsAsap?: boolean | undefined,
	orderReference?: string | undefined,
	pickupTasks?: ModelTypes["PickupTaskUpdateManyWithoutJobInput"] | undefined,
	pickupTime?: ModelTypes["DateTime"] | undefined,
	reasonForCancellation?: string | undefined,
	route?: string | undefined,
	stackId?: string | undefined,
	status?: ModelTypes["JobStatus"] | undefined
};
	["JobUpdateManyWithoutDriverInput"]: {
	connect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined
};
	["JobWhereInput"]: {
	AND?: Array<ModelTypes["JobWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["JobWhereInput"]> | undefined,
	OR?: Array<ModelTypes["JobWhereInput"]> | undefined,
	assignmentHistory?: ModelTypes["JobAssignmentHistoryListRelationFilter"] | undefined,
	canceledBy?: ModelTypes["CanceledBy"] | undefined,
	completedByDriverFullName?: ModelTypes["StringFilter"] | undefined,
	completedByDriverId?: ModelTypes["StringFilter"] | undefined,
	completionTime?: ModelTypes["DateTimeFilter"] | undefined,
	cost?: number | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	distanceUnit?: ModelTypes["DistanceUnitFilter"] | undefined,
	driver?: ModelTypes["DriverWhereInput"] | undefined,
	driverId?: ModelTypes["StringFilter"] | undefined,
	dropOffTasks?: ModelTypes["DropOffTaskListRelationFilter"] | undefined,
	estimatedDistanceMiles?: number | undefined,
	estimatedDurationMinutes?: number | undefined,
	estimatedPickupTime?: ModelTypes["DateTimeFilter"] | undefined,
	finishedAssignment?: boolean | undefined,
	fleet?: ModelTypes["FleetWhereInput"] | undefined,
	fleetId?: ModelTypes["StringFilter"] | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	initialCost?: number | undefined,
	jobAcceptWaitTime?: number | undefined,
	jobCompleteNotes?: ModelTypes["StringFilter"] | undefined,
	jobReference?: ModelTypes["StringFilter"] | undefined,
	onHold?: boolean | undefined,
	orderContents?: ModelTypes["JsonFilter"] | undefined,
	orderId?: ModelTypes["StringFilter"] | undefined,
	orderIsAsap?: boolean | undefined,
	orderReference?: ModelTypes["StringFilter"] | undefined,
	pickupTasks?: ModelTypes["PickupTaskListRelationFilter"] | undefined,
	pickupTime?: ModelTypes["DateTimeFilter"] | undefined,
	price?: number | undefined,
	stackId?: ModelTypes["StringFilter"] | undefined,
	stackStatus?: ModelTypes["JobStatusFilter"] | undefined,
	status?: ModelTypes["JobStatusFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined
};
	["JsonFilter"]: {
	equals?: string | undefined,
	not?: string | undefined
};
	["LocationCoordsInput"]: {
	address: ModelTypes["AddressInput"],
	latitude: number,
	longitude: number
};
	["LocationInput"]: {
	latitude: number,
	longitude: number
};
	["MinimalNode"]: {
		id: string,
	name: string
};
	["Mutation"]: {
		acceptJob?: ModelTypes["Job"] | undefined,
	assignJobToDriver: ModelTypes["Job"],
	/** Starts the assignment loop. Runs periodically */
	assignJobsScheduler: string,
	/** Transitions drivers to the correct status based on their booked breaks if they are not already in the correct status. Runs periodically */
	autoTransitionDriversStatusScheduler?: string | undefined,
	/** Transitions jobs to travelling-to-pickup status based on estimated pickup time and current driver position. Runs periodically */
	autoTravelToPickupScheduler: string,
	cancelJob: ModelTypes["Job"],
	/** Cancels jobs that are in the exception queue. It only does so for fleets that allow automated job cancelling. */
	cancelJobsScheduler: string,
	/** Mutation to complete a job, dedicated for drivers. Returns an array of 1 to 3 jobs */
	completeJob: Array<ModelTypes["Job"]>,
	/** Used by the user after receiving an invite */
	completeUserProfile?: string | undefined,
	/** Continues assigning jobs if the assignment loop did not finish. Runs periodically */
	continueJobsAssignmentScheduler: string,
	createApiImplementation?: ModelTypes["ApiImplementation"] | undefined,
	createBase?: ModelTypes["Base"] | undefined,
	createDropOffTask?: ModelTypes["DropOffTask"] | undefined,
	createFleet?: ModelTypes["Fleet"] | undefined,
	createFleetDriver?: ModelTypes["FleetDriver"] | undefined,
	/** Creates a job with a single pickup and drop off task or if a job with a similar pickup exists already,
      it stacks up to three dropoffs under certain conditions.
      Calculates and stores the price and cost based on the fleet settings */
	createJob: ModelTypes["Job"],
	createJobAssignmentHistory: ModelTypes["JobAssignmentHistory"],
	createPartner?: ModelTypes["Partner"] | undefined,
	createPickupTask?: ModelTypes["PickupTask"] | undefined,
	createPlatform?: ModelTypes["Platform"] | undefined,
	createUser: ModelTypes["CreateUserResponse"],
	createWebhook?: ModelTypes["Webhook"] | undefined,
	deleteApiImplementation?: ModelTypes["ApiImplementation"] | undefined,
	/** Due to possible side-effects this can only be triggered by admins for now */
	deleteDriver?: ModelTypes["Driver"] | undefined,
	deleteJob: ModelTypes["Job"],
	/** Can only be used by admins. Disabled in production. */
	deleteUser?: ModelTypes["User"] | undefined,
	deleteWebhook?: ModelTypes["Webhook"] | undefined,
	driverLogin: ModelTypes["DriverLoginResponse"],
	/** Dedicated mutation that is only triggered by the driver app when the driver logs out. Has a side effect of pausing notifications for the driver. */
	driverLogout: string,
	inviteDriver?: ModelTypes["Driver"] | undefined,
	inviteUser?: string | undefined,
	/** Returns a token if the login was successful */
	login?: string | undefined,
	notifyDriver?: boolean | undefined,
	rejectJob?: ModelTypes["Job"] | undefined,
	/** Removes a status option from a driver */
	removeDriverStatusOption: boolean,
	/** Sends an email containing a link or a token but DOES NOT mutate the password. That step is reserved for the subsequent 'resetPassword' mutation */
	requestPasswordReset?: string | undefined,
	/** Updates the user password. Returns the state of the mutation */
	resetPassword?: string | undefined,
	setDriverStatusFromOption: boolean,
	updateApiImplementation?: ModelTypes["ApiImplementation"] | undefined,
	updateBase?: ModelTypes["Base"] | undefined,
	updateDriver?: ModelTypes["Driver"] | undefined,
	/** Ideally used by drivers to complete their details */
	updateDriverDetails?: ModelTypes["Driver"] | undefined,
	updateDriverLocation?: string | undefined,
	updateDropOffTask?: ModelTypes["DropOffTask"] | undefined,
	updateDropOffTaskProofOfDelivery?: ModelTypes["DropOffTask"] | undefined,
	updateFleet: ModelTypes["Fleet"],
	updateFleetDriver: ModelTypes["FleetDriver"],
	updateJob: ModelTypes["Job"],
	updateJobAssignmentHistory: ModelTypes["JobAssignmentHistory"],
	updateJobPickupTime?: ModelTypes["Job"] | undefined,
	updateJobStatus: ModelTypes["Job"],
	updatePartner?: ModelTypes["Partner"] | undefined,
	/** Returns a new token if the update was successful */
	updatePassword?: string | undefined,
	updatePickupTask?: ModelTypes["PickupTask"] | undefined,
	updatePlatform?: ModelTypes["Platform"] | undefined,
	updatePushToken?: ModelTypes["Driver"] | undefined,
	updateUser: ModelTypes["User"],
	updateWebhook?: ModelTypes["Webhook"] | undefined,
	/** Uploads a photo to S3 and then stores the path to it on the Job node */
	uploadJobCompleteProof: boolean,
	verifyPhone: ModelTypes["VerifyPhoneResponse"]
};
	["NotifyDriverInput"]: {
	body: string,
	data: ModelTypes["JSON"],
	driverId: string,
	subtitle?: string | undefined,
	title: string
};
	["OptionForDriverStatus"]: {
		booked: boolean,
	durationInMinutes?: number | undefined,
	id: string,
	label: string,
	maxStartTime?: string | undefined,
	minStartTime?: string | undefined,
	minimalLabel: string,
	status: string
};
	["PaginatedJobs"]: {
		count: number,
	jobs: Array<ModelTypes["Job"]>
};
	["Partner"]: {
		createdAt: ModelTypes["DateTime"],
	fleets: Array<ModelTypes["Fleet"]>,
	id: string,
	name: string,
	platform?: ModelTypes["Platform"] | undefined,
	platformId: string,
	updatedAt: ModelTypes["DateTime"],
	users: Array<ModelTypes["User"]>
};
	["PartnerConnectOrDisconnectInput"]: {
	connect?: ModelTypes["WhereRequiredIdInput"] | undefined,
	disconnect?: ModelTypes["WhereRequiredIdInput"] | undefined
};
	["PartnerCreateInput"]: {
	name: string,
	platform: ModelTypes["PlatformConnectById"]
};
	["PartnerCreateNestedOneWithoutFleetsInput"]: {
	connect?: ModelTypes["WhereRequiredIdInput"] | undefined,
	create?: ModelTypes["PartnerCreateInput"] | undefined
};
	["PartnerCreateWithoutPlatformInput"]: {
	fleets?: ModelTypes["FleetCreateNestedManyWithoutPartnerInput"] | undefined,
	name: string
};
	["PartnerListRelationFilter"]: {
	every?: ModelTypes["PartnerWhereInput"] | undefined,
	none?: ModelTypes["PartnerWhereInput"] | undefined,
	some?: ModelTypes["PartnerWhereInput"] | undefined
};
	["PartnerOrderByInput"]: {
	createdAt?: ModelTypes["SortOrder"] | undefined,
	fleets?: ModelTypes["FleetOrderByRelationAggregateInput"] | undefined,
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	partners?: ModelTypes["PartnerOrderByRelationAggregateInput"] | undefined,
	platform?: ModelTypes["PlatformOrderByInput"] | undefined,
	platformId?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["PartnerOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["PartnerUpdateInput"]: {
	fleets?: ModelTypes["FleetUpdateManyWithoutPartnerInput"] | undefined,
	id: string,
	name?: string | undefined
};
	["PartnerUpdatemanyWithoutPlatformInput"]: {
	connect?: Array<ModelTypes["PartnerWhereUniqueInput"]> | undefined,
	delete?: Array<ModelTypes["PartnerWhereUniqueInput"]> | undefined,
	disconnect?: Array<ModelTypes["PartnerWhereUniqueInput"]> | undefined
};
	["PartnerWhereInput"]: {
	AND?: Array<ModelTypes["PartnerWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PartnerWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PartnerWhereInput"]> | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	fleets?: ModelTypes["FleetListRelationFilter"] | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	platform?: ModelTypes["PlatformWhereInput"] | undefined,
	platformId?: ModelTypes["StringFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined
};
	["PartnerWhereUniqueInput"]: {
	id?: string | undefined
};
	["PickupTask"]: {
		createdAt: ModelTypes["DateTime"],
	id: string,
	job: ModelTypes["Job"],
	jobId: string,
	location: ModelTypes["JSON"],
	locationHashed: string,
	locationName: string,
	pickupContact?: ModelTypes["JSON"] | undefined,
	pickupNotes?: string | undefined,
	updatedAt: ModelTypes["DateTime"]
};
	["PickupTaskCreateInput"]: {
	job: ModelTypes["JobConnectOneInput"],
	location: ModelTypes["LocationCoordsInput"],
	locationName: string,
	pickupContact?: ModelTypes["ContactInput"] | undefined,
	pickupNotes?: string | undefined
};
	/** Input required by JobCreateDirectInput */
["PickupTaskInput"]: {
	location: ModelTypes["LocationCoordsInput"],
	locationName: string,
	pickupContact: ModelTypes["ContactInput"],
	pickupNotes?: string | undefined
};
	["PickupTaskListRelationFilter"]: {
	every?: ModelTypes["PickupTaskWhereInput"] | undefined,
	none?: ModelTypes["PickupTaskWhereInput"] | undefined,
	some?: ModelTypes["PickupTaskWhereInput"] | undefined
};
	["PickupTaskOrderByInput"]: {
	createdAt?: ModelTypes["SortOrder"] | undefined,
	job?: ModelTypes["JobOrderByInput"] | undefined,
	locationHashed?: ModelTypes["SortOrder"] | undefined,
	locationName?: ModelTypes["SortOrder"] | undefined,
	pickupNotes?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["PickupTaskOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["PickupTaskUpdateInput"]: {
	id: string,
	job?: ModelTypes["JobConnectOneInput"] | undefined,
	location?: ModelTypes["LocationCoordsInput"] | undefined,
	locationName?: string | undefined,
	pickupContact?: ModelTypes["ContactInput"] | undefined,
	pickupNotes?: string | undefined,
	proofOfDelivery?: string | undefined
};
	["PickupTaskUpdateManyWithoutJobInput"]: {
	connect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	delete?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined
};
	["PickupTaskWhereInput"]: {
	AND?: Array<ModelTypes["PickupTaskWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PickupTaskWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PickupTaskWhereInput"]> | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	id?: string | undefined,
	job?: ModelTypes["JobWhereInput"] | undefined,
	jobId?: string | undefined,
	locationHashed?: string | undefined,
	locationName?: string | undefined,
	pickupNotes?: string | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined
};
	["Platform"]: {
		createdAt: ModelTypes["DateTime"],
	id: string,
	name: string,
	partners: Array<ModelTypes["Partner"]>,
	updatedAt: ModelTypes["DateTime"]
};
	["PlatformConnectById"]: {
	connect: ModelTypes["WhereRequiredIdInput"]
};
	["PlatformCreateInput"]: {
	name: string,
	partners?: ModelTypes["PlatformCreateOrConnectPartnerInput"] | undefined
};
	["PlatformCreateOrConnectPartnerInput"]: {
	connect?: Array<string> | undefined,
	create?: Array<ModelTypes["PartnerCreateWithoutPlatformInput"]> | undefined
};
	["PlatformOrderByInput"]: {
	createdAt?: ModelTypes["SortOrder"] | undefined,
	id?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	partners?: ModelTypes["PartnerOrderByRelationAggregateInput"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["PlatformUpdateInput"]: {
	id: string,
	name?: string | undefined,
	partners?: ModelTypes["PartnerUpdatemanyWithoutPlatformInput"] | undefined
};
	["PlatformWhereInput"]: {
	AND?: Array<ModelTypes["PlatformWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["PlatformWhereInput"]> | undefined,
	OR?: Array<ModelTypes["PlatformWhereInput"]> | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	partners?: ModelTypes["PartnerListRelationFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined
};
	["Query"]: {
		apiImplementation?: ModelTypes["ApiImplementation"] | undefined,
	apiImplementations: Array<ModelTypes["ApiImplementation"]>,
	assignedJobs: Array<ModelTypes["Job"]>,
	/** Returns an array of available nodes the current user can grant access to new users.
      It only returns the highest level permissions for the desired role.
      For example, if the desired role is "PARTNER_USER" role, it will return a list of partner ids. */
	availableNodesByUserAndDesiredRole: Array<ModelTypes["MinimalNode"]>,
	/** Returns an array of available roles the current user can grant to new users */
	availableRolesByUser: Array<ModelTypes["UserRole"]>,
	base?: ModelTypes["Base"] | undefined,
	bases: Array<ModelTypes["Base"]>,
	/** Returns an array of booked and available options the current driver can change to */
	bookedAndAvailableOptionsForDriverStatus: Array<ModelTypes["OptionForDriverStatus"]>,
	calculateRoute?: string | undefined,
	/** Returns the collisions, stackability and plausability state between the driver's current jobs and a new one */
	collisionsOrStackability?: ModelTypes["CollisionsOrStackability"] | undefined,
	currentDriver?: ModelTypes["Driver"] | undefined,
	/** Returns an array of assigned, not on hold and en-route jobs */
	currentJobs?: Array<ModelTypes["Job"]> | undefined,
	currentUser: ModelTypes["User"],
	deliveryEstimate?: ModelTypes["DeliveryEstimate"] | undefined,
	driver?: ModelTypes["Driver"] | undefined,
	drivers?: Array<ModelTypes["Driver"]> | undefined,
	dropOffTask?: ModelTypes["DropOffTask"] | undefined,
	dropOffTasks: Array<ModelTypes["DropOffTask"]>,
	/** Returns the first 3 drivers closest to a pickup, in ascending order of distance */
	eligibleDriversForPickup: Array<ModelTypes["EligibleDriversForPickup"]>,
	/** Returns jobs that were not assigned and require a human operator */
	exceptionQueue: Array<ModelTypes["Job"]>,
	fleet?: ModelTypes["Fleet"] | undefined,
	fleetDriver?: ModelTypes["FleetDriver"] | undefined,
	fleetDrivers?: Array<ModelTypes["FleetDriver"]> | undefined,
	fleets: Array<ModelTypes["Fleet"]>,
	job?: ModelTypes["Job"] | undefined,
	jobAssignmentHistories: Array<ModelTypes["JobAssignmentHistory"]>,
	jobAssignmentHistory?: ModelTypes["JobAssignmentHistory"] | undefined,
	/** Returns jobs. If no filters are passed it will only return jobs relevant for today UTC */
	jobs: Array<ModelTypes["Job"]>,
	/** Returns the jobs and an additional field count. If no filters are passed it will only return jobs relevant for today UTC */
	paginatedJobs: ModelTypes["PaginatedJobs"],
	partner?: ModelTypes["Partner"] | undefined,
	partners: Array<ModelTypes["Partner"]>,
	/** Returns an array of jobs, the first one needs to be accepted/rejected while the rest are simply completing the stack */
	pendingJobAndStack?: Array<ModelTypes["Job"]> | undefined,
	pickupTask?: ModelTypes["PickupTask"] | undefined,
	pickupTasks: Array<ModelTypes["PickupTask"]>,
	platform?: ModelTypes["Platform"] | undefined,
	platforms: Array<ModelTypes["Platform"]>,
	timeConnectedInMs?: string | undefined,
	userByEmail?: ModelTypes["User"] | undefined,
	userById?: ModelTypes["User"] | undefined,
	users: Array<ModelTypes["User"]>,
	vehicle?: ModelTypes["Vehicle"] | undefined,
	vehicles?: Array<ModelTypes["Vehicle"]> | undefined,
	webhook?: ModelTypes["Webhook"] | undefined,
	webhooks: Array<ModelTypes["Webhook"]>
};
	["SortOrder"]:SortOrder;
	["StringFilter"]: {
	contains?: string | undefined,
	endsWith?: string | undefined,
	equals?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	in?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	not?: ModelTypes["StringFilter"] | undefined,
	notIn?: Array<string> | undefined,
	startsWith?: string | undefined
};
	["Subscription"]: {
		assignedJobs: Array<ModelTypes["Job"]>,
	currentDriver?: ModelTypes["Driver"] | undefined,
	/** Returns an array of assigned, not on hold and en-route jobs */
	currentJobs?: Array<ModelTypes["Job"]> | undefined,
	driver?: ModelTypes["Driver"] | undefined,
	drivers?: Array<ModelTypes["Driver"]> | undefined,
	job?: ModelTypes["Job"] | undefined,
	/** Returns jobs. If no filters are passed it will only return jobs relevant for today UTC */
	jobs: Array<ModelTypes["Job"]>,
	/** Returns the jobs and an additional field count. If no filters are passed it will only return jobs relevant for today UTC */
	paginatedJobs: ModelTypes["PaginatedJobs"],
	/** Returns an array of jobs, the first one needs to be accepted/rejected while the rest are simply completing the stack */
	pendingJobAndStack?: Array<ModelTypes["Job"]> | undefined
};
	["TimeConnectedInMsInput"]: {
	/** If not provided the query will default to the user on the context */
	driverId?: string | undefined,
	/** Optional parameter that marks the start of day timestamp in ms for the driver. It helps with addressing time zone variants */
	startOfDayTimestamp?: number | undefined
};
	["UpdateJobPickupTimeInput"]: {
	estimatedPickupTime?: ModelTypes["DateTime"] | undefined,
	id: string
};
	["UpdateJobStatusInput"]: {
	id: string,
	status: ModelTypes["JobStatus"]
};
	["UploadJobCompleteProofInput"]: {
	jobCompleteNotes?: string | undefined,
	jobCompletePhotoProof?: string | undefined,
	jobId: string
};
	["User"]: {
		createdAt: ModelTypes["DateTime"],
	email: string,
	enabled: boolean,
	firstName: string,
	fleets: Array<ModelTypes["Fleet"]>,
	id: string,
	lastName: string,
	partners: Array<ModelTypes["Partner"]>,
	/** The hashed password */
	password?: string | undefined,
	phoneNumber: string,
	role: string,
	updatedAt: ModelTypes["DateTime"],
	userStatus: string
};
	["UserConnectInput"]: {
	connect?: ModelTypes["WhereRequiredIdInput"] | undefined
};
	["UserCreateInput"]: {
	email: string,
	firstName: string,
	lastName: string,
	partners?: ModelTypes["PartnerConnectOrDisconnectInput"] | undefined,
	password: string,
	phoneNumber: string,
	role: ModelTypes["UserRole"],
	userStatus: ModelTypes["UserStatus"]
};
	["UserCreateNewPasswordInput"]: {
	password: string,
	token: string
};
	["UserInviteInput"]: {
	email: string,
	/** The highest level ids of nodes the user has access to. E.g. - a partner-user should get a list of partnerIds to connect */
	nodes?: Array<string> | undefined,
	role: ModelTypes["UserRole"]
};
	["UserLoginInput"]: {
	email: string,
	password: string
};
	["UserOrderByInput"]: {
	createdAt?: ModelTypes["SortOrder"] | undefined,
	email?: ModelTypes["SortOrder"] | undefined,
	enabled?: ModelTypes["SortOrder"] | undefined,
	firstName?: ModelTypes["SortOrder"] | undefined,
	id?: ModelTypes["SortOrder"] | undefined,
	lastName?: ModelTypes["SortOrder"] | undefined,
	phoneNumber?: ModelTypes["SortOrder"] | undefined,
	role?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	userStatus?: ModelTypes["SortOrder"] | undefined
};
	["UserResetPasswordInput"]: {
	key: string,
	newPassword: string
};
	["UserRole"]:UserRole;
	["UserRoleFilter"]: {
	equals?: ModelTypes["UserRole"] | undefined,
	in?: Array<ModelTypes["UserRole"]> | undefined,
	not?: ModelTypes["UserRoleFilter"] | undefined,
	notIn?: Array<ModelTypes["UserRole"]> | undefined
};
	["UserStatus"]:UserStatus;
	["UserStatusFilter"]: {
	equals?: ModelTypes["UserStatus"] | undefined,
	in?: Array<ModelTypes["UserStatus"]> | undefined,
	not?: ModelTypes["UserStatusFilter"] | undefined,
	notIn?: Array<ModelTypes["UserStatus"]> | undefined
};
	["UserUpdateInput"]: {
	email?: string | undefined,
	enabled?: boolean | undefined,
	firstName?: string | undefined,
	id: string,
	lastName?: string | undefined,
	/** The highest level ids of nodes the user has access to. It connects and disconnects nodes according to this array if passed in. */
	nodes?: Array<string> | undefined,
	phoneNumber?: string | undefined,
	role?: ModelTypes["UserRole"] | undefined,
	userStatus?: ModelTypes["UserStatus"] | undefined
};
	["UserUpdatePasswordInput"]: {
	newPassword: string,
	oldPassword: string
};
	["UserWhereInput"]: {
	AND?: Array<ModelTypes["UserWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["UserWhereInput"]> | undefined,
	OR?: Array<ModelTypes["UserWhereInput"]> | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	email?: ModelTypes["StringFilter"] | undefined,
	enabled?: ModelTypes["BooleanFilter"] | undefined,
	firstName?: ModelTypes["StringFilter"] | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	lastName?: ModelTypes["StringFilter"] | undefined,
	phoneNumber?: ModelTypes["StringFilter"] | undefined,
	role?: ModelTypes["UserRoleFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	userStatus?: ModelTypes["UserStatusFilter"] | undefined
};
	["Vehicle"]: {
		createdAt: ModelTypes["DateTime"],
	driver?: ModelTypes["Driver"] | undefined,
	driverId?: string | undefined,
	id: string,
	make?: string | undefined,
	model?: string | undefined,
	ownerDriver: boolean,
	ownerFleet?: ModelTypes["Fleet"] | undefined,
	ownerFleetId?: string | undefined,
	reg?: string | undefined,
	type: ModelTypes["VehicleType"],
	updatedAt: ModelTypes["DateTime"]
};
	["VehicleConnectOrDisconnectOneInput"]: {
	connect?: ModelTypes["WhereRequiredIdInput"] | undefined,
	disconnect?: boolean | undefined
};
	["VehicleCreateInput"]: {
	driver?: ModelTypes["DriverConnectOneInput"] | undefined,
	make?: string | undefined,
	model?: string | undefined,
	ownerDriver?: boolean | undefined,
	ownerFleet?: ModelTypes["FleetConnectOneInput"] | undefined,
	reg?: string | undefined,
	type: ModelTypes["VehicleType"]
};
	["VehicleCreateNestedOneWithoutDriverInput"]: {
	connect?: ModelTypes["WhereRequiredIdInput"] | undefined,
	create?: ModelTypes["VehicleCreateInput"] | undefined
};
	["VehicleCreateNestedWithoutOwnerFleetInput"]: {
	connect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<ModelTypes["VehicleCreateWithoutOwnerFleetInput"]> | undefined
};
	["VehicleCreateWithoutOwnerFleetInput"]: {
	driver: ModelTypes["DriverConnectOneInput"],
	make?: string | undefined,
	model?: string | undefined,
	ownerDriver?: boolean | undefined,
	reg?: string | undefined,
	type: ModelTypes["VehicleType"]
};
	["VehicleListRelationFilter"]: {
	every?: ModelTypes["VehicleWhereInput"] | undefined,
	none?: ModelTypes["VehicleWhereInput"] | undefined,
	some?: ModelTypes["VehicleWhereInput"] | undefined
};
	["VehicleOrderByInput"]: {
	createdAt?: ModelTypes["SortOrder"] | undefined,
	driver?: ModelTypes["DriverOrderByInput"] | undefined,
	driverId?: ModelTypes["SortOrder"] | undefined,
	id?: ModelTypes["SortOrder"] | undefined,
	make?: ModelTypes["SortOrder"] | undefined,
	model?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	ownerDriver?: ModelTypes["SortOrder"] | undefined,
	ownerFleet?: ModelTypes["FleetOrderByInput"] | undefined,
	ownerFleetId?: ModelTypes["SortOrder"] | undefined,
	reg?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined
};
	["VehicleOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["VehicleType"]:VehicleType;
	["VehicleUpdateInput"]: {
	driver?: ModelTypes["DriverConnectOrDisconnectOneInput"] | undefined,
	id: string,
	make?: string | undefined,
	model?: string | undefined,
	ownerDriver?: boolean | undefined,
	ownerFleet?: ModelTypes["FleetConnectOrDisconnectOneInput"] | undefined,
	reg?: string | undefined,
	type?: ModelTypes["VehicleType"] | undefined
};
	["VehicleUpdateManyWithoutOwnerFleetInput"]: {
	connect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<ModelTypes["VehicleCreateWithoutOwnerFleetInput"]> | undefined,
	delete?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined
};
	["VehicleWhereInput"]: {
	AND?: Array<ModelTypes["VehicleWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["VehicleWhereInput"]> | undefined,
	OR?: Array<ModelTypes["VehicleWhereInput"]> | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	driver?: ModelTypes["DriverWhereInput"] | undefined,
	driverId?: ModelTypes["StringFilter"] | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	make?: ModelTypes["StringFilter"] | undefined,
	model?: ModelTypes["StringFilter"] | undefined,
	ownerDriver?: ModelTypes["BooleanFilter"] | undefined,
	ownerFleet?: ModelTypes["FleetWhereInput"] | undefined,
	ownerFleetId?: ModelTypes["StringFilter"] | undefined,
	reg?: ModelTypes["StringFilter"] | undefined,
	type?: ModelTypes["VehicleType"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined
};
	/** Response from verify phone */
["VerifyPhoneResponse"]: {
		completedSignUp: boolean,
	token?: string | undefined
};
	["Webhook"]: {
		apiKeyHeader?: string | undefined,
	apiKeyValue?: string | undefined,
	createdAt: ModelTypes["DateTime"],
	fleet: ModelTypes["Fleet"],
	fleetId: string,
	forJobStatus?: ModelTypes["JobStatus"] | undefined,
	id: string,
	isRedboxApi: boolean,
	name?: string | undefined,
	updatedAt: ModelTypes["DateTime"],
	url: string
};
	["WebhookCreateInput"]: {
	apiKeyHeader?: string | undefined,
	apiKeyValue?: string | undefined,
	fleet: ModelTypes["FleetConnectOneInput"],
	forJobStatus?: ModelTypes["JobStatus"] | undefined,
	isRedboxApi?: boolean | undefined,
	name?: string | undefined,
	url: string
};
	["WebhookCreateNestedManyWithoutFleetInput"]: {
	connect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<ModelTypes["WebhookCreateWithoutFleetInput"]> | undefined
};
	["WebhookCreateWithoutFleetInput"]: {
	forJobStatus?: ModelTypes["JobStatus"] | undefined,
	name?: string | undefined,
	url: string
};
	["WebhookListRelationFilter"]: {
	every?: ModelTypes["WebhookWhereInput"] | undefined,
	none?: ModelTypes["WebhookWhereInput"] | undefined,
	some?: ModelTypes["WebhookWhereInput"] | undefined
};
	["WebhookOrderByInput"]: {
	apiKeyHeader?: ModelTypes["SortOrder"] | undefined,
	createdAt?: ModelTypes["SortOrder"] | undefined,
	fleet?: ModelTypes["FleetOrderByInput"] | undefined,
	isRedboxApi?: ModelTypes["SortOrder"] | undefined,
	name?: ModelTypes["SortOrder"] | undefined,
	updatedAt?: ModelTypes["SortOrder"] | undefined,
	url?: ModelTypes["SortOrder"] | undefined
};
	["WebhookOrderByRelationAggregateInput"]: {
	_count?: ModelTypes["SortOrder"] | undefined
};
	["WebhookUpdateInput"]: {
	apiKeyHeader?: string | undefined,
	apiKeyValue?: string | undefined,
	fleet?: ModelTypes["FleetConnectOneInput"] | undefined,
	forJobStatus?: ModelTypes["JobStatus"] | undefined,
	id: string,
	isRedboxApi?: boolean | undefined,
	name?: string | undefined,
	url?: string | undefined
};
	["WebhookUpdateManyWithoutFleetInput"]: {
	connect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<ModelTypes["WebhookCreateWithoutFleetInput"]> | undefined,
	delete?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<ModelTypes["WhereRequiredIdInput"]> | undefined
};
	["WebhookWhereInput"]: {
	AND?: Array<ModelTypes["WebhookWhereInput"]> | undefined,
	NOT?: Array<ModelTypes["WebhookWhereInput"]> | undefined,
	OR?: Array<ModelTypes["WebhookWhereInput"]> | undefined,
	apiKeyHeader?: ModelTypes["StringFilter"] | undefined,
	createdAt?: ModelTypes["DateTimeFilter"] | undefined,
	fleet?: ModelTypes["FleetWhereInput"] | undefined,
	fleetId?: ModelTypes["StringFilter"] | undefined,
	forJobStatus?: ModelTypes["JobStatus"] | undefined,
	id?: ModelTypes["StringFilter"] | undefined,
	isRedboxApi?: ModelTypes["BooleanFilter"] | undefined,
	name?: ModelTypes["StringFilter"] | undefined,
	updatedAt?: ModelTypes["DateTimeFilter"] | undefined,
	url?: ModelTypes["StringFilter"] | undefined
};
	["WhereRequiredIdInput"]: {
	id: string
}
    }

export type GraphQLTypes = {
    ["AddressInput"]: {
		city: string,
	firstLine: string,
	postcode: string,
	secondLine: string,
	thirdLine: string
};
	["ApiImplementation"]: {
	__typename: "ApiImplementation",
	apiKey: string,
	createdAt: GraphQLTypes["DateTime"],
	fleet: GraphQLTypes["Fleet"],
	fleetId: string,
	id: string,
	name: string,
	updatedAt: GraphQLTypes["DateTime"]
};
	["ApiImplementationCreateInput"]: {
		apiKey: string,
	fleet: GraphQLTypes["FleetConnectOneInput"],
	name: string
};
	["ApiImplementationCreateNestedManyWithoutFleetInput"]: {
		connect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<GraphQLTypes["ApiImplementationCreateWithoutFleetInput"]> | undefined
};
	["ApiImplementationCreateWithoutFleetInput"]: {
		apiKey: string,
	name: string
};
	["ApiImplementationListRelationFilter"]: {
		every?: GraphQLTypes["ApiImplementationWhereInput"] | undefined,
	none?: GraphQLTypes["ApiImplementationWhereInput"] | undefined,
	some?: GraphQLTypes["ApiImplementationWhereInput"] | undefined
};
	["ApiImplementationOrderByInput"]: {
		apiKey?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	fleet?: GraphQLTypes["FleetOrderByInput"] | undefined,
	fleetId?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["ApiImplementationOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["ApiImplementationUpdateInput"]: {
		apiKey?: string | undefined,
	fleet?: GraphQLTypes["FleetConnectOneInput"] | undefined,
	id: string,
	name?: string | undefined
};
	["ApiImplementationUpdateManyWithoutFleetInput"]: {
		connect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<GraphQLTypes["ApiImplementationCreateWithoutFleetInput"]> | undefined,
	delete?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined
};
	["ApiImplementationWhereInput"]: {
		AND?: Array<GraphQLTypes["ApiImplementationWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["ApiImplementationWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["ApiImplementationWhereInput"]> | undefined,
	apiKey?: GraphQLTypes["StringFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	fleet?: GraphQLTypes["FleetWhereInput"] | undefined,
	fleetId?: GraphQLTypes["StringFilter"] | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	model?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined
};
	["AssignJobToDriverInput"]: {
		driverId: string,
	jobId: string
};
	["AuthTokenType"]: AuthTokenType;
	["AvailableNodesByUserAndDesiredRoleInput"]: {
		desiredRole: GraphQLTypes["UserRole"]
};
	["Base"]: {
	__typename: "Base",
	address: GraphQLTypes["JSON"],
	createdAt: GraphQLTypes["DateTime"],
	fleet: GraphQLTypes["Fleet"],
	fleetId: string,
	geo: GraphQLTypes["JSON"],
	id: string,
	name: string,
	updatedAt: GraphQLTypes["DateTime"]
};
	["BaseCreateInput"]: {
		address: string,
	fleet: GraphQLTypes["FleetConnectOneInput"],
	geo: string,
	name: string
};
	["BaseCreateNestedWithoutFleetInput"]: {
		connect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<GraphQLTypes["BaseCreateWithoutFleetInput"]> | undefined
};
	["BaseCreateWithoutFleetInput"]: {
		address: string,
	geo: string,
	name: string
};
	["BaseListRelationFilter"]: {
		every?: GraphQLTypes["BaseWhereInput"] | undefined,
	none?: GraphQLTypes["BaseWhereInput"] | undefined,
	some?: GraphQLTypes["BaseWhereInput"] | undefined
};
	["BaseOrderByInput"]: {
		address?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	fleet?: GraphQLTypes["FleetOrderByInput"] | undefined,
	fleetId?: GraphQLTypes["SortOrder"] | undefined,
	geo?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["BaseOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["BaseUpdateInput"]: {
		address?: string | undefined,
	fleet?: GraphQLTypes["FleetConnectOneInput"] | undefined,
	geo?: string | undefined,
	id: string,
	name?: string | undefined
};
	["BaseUpdateManyWithoutFleetInput"]: {
		connect: Array<GraphQLTypes["WhereRequiredIdInput"]>,
	create?: Array<GraphQLTypes["BaseCreateWithoutFleetInput"]> | undefined,
	delete: Array<GraphQLTypes["WhereRequiredIdInput"]>,
	disconnect: Array<GraphQLTypes["WhereRequiredIdInput"]>,
	set: Array<GraphQLTypes["WhereRequiredIdInput"]>
};
	["BaseWhereInput"]: {
		AND?: Array<GraphQLTypes["BaseWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["BaseWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["BaseWhereInput"]> | undefined,
	address?: GraphQLTypes["JsonFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	fleet?: GraphQLTypes["FleetWhereInput"] | undefined,
	fleetId?: GraphQLTypes["StringFilter"] | undefined,
	geo?: GraphQLTypes["JsonFilter"] | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined
};
	["BooleanFilter"]: {
		equals?: boolean | undefined,
	not?: GraphQLTypes["BooleanFilter"] | undefined
};
	["CalculateRouteInput"]: {
		pointA: GraphQLTypes["CoordinatesInput"],
	pointB: GraphQLTypes["CoordinatesInput"]
};
	["CancelJobInput"]: {
		canceledBy: GraphQLTypes["CanceledBy"],
	id: string
};
	["CanceledBy"]: CanceledBy;
	["CollisionsOrStackability"]: {
	__typename: "CollisionsOrStackability",
	isPlausible: boolean,
	isStackable: boolean,
	jobsOverlap: boolean
};
	["CompleteUserProfileInput"]: {
		firstName: string,
	key: string,
	lastName: string,
	password: string,
	phoneNumber?: string | undefined
};
	["ContactInput"]: {
		name?: string | undefined,
	phone: string
};
	["Coordinates"]: {
	__typename: "Coordinates",
	latitude: number,
	longitude: number
};
	["CoordinatesInput"]: {
		latitude: number,
	longitude: number
};
	["CreateUserResponse"]: {
	__typename: "CreateUserResponse",
	id: string,
	jwtToken: string
};
	/** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
["DateTime"]: "scalar" & { name: "DateTime" };
	["DateTimeFilter"]: {
		equals?: GraphQLTypes["DateTime"] | undefined,
	gt?: GraphQLTypes["DateTime"] | undefined,
	gte?: GraphQLTypes["DateTime"] | undefined,
	in?: Array<GraphQLTypes["DateTime"]> | undefined,
	lt?: GraphQLTypes["DateTime"] | undefined,
	lte?: GraphQLTypes["DateTime"] | undefined,
	not?: GraphQLTypes["DateTimeFilter"] | undefined,
	notIn?: Array<GraphQLTypes["DateTime"]> | undefined
};
	["DeliveryEstimate"]: {
	__typename: "DeliveryEstimate",
	costInPennies: number,
	dropoffCoordinates: GraphQLTypes["Coordinates"],
	pickupCoordinates: GraphQLTypes["Coordinates"]
};
	["DeliveryEstimateInput"]: {
		dropoffCoordinates: GraphQLTypes["CoordinatesInput"],
	fleetId: string,
	pickupCoordinates: GraphQLTypes["CoordinatesInput"]
};
	["DistanceUnit"]: DistanceUnit;
	["DistanceUnitFilter"]: {
		equals?: GraphQLTypes["DistanceUnit"] | undefined,
	in?: Array<GraphQLTypes["DistanceUnit"]> | undefined,
	not?: GraphQLTypes["DistanceUnit"] | undefined,
	notIn?: Array<GraphQLTypes["DistanceUnit"]> | undefined
};
	["Driver"]: {
	__typename: "Driver",
	allowJobRejection: boolean,
	allowStackingOrders: boolean,
	breaks?: GraphQLTypes["JSON"] | undefined,
	completedSignUp: boolean,
	costPerDistanceUnit: number,
	createdAt: GraphQLTypes["DateTime"],
	currentLocation?: GraphQLTypes["JSON"] | undefined,
	distanceUnit: GraphQLTypes["DistanceUnit"],
	email?: string | undefined,
	firstName?: string | undefined,
	fleetContact: GraphQLTypes["DriverFleetContact"],
	fleetDrivers?: Array<GraphQLTypes["FleetDriver"]> | undefined,
	fleetGroups: GraphQLTypes["JSON"],
	heading: number,
	id: string,
	inactiveStartingAt?: GraphQLTypes["DateTime"] | undefined,
	jobAssignments: Array<GraphQLTypes["JobAssignmentHistory"]>,
	jobs: Array<GraphQLTypes["Job"]>,
	lastName?: string | undefined,
	middleNames?: string | undefined,
	pauseNotifications: boolean,
	phoneNumber: string,
	pushToken?: string | undefined,
	/** Stores the associated sns endpoint ARN so the server can send push messages */
	snsEndpointARN?: string | undefined,
	speed: number,
	status: GraphQLTypes["DriverStatus"],
	title?: string | undefined,
	/** Synthetic field that counts the number of completed jobs for the current shift */
	todaysJobsCounter: number,
	/** Synthetic field that counts the distance covered for the completed jobs for the current shift */
	todaysMilesCounter: number,
	updatedAt: GraphQLTypes["DateTime"],
	vehicle?: GraphQLTypes["Vehicle"] | undefined
};
	["DriverConnectOneInput"]: {
		connect: GraphQLTypes["WhereRequiredIdInput"]
};
	["DriverConnectOrDisconnectInput"]: {
		connect?: GraphQLTypes["WhereRequiredIdInput"] | undefined,
	disconnect?: boolean | undefined
};
	["DriverConnectOrDisconnectOneInput"]: {
		connect?: GraphQLTypes["WhereRequiredIdInput"] | undefined,
	disconnect?: boolean | undefined
};
	["DriverCreateInput"]: {
		allowJobRejection?: boolean | undefined,
	allowStackingOrders?: boolean | undefined,
	completedSignUp?: boolean | undefined,
	costPerDistanceUnit?: number | undefined,
	currentLocation: string,
	distanceUnit?: GraphQLTypes["DistanceUnit"] | undefined,
	email?: string | undefined,
	firstName?: string | undefined,
	fleetGroups: string,
	fleetId: string,
	lastName?: string | undefined,
	middleNames?: string | undefined,
	phoneNumber: string,
	pushToken?: string | undefined,
	status: GraphQLTypes["DriverStatus"],
	title?: string | undefined,
	vehicle?: GraphQLTypes["VehicleCreateNestedOneWithoutDriverInput"] | undefined
};
	["DriverCreateNestedOneWithoutFleetDriversInput"]: {
		connect?: GraphQLTypes["WhereRequiredIdInput"] | undefined,
	create?: GraphQLTypes["DriverCreateWithoutFleetDriversInput"] | undefined
};
	["DriverCreateWithoutFleetDriversInput"]: {
		allowJobRejection?: boolean | undefined,
	allowStackingOrders?: boolean | undefined,
	completedSignUp?: boolean | undefined,
	costPerDistanceUnit?: number | undefined,
	currentLocation: string,
	distanceUnit?: GraphQLTypes["DistanceUnit"] | undefined,
	email?: string | undefined,
	firstName?: string | undefined,
	fleetGroups: string,
	lastName?: string | undefined,
	middelNames?: string | undefined,
	phoneNumber: string,
	pushToken?: string | undefined,
	status: GraphQLTypes["DriverStatus"],
	title?: string | undefined,
	vehicle?: GraphQLTypes["VehicleCreateNestedOneWithoutDriverInput"] | undefined
};
	["DriverFleetContact"]: {
	__typename: "DriverFleetContact",
	fleetContactPhone?: string | undefined,
	fleetName?: string | undefined
};
	["DriverInviteInput"]: {
		email?: string | undefined,
	firstName?: string | undefined,
	fleetId: string,
	lastName?: string | undefined,
	phoneNumber: string
};
	["DriverJobAssignmentStatus"]: DriverJobAssignmentStatus;
	/** Response from driver login */
["DriverLoginResponse"]: {
	__typename: "DriverLoginResponse",
	expireAt: GraphQLTypes["DateTime"],
	phoneNumber: string,
	token?: string | undefined
};
	["DriverOrderByInput"]: {
		allowJobRejection?: GraphQLTypes["SortOrder"] | undefined,
	allowStackingOrders?: GraphQLTypes["SortOrder"] | undefined,
	breaks?: GraphQLTypes["SortOrder"] | undefined,
	completedSignUp?: GraphQLTypes["SortOrder"] | undefined,
	costPerDistanceUnit?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	currentLocation?: GraphQLTypes["SortOrder"] | undefined,
	distanceUnit?: GraphQLTypes["SortOrder"] | undefined,
	email?: GraphQLTypes["SortOrder"] | undefined,
	firstName?: GraphQLTypes["SortOrder"] | undefined,
	fleetDrivers?: GraphQLTypes["FleetDriverOrderByRelationAggregateInput"] | undefined,
	fleetGroups?: GraphQLTypes["SortOrder"] | undefined,
	id?: GraphQLTypes["SortOrder"] | undefined,
	inactiveStartingAt?: GraphQLTypes["SortOrder"] | undefined,
	jobAssignments?: GraphQLTypes["JobAssignmentHistoryOrderByRelationAggregateInput"] | undefined,
	jobs?: GraphQLTypes["JobOrderByRelationAggregateInput"] | undefined,
	lastName?: GraphQLTypes["SortOrder"] | undefined,
	middleNames?: GraphQLTypes["SortOrder"] | undefined,
	phoneNumber?: GraphQLTypes["SortOrder"] | undefined,
	pushToken?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	vehicle?: GraphQLTypes["VehicleOrderByInput"] | undefined
};
	["DriverSetLocationInput"]: {
		currentLocation?: GraphQLTypes["LocationInput"] | undefined,
	id: string
};
	["DriverStatus"]: DriverStatus;
	["DriverUpdateDetailsInput"]: {
		allowJobRejection?: boolean | undefined,
	allowStackingOrders?: boolean | undefined,
	completedSignUp?: boolean | undefined,
	email?: string | undefined,
	firstName?: string | undefined,
	id: string,
	lastName?: string | undefined,
	middleNames?: string | undefined,
	phoneNumber?: string | undefined,
	title?: string | undefined
};
	["DriverUpdateInput"]: {
		allowJobRejection?: boolean | undefined,
	allowStackingOrders?: boolean | undefined,
	completedSignUp?: boolean | undefined,
	costPerDistanceUnit?: number | undefined,
	currentLocation?: GraphQLTypes["LocationInput"] | undefined,
	distanceUnit?: GraphQLTypes["DistanceUnit"] | undefined,
	email?: string | undefined,
	firstName?: string | undefined,
	fleetGroups?: string | undefined,
	fleetIds?: Array<string> | undefined,
	heading?: number | undefined,
	id: string,
	lastName?: string | undefined,
	middleNames?: string | undefined,
	phoneNumber?: string | undefined,
	pushToken?: string | undefined,
	speed?: number | undefined,
	status?: GraphQLTypes["DriverStatus"] | undefined,
	title?: string | undefined
};
	["DriverUpdatePushTokenInput"]: {
		id: string,
	isAppleDevice: boolean,
	pushToken: string
};
	["DriverWhereInput"]: {
		AND?: Array<GraphQLTypes["DriverWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["DriverWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["DriverWhereInput"]> | undefined,
	allowJobRejection?: GraphQLTypes["BooleanFilter"] | undefined,
	allowStackingOrders?: GraphQLTypes["BooleanFilter"] | undefined,
	breaks?: GraphQLTypes["JsonFilter"] | undefined,
	completedSignUp?: GraphQLTypes["BooleanFilter"] | undefined,
	costPerDistanceUnit?: number | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	currentLocation?: GraphQLTypes["JsonFilter"] | undefined,
	distanceUnit?: GraphQLTypes["DistanceUnit"] | undefined,
	email?: GraphQLTypes["StringFilter"] | undefined,
	firstName?: GraphQLTypes["StringFilter"] | undefined,
	fleetDrivers?: GraphQLTypes["FleetDriverListRelationFilter"] | undefined,
	fleetGroups?: GraphQLTypes["JsonFilter"] | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	inactiveStartingAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	jobAssignments?: GraphQLTypes["JobAssignmentHistoryListRelationFilter"] | undefined,
	jobs?: GraphQLTypes["JobListRelationFilter"] | undefined,
	lastName?: GraphQLTypes["StringFilter"] | undefined,
	middleNames?: GraphQLTypes["StringFilter"] | undefined,
	pauseNotifications?: GraphQLTypes["BooleanFilter"] | undefined,
	phoneNumber?: GraphQLTypes["StringFilter"] | undefined,
	pushToken?: string | undefined,
	status?: GraphQLTypes["DriverStatus"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	vehicle?: GraphQLTypes["VehicleWhereInput"] | undefined
};
	["DropOffTask"]: {
	__typename: "DropOffTask",
	ageVerification: boolean,
	/** Duration in seconds for a drop off to be completed */
	allocatedTime: number,
	createdAt: GraphQLTypes["DateTime"],
	dropOffContact?: GraphQLTypes["JSON"] | undefined,
	dropOffNotes?: string | undefined,
	id: string,
	job: GraphQLTypes["Job"],
	jobId: string,
	location: GraphQLTypes["JSON"],
	locationName: string,
	orderInStack: number,
	proofOfDelivery?: string | undefined,
	updatedAt: GraphQLTypes["DateTime"]
};
	["DropOffTaskCreateInput"]: {
		ageVerification?: boolean | undefined,
	allocatedTime?: number | undefined,
	dropOffContact?: GraphQLTypes["ContactInput"] | undefined,
	dropOffNotes?: string | undefined,
	job: GraphQLTypes["JobConnectOneInput"],
	location: GraphQLTypes["LocationCoordsInput"],
	locationName: string,
	orderInStack?: number | undefined,
	proofOfDelivery?: string | undefined
};
	/** Input required by JobCreateDirectInput */
["DropOffTaskInput"]: {
		ageVerification?: boolean | undefined,
	dropOffContact: GraphQLTypes["ContactInput"],
	dropOffNotes?: string | undefined,
	location: GraphQLTypes["LocationCoordsInput"],
	locationName: string,
	orderInStack?: number | undefined,
	proofOfDelivery?: string | undefined
};
	["DropOffTaskListRelationFilter"]: {
		every?: GraphQLTypes["DropOffTaskWhereInput"] | undefined,
	none?: GraphQLTypes["DropOffTaskWhereInput"] | undefined,
	some?: GraphQLTypes["DropOffTaskWhereInput"] | undefined
};
	["DropOffTaskOrderByInput"]: {
		ageVerification?: GraphQLTypes["SortOrder"] | undefined,
	allocatedTime?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	dropOffNotes?: GraphQLTypes["SortOrder"] | undefined,
	job?: GraphQLTypes["JobOrderByInput"] | undefined,
	locationName?: GraphQLTypes["SortOrder"] | undefined,
	orderInStack?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["DropOffTaskOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["DropOffTaskUpdateInput"]: {
		ageVerification?: boolean | undefined,
	dropOffContact?: GraphQLTypes["ContactInput"] | undefined,
	dropOffNotes?: string | undefined,
	id: string,
	job?: GraphQLTypes["JobConnectOneInput"] | undefined,
	location?: GraphQLTypes["LocationCoordsInput"] | undefined,
	locationName?: string | undefined,
	orderInStack?: number | undefined,
	proofOfDelivery?: string | undefined
};
	["DropOffTaskUpdateManyWithoutJobInput"]: {
		connect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	delete?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined
};
	["DropOffTaskUpdateProofOfDelivery"]: {
		id: string,
	proofOfDelivery: string
};
	["DropOffTaskWhereInput"]: {
		AND?: Array<GraphQLTypes["DropOffTaskWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["DropOffTaskWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["DropOffTaskWhereInput"]> | undefined,
	ageVerification?: boolean | undefined,
	allocatedTime?: number | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	dropOffNotes?: string | undefined,
	id?: string | undefined,
	job?: GraphQLTypes["JobWhereInput"] | undefined,
	jobId?: string | undefined,
	locationName?: string | undefined,
	orderInStack?: number | undefined,
	proofOfDelivery?: GraphQLTypes["JsonFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined
};
	["EligibleDriversForPickup"]: {
	__typename: "EligibleDriversForPickup",
	driverId: string,
	driverLocation: GraphQLTypes["Coordinates"],
	geographicalDistance: number,
	roadDistance?: number | undefined
};
	["EligibleDriversForPickupInput"]: {
		taskId: string,
	/** If left to true will return the closest drivers based on the geographical distabce rather than the road one */
	useGeographicalDistance?: boolean | undefined
};
	["Fleet"]: {
	__typename: "Fleet",
	bases: Array<GraphQLTypes["Base"]>,
	broadcastJob: boolean,
	contactEmail?: string | undefined,
	contactFullAddress?: string | undefined,
	contactFullName?: string | undefined,
	contactPhone?: string | undefined,
	createdAt: GraphQLTypes["DateTime"],
	/** What we pay the drivers. Default value. Can be overwriten individually for each driver */
	defaultCostPerDistanceUnit: number,
	distanceUnit: GraphQLTypes["DistanceUnit"],
	fleetDrivers: Array<GraphQLTypes["FleetDriver"]>,
	geo: GraphQLTypes["JSON"],
	id: string,
	implementations: Array<GraphQLTypes["ApiImplementation"]>,
	/** Duration in seconds a job waits to be accepted by a driver */
	jobAcceptWaitTime: number,
	/** Estimated duration in seconds for a drop off to be completed */
	jobDropOffAllocatedTime: number,
	jobs: Array<GraphQLTypes["Job"]>,
	/** Duration in minutes a job waits before commencing searching for a driver */
	leadTime: number,
	legalCookies?: string | undefined,
	legalPrivacy?: string | undefined,
	legalTerms?: string | undefined,
	name: string,
	outrightRejectJob: boolean,
	partner?: GraphQLTypes["Partner"] | undefined,
	partnerId: string,
	/** What we charge the customer */
	pricePerDistanceUnit: number,
	updatedAt: GraphQLTypes["DateTime"],
	users: Array<GraphQLTypes["User"]>,
	vehicles: Array<GraphQLTypes["Vehicle"]>,
	webhooks: Array<GraphQLTypes["Webhook"]>
};
	["FleetConnectOneInput"]: {
		connect: GraphQLTypes["WhereRequiredIdInput"]
};
	["FleetConnectOrDisconnectOneInput"]: {
		connect?: GraphQLTypes["WhereRequiredIdInput"] | undefined,
	disconnect?: boolean | undefined
};
	["FleetCreateInput"]: {
		bases?: GraphQLTypes["BaseCreateNestedWithoutFleetInput"] | undefined,
	broadcastJob?: boolean | undefined,
	contactEmail?: string | undefined,
	contactFullAddress?: string | undefined,
	contactFullName?: string | undefined,
	contactPhone?: string | undefined,
	defaultCostPerDistanceUnit?: number | undefined,
	distanceUnit?: GraphQLTypes["DistanceUnit"] | undefined,
	fleetDrivers?: GraphQLTypes["FleetDriverCreateNestedWithoutFleetInput"] | undefined,
	geo?: string | undefined,
	implementations?: GraphQLTypes["ApiImplementationCreateNestedManyWithoutFleetInput"] | undefined,
	jobAcceptWaitTime?: number | undefined,
	jobDropOffAllocatedTime?: number | undefined,
	leadTime: number,
	legalCookies?: string | undefined,
	legalPrivacy?: string | undefined,
	legalTerms?: string | undefined,
	name: string,
	outrightRejectJob?: boolean | undefined,
	partner: GraphQLTypes["PartnerCreateNestedOneWithoutFleetsInput"],
	pricePerDistanceUnit?: number | undefined,
	vehicles?: GraphQLTypes["VehicleCreateNestedWithoutOwnerFleetInput"] | undefined,
	webhooks?: GraphQLTypes["WebhookCreateNestedManyWithoutFleetInput"] | undefined
};
	["FleetCreateNestedManyWithoutPartnerInput"]: {
		connect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<GraphQLTypes["FleetCreateWithoutPartnerInput"]> | undefined
};
	["FleetCreateWithoutPartnerInput"]: {
		broadcastJob?: boolean | undefined,
	contactEmail?: string | undefined,
	contactFullAddress?: string | undefined,
	contactFullName?: string | undefined,
	contactPhone?: string | undefined,
	defaultCostPerDistanceUnit?: number | undefined,
	distanceUnit?: GraphQLTypes["DistanceUnit"] | undefined,
	geo: string,
	jobAcceptWaitTime?: number | undefined,
	jobDropOffAllocatedTime?: number | undefined,
	leadTime: number,
	legalCookies: string,
	legalPrivacy: string,
	legalTerms: string,
	name: string,
	outrightRejectJob?: boolean | undefined,
	pricePerDistanceUnit?: number | undefined
};
	["FleetDriver"]: {
	__typename: "FleetDriver",
	createdAt: GraphQLTypes["DateTime"],
	driver: GraphQLTypes["Driver"],
	driverId: string,
	fleet: GraphQLTypes["Fleet"],
	fleetId: string,
	id: string,
	updatedAt: GraphQLTypes["DateTime"]
};
	["FleetDriverCreateInput"]: {
		driver?: GraphQLTypes["DriverConnectOneInput"] | undefined,
	fleet: GraphQLTypes["FleetConnectOneInput"]
};
	["FleetDriverCreateNestedWithoutFleetInput"]: {
		connect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<GraphQLTypes["FleetDriverCreateWithoutFleetInput"]> | undefined
};
	["FleetDriverCreateWithoutDriverInput"]: {
		fleet: GraphQLTypes["FleetConnectOneInput"]
};
	["FleetDriverCreateWithoutFleetInput"]: {
		driver?: GraphQLTypes["DriverConnectOneInput"] | undefined
};
	["FleetDriverListRelationFilter"]: {
		every?: GraphQLTypes["FleetDriverWhereInput"] | undefined,
	none?: GraphQLTypes["FleetDriverWhereInput"] | undefined,
	some?: GraphQLTypes["FleetDriverWhereInput"] | undefined
};
	["FleetDriverOrderByInput"]: {
		createdAt?: GraphQLTypes["SortOrder"] | undefined,
	driver?: GraphQLTypes["DriverOrderByInput"] | undefined,
	driverId?: GraphQLTypes["SortOrder"] | undefined,
	fleet: GraphQLTypes["FleetOrderByInput"],
	fleetId?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["FleetDriverOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["FleetDriverUpdateInput"]: {
		driver?: GraphQLTypes["DriverConnectOrDisconnectOneInput"] | undefined,
	fleet?: GraphQLTypes["FleetConnectOrDisconnectOneInput"] | undefined,
	id: string
};
	["FleetDriverUpdateManyWithoutDriverInput"]: {
		connect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<GraphQLTypes["FleetDriverCreateWithoutDriverInput"]> | undefined,
	delete?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined
};
	["FleetDriverUpdateManyWithoutFleetInput"]: {
		connect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<GraphQLTypes["FleetDriverCreateWithoutFleetInput"]> | undefined,
	delete?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined
};
	["FleetDriverWhereInput"]: {
		AND?: Array<GraphQLTypes["FleetDriverWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["FleetDriverWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["FleetDriverWhereInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	driver?: GraphQLTypes["DriverWhereInput"] | undefined,
	driverId?: GraphQLTypes["StringFilter"] | undefined,
	fleet?: GraphQLTypes["FleetWhereInput"] | undefined,
	fleetId?: GraphQLTypes["StringFilter"] | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined
};
	["FleetListRelationFilter"]: {
		every?: GraphQLTypes["FleetWhereInput"] | undefined,
	none?: GraphQLTypes["FleetWhereInput"] | undefined,
	some?: GraphQLTypes["FleetWhereInput"] | undefined
};
	["FleetOrderByInput"]: {
		bases?: GraphQLTypes["BaseOrderByRelationAggregateInput"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	defaultCostPerDistanceUnit?: GraphQLTypes["SortOrder"] | undefined,
	distanceUnit?: GraphQLTypes["SortOrder"] | undefined,
	fleetDrivers?: GraphQLTypes["FleetDriverOrderByRelationAggregateInput"] | undefined,
	geo?: GraphQLTypes["SortOrder"] | undefined,
	id?: GraphQLTypes["SortOrder"] | undefined,
	implementations?: GraphQLTypes["ApiImplementationOrderByRelationAggregateInput"] | undefined,
	jobAcceptWaitTime?: GraphQLTypes["SortOrder"] | undefined,
	jobDropOffAllocatedTime?: GraphQLTypes["SortOrder"] | undefined,
	jobs?: GraphQLTypes["JobOrderByRelationAggregateInput"] | undefined,
	leadTime?: GraphQLTypes["SortOrder"] | undefined,
	legalCookies?: GraphQLTypes["SortOrder"] | undefined,
	legalPrivacy?: GraphQLTypes["SortOrder"] | undefined,
	legalTerms?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	partner?: GraphQLTypes["PartnerOrderByInput"] | undefined,
	partnerId?: GraphQLTypes["SortOrder"] | undefined,
	pricePerDistanceUnit?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	vehicles?: GraphQLTypes["VehicleOrderByRelationAggregateInput"] | undefined,
	webhooks?: GraphQLTypes["WebhookOrderByRelationAggregateInput"] | undefined
};
	["FleetOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["FleetUpdateInput"]: {
		bases?: GraphQLTypes["BaseUpdateManyWithoutFleetInput"] | undefined,
	broadcastJob?: boolean | undefined,
	contactEmail?: string | undefined,
	contactFullAddress?: string | undefined,
	contactFullName?: string | undefined,
	contactPhone?: string | undefined,
	defaultCostPerDistanceUnit?: number | undefined,
	distanceUnit?: GraphQLTypes["DistanceUnit"] | undefined,
	fleetDrivers?: GraphQLTypes["FleetDriverUpdateManyWithoutFleetInput"] | undefined,
	geo?: string | undefined,
	id: string,
	implementations?: GraphQLTypes["ApiImplementationUpdateManyWithoutFleetInput"] | undefined,
	jobAcceptWaitTime?: number | undefined,
	jobDropOffAllocatedTime?: number | undefined,
	leadTime?: number | undefined,
	legalCookies?: string | undefined,
	legalPrivacy?: string | undefined,
	legalTerms?: string | undefined,
	name?: string | undefined,
	outrightRejectJob?: boolean | undefined,
	pricePerDistanceUnit?: number | undefined,
	vehicles?: GraphQLTypes["VehicleUpdateManyWithoutOwnerFleetInput"] | undefined,
	webhooks?: GraphQLTypes["WebhookUpdateManyWithoutFleetInput"] | undefined
};
	["FleetUpdateManyWithoutPartnerInput"]: {
		connect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<GraphQLTypes["FleetCreateWithoutPartnerInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined
};
	["FleetWhereInput"]: {
		AND?: GraphQLTypes["FleetWhereInput"] | undefined,
	NOT?: GraphQLTypes["FleetWhereInput"] | undefined,
	OR?: GraphQLTypes["FleetWhereInput"] | undefined,
	bases?: GraphQLTypes["BaseListRelationFilter"] | undefined,
	broadcastJob?: GraphQLTypes["BooleanFilter"] | undefined,
	contactEmail?: GraphQLTypes["StringFilter"] | undefined,
	contactFullAddress?: GraphQLTypes["StringFilter"] | undefined,
	contactFullName?: GraphQLTypes["StringFilter"] | undefined,
	contactPhone?: GraphQLTypes["StringFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	defaultCostPerDistanceUnit?: GraphQLTypes["IntFilter"] | undefined,
	distanceUnit?: GraphQLTypes["DistanceUnitFilter"] | undefined,
	fleetDrivers?: GraphQLTypes["FleetDriverListRelationFilter"] | undefined,
	geo?: GraphQLTypes["JsonFilter"] | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	implementations?: GraphQLTypes["ApiImplementationListRelationFilter"] | undefined,
	jobAcceptWaitTime?: GraphQLTypes["IntFilter"] | undefined,
	jobDropOffAllocatedTime?: GraphQLTypes["IntFilter"] | undefined,
	jobs?: GraphQLTypes["JobListRelationFilter"] | undefined,
	leadTime?: GraphQLTypes["IntFilter"] | undefined,
	legalCookies?: GraphQLTypes["StringFilter"] | undefined,
	legalPrivacy?: GraphQLTypes["StringFilter"] | undefined,
	legalTerms?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	outrightRejectJob?: GraphQLTypes["BooleanFilter"] | undefined,
	partner?: GraphQLTypes["PartnerWhereInput"] | undefined,
	partnerId?: GraphQLTypes["StringFilter"] | undefined,
	pricePerDistanceUnit?: GraphQLTypes["IntFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	vehicles?: GraphQLTypes["VehicleListRelationFilter"] | undefined,
	webhooks?: GraphQLTypes["WebhookListRelationFilter"] | undefined
};
	["IntFilter"]: {
		equals?: number | undefined,
	gt?: number | undefined,
	gte?: number | undefined,
	in?: Array<number> | undefined,
	lt?: number | undefined,
	lte?: number | undefined,
	not?: GraphQLTypes["IntFilter"] | undefined,
	notIn?: Array<number> | undefined
};
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
["JSON"]: "scalar" & { name: "JSON" };
	["Job"]: {
	__typename: "Job",
	assignmentHistory: Array<GraphQLTypes["JobAssignmentHistory"]>,
	canceledBy?: GraphQLTypes["CanceledBy"] | undefined,
	completedByDriverFullName?: string | undefined,
	completedByDriverId?: string | undefined,
	completionTime?: GraphQLTypes["DateTime"] | undefined,
	/** Initially matches the initialCost but will change to reflect the cost of the journey's equivalent leg for stacked jobs */
	cost: number,
	createdAt: GraphQLTypes["DateTime"],
	/** Stored at creation time and cannot be updated */
	distanceUnit: GraphQLTypes["DistanceUnit"],
	driver?: GraphQLTypes["Driver"] | undefined,
	driverId?: string | undefined,
	dropOffTasks: Array<GraphQLTypes["DropOffTask"]>,
	estimatedDistanceMiles: number,
	estimatedDurationMinutes: number,
	estimatedPickupTime?: GraphQLTypes["DateTime"] | undefined,
	/** Flag used by the scheduler to continue looking for drivers or cancel jobs under certain conditions. Set to true once a driver manually accepts or rejects a job, or once the assignment loop finishes. */
	finishedAssignment: boolean,
	fleet: GraphQLTypes["Fleet"],
	fleetContactEmail?: string | undefined,
	fleetContactFullAddress?: string | undefined,
	fleetContactFullName?: string | undefined,
	fleetContactPhone?: string | undefined,
	fleetId: string,
	id: string,
	/** The ideal route, this is calculated at creation time and is what the customer is charged for. Never changes and is kept for reference */
	idealRoute?: GraphQLTypes["JSON"] | undefined,
	/** Stored at creation time and updates if the job is reassigned. This is what we might pay the driver */
	initialCost: number,
	/** The max amount of time until the job may be automatically rejected if the driver does not accept or reject manually */
	jobAcceptWaitTime: number,
	/** Holds notes related to the delivery completion */
	jobCompleteNotes?: string | undefined,
	/** Creates on demand a presigned URL that points to an image in S3 */
	jobCompletePhotoProof?: string | undefined,
	/** Flag that signals the existence of a pic. Internally, it spares a trip to the S3 bucket and generating a url */
	jobHasPhotoProof: boolean,
	/** Human readable reference for the job, used by drivers to contact dispatch */
	jobReference: string,
	onHold: boolean,
	orderContents: GraphQLTypes["JSON"],
	orderId: string,
	/** Flag that signals the order must assign a driver ASAP or reject the job */
	orderIsAsap: boolean,
	/** Human readable reference for the order, used by dispatch to contact the partner, e.g. Redbox */
	orderReference: string,
	pickupTasks: Array<GraphQLTypes["PickupTask"]>,
	pickupTime?: GraphQLTypes["DateTime"] | undefined,
	/** Stored at creation time and cannot be updated. This is what we charge the customer */
	price: number,
	reasonForCancellation?: string | undefined,
	/** The suggested route, might be simply a journey's leg when dealing with stacked jobs */
	route?: GraphQLTypes["JSON"] | undefined,
	stackId?: string | undefined,
	/** The Lowest common denominator of the job's statuses in the stack */
	stackStatus?: GraphQLTypes["JobStatus"] | undefined,
	status: GraphQLTypes["JobStatus"],
	updatedAt: GraphQLTypes["DateTime"]
};
	["JobAssignmentHistory"]: {
	__typename: "JobAssignmentHistory",
	autoRejectAt?: GraphQLTypes["DateTime"] | undefined,
	createdAt: GraphQLTypes["DateTime"],
	driver: GraphQLTypes["Driver"],
	driverId: string,
	id: string,
	job: GraphQLTypes["Job"],
	jobId: string,
	status: GraphQLTypes["DriverJobAssignmentStatus"],
	updatedAt: GraphQLTypes["DateTime"]
};
	["JobAssignmentHistoryCreateInput"]: {
		autoRejectAt?: string | undefined,
	driver: GraphQLTypes["DriverConnectOneInput"],
	job: GraphQLTypes["JobConnectOneInput"],
	status: GraphQLTypes["DriverJobAssignmentStatus"]
};
	["JobAssignmentHistoryListRelationFilter"]: {
		every?: GraphQLTypes["JobAssignmentHistoryWhereInput"] | undefined,
	none?: GraphQLTypes["JobAssignmentHistoryWhereInput"] | undefined,
	some?: GraphQLTypes["JobAssignmentHistoryWhereInput"] | undefined
};
	["JobAssignmentHistoryOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["JobAssignmentHistoryUpdateInput"]: {
		autoRejectAt?: string | undefined,
	driver?: GraphQLTypes["DriverConnectOneInput"] | undefined,
	id: string,
	job?: GraphQLTypes["JobConnectOneInput"] | undefined,
	status?: GraphQLTypes["DriverJobAssignmentStatus"] | undefined
};
	["JobAssignmentHistoryUpdateManyWithoutDriverInput"]: {
		connect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined
};
	["JobAssignmentHistoryUpdateManyWithoutJobInput"]: {
		connect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	delete?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined
};
	["JobAssignmentHistoryWhereInput"]: {
		AND?: Array<GraphQLTypes["JobAssignmentHistoryWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["JobAssignmentHistoryWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["JobAssignmentHistoryWhereInput"]> | undefined,
	autoRejectAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	driver?: GraphQLTypes["DriverWhereInput"] | undefined,
	driverId?: string | undefined,
	id?: string | undefined,
	job?: GraphQLTypes["JobWhereInput"] | undefined,
	jobId?: string | undefined,
	status?: GraphQLTypes["DriverJobAssignmentStatus"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined
};
	["JobAssignmentOrderByInput"]: {
		autoRejectAt?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	driver?: GraphQLTypes["DriverOrderByInput"] | undefined,
	job?: GraphQLTypes["JobOrderByInput"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["JobConnectOneInput"]: {
		connect: GraphQLTypes["WhereRequiredIdInput"]
};
	["JobCreateDirectInput"]: {
		dropOffTask: GraphQLTypes["DropOffTaskInput"],
	estimatedPickupTime?: GraphQLTypes["DateTime"] | undefined,
	fleetId: string,
	orderContents: Array<string>,
	orderId: string,
	orderIsAsap: boolean,
	orderReference: string,
	pickupTask: GraphQLTypes["PickupTaskInput"],
	/** This will be calculated based on the route */
	price?: number | undefined
};
	["JobListRelationFilter"]: {
		every?: GraphQLTypes["JobWhereInput"] | undefined,
	none?: GraphQLTypes["JobWhereInput"] | undefined,
	some?: GraphQLTypes["JobWhereInput"] | undefined
};
	["JobOrderByInput"]: {
		assignmentHistory?: GraphQLTypes["JobAssignmentHistoryOrderByRelationAggregateInput"] | undefined,
	canceledBy?: GraphQLTypes["SortOrder"] | undefined,
	completedByDriverFullName?: GraphQLTypes["SortOrder"] | undefined,
	completedByDriverId?: GraphQLTypes["SortOrder"] | undefined,
	completionTime?: GraphQLTypes["SortOrder"] | undefined,
	cost?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	distanceUnit?: GraphQLTypes["SortOrder"] | undefined,
	driver?: GraphQLTypes["DriverOrderByInput"] | undefined,
	dropOffTasks?: GraphQLTypes["DropOffTaskOrderByRelationAggregateInput"] | undefined,
	estimatedDistanceMiles?: GraphQLTypes["SortOrder"] | undefined,
	estimatedDurationMinutes?: GraphQLTypes["SortOrder"] | undefined,
	estimatedPickupTime?: GraphQLTypes["SortOrder"] | undefined,
	finishedAssignment?: GraphQLTypes["SortOrder"] | undefined,
	fleet?: GraphQLTypes["FleetOrderByInput"] | undefined,
	initialCost?: GraphQLTypes["SortOrder"] | undefined,
	jobAcceptWaitTime?: GraphQLTypes["SortOrder"] | undefined,
	jobCompleteNotes?: GraphQLTypes["SortOrder"] | undefined,
	jobReference?: GraphQLTypes["SortOrder"] | undefined,
	onHold?: GraphQLTypes["SortOrder"] | undefined,
	orderContents?: GraphQLTypes["SortOrder"] | undefined,
	orderId?: GraphQLTypes["SortOrder"] | undefined,
	orderIsAsap?: GraphQLTypes["SortOrder"] | undefined,
	orderReference?: GraphQLTypes["SortOrder"] | undefined,
	pickupTasks?: GraphQLTypes["PickupTaskOrderByRelationAggregateInput"] | undefined,
	pickupTime?: GraphQLTypes["SortOrder"] | undefined,
	price?: GraphQLTypes["SortOrder"] | undefined,
	stackId?: GraphQLTypes["SortOrder"] | undefined,
	stackStatus?: GraphQLTypes["SortOrder"] | undefined,
	status?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["JobOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["JobStatus"]: JobStatus;
	["JobStatusFilter"]: {
		equals?: GraphQLTypes["JobStatus"] | undefined,
	in?: Array<GraphQLTypes["JobStatus"]> | undefined,
	not?: GraphQLTypes["JobStatus"] | undefined,
	notIn?: Array<GraphQLTypes["JobStatus"]> | undefined
};
	["JobUpdateInput"]: {
		assignmentHistory?: GraphQLTypes["JobAssignmentHistoryUpdateManyWithoutJobInput"] | undefined,
	canceledBy?: GraphQLTypes["CanceledBy"] | undefined,
	completionTime?: GraphQLTypes["DateTime"] | undefined,
	cost?: number | undefined,
	driver?: GraphQLTypes["DriverConnectOrDisconnectInput"] | undefined,
	dropOffTasks?: GraphQLTypes["DropOffTaskUpdateManyWithoutJobInput"] | undefined,
	estimatedDistanceMiles?: number | undefined,
	estimatedDurationMinutes?: number | undefined,
	estimatedPickupTime?: GraphQLTypes["DateTime"] | undefined,
	finishedAssignment?: boolean | undefined,
	fleet?: GraphQLTypes["FleetConnectOneInput"] | undefined,
	id: string,
	initialCost?: number | undefined,
	initialRoute?: string | undefined,
	jobAcceptWaitTime?: number | undefined,
	onHold?: boolean | undefined,
	orderContents?: Array<string> | undefined,
	orderIsAsap?: boolean | undefined,
	orderReference?: string | undefined,
	pickupTasks?: GraphQLTypes["PickupTaskUpdateManyWithoutJobInput"] | undefined,
	pickupTime?: GraphQLTypes["DateTime"] | undefined,
	reasonForCancellation?: string | undefined,
	route?: string | undefined,
	stackId?: string | undefined,
	status?: GraphQLTypes["JobStatus"] | undefined
};
	["JobUpdateManyWithoutDriverInput"]: {
		connect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined
};
	["JobWhereInput"]: {
		AND?: Array<GraphQLTypes["JobWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["JobWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["JobWhereInput"]> | undefined,
	assignmentHistory?: GraphQLTypes["JobAssignmentHistoryListRelationFilter"] | undefined,
	canceledBy?: GraphQLTypes["CanceledBy"] | undefined,
	completedByDriverFullName?: GraphQLTypes["StringFilter"] | undefined,
	completedByDriverId?: GraphQLTypes["StringFilter"] | undefined,
	completionTime?: GraphQLTypes["DateTimeFilter"] | undefined,
	cost?: number | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	distanceUnit?: GraphQLTypes["DistanceUnitFilter"] | undefined,
	driver?: GraphQLTypes["DriverWhereInput"] | undefined,
	driverId?: GraphQLTypes["StringFilter"] | undefined,
	dropOffTasks?: GraphQLTypes["DropOffTaskListRelationFilter"] | undefined,
	estimatedDistanceMiles?: number | undefined,
	estimatedDurationMinutes?: number | undefined,
	estimatedPickupTime?: GraphQLTypes["DateTimeFilter"] | undefined,
	finishedAssignment?: boolean | undefined,
	fleet?: GraphQLTypes["FleetWhereInput"] | undefined,
	fleetId?: GraphQLTypes["StringFilter"] | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	initialCost?: number | undefined,
	jobAcceptWaitTime?: number | undefined,
	jobCompleteNotes?: GraphQLTypes["StringFilter"] | undefined,
	jobReference?: GraphQLTypes["StringFilter"] | undefined,
	onHold?: boolean | undefined,
	orderContents?: GraphQLTypes["JsonFilter"] | undefined,
	orderId?: GraphQLTypes["StringFilter"] | undefined,
	orderIsAsap?: boolean | undefined,
	orderReference?: GraphQLTypes["StringFilter"] | undefined,
	pickupTasks?: GraphQLTypes["PickupTaskListRelationFilter"] | undefined,
	pickupTime?: GraphQLTypes["DateTimeFilter"] | undefined,
	price?: number | undefined,
	stackId?: GraphQLTypes["StringFilter"] | undefined,
	stackStatus?: GraphQLTypes["JobStatusFilter"] | undefined,
	status?: GraphQLTypes["JobStatusFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined
};
	["JsonFilter"]: {
		equals?: string | undefined,
	not?: string | undefined
};
	["LocationCoordsInput"]: {
		address: GraphQLTypes["AddressInput"],
	latitude: number,
	longitude: number
};
	["LocationInput"]: {
		latitude: number,
	longitude: number
};
	["MinimalNode"]: {
	__typename: "MinimalNode",
	id: string,
	name: string
};
	["Mutation"]: {
	__typename: "Mutation",
	acceptJob?: GraphQLTypes["Job"] | undefined,
	assignJobToDriver: GraphQLTypes["Job"],
	/** Starts the assignment loop. Runs periodically */
	assignJobsScheduler: string,
	/** Transitions drivers to the correct status based on their booked breaks if they are not already in the correct status. Runs periodically */
	autoTransitionDriversStatusScheduler?: string | undefined,
	/** Transitions jobs to travelling-to-pickup status based on estimated pickup time and current driver position. Runs periodically */
	autoTravelToPickupScheduler: string,
	cancelJob: GraphQLTypes["Job"],
	/** Cancels jobs that are in the exception queue. It only does so for fleets that allow automated job cancelling. */
	cancelJobsScheduler: string,
	/** Mutation to complete a job, dedicated for drivers. Returns an array of 1 to 3 jobs */
	completeJob: Array<GraphQLTypes["Job"]>,
	/** Used by the user after receiving an invite */
	completeUserProfile?: string | undefined,
	/** Continues assigning jobs if the assignment loop did not finish. Runs periodically */
	continueJobsAssignmentScheduler: string,
	createApiImplementation?: GraphQLTypes["ApiImplementation"] | undefined,
	createBase?: GraphQLTypes["Base"] | undefined,
	createDropOffTask?: GraphQLTypes["DropOffTask"] | undefined,
	createFleet?: GraphQLTypes["Fleet"] | undefined,
	createFleetDriver?: GraphQLTypes["FleetDriver"] | undefined,
	/** Creates a job with a single pickup and drop off task or if a job with a similar pickup exists already,
      it stacks up to three dropoffs under certain conditions.
      Calculates and stores the price and cost based on the fleet settings */
	createJob: GraphQLTypes["Job"],
	createJobAssignmentHistory: GraphQLTypes["JobAssignmentHistory"],
	createPartner?: GraphQLTypes["Partner"] | undefined,
	createPickupTask?: GraphQLTypes["PickupTask"] | undefined,
	createPlatform?: GraphQLTypes["Platform"] | undefined,
	createUser: GraphQLTypes["CreateUserResponse"],
	createWebhook?: GraphQLTypes["Webhook"] | undefined,
	deleteApiImplementation?: GraphQLTypes["ApiImplementation"] | undefined,
	/** Due to possible side-effects this can only be triggered by admins for now */
	deleteDriver?: GraphQLTypes["Driver"] | undefined,
	deleteJob: GraphQLTypes["Job"],
	/** Can only be used by admins. Disabled in production. */
	deleteUser?: GraphQLTypes["User"] | undefined,
	deleteWebhook?: GraphQLTypes["Webhook"] | undefined,
	driverLogin: GraphQLTypes["DriverLoginResponse"],
	/** Dedicated mutation that is only triggered by the driver app when the driver logs out. Has a side effect of pausing notifications for the driver. */
	driverLogout: string,
	inviteDriver?: GraphQLTypes["Driver"] | undefined,
	inviteUser?: string | undefined,
	/** Returns a token if the login was successful */
	login?: string | undefined,
	notifyDriver?: boolean | undefined,
	rejectJob?: GraphQLTypes["Job"] | undefined,
	/** Removes a status option from a driver */
	removeDriverStatusOption: boolean,
	/** Sends an email containing a link or a token but DOES NOT mutate the password. That step is reserved for the subsequent 'resetPassword' mutation */
	requestPasswordReset?: string | undefined,
	/** Updates the user password. Returns the state of the mutation */
	resetPassword?: string | undefined,
	setDriverStatusFromOption: boolean,
	updateApiImplementation?: GraphQLTypes["ApiImplementation"] | undefined,
	updateBase?: GraphQLTypes["Base"] | undefined,
	updateDriver?: GraphQLTypes["Driver"] | undefined,
	/** Ideally used by drivers to complete their details */
	updateDriverDetails?: GraphQLTypes["Driver"] | undefined,
	updateDriverLocation?: string | undefined,
	updateDropOffTask?: GraphQLTypes["DropOffTask"] | undefined,
	updateDropOffTaskProofOfDelivery?: GraphQLTypes["DropOffTask"] | undefined,
	updateFleet: GraphQLTypes["Fleet"],
	updateFleetDriver: GraphQLTypes["FleetDriver"],
	updateJob: GraphQLTypes["Job"],
	updateJobAssignmentHistory: GraphQLTypes["JobAssignmentHistory"],
	updateJobPickupTime?: GraphQLTypes["Job"] | undefined,
	updateJobStatus: GraphQLTypes["Job"],
	updatePartner?: GraphQLTypes["Partner"] | undefined,
	/** Returns a new token if the update was successful */
	updatePassword?: string | undefined,
	updatePickupTask?: GraphQLTypes["PickupTask"] | undefined,
	updatePlatform?: GraphQLTypes["Platform"] | undefined,
	updatePushToken?: GraphQLTypes["Driver"] | undefined,
	updateUser: GraphQLTypes["User"],
	updateWebhook?: GraphQLTypes["Webhook"] | undefined,
	/** Uploads a photo to S3 and then stores the path to it on the Job node */
	uploadJobCompleteProof: boolean,
	verifyPhone: GraphQLTypes["VerifyPhoneResponse"]
};
	["NotifyDriverInput"]: {
		body: string,
	data: GraphQLTypes["JSON"],
	driverId: string,
	subtitle?: string | undefined,
	title: string
};
	["OptionForDriverStatus"]: {
	__typename: "OptionForDriverStatus",
	booked: boolean,
	durationInMinutes?: number | undefined,
	id: string,
	label: string,
	maxStartTime?: string | undefined,
	minStartTime?: string | undefined,
	minimalLabel: string,
	status: string
};
	["PaginatedJobs"]: {
	__typename: "PaginatedJobs",
	count: number,
	jobs: Array<GraphQLTypes["Job"]>
};
	["Partner"]: {
	__typename: "Partner",
	createdAt: GraphQLTypes["DateTime"],
	fleets: Array<GraphQLTypes["Fleet"]>,
	id: string,
	name: string,
	platform?: GraphQLTypes["Platform"] | undefined,
	platformId: string,
	updatedAt: GraphQLTypes["DateTime"],
	users: Array<GraphQLTypes["User"]>
};
	["PartnerConnectOrDisconnectInput"]: {
		connect?: GraphQLTypes["WhereRequiredIdInput"] | undefined,
	disconnect?: GraphQLTypes["WhereRequiredIdInput"] | undefined
};
	["PartnerCreateInput"]: {
		name: string,
	platform: GraphQLTypes["PlatformConnectById"]
};
	["PartnerCreateNestedOneWithoutFleetsInput"]: {
		connect?: GraphQLTypes["WhereRequiredIdInput"] | undefined,
	create?: GraphQLTypes["PartnerCreateInput"] | undefined
};
	["PartnerCreateWithoutPlatformInput"]: {
		fleets?: GraphQLTypes["FleetCreateNestedManyWithoutPartnerInput"] | undefined,
	name: string
};
	["PartnerListRelationFilter"]: {
		every?: GraphQLTypes["PartnerWhereInput"] | undefined,
	none?: GraphQLTypes["PartnerWhereInput"] | undefined,
	some?: GraphQLTypes["PartnerWhereInput"] | undefined
};
	["PartnerOrderByInput"]: {
		createdAt?: GraphQLTypes["SortOrder"] | undefined,
	fleets?: GraphQLTypes["FleetOrderByRelationAggregateInput"] | undefined,
	id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	partners?: GraphQLTypes["PartnerOrderByRelationAggregateInput"] | undefined,
	platform?: GraphQLTypes["PlatformOrderByInput"] | undefined,
	platformId?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["PartnerOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["PartnerUpdateInput"]: {
		fleets?: GraphQLTypes["FleetUpdateManyWithoutPartnerInput"] | undefined,
	id: string,
	name?: string | undefined
};
	["PartnerUpdatemanyWithoutPlatformInput"]: {
		connect?: Array<GraphQLTypes["PartnerWhereUniqueInput"]> | undefined,
	delete?: Array<GraphQLTypes["PartnerWhereUniqueInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["PartnerWhereUniqueInput"]> | undefined
};
	["PartnerWhereInput"]: {
		AND?: Array<GraphQLTypes["PartnerWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PartnerWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PartnerWhereInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	fleets?: GraphQLTypes["FleetListRelationFilter"] | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	platform?: GraphQLTypes["PlatformWhereInput"] | undefined,
	platformId?: GraphQLTypes["StringFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined
};
	["PartnerWhereUniqueInput"]: {
		id?: string | undefined
};
	["PickupTask"]: {
	__typename: "PickupTask",
	createdAt: GraphQLTypes["DateTime"],
	id: string,
	job: GraphQLTypes["Job"],
	jobId: string,
	location: GraphQLTypes["JSON"],
	locationHashed: string,
	locationName: string,
	pickupContact?: GraphQLTypes["JSON"] | undefined,
	pickupNotes?: string | undefined,
	updatedAt: GraphQLTypes["DateTime"]
};
	["PickupTaskCreateInput"]: {
		job: GraphQLTypes["JobConnectOneInput"],
	location: GraphQLTypes["LocationCoordsInput"],
	locationName: string,
	pickupContact?: GraphQLTypes["ContactInput"] | undefined,
	pickupNotes?: string | undefined
};
	/** Input required by JobCreateDirectInput */
["PickupTaskInput"]: {
		location: GraphQLTypes["LocationCoordsInput"],
	locationName: string,
	pickupContact: GraphQLTypes["ContactInput"],
	pickupNotes?: string | undefined
};
	["PickupTaskListRelationFilter"]: {
		every?: GraphQLTypes["PickupTaskWhereInput"] | undefined,
	none?: GraphQLTypes["PickupTaskWhereInput"] | undefined,
	some?: GraphQLTypes["PickupTaskWhereInput"] | undefined
};
	["PickupTaskOrderByInput"]: {
		createdAt?: GraphQLTypes["SortOrder"] | undefined,
	job?: GraphQLTypes["JobOrderByInput"] | undefined,
	locationHashed?: GraphQLTypes["SortOrder"] | undefined,
	locationName?: GraphQLTypes["SortOrder"] | undefined,
	pickupNotes?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["PickupTaskOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["PickupTaskUpdateInput"]: {
		id: string,
	job?: GraphQLTypes["JobConnectOneInput"] | undefined,
	location?: GraphQLTypes["LocationCoordsInput"] | undefined,
	locationName?: string | undefined,
	pickupContact?: GraphQLTypes["ContactInput"] | undefined,
	pickupNotes?: string | undefined,
	proofOfDelivery?: string | undefined
};
	["PickupTaskUpdateManyWithoutJobInput"]: {
		connect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	delete?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined
};
	["PickupTaskWhereInput"]: {
		AND?: Array<GraphQLTypes["PickupTaskWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PickupTaskWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PickupTaskWhereInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	id?: string | undefined,
	job?: GraphQLTypes["JobWhereInput"] | undefined,
	jobId?: string | undefined,
	locationHashed?: string | undefined,
	locationName?: string | undefined,
	pickupNotes?: string | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined
};
	["Platform"]: {
	__typename: "Platform",
	createdAt: GraphQLTypes["DateTime"],
	id: string,
	name: string,
	partners: Array<GraphQLTypes["Partner"]>,
	updatedAt: GraphQLTypes["DateTime"]
};
	["PlatformConnectById"]: {
		connect: GraphQLTypes["WhereRequiredIdInput"]
};
	["PlatformCreateInput"]: {
		name: string,
	partners?: GraphQLTypes["PlatformCreateOrConnectPartnerInput"] | undefined
};
	["PlatformCreateOrConnectPartnerInput"]: {
		connect?: Array<string> | undefined,
	create?: Array<GraphQLTypes["PartnerCreateWithoutPlatformInput"]> | undefined
};
	["PlatformOrderByInput"]: {
		createdAt?: GraphQLTypes["SortOrder"] | undefined,
	id?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	partners?: GraphQLTypes["PartnerOrderByRelationAggregateInput"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["PlatformUpdateInput"]: {
		id: string,
	name?: string | undefined,
	partners?: GraphQLTypes["PartnerUpdatemanyWithoutPlatformInput"] | undefined
};
	["PlatformWhereInput"]: {
		AND?: Array<GraphQLTypes["PlatformWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["PlatformWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["PlatformWhereInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	partners?: GraphQLTypes["PartnerListRelationFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined
};
	["Query"]: {
	__typename: "Query",
	apiImplementation?: GraphQLTypes["ApiImplementation"] | undefined,
	apiImplementations: Array<GraphQLTypes["ApiImplementation"]>,
	assignedJobs: Array<GraphQLTypes["Job"]>,
	/** Returns an array of available nodes the current user can grant access to new users.
      It only returns the highest level permissions for the desired role.
      For example, if the desired role is "PARTNER_USER" role, it will return a list of partner ids. */
	availableNodesByUserAndDesiredRole: Array<GraphQLTypes["MinimalNode"]>,
	/** Returns an array of available roles the current user can grant to new users */
	availableRolesByUser: Array<GraphQLTypes["UserRole"]>,
	base?: GraphQLTypes["Base"] | undefined,
	bases: Array<GraphQLTypes["Base"]>,
	/** Returns an array of booked and available options the current driver can change to */
	bookedAndAvailableOptionsForDriverStatus: Array<GraphQLTypes["OptionForDriverStatus"]>,
	calculateRoute?: string | undefined,
	/** Returns the collisions, stackability and plausability state between the driver's current jobs and a new one */
	collisionsOrStackability?: GraphQLTypes["CollisionsOrStackability"] | undefined,
	currentDriver?: GraphQLTypes["Driver"] | undefined,
	/** Returns an array of assigned, not on hold and en-route jobs */
	currentJobs?: Array<GraphQLTypes["Job"]> | undefined,
	currentUser: GraphQLTypes["User"],
	deliveryEstimate?: GraphQLTypes["DeliveryEstimate"] | undefined,
	driver?: GraphQLTypes["Driver"] | undefined,
	drivers?: Array<GraphQLTypes["Driver"]> | undefined,
	dropOffTask?: GraphQLTypes["DropOffTask"] | undefined,
	dropOffTasks: Array<GraphQLTypes["DropOffTask"]>,
	/** Returns the first 3 drivers closest to a pickup, in ascending order of distance */
	eligibleDriversForPickup: Array<GraphQLTypes["EligibleDriversForPickup"]>,
	/** Returns jobs that were not assigned and require a human operator */
	exceptionQueue: Array<GraphQLTypes["Job"]>,
	fleet?: GraphQLTypes["Fleet"] | undefined,
	fleetDriver?: GraphQLTypes["FleetDriver"] | undefined,
	fleetDrivers?: Array<GraphQLTypes["FleetDriver"]> | undefined,
	fleets: Array<GraphQLTypes["Fleet"]>,
	job?: GraphQLTypes["Job"] | undefined,
	jobAssignmentHistories: Array<GraphQLTypes["JobAssignmentHistory"]>,
	jobAssignmentHistory?: GraphQLTypes["JobAssignmentHistory"] | undefined,
	/** Returns jobs. If no filters are passed it will only return jobs relevant for today UTC */
	jobs: Array<GraphQLTypes["Job"]>,
	/** Returns the jobs and an additional field count. If no filters are passed it will only return jobs relevant for today UTC */
	paginatedJobs: GraphQLTypes["PaginatedJobs"],
	partner?: GraphQLTypes["Partner"] | undefined,
	partners: Array<GraphQLTypes["Partner"]>,
	/** Returns an array of jobs, the first one needs to be accepted/rejected while the rest are simply completing the stack */
	pendingJobAndStack?: Array<GraphQLTypes["Job"]> | undefined,
	pickupTask?: GraphQLTypes["PickupTask"] | undefined,
	pickupTasks: Array<GraphQLTypes["PickupTask"]>,
	platform?: GraphQLTypes["Platform"] | undefined,
	platforms: Array<GraphQLTypes["Platform"]>,
	timeConnectedInMs?: string | undefined,
	userByEmail?: GraphQLTypes["User"] | undefined,
	userById?: GraphQLTypes["User"] | undefined,
	users: Array<GraphQLTypes["User"]>,
	vehicle?: GraphQLTypes["Vehicle"] | undefined,
	vehicles?: Array<GraphQLTypes["Vehicle"]> | undefined,
	webhook?: GraphQLTypes["Webhook"] | undefined,
	webhooks: Array<GraphQLTypes["Webhook"]>
};
	["SortOrder"]: SortOrder;
	["StringFilter"]: {
		contains?: string | undefined,
	endsWith?: string | undefined,
	equals?: string | undefined,
	gt?: string | undefined,
	gte?: string | undefined,
	in?: Array<string> | undefined,
	lt?: string | undefined,
	lte?: string | undefined,
	not?: GraphQLTypes["StringFilter"] | undefined,
	notIn?: Array<string> | undefined,
	startsWith?: string | undefined
};
	["Subscription"]: {
	__typename: "Subscription",
	assignedJobs: Array<GraphQLTypes["Job"]>,
	currentDriver?: GraphQLTypes["Driver"] | undefined,
	/** Returns an array of assigned, not on hold and en-route jobs */
	currentJobs?: Array<GraphQLTypes["Job"]> | undefined,
	driver?: GraphQLTypes["Driver"] | undefined,
	drivers?: Array<GraphQLTypes["Driver"]> | undefined,
	job?: GraphQLTypes["Job"] | undefined,
	/** Returns jobs. If no filters are passed it will only return jobs relevant for today UTC */
	jobs: Array<GraphQLTypes["Job"]>,
	/** Returns the jobs and an additional field count. If no filters are passed it will only return jobs relevant for today UTC */
	paginatedJobs: GraphQLTypes["PaginatedJobs"],
	/** Returns an array of jobs, the first one needs to be accepted/rejected while the rest are simply completing the stack */
	pendingJobAndStack?: Array<GraphQLTypes["Job"]> | undefined
};
	["TimeConnectedInMsInput"]: {
		/** If not provided the query will default to the user on the context */
	driverId?: string | undefined,
	/** Optional parameter that marks the start of day timestamp in ms for the driver. It helps with addressing time zone variants */
	startOfDayTimestamp?: number | undefined
};
	["UpdateJobPickupTimeInput"]: {
		estimatedPickupTime?: GraphQLTypes["DateTime"] | undefined,
	id: string
};
	["UpdateJobStatusInput"]: {
		id: string,
	status: GraphQLTypes["JobStatus"]
};
	["UploadJobCompleteProofInput"]: {
		jobCompleteNotes?: string | undefined,
	jobCompletePhotoProof?: string | undefined,
	jobId: string
};
	["User"]: {
	__typename: "User",
	createdAt: GraphQLTypes["DateTime"],
	email: string,
	enabled: boolean,
	firstName: string,
	fleets: Array<GraphQLTypes["Fleet"]>,
	id: string,
	lastName: string,
	partners: Array<GraphQLTypes["Partner"]>,
	/** The hashed password */
	password?: string | undefined,
	phoneNumber: string,
	role: string,
	updatedAt: GraphQLTypes["DateTime"],
	userStatus: string
};
	["UserConnectInput"]: {
		connect?: GraphQLTypes["WhereRequiredIdInput"] | undefined
};
	["UserCreateInput"]: {
		email: string,
	firstName: string,
	lastName: string,
	partners?: GraphQLTypes["PartnerConnectOrDisconnectInput"] | undefined,
	password: string,
	phoneNumber: string,
	role: GraphQLTypes["UserRole"],
	userStatus: GraphQLTypes["UserStatus"]
};
	["UserCreateNewPasswordInput"]: {
		password: string,
	token: string
};
	["UserInviteInput"]: {
		email: string,
	/** The highest level ids of nodes the user has access to. E.g. - a partner-user should get a list of partnerIds to connect */
	nodes?: Array<string> | undefined,
	role: GraphQLTypes["UserRole"]
};
	["UserLoginInput"]: {
		email: string,
	password: string
};
	["UserOrderByInput"]: {
		createdAt?: GraphQLTypes["SortOrder"] | undefined,
	email?: GraphQLTypes["SortOrder"] | undefined,
	enabled?: GraphQLTypes["SortOrder"] | undefined,
	firstName?: GraphQLTypes["SortOrder"] | undefined,
	id?: GraphQLTypes["SortOrder"] | undefined,
	lastName?: GraphQLTypes["SortOrder"] | undefined,
	phoneNumber?: GraphQLTypes["SortOrder"] | undefined,
	role?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	userStatus?: GraphQLTypes["SortOrder"] | undefined
};
	["UserResetPasswordInput"]: {
		key: string,
	newPassword: string
};
	["UserRole"]: UserRole;
	["UserRoleFilter"]: {
		equals?: GraphQLTypes["UserRole"] | undefined,
	in?: Array<GraphQLTypes["UserRole"]> | undefined,
	not?: GraphQLTypes["UserRoleFilter"] | undefined,
	notIn?: Array<GraphQLTypes["UserRole"]> | undefined
};
	["UserStatus"]: UserStatus;
	["UserStatusFilter"]: {
		equals?: GraphQLTypes["UserStatus"] | undefined,
	in?: Array<GraphQLTypes["UserStatus"]> | undefined,
	not?: GraphQLTypes["UserStatusFilter"] | undefined,
	notIn?: Array<GraphQLTypes["UserStatus"]> | undefined
};
	["UserUpdateInput"]: {
		email?: string | undefined,
	enabled?: boolean | undefined,
	firstName?: string | undefined,
	id: string,
	lastName?: string | undefined,
	/** The highest level ids of nodes the user has access to. It connects and disconnects nodes according to this array if passed in. */
	nodes?: Array<string> | undefined,
	phoneNumber?: string | undefined,
	role?: GraphQLTypes["UserRole"] | undefined,
	userStatus?: GraphQLTypes["UserStatus"] | undefined
};
	["UserUpdatePasswordInput"]: {
		newPassword: string,
	oldPassword: string
};
	["UserWhereInput"]: {
		AND?: Array<GraphQLTypes["UserWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["UserWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["UserWhereInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	email?: GraphQLTypes["StringFilter"] | undefined,
	enabled?: GraphQLTypes["BooleanFilter"] | undefined,
	firstName?: GraphQLTypes["StringFilter"] | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	lastName?: GraphQLTypes["StringFilter"] | undefined,
	phoneNumber?: GraphQLTypes["StringFilter"] | undefined,
	role?: GraphQLTypes["UserRoleFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	userStatus?: GraphQLTypes["UserStatusFilter"] | undefined
};
	["Vehicle"]: {
	__typename: "Vehicle",
	createdAt: GraphQLTypes["DateTime"],
	driver?: GraphQLTypes["Driver"] | undefined,
	driverId?: string | undefined,
	id: string,
	make?: string | undefined,
	model?: string | undefined,
	ownerDriver: boolean,
	ownerFleet?: GraphQLTypes["Fleet"] | undefined,
	ownerFleetId?: string | undefined,
	reg?: string | undefined,
	type: GraphQLTypes["VehicleType"],
	updatedAt: GraphQLTypes["DateTime"]
};
	["VehicleConnectOrDisconnectOneInput"]: {
		connect?: GraphQLTypes["WhereRequiredIdInput"] | undefined,
	disconnect?: boolean | undefined
};
	["VehicleCreateInput"]: {
		driver?: GraphQLTypes["DriverConnectOneInput"] | undefined,
	make?: string | undefined,
	model?: string | undefined,
	ownerDriver?: boolean | undefined,
	ownerFleet?: GraphQLTypes["FleetConnectOneInput"] | undefined,
	reg?: string | undefined,
	type: GraphQLTypes["VehicleType"]
};
	["VehicleCreateNestedOneWithoutDriverInput"]: {
		connect?: GraphQLTypes["WhereRequiredIdInput"] | undefined,
	create?: GraphQLTypes["VehicleCreateInput"] | undefined
};
	["VehicleCreateNestedWithoutOwnerFleetInput"]: {
		connect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<GraphQLTypes["VehicleCreateWithoutOwnerFleetInput"]> | undefined
};
	["VehicleCreateWithoutOwnerFleetInput"]: {
		driver: GraphQLTypes["DriverConnectOneInput"],
	make?: string | undefined,
	model?: string | undefined,
	ownerDriver?: boolean | undefined,
	reg?: string | undefined,
	type: GraphQLTypes["VehicleType"]
};
	["VehicleListRelationFilter"]: {
		every?: GraphQLTypes["VehicleWhereInput"] | undefined,
	none?: GraphQLTypes["VehicleWhereInput"] | undefined,
	some?: GraphQLTypes["VehicleWhereInput"] | undefined
};
	["VehicleOrderByInput"]: {
		createdAt?: GraphQLTypes["SortOrder"] | undefined,
	driver?: GraphQLTypes["DriverOrderByInput"] | undefined,
	driverId?: GraphQLTypes["SortOrder"] | undefined,
	id?: GraphQLTypes["SortOrder"] | undefined,
	make?: GraphQLTypes["SortOrder"] | undefined,
	model?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	ownerDriver?: GraphQLTypes["SortOrder"] | undefined,
	ownerFleet?: GraphQLTypes["FleetOrderByInput"] | undefined,
	ownerFleetId?: GraphQLTypes["SortOrder"] | undefined,
	reg?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined
};
	["VehicleOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["VehicleType"]: VehicleType;
	["VehicleUpdateInput"]: {
		driver?: GraphQLTypes["DriverConnectOrDisconnectOneInput"] | undefined,
	id: string,
	make?: string | undefined,
	model?: string | undefined,
	ownerDriver?: boolean | undefined,
	ownerFleet?: GraphQLTypes["FleetConnectOrDisconnectOneInput"] | undefined,
	reg?: string | undefined,
	type?: GraphQLTypes["VehicleType"] | undefined
};
	["VehicleUpdateManyWithoutOwnerFleetInput"]: {
		connect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<GraphQLTypes["VehicleCreateWithoutOwnerFleetInput"]> | undefined,
	delete?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined
};
	["VehicleWhereInput"]: {
		AND?: Array<GraphQLTypes["VehicleWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["VehicleWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["VehicleWhereInput"]> | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	driver?: GraphQLTypes["DriverWhereInput"] | undefined,
	driverId?: GraphQLTypes["StringFilter"] | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	make?: GraphQLTypes["StringFilter"] | undefined,
	model?: GraphQLTypes["StringFilter"] | undefined,
	ownerDriver?: GraphQLTypes["BooleanFilter"] | undefined,
	ownerFleet?: GraphQLTypes["FleetWhereInput"] | undefined,
	ownerFleetId?: GraphQLTypes["StringFilter"] | undefined,
	reg?: GraphQLTypes["StringFilter"] | undefined,
	type?: GraphQLTypes["VehicleType"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined
};
	/** Response from verify phone */
["VerifyPhoneResponse"]: {
	__typename: "VerifyPhoneResponse",
	completedSignUp: boolean,
	token?: string | undefined
};
	["Webhook"]: {
	__typename: "Webhook",
	apiKeyHeader?: string | undefined,
	apiKeyValue?: string | undefined,
	createdAt: GraphQLTypes["DateTime"],
	fleet: GraphQLTypes["Fleet"],
	fleetId: string,
	forJobStatus?: GraphQLTypes["JobStatus"] | undefined,
	id: string,
	isRedboxApi: boolean,
	name?: string | undefined,
	updatedAt: GraphQLTypes["DateTime"],
	url: string
};
	["WebhookCreateInput"]: {
		apiKeyHeader?: string | undefined,
	apiKeyValue?: string | undefined,
	fleet: GraphQLTypes["FleetConnectOneInput"],
	forJobStatus?: GraphQLTypes["JobStatus"] | undefined,
	isRedboxApi?: boolean | undefined,
	name?: string | undefined,
	url: string
};
	["WebhookCreateNestedManyWithoutFleetInput"]: {
		connect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<GraphQLTypes["WebhookCreateWithoutFleetInput"]> | undefined
};
	["WebhookCreateWithoutFleetInput"]: {
		forJobStatus?: GraphQLTypes["JobStatus"] | undefined,
	name?: string | undefined,
	url: string
};
	["WebhookListRelationFilter"]: {
		every?: GraphQLTypes["WebhookWhereInput"] | undefined,
	none?: GraphQLTypes["WebhookWhereInput"] | undefined,
	some?: GraphQLTypes["WebhookWhereInput"] | undefined
};
	["WebhookOrderByInput"]: {
		apiKeyHeader?: GraphQLTypes["SortOrder"] | undefined,
	createdAt?: GraphQLTypes["SortOrder"] | undefined,
	fleet?: GraphQLTypes["FleetOrderByInput"] | undefined,
	isRedboxApi?: GraphQLTypes["SortOrder"] | undefined,
	name?: GraphQLTypes["SortOrder"] | undefined,
	updatedAt?: GraphQLTypes["SortOrder"] | undefined,
	url?: GraphQLTypes["SortOrder"] | undefined
};
	["WebhookOrderByRelationAggregateInput"]: {
		_count?: GraphQLTypes["SortOrder"] | undefined
};
	["WebhookUpdateInput"]: {
		apiKeyHeader?: string | undefined,
	apiKeyValue?: string | undefined,
	fleet?: GraphQLTypes["FleetConnectOneInput"] | undefined,
	forJobStatus?: GraphQLTypes["JobStatus"] | undefined,
	id: string,
	isRedboxApi?: boolean | undefined,
	name?: string | undefined,
	url?: string | undefined
};
	["WebhookUpdateManyWithoutFleetInput"]: {
		connect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	create?: Array<GraphQLTypes["WebhookCreateWithoutFleetInput"]> | undefined,
	delete?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	disconnect?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined,
	set?: Array<GraphQLTypes["WhereRequiredIdInput"]> | undefined
};
	["WebhookWhereInput"]: {
		AND?: Array<GraphQLTypes["WebhookWhereInput"]> | undefined,
	NOT?: Array<GraphQLTypes["WebhookWhereInput"]> | undefined,
	OR?: Array<GraphQLTypes["WebhookWhereInput"]> | undefined,
	apiKeyHeader?: GraphQLTypes["StringFilter"] | undefined,
	createdAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	fleet?: GraphQLTypes["FleetWhereInput"] | undefined,
	fleetId?: GraphQLTypes["StringFilter"] | undefined,
	forJobStatus?: GraphQLTypes["JobStatus"] | undefined,
	id?: GraphQLTypes["StringFilter"] | undefined,
	isRedboxApi?: GraphQLTypes["BooleanFilter"] | undefined,
	name?: GraphQLTypes["StringFilter"] | undefined,
	updatedAt?: GraphQLTypes["DateTimeFilter"] | undefined,
	url?: GraphQLTypes["StringFilter"] | undefined
};
	["WhereRequiredIdInput"]: {
		id: string
}
    }
export const enum AuthTokenType {
	DRIVER = "DRIVER",
	DRIVER_VERIFICATION = "DRIVER_VERIFICATION",
	MANAGEMENT = "MANAGEMENT",
	SERVER = "SERVER"
}
export const enum CanceledBy {
	CLIENT = "CLIENT",
	DRIVER = "DRIVER",
	OPERATIONS = "OPERATIONS",
	SYSTEM = "SYSTEM"
}
export const enum DistanceUnit {
	KILOMETERS = "KILOMETERS",
	MILES = "MILES"
}
export const enum DriverJobAssignmentStatus {
	ACCEPTED = "ACCEPTED",
	PENDING = "PENDING",
	REJECTED = "REJECTED"
}
export const enum DriverStatus {
	ACTIVE = "ACTIVE",
	INACTIVE = "INACTIVE",
	NO_LONGER_RECEIVING_NEW_JOBS = "NO_LONGER_RECEIVING_NEW_JOBS",
	ON_BREAK = "ON_BREAK"
}
export const enum JobStatus {
	ALMOST_AT_DROP_OFF = "ALMOST_AT_DROP_OFF",
	ALMOST_AT_PICKUP = "ALMOST_AT_PICKUP",
	ASSIGNED = "ASSIGNED",
	AWAITING_ASSIGNMENT = "AWAITING_ASSIGNMENT",
	CANCELLED = "CANCELLED",
	COMPLETE = "COMPLETE",
	EXCEPTION = "EXCEPTION",
	PENDING = "PENDING",
	TRAVELLING_TO_DROP_OFF = "TRAVELLING_TO_DROP_OFF",
	TRAVELLING_TO_PICKUP = "TRAVELLING_TO_PICKUP",
	WAITING_AT_DROP_OFF = "WAITING_AT_DROP_OFF",
	WAITING_AT_PICKUP = "WAITING_AT_PICKUP"
}
export const enum SortOrder {
	asc = "asc",
	desc = "desc"
}
export const enum UserRole {
	ADMIN = "ADMIN",
	FLEET_USER = "FLEET_USER",
	PARTNER_USER = "PARTNER_USER",
	SERVER = "SERVER"
}
export const enum UserStatus {
	ARCHIVED = "ARCHIVED",
	COMPROMISED = "COMPROMISED",
	CONFIRMED = "CONFIRMED",
	FORCE_CHANGE_PASSWORD = "FORCE_CHANGE_PASSWORD",
	RESET_REQUIRED = "RESET_REQUIRED",
	UNCONFIRMED = "UNCONFIRMED",
	UNKNOWN = "UNKNOWN"
}
export const enum VehicleType {
	BIKE = "BIKE",
	CAR = "CAR",
	VAN = "VAN"
}

type ZEUS_VARIABLES = {
	["AddressInput"]: ValueTypes["AddressInput"];
	["ApiImplementationCreateInput"]: ValueTypes["ApiImplementationCreateInput"];
	["ApiImplementationCreateNestedManyWithoutFleetInput"]: ValueTypes["ApiImplementationCreateNestedManyWithoutFleetInput"];
	["ApiImplementationCreateWithoutFleetInput"]: ValueTypes["ApiImplementationCreateWithoutFleetInput"];
	["ApiImplementationListRelationFilter"]: ValueTypes["ApiImplementationListRelationFilter"];
	["ApiImplementationOrderByInput"]: ValueTypes["ApiImplementationOrderByInput"];
	["ApiImplementationOrderByRelationAggregateInput"]: ValueTypes["ApiImplementationOrderByRelationAggregateInput"];
	["ApiImplementationUpdateInput"]: ValueTypes["ApiImplementationUpdateInput"];
	["ApiImplementationUpdateManyWithoutFleetInput"]: ValueTypes["ApiImplementationUpdateManyWithoutFleetInput"];
	["ApiImplementationWhereInput"]: ValueTypes["ApiImplementationWhereInput"];
	["AssignJobToDriverInput"]: ValueTypes["AssignJobToDriverInput"];
	["AuthTokenType"]: ValueTypes["AuthTokenType"];
	["AvailableNodesByUserAndDesiredRoleInput"]: ValueTypes["AvailableNodesByUserAndDesiredRoleInput"];
	["BaseCreateInput"]: ValueTypes["BaseCreateInput"];
	["BaseCreateNestedWithoutFleetInput"]: ValueTypes["BaseCreateNestedWithoutFleetInput"];
	["BaseCreateWithoutFleetInput"]: ValueTypes["BaseCreateWithoutFleetInput"];
	["BaseListRelationFilter"]: ValueTypes["BaseListRelationFilter"];
	["BaseOrderByInput"]: ValueTypes["BaseOrderByInput"];
	["BaseOrderByRelationAggregateInput"]: ValueTypes["BaseOrderByRelationAggregateInput"];
	["BaseUpdateInput"]: ValueTypes["BaseUpdateInput"];
	["BaseUpdateManyWithoutFleetInput"]: ValueTypes["BaseUpdateManyWithoutFleetInput"];
	["BaseWhereInput"]: ValueTypes["BaseWhereInput"];
	["BooleanFilter"]: ValueTypes["BooleanFilter"];
	["CalculateRouteInput"]: ValueTypes["CalculateRouteInput"];
	["CancelJobInput"]: ValueTypes["CancelJobInput"];
	["CanceledBy"]: ValueTypes["CanceledBy"];
	["CompleteUserProfileInput"]: ValueTypes["CompleteUserProfileInput"];
	["ContactInput"]: ValueTypes["ContactInput"];
	["CoordinatesInput"]: ValueTypes["CoordinatesInput"];
	["DateTime"]: ValueTypes["DateTime"];
	["DateTimeFilter"]: ValueTypes["DateTimeFilter"];
	["DeliveryEstimateInput"]: ValueTypes["DeliveryEstimateInput"];
	["DistanceUnit"]: ValueTypes["DistanceUnit"];
	["DistanceUnitFilter"]: ValueTypes["DistanceUnitFilter"];
	["DriverConnectOneInput"]: ValueTypes["DriverConnectOneInput"];
	["DriverConnectOrDisconnectInput"]: ValueTypes["DriverConnectOrDisconnectInput"];
	["DriverConnectOrDisconnectOneInput"]: ValueTypes["DriverConnectOrDisconnectOneInput"];
	["DriverCreateInput"]: ValueTypes["DriverCreateInput"];
	["DriverCreateNestedOneWithoutFleetDriversInput"]: ValueTypes["DriverCreateNestedOneWithoutFleetDriversInput"];
	["DriverCreateWithoutFleetDriversInput"]: ValueTypes["DriverCreateWithoutFleetDriversInput"];
	["DriverInviteInput"]: ValueTypes["DriverInviteInput"];
	["DriverJobAssignmentStatus"]: ValueTypes["DriverJobAssignmentStatus"];
	["DriverOrderByInput"]: ValueTypes["DriverOrderByInput"];
	["DriverSetLocationInput"]: ValueTypes["DriverSetLocationInput"];
	["DriverStatus"]: ValueTypes["DriverStatus"];
	["DriverUpdateDetailsInput"]: ValueTypes["DriverUpdateDetailsInput"];
	["DriverUpdateInput"]: ValueTypes["DriverUpdateInput"];
	["DriverUpdatePushTokenInput"]: ValueTypes["DriverUpdatePushTokenInput"];
	["DriverWhereInput"]: ValueTypes["DriverWhereInput"];
	["DropOffTaskCreateInput"]: ValueTypes["DropOffTaskCreateInput"];
	["DropOffTaskInput"]: ValueTypes["DropOffTaskInput"];
	["DropOffTaskListRelationFilter"]: ValueTypes["DropOffTaskListRelationFilter"];
	["DropOffTaskOrderByInput"]: ValueTypes["DropOffTaskOrderByInput"];
	["DropOffTaskOrderByRelationAggregateInput"]: ValueTypes["DropOffTaskOrderByRelationAggregateInput"];
	["DropOffTaskUpdateInput"]: ValueTypes["DropOffTaskUpdateInput"];
	["DropOffTaskUpdateManyWithoutJobInput"]: ValueTypes["DropOffTaskUpdateManyWithoutJobInput"];
	["DropOffTaskUpdateProofOfDelivery"]: ValueTypes["DropOffTaskUpdateProofOfDelivery"];
	["DropOffTaskWhereInput"]: ValueTypes["DropOffTaskWhereInput"];
	["EligibleDriversForPickupInput"]: ValueTypes["EligibleDriversForPickupInput"];
	["FleetConnectOneInput"]: ValueTypes["FleetConnectOneInput"];
	["FleetConnectOrDisconnectOneInput"]: ValueTypes["FleetConnectOrDisconnectOneInput"];
	["FleetCreateInput"]: ValueTypes["FleetCreateInput"];
	["FleetCreateNestedManyWithoutPartnerInput"]: ValueTypes["FleetCreateNestedManyWithoutPartnerInput"];
	["FleetCreateWithoutPartnerInput"]: ValueTypes["FleetCreateWithoutPartnerInput"];
	["FleetDriverCreateInput"]: ValueTypes["FleetDriverCreateInput"];
	["FleetDriverCreateNestedWithoutFleetInput"]: ValueTypes["FleetDriverCreateNestedWithoutFleetInput"];
	["FleetDriverCreateWithoutDriverInput"]: ValueTypes["FleetDriverCreateWithoutDriverInput"];
	["FleetDriverCreateWithoutFleetInput"]: ValueTypes["FleetDriverCreateWithoutFleetInput"];
	["FleetDriverListRelationFilter"]: ValueTypes["FleetDriverListRelationFilter"];
	["FleetDriverOrderByInput"]: ValueTypes["FleetDriverOrderByInput"];
	["FleetDriverOrderByRelationAggregateInput"]: ValueTypes["FleetDriverOrderByRelationAggregateInput"];
	["FleetDriverUpdateInput"]: ValueTypes["FleetDriverUpdateInput"];
	["FleetDriverUpdateManyWithoutDriverInput"]: ValueTypes["FleetDriverUpdateManyWithoutDriverInput"];
	["FleetDriverUpdateManyWithoutFleetInput"]: ValueTypes["FleetDriverUpdateManyWithoutFleetInput"];
	["FleetDriverWhereInput"]: ValueTypes["FleetDriverWhereInput"];
	["FleetListRelationFilter"]: ValueTypes["FleetListRelationFilter"];
	["FleetOrderByInput"]: ValueTypes["FleetOrderByInput"];
	["FleetOrderByRelationAggregateInput"]: ValueTypes["FleetOrderByRelationAggregateInput"];
	["FleetUpdateInput"]: ValueTypes["FleetUpdateInput"];
	["FleetUpdateManyWithoutPartnerInput"]: ValueTypes["FleetUpdateManyWithoutPartnerInput"];
	["FleetWhereInput"]: ValueTypes["FleetWhereInput"];
	["IntFilter"]: ValueTypes["IntFilter"];
	["JSON"]: ValueTypes["JSON"];
	["JobAssignmentHistoryCreateInput"]: ValueTypes["JobAssignmentHistoryCreateInput"];
	["JobAssignmentHistoryListRelationFilter"]: ValueTypes["JobAssignmentHistoryListRelationFilter"];
	["JobAssignmentHistoryOrderByRelationAggregateInput"]: ValueTypes["JobAssignmentHistoryOrderByRelationAggregateInput"];
	["JobAssignmentHistoryUpdateInput"]: ValueTypes["JobAssignmentHistoryUpdateInput"];
	["JobAssignmentHistoryUpdateManyWithoutDriverInput"]: ValueTypes["JobAssignmentHistoryUpdateManyWithoutDriverInput"];
	["JobAssignmentHistoryUpdateManyWithoutJobInput"]: ValueTypes["JobAssignmentHistoryUpdateManyWithoutJobInput"];
	["JobAssignmentHistoryWhereInput"]: ValueTypes["JobAssignmentHistoryWhereInput"];
	["JobAssignmentOrderByInput"]: ValueTypes["JobAssignmentOrderByInput"];
	["JobConnectOneInput"]: ValueTypes["JobConnectOneInput"];
	["JobCreateDirectInput"]: ValueTypes["JobCreateDirectInput"];
	["JobListRelationFilter"]: ValueTypes["JobListRelationFilter"];
	["JobOrderByInput"]: ValueTypes["JobOrderByInput"];
	["JobOrderByRelationAggregateInput"]: ValueTypes["JobOrderByRelationAggregateInput"];
	["JobStatus"]: ValueTypes["JobStatus"];
	["JobStatusFilter"]: ValueTypes["JobStatusFilter"];
	["JobUpdateInput"]: ValueTypes["JobUpdateInput"];
	["JobUpdateManyWithoutDriverInput"]: ValueTypes["JobUpdateManyWithoutDriverInput"];
	["JobWhereInput"]: ValueTypes["JobWhereInput"];
	["JsonFilter"]: ValueTypes["JsonFilter"];
	["LocationCoordsInput"]: ValueTypes["LocationCoordsInput"];
	["LocationInput"]: ValueTypes["LocationInput"];
	["NotifyDriverInput"]: ValueTypes["NotifyDriverInput"];
	["PartnerConnectOrDisconnectInput"]: ValueTypes["PartnerConnectOrDisconnectInput"];
	["PartnerCreateInput"]: ValueTypes["PartnerCreateInput"];
	["PartnerCreateNestedOneWithoutFleetsInput"]: ValueTypes["PartnerCreateNestedOneWithoutFleetsInput"];
	["PartnerCreateWithoutPlatformInput"]: ValueTypes["PartnerCreateWithoutPlatformInput"];
	["PartnerListRelationFilter"]: ValueTypes["PartnerListRelationFilter"];
	["PartnerOrderByInput"]: ValueTypes["PartnerOrderByInput"];
	["PartnerOrderByRelationAggregateInput"]: ValueTypes["PartnerOrderByRelationAggregateInput"];
	["PartnerUpdateInput"]: ValueTypes["PartnerUpdateInput"];
	["PartnerUpdatemanyWithoutPlatformInput"]: ValueTypes["PartnerUpdatemanyWithoutPlatformInput"];
	["PartnerWhereInput"]: ValueTypes["PartnerWhereInput"];
	["PartnerWhereUniqueInput"]: ValueTypes["PartnerWhereUniqueInput"];
	["PickupTaskCreateInput"]: ValueTypes["PickupTaskCreateInput"];
	["PickupTaskInput"]: ValueTypes["PickupTaskInput"];
	["PickupTaskListRelationFilter"]: ValueTypes["PickupTaskListRelationFilter"];
	["PickupTaskOrderByInput"]: ValueTypes["PickupTaskOrderByInput"];
	["PickupTaskOrderByRelationAggregateInput"]: ValueTypes["PickupTaskOrderByRelationAggregateInput"];
	["PickupTaskUpdateInput"]: ValueTypes["PickupTaskUpdateInput"];
	["PickupTaskUpdateManyWithoutJobInput"]: ValueTypes["PickupTaskUpdateManyWithoutJobInput"];
	["PickupTaskWhereInput"]: ValueTypes["PickupTaskWhereInput"];
	["PlatformConnectById"]: ValueTypes["PlatformConnectById"];
	["PlatformCreateInput"]: ValueTypes["PlatformCreateInput"];
	["PlatformCreateOrConnectPartnerInput"]: ValueTypes["PlatformCreateOrConnectPartnerInput"];
	["PlatformOrderByInput"]: ValueTypes["PlatformOrderByInput"];
	["PlatformUpdateInput"]: ValueTypes["PlatformUpdateInput"];
	["PlatformWhereInput"]: ValueTypes["PlatformWhereInput"];
	["SortOrder"]: ValueTypes["SortOrder"];
	["StringFilter"]: ValueTypes["StringFilter"];
	["TimeConnectedInMsInput"]: ValueTypes["TimeConnectedInMsInput"];
	["UpdateJobPickupTimeInput"]: ValueTypes["UpdateJobPickupTimeInput"];
	["UpdateJobStatusInput"]: ValueTypes["UpdateJobStatusInput"];
	["UploadJobCompleteProofInput"]: ValueTypes["UploadJobCompleteProofInput"];
	["UserConnectInput"]: ValueTypes["UserConnectInput"];
	["UserCreateInput"]: ValueTypes["UserCreateInput"];
	["UserCreateNewPasswordInput"]: ValueTypes["UserCreateNewPasswordInput"];
	["UserInviteInput"]: ValueTypes["UserInviteInput"];
	["UserLoginInput"]: ValueTypes["UserLoginInput"];
	["UserOrderByInput"]: ValueTypes["UserOrderByInput"];
	["UserResetPasswordInput"]: ValueTypes["UserResetPasswordInput"];
	["UserRole"]: ValueTypes["UserRole"];
	["UserRoleFilter"]: ValueTypes["UserRoleFilter"];
	["UserStatus"]: ValueTypes["UserStatus"];
	["UserStatusFilter"]: ValueTypes["UserStatusFilter"];
	["UserUpdateInput"]: ValueTypes["UserUpdateInput"];
	["UserUpdatePasswordInput"]: ValueTypes["UserUpdatePasswordInput"];
	["UserWhereInput"]: ValueTypes["UserWhereInput"];
	["VehicleConnectOrDisconnectOneInput"]: ValueTypes["VehicleConnectOrDisconnectOneInput"];
	["VehicleCreateInput"]: ValueTypes["VehicleCreateInput"];
	["VehicleCreateNestedOneWithoutDriverInput"]: ValueTypes["VehicleCreateNestedOneWithoutDriverInput"];
	["VehicleCreateNestedWithoutOwnerFleetInput"]: ValueTypes["VehicleCreateNestedWithoutOwnerFleetInput"];
	["VehicleCreateWithoutOwnerFleetInput"]: ValueTypes["VehicleCreateWithoutOwnerFleetInput"];
	["VehicleListRelationFilter"]: ValueTypes["VehicleListRelationFilter"];
	["VehicleOrderByInput"]: ValueTypes["VehicleOrderByInput"];
	["VehicleOrderByRelationAggregateInput"]: ValueTypes["VehicleOrderByRelationAggregateInput"];
	["VehicleType"]: ValueTypes["VehicleType"];
	["VehicleUpdateInput"]: ValueTypes["VehicleUpdateInput"];
	["VehicleUpdateManyWithoutOwnerFleetInput"]: ValueTypes["VehicleUpdateManyWithoutOwnerFleetInput"];
	["VehicleWhereInput"]: ValueTypes["VehicleWhereInput"];
	["WebhookCreateInput"]: ValueTypes["WebhookCreateInput"];
	["WebhookCreateNestedManyWithoutFleetInput"]: ValueTypes["WebhookCreateNestedManyWithoutFleetInput"];
	["WebhookCreateWithoutFleetInput"]: ValueTypes["WebhookCreateWithoutFleetInput"];
	["WebhookListRelationFilter"]: ValueTypes["WebhookListRelationFilter"];
	["WebhookOrderByInput"]: ValueTypes["WebhookOrderByInput"];
	["WebhookOrderByRelationAggregateInput"]: ValueTypes["WebhookOrderByRelationAggregateInput"];
	["WebhookUpdateInput"]: ValueTypes["WebhookUpdateInput"];
	["WebhookUpdateManyWithoutFleetInput"]: ValueTypes["WebhookUpdateManyWithoutFleetInput"];
	["WebhookWhereInput"]: ValueTypes["WebhookWhereInput"];
	["WhereRequiredIdInput"]: ValueTypes["WhereRequiredIdInput"];
}