import { NonIdealState, Spinner } from '@blueprintjs/core'
import React from 'react'

// This file provides NonIdealStates with predefined props for certain states
// this ensures consistency in wording

interface IBaseNonIdealStateProps {
  entityName: string
}

export const LoadingState = ({ entityName }: IBaseNonIdealStateProps) => (
  <NonIdealState
    icon={<Spinner />}
    title={`Loading ${entityName}`}
    description="Please wait..."
  />
)

// could extend interface and pass down error message
export const ErrorState = ({ entityName }: IBaseNonIdealStateProps) => (
  <NonIdealState
    icon="error"
    title={`Error loading ${entityName}`}
    description="Please try again"
  />
)

export const EmptyListState = ({ entityName }: IBaseNonIdealStateProps) => (
  <NonIdealState icon="th-list" title={`No ${entityName} found`} />
)

export const EmptyListAwaitingUserInput = ({
  message,
}: {
  message: string
}) => <NonIdealState icon="th-list" title={message} />
