import { typedGql } from '../../../zeus/typedDocumentNode'
import { $ } from '../../../zeus'
import { platformSelector } from './platformSelector'

export const LIST_PLATFORMS = typedGql('query')({
  platforms: [
    {
      orderBy: {
        name: $('sortByNameDirection', 'SortOrder!'),
      },
    },
    platformSelector,
  ],
})
