import React from 'react'
import { FormContainer } from './Form.styles'

interface IBaseFormViewProps {
  children?: JSX.Element | JSX.Element[]
}

export const FormView = ({ children }: IBaseFormViewProps) => (
  <FormContainer>{children}</FormContainer>
)
