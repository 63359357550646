import React from 'react'
import { useQuery } from '@apollo/client'
import { Button, MenuItem, Spinner } from '@blueprintjs/core'
import { StringParam, useQueryParam } from 'use-query-params'
import { typedGql } from '../../zeus/typedDocumentNode'
import { SortOrder } from '../../zeus'
import { QUERY_PARAMS } from '../../utils/queryParamsNames'
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select'

export interface Fleet {
  value: string
  label: string
}

const filterFleets: ItemPredicate<Fleet> = (
  query,
  fleet,
  _index,
  exactMatch
) => {
  const normalizedTitle = fleet.label.toLowerCase()
  const normalizedQuery = query.toLowerCase()

  if (exactMatch) {
    return normalizedTitle === normalizedQuery
  } else {
    return `${normalizedTitle} ${fleet.label}`.includes(normalizedQuery)
  }
}

const renderFleets: ItemRenderer<Fleet> = (
  fleet,
  { handleClick, handleFocus, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      onClick={handleClick}
      key={fleet.value}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={fleet.label}
    />
  )
}

const LIST_FLEETS = typedGql('query')({
  fleets: [
    {
      orderBy: {
        name: SortOrder.asc,
      },
    },
    {
      id: true,
      name: true,
    },
  ],
})

export const FilterByFleet = () => {
  const { data, loading } = useQuery(LIST_FLEETS)
  const [fleetId, setFleetId] = useQueryParam(QUERY_PARAMS.fleetId, StringParam)

  const fleets: Fleet[] =
    data?.fleets.map(({ id, name }) => ({
      value: id,
      label: name,
    })) ?? []

  const selectedFleet = fleets.find(fleet => fleet.value === fleetId)

  const handleChange = (item: Fleet) => {
    setFleetId(item.value ? item.value : undefined)
  }

  if (loading) {
    return <Spinner />
  }

  return (
    <Select
      onItemSelect={handleChange}
      items={[{ value: '', label: 'Filter by fleet...' }, ...fleets]}
      itemPredicate={filterFleets}
      itemRenderer={renderFleets}
      noResults={
        <MenuItem
          disabled={true}
          text="No results."
          roleStructure="listoption"
        />
      }
    >
      <Button
        text={selectedFleet?.label ?? 'Filter by fleet'}
        rightIcon="double-caret-vertical"
      />
    </Select>
  )
}
