import { pinDriverDark, pinDriverLight } from '../../assets/features'
import { Marker } from 'maplibre-gl'
import { createElementForMarker } from './createElementForMarker'
import { PartialDriver, createDriverPopup } from './popupUtils'

export type DriversMarkers = {
  driverMarkers: Marker[] | undefined
}

export const createDriversToBeDrawn = (
  drivers: PartialDriver[]
): DriversMarkers => {
  const response: DriversMarkers = {
    driverMarkers: undefined,
  }

  // Pickup Tasks
  if (drivers && drivers.length > 0) {
    drivers.forEach(driver => {
      if (!driver.currentLocation) return
      const { longitude, latitude } = driver.currentLocation
      if (!longitude || !latitude) return

      const el = createElementForMarker({
        id: driver.id,
        src: pinDriverDark,
        style: {
          width: '26px',
          height: '26px',
        },
        isDriverMarker: true,
      })

      const popup = createDriverPopup(driver)
      const marker = new Marker(el)
        .setLngLat([longitude, latitude])
        .setPopup(popup)

      response.driverMarkers
        ? response.driverMarkers.push(marker)
        : (response.driverMarkers = [marker])
    })
  }

  return response
}
