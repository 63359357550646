import styled from 'styled-components'

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const HorizontalContainer = styled.div`
  display: flex;
`

export const RightPanel = styled.div`
  border-left: 2px solid #dededf;
  min-width: 45%;
`
