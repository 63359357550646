import React from 'react'
import { UserRole } from '../../zeus'
import { BaseMultiSelectProps } from './types'
import { ToastType, useToaster } from '../../hooks/useToaster'
import { useQuery } from '@apollo/client'
import { AVAILABLE_NODES_BY_USER_AND_ROLE } from '../../pages/Admin/Users/gql/availableNodesByUserAndRole'
import { MultiSelect } from '@blueprintjs/select'
import { Tag } from '@blueprintjs/core'

type Props = BaseMultiSelectProps & {
  desiredRole: UserRole
}

export const NodesMultiSelect = (props: Props) => {
  if (!props.desiredRole) return null

  const openErrorToast = useToaster({ type: ToastType.ERROR })
  const { data, loading, error } = useQuery(AVAILABLE_NODES_BY_USER_AND_ROLE, {
    variables: {
      input: {
        desiredRole: props.desiredRole,
      },
    },
  })

  if (error) {
    openErrorToast(error.message || 'Failed to fetch available nodes')
    return null
  }
  if (!data?.availableNodesByUserAndDesiredRole || loading) return null

  const nodeEntries = data.availableNodesByUserAndDesiredRole.map(node => ({
    id: node.id,
    name: node.name,
  }))

  return (
    <MultiSelect
      {...props}
      tagRenderer={node => node.name}
      items={nodeEntries}
      selectedItems={props.selectedItems}
      itemRenderer={({ id, name }, { modifiers, handleClick }) => {
        if (!modifiers.matchesPredicate) {
          return null
        }

        return (
          <li
            key={id}
            onClick={e => {
              if (!props.selectedItems?.find(item => item.id === id)) {
                handleClick(e)
              }
            }}
            style={{ listStyle: 'none', margin: '5px' }}
          >
            <Tag
              intent={
                props.selectedItems?.find(item => item.id === id)
                  ? 'none'
                  : 'primary'
              }
            >
              {name}
            </Tag>
          </li>
        )
      }}
    />
  )
}
