import { typedGql } from '../../../../zeus/typedDocumentNode'
import { $ } from '../../../../zeus'

export const DELETE_USER = typedGql('mutation')({
  deleteUser: [
    {
      userId: $('userId', 'String!'),
    },
    {
      id: true,
    },
  ],
})
