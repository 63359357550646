import { add, endOfDay } from 'date-fns'
import { DateFilterConstIntervals } from '.'

type TranslateDateRangeFilterToGraphQLVarsProps = {
  dateFilterTypeKey: string
  startOfRangeDate?: string
  endOfRangeDate?: string
  jobsOrderBy?: string
}

export const translateDateRangeFilterToGraphQLVars = ({
  dateFilterTypeKey,
  startOfRangeDate,
  endOfRangeDate,
  jobsOrderBy,
}: TranslateDateRangeFilterToGraphQLVarsProps) => {
  if (!startOfRangeDate || !dateFilterTypeKey) {
    return
  }

  let lte
  switch (dateFilterTypeKey as DateFilterConstIntervals) {
    case 'DAY':
      lte = endOfDay(new Date(startOfRangeDate))
      break
    case 'WEEK':
      lte = endOfDay(add(new Date(startOfRangeDate), { weeks: 1, days: -1 }))
      break
    case 'MONTH':
      lte = endOfDay(add(new Date(startOfRangeDate), { months: 1, days: -1 }))
      break
    case 'QUARTER':
      lte = endOfDay(add(new Date(startOfRangeDate), { months: 3, days: -1 }))
      break
    case 'CUSTOM':
      lte = endOfDay(new Date(endOfRangeDate || startOfRangeDate)) // failsafe
      break
    default:
      lte = endOfDay(new Date(startOfRangeDate))
  }

  const criteria = jobsOrderBy?.includes('pickup')
    ? 'estimatedPickupTime'
    : 'createdAt'

  return {
    [criteria]: {
      gte: new Date(startOfRangeDate).toISOString(),
      lte: lte.toISOString(),
    },
  }
}
