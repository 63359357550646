import { $, SortOrder } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const API_IMPLEMENTATIONS_BY_FLEET = typedGql('query')({
  apiImplementations: [
    {
      where: {
        fleetId: {
          equals: $('fleetId', 'String!'),
        },
      },
      orderBy: {
        name: SortOrder.asc,
      },
    },
    { id: true, name: true, apiKey: true },
  ],
})
