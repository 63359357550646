import { groupBy, pullAll } from 'lodash'
import { addMinutes, differenceInMinutes } from 'date-fns'
import React from 'react'
import { ModelTypes } from '../../../zeus'
import { HorizontalContainer, StackedJobsContainer } from './styles'
import { JobBlock } from './JobBlock'
import { SCALING_FACTOR } from './Timeline'
import { BreakBlock } from './BreakBlock'

type PartialJob = Pick<
  ModelTypes['Job'],
  | 'id'
  | 'estimatedPickupTime'
  | 'estimatedDurationMinutes'
  | 'price'
  | 'stackId'
>

type BreakOption = ModelTypes['OptionForDriverStatus']

type JobOrBreakOption = PartialJob | BreakOption

export const JobsRow = ({
  jobs,
  startingTime,
  breaks,
}: {
  startingTime: Date
  jobs: PartialJob[]
  breaks?: ModelTypes['OptionForDriverStatus'][] | null
}) => {
  const startingTimeInMillisec = startingTime.getTime()

  // <jobs> holds all the jobs for the day
  // filter passed jobs that finished after now
  const relevantJobs = jobs
    .filter(({ estimatedPickupTime, estimatedDurationMinutes }) => {
      const pickupInMillisec = new Date(estimatedPickupTime || 0).getTime()
      const durationInMillisec = estimatedDurationMinutes * 60 * 1000

      // simplest case, job starts in the future
      if (pickupInMillisec >= startingTimeInMillisec) {
        return true
      }

      // true if job finishes after now
      return pickupInMillisec + durationInMillisec >= startingTimeInMillisec
    })
    .sort((a, b) => {
      const aPickupInMillisec = new Date(a.estimatedPickupTime || 0).getTime()
      const bPickupInMillisec = new Date(b.estimatedPickupTime || 0).getTime()

      return aPickupInMillisec - bPickupInMillisec
    })

  // extract stacked jobs from relevantJobs
  const relevantStackedJobs = relevantJobs.filter(job => job.stackId)
  pullAll(relevantJobs, relevantStackedJobs) // mutates relevantJobs
  // group stacked jobs by stackId
  const relevantStackedJobsGrouped = groupBy(
    relevantStackedJobs,
    job => job.stackId
  )

  // treat each stack as a synthetic single job for duration purposes
  const relevantStackedJobsGroupedAsSingleJob: PartialJob[] = Object.values(
    relevantStackedJobsGrouped
  ).map(jobsInStack => {
    // sum up durations
    const estimatedDurationMinutes = jobsInStack.reduce(
      (acc, job) => acc + (job.estimatedDurationMinutes || 0),
      0
    )

    // sort by pickup time
    const sortedJobsInStack = jobsInStack.sort((a, b) => {
      const aPickupInMillisec = new Date(a.estimatedPickupTime || 0).getTime()
      const bPickupInMillisec = new Date(b.estimatedPickupTime || 0).getTime()

      return aPickupInMillisec - bPickupInMillisec
    })

    const earliestPickupTime = sortedJobsInStack[0].estimatedPickupTime
    const latestPickupTime =
      sortedJobsInStack[sortedJobsInStack.length - 1].estimatedPickupTime
    const totalStackDurationInMinutes =
      estimatedDurationMinutes +
      differenceInMinutes(
        new Date(latestPickupTime),
        new Date(earliestPickupTime)
      )

    return {
      id: jobsInStack[0].stackId!,
      estimatedPickupTime: earliestPickupTime,
      estimatedDurationMinutes: totalStackDurationInMinutes,
      stackId: jobsInStack[0].stackId,
      price: jobsInStack[0].price, // irelevant
    }
  })

  const incomingBreaks = breaks ?? []
  const relevantBreaks: BreakOption[] = incomingBreaks.filter(breakOption => {
    if (!breakOption.minStartTime) {
      console.warn('Discarding break without minStartTime', breakOption)
      return false
    }

    const breakStart = new Date(breakOption.minStartTime)
    const breakEnd = addMinutes(
      breakStart,
      breakOption.durationInMinutes ?? 600
    ) // 10 hours filler for INACTIVE

    return breakEnd.getTime() >= startingTimeInMillisec
  })

  const sortedJobsAndBreaks: Array<JobOrBreakOption> = [
    ...relevantJobs,
    ...relevantBreaks,
    ...relevantStackedJobsGroupedAsSingleJob,
  ].sort((a, b) => {
    let aStartInMillisec = 0
    if ('estimatedPickupTime' in a) {
      aStartInMillisec = new Date(a.estimatedPickupTime || 0).getTime()
    }
    if ('minStartTime' in a) {
      aStartInMillisec = new Date(a.minStartTime || 0).getTime()
    }

    let bStartInMillisec = 0
    if ('estimatedPickupTime' in b) {
      bStartInMillisec = new Date(b.estimatedPickupTime || 0).getTime()
    }
    if ('minStartTime' in b) {
      bStartInMillisec = new Date(b.minStartTime || 0).getTime()
    }

    return aStartInMillisec - bStartInMillisec
  })

  return (
    <HorizontalContainer>
      {sortedJobsAndBreaks.map((jobOrBreak, index) => {
        const additionalLeftShift = totalDurationForJobsOrBreaksInMinutes(
          sortedJobsAndBreaks.slice(0, index)
        )
        if ('estimatedPickupTime' in jobOrBreak) {
          // this is a job

          if (jobOrBreak.stackId) {
            // this is a stacked job

            let left: number | undefined = undefined
            let pickupInMillisec = 0
            if (startingTimeInMillisec) {
              // conditionally position based on start time
              // a negative diff -> pickup was in the past
              pickupInMillisec = new Date(
                jobOrBreak.estimatedPickupTime || 0
              ).getTime()

              const diffInMillisec = pickupInMillisec - startingTimeInMillisec
              left = Math.floor(diffInMillisec / 1000 / 60) * SCALING_FACTOR
            }

            // apply additional left shift
            if (left) {
              left = left - Math.floor(additionalLeftShift) * SCALING_FACTOR
            }
            return (
              <StackedJobsContainer key={jobOrBreak.stackId} left={left}>
                {relevantStackedJobsGrouped[jobOrBreak.stackId].map(job => (
                  <JobBlock
                    key={job.id}
                    {...job}
                    isLastInQueue={true}
                    stacked
                    additionalLeftShift={additionalLeftShift}
                  />
                ))}
              </StackedJobsContainer>
            )
          } else {
            // this is a single job
            const indexInQ = relevantJobs.findIndex(
              jobInQ => jobInQ.id === jobOrBreak.id
            )
            const isLastInQueue = indexInQ >= relevantJobs.length - 1

            return (
              <JobBlock
                key={jobOrBreak.id}
                {...jobOrBreak}
                startingTimeInMillisec={startingTimeInMillisec}
                isLastInQueue={isLastInQueue}
                additionalLeftShift={additionalLeftShift}
              />
            )
          }
        } else if ('minStartTime' in jobOrBreak) {
          // this is a break
          return (
            <BreakBlock
              key={jobOrBreak.id}
              block={jobOrBreak}
              startingTimeInMillisec={startingTimeInMillisec}
              additionalLeftShift={additionalLeftShift}
            />
          )
        } else {
          return null
        }
      })}
    </HorizontalContainer>
  )
}

const totalDurationForJobsOrBreaksInMinutes = (
  jobsOrBreaks: Array<JobOrBreakOption>
) => {
  return jobsOrBreaks.reduce((acc, jobOrBreak) => {
    if ('estimatedDurationMinutes' in jobOrBreak) {
      // this is a job
      return acc + jobOrBreak.estimatedDurationMinutes
    } else if ('durationInMinutes' in jobOrBreak) {
      // this is a break
      return acc + (jobOrBreak.durationInMinutes ?? 0)
    } else {
      return acc
    }
  }, 0)
}
