import { Button } from '@blueprintjs/core'
import React from 'react'
import { useHistory } from 'react-router-dom'

export const ClearFilters = () => {
  const history = useHistory()

  return (
    <Button
      minimal
      icon="filter-remove"
      onClick={() => {
        history.push(history.location.pathname)
      }}
    />
  )
}
