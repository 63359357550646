import { JobStatus } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const OLD_JOBS = typedGql('query')({
  jobs: [
    {
      where: {
        AND: [
          {
            estimatedPickupTime: {
              lt: new Date().toISOString(),
            },
          },
          {
            status: { in: [JobStatus['ASSIGNED'], JobStatus['PENDING']] },
          },
        ],
      },
    },
    {
      id: true,
      status: true,
      createdAt: true,
      updatedAt: true,
      estimatedPickupTime: true,
    },
  ],
})
