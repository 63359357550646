import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const UPDATE_DRIVER = typedGql('mutation')({
  updateDriver: [
    {
      input: $('input', 'DriverUpdateInput!'),
    },
    {
      id: true,
      title: true,
      firstName: true,
      middleNames: true,
      lastName: true,
      email: true,
      completedSignUp: true,
      allowJobRejection: true,
      allowStackingOrders: true,
      distanceUnit: true,
      costPerDistanceUnit: true,
      fleetDrivers: {
        id: true,
        fleet: {
          id: true,
          name: true,
        },
      },
    },
  ],
})
