import { Card } from '@blueprintjs/core'
import React, { useContext } from 'react'
import { FormView } from '../../components/Views/Form'
import { AddPlatformDialog } from '../../dialogs/AddPlatformDialog'
import { DialogContext } from '../../providers/DialogProvider'
import { configurePageLayout } from '../../providers/PageLayoutProvider'
import { PlatformContent } from './PlatformContent'

export const Platform = () => {
  // const { openDialog, closeDialog } = useContext(DialogContext)

  configurePageLayout()
  //   {
  //   tabs: [],
  //   button: {
  //     onClick: () =>
  //       openDialog({
  //         title: 'Add Partner',
  //         content: <AddPlatformDialog closeDialog={closeDialog} />,
  //       }),
  //     text: 'Add Platform',
  //   },
  // }

  return (
    <FormView>
      <Card>
        <PlatformContent />
      </Card>
    </FormView>
  )
}
