import React from 'react'
import { Redirect, Route, BrowserRouter, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { QueryParamProvider } from 'use-query-params'

// providers
import { DialogProvider } from './providers/DialogProvider'

import { PageLayout } from './components/PageLayout/PageLayout'

// pages
import { Dashboard } from './pages/Dashboard/Dashboard'
import { Platform } from './pages/Platform/Platform'
import { NotFound } from './pages/NotFound'
import { Login } from './pages/Login/Login'
import { Partners } from './pages/Partners/Partners'
import { ApolloProvider } from './providers/ApolloProvider'
import { Fleets } from './pages/Fleets/Fleets'
import { Admin } from './pages/Admin/Admin'
import { Drivers } from './pages/Drivers/Drivers'
import { Jobs } from './pages/Jobs/index'
import { theme } from './styles/theme'
import { SessionTokenProvider } from './providers/SessionTokenProvider'
import { CurrentUserProvider } from './providers/CurrentUserProvider'
import { InviteDeepLinking } from './pages/InviteDeepLinking'
import { Reset } from './pages/Login/Reset'
import { Register } from './pages/Login/Register'
import { ExpiredToken } from './pages/Login/ExpiredToken'
import { FocusStyleManager } from '@blueprintjs/core'
import { PageLayoutProvider } from './providers/PageLayoutProvider'

export const App = () => {
  FocusStyleManager.onlyShowFocusOnTabs()

  return (
    <SessionTokenProvider>
      <ApolloProvider>
        <CurrentUserProvider>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <QueryParamProvider ReactRouterRoute={Route}>
                <Switch>
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route path="/invite">
                    <InviteDeepLinking />
                  </Route>
                  <Route path={['/reset/:key', '/reset']}>
                    <Reset />
                  </Route>
                  <Route path={['/register/:key', '/register']}>
                    <Register />
                  </Route>
                  <Route path={'/expiredToken'}>
                    <ExpiredToken />
                  </Route>
                  <PageLayoutProvider>
                    <DialogProvider>
                      <PageLayout>
                        <Switch>
                          <Route path="/" exact>
                            <Redirect to="/dashboard" />
                          </Route>
                          <Route path="/dashboard">
                            <Dashboard />
                          </Route>
                          <Route path="/platform">
                            <Platform />
                          </Route>
                          <Route path="/partners">
                            <Partners />
                          </Route>
                          <Route path="/fleets">
                            <Fleets />
                          </Route>
                          <Route path="/drivers">
                            <Drivers />
                          </Route>
                          {/* todo */}
                          <Route path="/jobs">
                            <Jobs />
                          </Route>
                          {/* todo */}
                          <Route path="/admin">
                            <Admin />
                          </Route>
                          <Route>
                            <NotFound />
                          </Route>
                        </Switch>
                      </PageLayout>
                    </DialogProvider>
                  </PageLayoutProvider>
                </Switch>
              </QueryParamProvider>
            </BrowserRouter>
          </ThemeProvider>
        </CurrentUserProvider>
      </ApolloProvider>
    </SessionTokenProvider>
  )
}
