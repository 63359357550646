import { Tabs, TabsProps } from '@blueprintjs/core'
import styled from 'styled-components'

export const TabBarContainer = styled.div`
  top: 48px;
  z-index: 2;
  height: 48px;
  width: 100%;
  background: ${({ theme }) => theme.colors.tabBarBG};
  position: fixed;
  display: flex;
  align-items: center;

  // can't style Tabs directly with styled(Tabs)
  // so cheat and use the class
  .bp5-tabs {
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.colors.tabBarBG};
    min-width: max-content;
    flex-grow: 1;
    padding: 0 60px;
    .bp5-tab {
      color: ${({ theme }) => theme.colors.tabBarTabText};
      &:hover {
        color: ${({ theme }) => theme.colors.tabBarTabHoverText};
      }
    }
    .bp5-tab[aria-selected='true'] {
      color: ${({ theme }) => theme.colors.tabBarTabActiveText};
    }
    .bp5-tab-indicator {
      bottom: -9px;
      background-color: ${({ theme }) => theme.colors.highlight} !important;
    }
  }
`
