import { useMutation } from '@apollo/client'
import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
  Spinner,
} from '@blueprintjs/core'
import { DialogBody, DialogFooter } from '@blueprintjs/core'
import { useFormik } from 'formik'
import React from 'react'
import { FleetMultiSelect } from '../components/Select/FleetMultiSelect'
import { ToastType, useToaster } from '../hooks/useToaster'
import { IBaseDialogProps } from './types'
import { INVITE_DRIVER } from '../pages/Drivers/gql/inviteDriver'
import { LIST_DRIVERS } from '../pages/Drivers/gql/drivers'
import { PhoneInput } from '../components/Phone'

export const InviteDriverDialog = ({ closeDialog }: IBaseDialogProps) => {
  const [inviteDriver, { loading }] = useMutation(INVITE_DRIVER, {
    refetchQueries: [LIST_DRIVERS],
  })

  const openSuccessToast = useToaster({ type: ToastType.SUCCESS })
  const openErrorToast = useToaster({ type: ToastType.ERROR })

  const { values, handleChange, handleSubmit, errors, setFieldValue } =
    useFormik({
      initialValues: {
        phoneNumber: '',
        email: '',
        fleet: { id: '', name: '' },
        firstName: '',
        lastName: '',
      },
      onSubmit: async input => {
        const { fleet, ...vars } = input

        const { data } = await inviteDriver({
          variables: { input: { ...vars, fleetId: fleet.id } },
        })

        if (data?.inviteDriver?.id) {
          openSuccessToast(
            `Created driver ${data?.inviteDriver?.id} and sent notification to complete signup`
          )

          closeDialog()
        } else {
          openErrorToast('Failed to create driver')
        }
      },
    })

  const clearPhoneNumber = () => {
    setFieldValue('phoneNumber', '+44')
  }

  return (
    <>
      <DialogBody>
        <form onSubmit={handleSubmit}>
          <FormGroup label="Phone">
            <PhoneInput
              onChange={(number: string) => {
                setFieldValue('phoneNumber', `${'+' + number}`)
              }}
              clearInput={clearPhoneNumber}
              value={values.phoneNumber}
            />
          </FormGroup>
          <FormGroup label="Email">
            <InputGroup
              name="email"
              onChange={handleChange}
              intent={errors.email ? Intent.DANGER : Intent.NONE}
              value={values.email}
              data-testid="email-input"
              large
            />
          </FormGroup>
          <FormGroup label="Fleet">
            <FleetMultiSelect
              selectedItems={[values.fleet]}
              fill
              onItemSelect={item => {
                if (item) {
                  setFieldValue('fleet', item)
                }
              }}
              tagInputProps={{
                placeholder: 'Select fleet(s)',

                onRemove: (_tag, index) => {
                  setFieldValue('fleet', null)
                },
              }}
            />
          </FormGroup>
          <FormGroup
            label="First Name"
            helperText="Optional, can be filled in by the driver at registration"
          >
            <InputGroup
              name="firstName"
              onChange={handleChange}
              value={values.firstName}
              data-testid="first-name-input"
              large
            />
          </FormGroup>

          <FormGroup
            label="Last Name"
            helperText="Optional, can be filled in by the driver at registration"
          >
            <InputGroup
              name="lastName"
              onChange={handleChange}
              value={values.lastName}
              data-testid="last-name-input"
              large
            />
          </FormGroup>

          <DialogFooter>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={closeDialog}>Cancel</Button>
              <Button
                icon={loading && <Spinner size={20} />}
                intent={Intent.PRIMARY}
                type="submit"
              >
                Create
              </Button>
            </div>
          </DialogFooter>
        </form>
      </DialogBody>
    </>
  )
}
