import { typedGql } from '../../../zeus/typedDocumentNode'
import { $ } from '../../../zeus'

export const CALCULATE_ROUTE = typedGql('query')({
  calculateRoute: [
    {
      input: $('input', 'CalculateRouteInput!'),
    },
    true,
  ],
})
