import {
  pinPickup,
  pinDropOffB,
  pinDropOffC,
  pinDropOffD,
} from '../../assets/features'
import { Marker } from 'maplibre-gl'
import { Coordinates } from 'maplibre-gl-js-amplify/lib/esm/types'
import {
  createTaskPopup,
  PartialDropOffTask,
  PartialPickupTask,
} from './popupUtils'
import { createRoute } from './createRoute'
import { Location } from '../../utils/location'
import { createElementForMarker } from './createElementForMarker'

export const DROPOFF_TASK_INDEX_TO_PIN: Record<number, string> = {
  0: pinDropOffB,
  1: pinDropOffC,
  2: pinDropOffD,
}

export type TasksMarkersAndRoutesType = {
  tasksMarkers: Marker[] | undefined
  routesCoordinates: Coordinates[] | undefined
  estimatedRoute:
    | {
        id: string
        source: any
        layer: any
      }
    | undefined
  pickupLocation: Coordinates | undefined
}

/**
 *
 * @param job Required in order to draw tasks on map
 * @returns an object containing markers and a route, ready to be drawn on a map
 * note that it also attaches the popup info displayed on marker click
 */
export const createTasksAndRouteToBeDrawn = ({
  pickupTasks,
  dropOffTasks,
  route,
  multiLayer,
}: {
  pickupTasks: PartialPickupTask[]
  dropOffTasks: PartialDropOffTask[]
  route?: any
  multiLayer?: boolean
}): TasksMarkersAndRoutesType => {
  const response: TasksMarkersAndRoutesType = {
    tasksMarkers: undefined,
    routesCoordinates: undefined,
    estimatedRoute: undefined,
    pickupLocation: undefined,
  }

  // Pickup Tasks
  if (pickupTasks && pickupTasks.length > 0) {
    pickupTasks.forEach(pickupTask => {
      if (!pickupTask || !pickupTask.location) return

      const { longitude, latitude } = pickupTask.location as unknown as Location
      response.pickupLocation = [longitude, latitude]
      const el = createElementForMarker({
        id: pickupTask.id,
        src: pinPickup,
        style: {
          width: '26px',
          height: '33px',
        },
      })

      const popup = createTaskPopup(pickupTask)
      const marker = new Marker(el)
        .setLngLat([longitude, latitude])
        .setPopup(popup)

      response.tasksMarkers
        ? response.tasksMarkers.push(marker)
        : (response.tasksMarkers = [marker])
      response.routesCoordinates
        ? response.routesCoordinates.push([longitude, latitude])
        : (response.routesCoordinates = [[longitude, latitude]])
    })
  }

  // DropOff Tasks
  dropOffTasks.forEach(dropOffTask => {
    if (!dropOffTask || !dropOffTask.location) return

    const dropOffTaskLocation = dropOffTask.location
    const { longitude, latitude } = dropOffTaskLocation as unknown as Location
    const el = createElementForMarker({
      id: dropOffTask.id,
      src: DROPOFF_TASK_INDEX_TO_PIN[dropOffTask.orderInStack],
      style: {
        width: '26px',
        height: '33px',
      },
    })

    const popup = createTaskPopup(dropOffTask)
    const marker = new Marker(el)
      .setLngLat([longitude, latitude])
      .setPopup(popup)

    response.tasksMarkers
      ? response.tasksMarkers.push(marker)
      : (response.tasksMarkers = [marker])
    response.routesCoordinates
      ? response.routesCoordinates.push([longitude, latitude])
      : (response.routesCoordinates = [[longitude, latitude]])
  })

  // Route
  if (route) {
    const routeToBeDrawn = createRoute({ routeJSON: route, multiLayer })
    if (route) {
      response.estimatedRoute = routeToBeDrawn
    }
  }

  return response
}
