import { typedGql } from '../../../zeus/typedDocumentNode'
import { ResultOf } from '@graphql-typed-document-node/core'
import { $ } from '../../../zeus'

export const LIST_DRIVERS = typedGql('query')({
  drivers: [
    {
      where: $('where', 'DriverWhereInput'),
    },
    {
      id: true,
      phoneNumber: true,
      title: true,
      firstName: true,
      middleNames: true,
      lastName: true,
      email: true,
      completedSignUp: true,
      currentLocation: true,
      heading: true,
      speed: true,
      status: true,
      breaks: true,
      pushToken: true,
      updatedAt: true,
      allowJobRejection: true,
      allowStackingOrders: true,
    },
  ],
})
export const LIST_DRIVERS_SUBSCRIPTION = typedGql('subscription')({
  drivers: [
    {
      where: $('where', 'DriverWhereInput'),
    },
    {
      id: true,
      phoneNumber: true,
      title: true,
      firstName: true,
      middleNames: true,
      lastName: true,
      email: true,
      completedSignUp: true,
      currentLocation: true,
      heading: true,
      speed: true,
      status: true,
      breaks: true,
      pushToken: true,
      updatedAt: true,
      allowJobRejection: true,
      allowStackingOrders: true,
    },
  ],
})

export type LIST_DRIVERS_RESPONSE = ResultOf<typeof LIST_DRIVERS>
