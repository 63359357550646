import React from 'react'
import { addMinutes, differenceInMinutes, format } from 'date-fns'
import isNil from 'lodash/isNil'
import { DeltaContainer } from './styles'

export const DATE_TIME_FORMAT_FOR_ESTIMATED_TIME = 'HH:mm'

type EstimatedPickupTimeActualAndDeltaProps = {
  estimatedPickupTime?: Date | string
  pickupTime?: Date | string
  dateTimeFormat?: string
}

type EstimatedDropOffTimeActualAndDeltaProps = {
  pickupTime?: Date | string
  estimatedPickupTime?: Date | string
  estimatedDurationMinutes?: number
  completionTime?: Date | string
  dateTimeFormat?: string
}

export const EstimatedPickupTimeActualAndDelta = ({
  estimatedPickupTime,
  pickupTime,
  dateTimeFormat = DATE_TIME_FORMAT_FOR_ESTIMATED_TIME,
}: EstimatedPickupTimeActualAndDeltaProps) => {
  // calculate delta
  const delta =
    estimatedPickupTime && pickupTime
      ? differenceInMinutes(new Date(estimatedPickupTime), new Date(pickupTime))
      : undefined

  return (
    <span>
      {estimatedPickupTime
        ? format(new Date(estimatedPickupTime), dateTimeFormat)
        : '---'}
      &nbsp;/&nbsp;
      {pickupTime ? format(new Date(pickupTime), dateTimeFormat) : '---'}
      &nbsp;/&nbsp;
      {delta ? (
        <DeltaContainer delta={delta}>{delta} min</DeltaContainer>
      ) : (
        '---'
      )}
    </span>
  )
}

export const EstimatedDropOffTimeActualAndDelta = ({
  pickupTime,
  estimatedPickupTime,
  estimatedDurationMinutes,
  completionTime,
  dateTimeFormat = DATE_TIME_FORMAT_FOR_ESTIMATED_TIME,
}: EstimatedDropOffTimeActualAndDeltaProps) => {
  // calculate delta
  const delta =
    pickupTime && completionTime && !isNil(estimatedDurationMinutes)
      ? differenceInMinutes(
          addMinutes(new Date(pickupTime), estimatedDurationMinutes),
          new Date(completionTime)
        )
      : estimatedPickupTime &&
        completionTime &&
        !isNil(estimatedDurationMinutes)
      ? differenceInMinutes(
          addMinutes(new Date(estimatedPickupTime), estimatedDurationMinutes),
          new Date(completionTime)
        )
      : undefined

  return (
    <span>
      {pickupTime && !isNil(estimatedDurationMinutes)
        ? format(
            addMinutes(new Date(pickupTime), estimatedDurationMinutes),
            dateTimeFormat
          )
        : estimatedPickupTime && !isNil(estimatedDurationMinutes)
        ? format(
            addMinutes(new Date(estimatedPickupTime), estimatedDurationMinutes),
            dateTimeFormat
          )
        : '---'}
      &nbsp;/&nbsp;
      {completionTime
        ? format(new Date(completionTime as string), dateTimeFormat)
        : '---'}
      &nbsp;/&nbsp;
      {delta ? (
        <DeltaContainer delta={delta}>{delta} min</DeltaContainer>
      ) : (
        '---'
      )}
    </span>
  )
}
