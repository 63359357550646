import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const UPDATE_FLEET = typedGql('mutation')({
  updateFleet: [
    {
      input: {
        id: $('id', 'String!'),
        name: $('name', 'String'),
        leadTime: $('leadTime', 'Int'),
        jobAcceptWaitTime: $('jobAcceptWaitTime', 'Int'),
        jobDropOffAllocatedTime: $('jobDropOffAllocatedTime', 'Int'),
        outrightRejectJob: $('outrightRejectJob', 'Boolean'),
        pricePerDistanceUnit: $('pricePerDistanceUnit', 'Int'),
        defaultCostPerDistanceUnit: $('defaultCostPerDistanceUnit', 'Int'),
        distanceUnit: $('distanceUnit', 'DistanceUnit'),
        contactFullName: $('contactFullName', 'String'),
        contactEmail: $('contactEmail', 'String'),
        contactPhone: $('contactPhone', 'String'),
        contactFullAddress: $('contactFullAddress', 'String'),
      },
    },
    {
      name: true,
      leadTime: true,
      jobAcceptWaitTime: true,
      jobDropOffAllocatedTime: true,
      outrightRejectJob: true,
      pricePerDistanceUnit: true,
      defaultCostPerDistanceUnit: true,
      distanceUnit: true,
      contactEmail: true,
      contactPhone: true,
      contactFullName: true,
      contactFullAddress: true,
    },
  ],
})
