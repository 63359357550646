import { useQuery } from '@apollo/client'
import { HTMLSelect, Spinner } from '@blueprintjs/core'
import React from 'react'
import { BaseSelectProps } from './types'
import { LIST_PLATFORMS } from '../../pages/Platform/gql/platforms'
import { ModelTypes, SortOrder } from '../../zeus'

type PartialPlatform = Pick<ModelTypes['Platform'], 'id' | 'name'>

export const PlatformSelect = (props: BaseSelectProps) => {
  const { data, loading } = useQuery(LIST_PLATFORMS, {
    variables: { sortByNameDirection: SortOrder.asc },
  })

  if (loading) {
    return <Spinner />
  }

  const platforms =
    data?.platforms.map(({ id, name }: PartialPlatform) => ({
      value: id,
      label: name,
    })) || []

  return (
    <HTMLSelect
      {...props}
      options={[{ value: '', label: 'Select a platform...' }, ...platforms]}
    />
  )
}
