import React from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'

export const NotFound = () => {
  const location = useLocation()
  console.log(location)
  const match = useRouteMatch()
  console.log(match)

  return <div>Not found</div>
}
