import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'
import { ResultOf } from '@graphql-typed-document-node/core'

export const DRIVER_BY_ID = typedGql('query')({
  driver: [
    {
      id: $('driverId', 'String!'),
    },
    {
      id: true,
      phoneNumber: true,
      title: true,
      firstName: true,
      middleNames: true,
      lastName: true,
      email: true,
      completedSignUp: true,
      allowJobRejection: true,
      allowStackingOrders: true,
      currentLocation: true,
      status: true,
      vehicle: {
        id: true,
        reg: true,
      },
      breaks: true,
      fleetDrivers: {
        id: true,
        fleet: {
          id: true,
          name: true,
        },
      },
      costPerDistanceUnit: true,
      distanceUnit: true,
    },
  ],
})

export type DRIVER_BY_ID_RESPONSE = ResultOf<typeof DRIVER_BY_ID>
