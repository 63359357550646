import React, { useContext } from 'react'
import { useFormik } from 'formik'
import { FormGroup, InputGroup, Button, Intent } from '@blueprintjs/core'
import { LoginButtonContainer } from './Login.styles'
import { ToastType, useToaster } from '../../hooks/useToaster'
import { typedGql } from '../../zeus/typedDocumentNode'
import { $ } from '../../zeus'
import { useMutation } from '@apollo/client'
import { SessionTokenContext } from '../../providers/SessionTokenProvider'
import { get } from 'lodash'
import { UserActionType } from '../../providers/currentUserState'
import { CurrentUserContext } from '../../providers/CurrentUserProvider'

const loginMutation = typedGql('mutation')({
  login: [
    {
      input: {
        email: $('email', 'String!'),
        password: $('password', 'String!'),
      },
    },
    true,
  ],
})

export const LoginForm = () => {
  const showErrorToast = useToaster({ type: ToastType.ERROR })
  const [login] = useMutation(loginMutation)
  const { setStoredToken } = useContext(SessionTokenContext)
  const { dispatch } = useContext(CurrentUserContext)

  const { values, handleChange, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async values => {
      const { data } = await login({
        variables: {
          email: values.email,
          password: values.password,
        },
      })
      const token = data?.login

      if (token) setStoredToken(token)
    },
  })

  return (
    <form onSubmit={handleSubmit}>
      <FormGroup>
        <InputGroup
          placeholder={'Email'}
          value={values.email}
          onChange={handleChange}
          name="email"
          large
        />
      </FormGroup>
      <FormGroup>
        <InputGroup
          placeholder={'Password'}
          value={values.password}
          onChange={handleChange}
          type="password"
          name="password"
          large
        />
      </FormGroup>

      <LoginButtonContainer>
        <Button
          minimal
          intent={Intent.PRIMARY}
          style={{ marginRight: '10px' }}
          onClick={() => {
            dispatch({ type: UserActionType.REQUEST_RESET_PASSWORD })
          }}
        >
          Forgot?
        </Button>
        <Button type="submit" loading={isSubmitting}>
          Login
        </Button>
      </LoginButtonContainer>
    </form>
  )
}
