import { useQuery } from '@apollo/client'
import { HTMLTable } from '@blueprintjs/core'
import React from 'react'
import { useParams } from 'react-router-dom'
import {
  EmptyListState,
  ErrorState,
  LoadingState,
} from '../../../../components/NonIdealState/NonIdealState'
import { API_IMPLEMENTATIONS_BY_FLEET } from '../../gql/apiImplementationsByFleet'

type IRouteParams = {
  id: string
}

export const ApiKeys = () => {
  const { id } = useParams<IRouteParams>()

  const { data, loading, error } = useQuery(API_IMPLEMENTATIONS_BY_FLEET, {
    variables: { fleetId: id },
  })

  if (loading) {
    return <LoadingState entityName="api keys" />
  }

  if (error) {
    return <ErrorState entityName="api keys" />
  }

  if (!data?.apiImplementations.length) {
    return <EmptyListState entityName="api keys" />
  }

  return (
    <HTMLTable>
      <thead>
        <tr>
          <th>Name</th>
          <th>Key</th>
        </tr>
      </thead>
      <tbody>
        {data.apiImplementations.map(({ apiKey, id, name }) => (
          <tr key={id}>
            <td>{name}</td>
            <td>{apiKey}</td>
          </tr>
        ))}
      </tbody>
    </HTMLTable>
  )
}
