import { typedGql } from '../../../zeus/typedDocumentNode'
import { $ } from '../../../zeus'

export const CREATE_PARTNER = typedGql('mutation')({
  createPartner: [
    {
      input: {
        name: $('name', 'String!'),
        platform: {
          connect: {
            id: $('platformId', 'String!'),
          },
        },
      },
    },
    {
      id: true,
      name: true,
    },
  ],
})
