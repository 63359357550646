export const QUERY_PARAMS = {
  jobOrOrderLike: 'jobOrOrderLike',
  jobStatus: 'jobStatus',
  driverStatus: 'driverStatus',
  fleetId: 'fleetId',
  leadTimeGuide: 'leadTimeGuide',
  driverId: 'driverId',
  userId: 'userId',
  selectedDriver: 'selectedDriver',
  jobId: 'jobId',
  webhookId: 'webhookId',
  jobsOrderby: 'jobsOrderBy',
  dateFilterTypeKey: 'dateFilterTypeKey',
  startOfRangeDate: 'startOfRangeDate',
  endOfRangeDate: 'endOfRangeDate',
  stackId: 'stackId',
}
