import React from 'react'
import { ReactSVG } from 'react-svg'
import {
  LoginBackground,
  LoginCard,
  LoginContainer,
  LoginLogo,
} from './Login.styles'
import wayboxLogo from '../../assets/waybox-logo.svg'
import { ResetPasswordForm } from './ResetPasswordForm'

export const Reset = () => {
  return (
    <LoginBackground>
      <LoginContainer>
        <LoginCard elevation={2}>
          <LoginLogo>
            <ReactSVG src={wayboxLogo} alt="waybox logo" />
          </LoginLogo>
          <ResetPasswordForm />
        </LoginCard>
      </LoginContainer>
    </LoginBackground>
  )
}
