import React, { ReactNode } from 'react'
import { useDroppable } from '@dnd-kit/core'

type DroppableProps = {
  id: string
  children: ReactNode
}

// prefer in our case to make this table row a droppable area
// because the child (job row) might not exist, be empty, so on
export const DroppableTR = ({ id, children }: DroppableProps) => {
  const { setNodeRef } = useDroppable({
    id,
  })

  return (
    <tr ref={setNodeRef} style={{ overflowX: 'clip' }}>
      {children}
    </tr>
  )
}
