import { useQuery } from '@apollo/client'
import React from 'react'
import { JobStatus } from '../../../zeus'
import { TableView } from '../../../components/Views/Table'
import { StringParam, useQueryParam } from 'use-query-params'
import { QUERY_PARAMS } from '../../../utils/queryParamsNames'
import { FleetSelect } from '../../../components/Select/FleetSelect'
import { JOBS_WITH_EXCEPTIONS_BY_FLEET } from '../gql/jobsWithExceptionsByFleet'
import { JobStatusSection } from '../../Jobs/JobStatusSection'
import { EllipsisText } from '../../../atomics/EllipsisText'
import { ReassignDriver } from './ReassignDriver'
import { DATE_TIME_FORMAT } from '../../Jobs'
import { format } from 'date-fns'
import {
  EmptyListAwaitingUserInput,
  ErrorState,
  LoadingState,
} from '../../../components/NonIdealState/NonIdealState'
import { FilterBar } from '../../../components/Filters/FilterBar'

export const ExceptionsDashboard = () => {
  const [fleetId, setFleetId] = useQueryParam<string>(QUERY_PARAMS.fleetId)
  const [jobId, setJobId] = useQueryParam(QUERY_PARAMS.jobId, StringParam)
  const [_stackId, setStackId] = useQueryParam(
    QUERY_PARAMS.stackId,
    StringParam
  )

  const { data, loading, error } = useQuery(JOBS_WITH_EXCEPTIONS_BY_FLEET, {
    variables: {
      fleetId,
      jobsWhere: {
        AND: [
          { fleetId: { equals: fleetId } },
          { status: { in: [JobStatus.ASSIGNED] } },
        ],
      },
    },
    pollInterval: 1000 * 30, // 30 sec
    fetchPolicy: 'network-only',
  })

  let content = null
  if (loading) {
    content = <LoadingState entityName="exceptions" />
  } else if (data === null && error) {
    content = <ErrorState entityName="exceptions" />
  } else if (!fleetId) {
    content = <EmptyListAwaitingUserInput message="Please select a fleet" />
  } else if (fleetId && data?.exceptionQueue.length === 0) {
    content = <EmptyListAwaitingUserInput message="No results" />
  }

  return (
    <>
      <FilterBar>
        <FleetSelect
          onChange={e => {
            const id = e.target.value
            setFleetId(id)
          }}
        />
      </FilterBar>
      <div>
        <TableView>
          <thead>
            <tr>
              <tr></tr>
              <th>Job</th>
              <th>Stack</th>
              <th>Order</th>
              <th>Job / Stack Status</th>
              <th>Driver</th>
              <th>Fleet</th>

              <th>Created at</th>
              <th>Pickup Time Est.</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {data?.exceptionQueue.map((item, index) => (
              <tr key={item.id}>
                <td></td>
                <td
                  onClick={() => {
                    setStackId(item.stackId)
                    setJobId(item.id)
                  }}
                >
                  {item.stackId ? (
                    <EllipsisText>Stack {item.stackId}</EllipsisText>
                  ) : item.id ? (
                    item.jobReference
                  ) : (
                    '---'
                  )}
                </td>
                <td>
                  <EllipsisText>{item.stackId || '---'}</EllipsisText>
                </td>
                <td>{item.orderId}</td>
                <td>
                  <JobStatusSection status={item.status} onHold={item.onHold} />
                </td>
                <td>{item.driver?.firstName || '---'}</td>
                <td>{item.fleet.name}</td>
                <td>
                  {format(new Date(item.createdAt as string), DATE_TIME_FORMAT)}
                </td>
                <td>
                  {format(
                    new Date(item.estimatedPickupTime as string),
                    'HH:mm'
                  )}
                </td>
                <td>
                  <ReassignDriver jobId={item.id} />
                </td>
              </tr>
            ))}
          </tbody>
        </TableView>
        <div style={{ marginTop: '5%' }}>{content}</div>
      </div>
    </>
  )
}
