import React from 'react'
import { useFormik } from 'formik'
import { useMutation } from '@apollo/client'
import {
  Button,
  FormGroup,
  InputGroup,
  Intent,
  Spinner,
} from '@blueprintjs/core'

import { useToaster, ToastType } from '../../hooks/useToaster'
import { UPDATE_PLATFORM } from './gql/updatePlatform'
import { ModelTypes } from '../../zeus'

export const PlatformForm = ({
  platform,
}: {
  platform: Pick<ModelTypes['Platform'], 'id' | 'name'>
}) => {
  const showSuccessToast = useToaster({ type: ToastType.SUCCESS })
  const [updatePlatform, { loading: mutationLoading }] = useMutation(
    UPDATE_PLATFORM,
    {
      onCompleted: () => {
        showSuccessToast('Platform updated')
      },
    }
  )

  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      id: platform.id,
      name: platform.name,
    },
    onSubmit: values => {
      updatePlatform({
        variables: {
          input: values,
        },
      })
    },
  })

  return mutationLoading ? (
    <Spinner />
  ) : (
    <form onSubmit={handleSubmit}>
      <FormGroup label="Name">
        <InputGroup
          name="name"
          onChange={handleChange}
          intent={errors.name ? Intent.DANGER : Intent.NONE}
          value={values.name}
          large
        />
      </FormGroup>

      <Button type="submit">Save</Button>
    </form>
  )
}
