import { Button } from '@blueprintjs/core'
import React from 'react'

type DatePickerButtonProps = {
  disabled: boolean
  minimal: boolean
  children: React.ReactNode
}

export const DatePickerButton = ({
  disabled,
  minimal,
  children,
}: DatePickerButtonProps) => (
  <Button
    disabled={disabled}
    minimal={minimal}
    rightIcon="double-caret-vertical"
    fill={true}
  >
    {children}
  </Button>
)
