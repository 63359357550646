import { Card } from '@blueprintjs/core'
import React from 'react'
import { GET_JOB_RESPONSE_TYPE } from './gql/job'

// TODO https://korelogic.atlassian.net/browse/DIS-175 - allow editing from the website
export const ProofOfDeliveryPanel = ({
  job,
}: {
  job: GET_JOB_RESPONSE_TYPE['job']
}) => {
  if (!job) return null

  const { jobCompleteNotes, jobCompletePhotoProof } = job
  return (
    <Card>
      {jobCompleteNotes || jobCompletePhotoProof ? (
        <>
          {jobCompleteNotes && (
            <p>
              <span style={{ fontWeight: 'bold' }}>Notes: </span>
              {jobCompleteNotes}
            </p>
          )}
          {jobCompletePhotoProof && (
            <img
              src={jobCompletePhotoProof}
              alt="Proof of delivery picture"
              width={300}
            />
          )}
        </>
      ) : (
        <>No proof of delivery present</>
      )}
    </Card>
  )
}
