import styled from 'styled-components'

export const OptionsContainer = styled.div<{
  flexDirection: 'row' | 'column'
}>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
`

export const OptionContainer = styled.div<{
  flexDirection?: 'row' | 'column'
}>`
  margin-right: 5px;
  margin-bottom: ${({ flexDirection }) =>
    flexDirection === 'column' ? '5px' : 'inherit'};
`
