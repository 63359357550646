import { IconSize, Intent, Switch, Tag } from '@blueprintjs/core'
import React, { FC } from 'react'
import { DriverSection } from './DriverSection'
import { JobStatusSection } from './JobStatusSection'
import {
  OrderRefAndStatusAndDriverContainer,
  StatusAndDriverContainer,
} from './styles'
import { GET_JOB_RESPONSE_TYPE } from './gql/job'
import { ToastType, useToaster } from '../../hooks/useToaster'
import { useMutation } from '@apollo/client'
import { TOGGLE_ON_HOLD_SWITCH } from './gql/toggleOnHoldSwitch'
import { LIST_JOBS } from './gql/jobs'
import { Explanation } from '../../atomics/Explanation'

export const StatusAndDriver: FC<{
  job: GET_JOB_RESPONSE_TYPE['job']
  closeDrawer: Function
}> = ({ job, closeDrawer }) => {
  if (!job) return null
  const openErrorToast = useToaster({ type: ToastType.ERROR })
  const openSuccessToast = useToaster({ type: ToastType.SUCCESS })

  const [toggleOnHoldSwitch, { loading: toggleOnHoldSwitchLoading }] =
    useMutation(TOGGLE_ON_HOLD_SWITCH, {
      refetchQueries: [LIST_JOBS],
      onCompleted: _data => {
        openSuccessToast('Updated job!')
        closeDrawer()
      },
      onError: err => {
        openErrorToast(err.message)
      },
    })

  const ageRestricted = job.dropOffTasks?.some(
    dropOffTask => dropOffTask?.ageVerification
  )

  return (
    <OrderRefAndStatusAndDriverContainer>
      <div>
        <div>
          <p
            style={{
              padding: '5px 0px',
            }}
          >
            <strong>Job:</strong> {job.id}
            &nbsp;/ <strong>Job reference:</strong> {job.jobReference}
            &nbsp;/ <strong>Fleet name:</strong> {job.fleet.name}
          </p>
        </div>
        <div>
          <p
            style={{
              padding: '5px 0px',
            }}
          >
            <strong>Order:</strong> {job.orderId}{' '}
            {ageRestricted && (
              <Tag minimal round intent={Intent.DANGER}>
                18+
              </Tag>
            )}
            {job.orderIsAsap && (
              <>
                {' '}
                <Explanation
                  icon="time"
                  intent={Intent.PRIMARY}
                  size={IconSize.STANDARD}
                  content="Order is ASAP"
                />
              </>
            )}
            &nbsp;<strong>/ Order ref:</strong> {job.orderReference}
          </p>
        </div>
      </div>
      <StatusAndDriverContainer>
        <JobStatusSection status={job.status} onHold={job.onHold} />
        <DriverSection
          driver={job.driver}
          status={job.status}
          completedByDriverId={job.completedByDriverId}
          completedByDriverFullName={job.completedByDriverFullName}
        />
        <Switch
          label="On Hold"
          defaultChecked={job.onHold}
          disabled={toggleOnHoldSwitchLoading}
          onChange={async e => {
            await toggleOnHoldSwitch({
              variables: {
                id: job.id,
                onHold: e.currentTarget.checked,
              },
            })
          }}
        />
      </StatusAndDriverContainer>
    </OrderRefAndStatusAndDriverContainer>
  )
}
