import { useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router'
import {
  EmptyListState,
  ErrorState,
  LoadingState,
} from '../../../../components/NonIdealState/NonIdealState'
import { EditFleetForm } from './EditFleetForm'
import { FLEET_BY_ID } from '../../gql/fleet'

type IRouteParams = {
  id: string
}

export const EditFleetContent = () => {
  const { id } = useParams<IRouteParams>()

  const { data, loading, error } = useQuery(FLEET_BY_ID, {
    variables: { id },
    fetchPolicy: 'network-only',
  })

  if (loading) {
    return <LoadingState entityName="fleet" />
  }

  if (error) {
    return <ErrorState entityName="fleet" />
  }

  if (!data?.fleet) {
    return <EmptyListState entityName="fleet" />
  }

  return <EditFleetForm fleet={data.fleet} />
}
