import React, { useContext } from 'react'
import { Card } from '@blueprintjs/core'
import { Redirect, Route, Switch } from 'react-router'
import { EditFleetContent } from './Details/EditFleetContent'
import { ApiKeys } from './ApiKeys/ApiKeys'
import { configurePageLayout } from '../../../providers/PageLayoutProvider'
import { Webhooks } from './Webhooks/Webooks'
import { useLocation, useParams } from 'react-router-dom'
import { AddWebhookDialog } from '../../../dialogs/AddWebhooksDialog'
import { GenerateApiKeyDialog } from '../../../dialogs/GenerateApiKeyDialog'
import { DialogContext, DialogConfig } from '../../../providers/DialogProvider'
import { FormView } from '../../../components/Views/Form'
import { PageLayoutButton } from '../../../components/Header/types'

const getButtonForRoute = (
  route: string,
  id: string,
  closeDialog: () => void,
  // TODO move this into a cusotm hook
  openDialog: (dialog: DialogConfig) => void
): PageLayoutButton | null => {
  const buttonPerRoute: Record<string, PageLayoutButton | null> = {
    details: null,
    'api-keys': {
      onClick: () => {
        openDialog({
          title: 'Add Api Key',
          content: (
            <GenerateApiKeyDialog fleetId={id} closeDialog={closeDialog} />
          ),
        })
      },
      text: 'Generate API Key',
    },
    webhooks: {
      onClick: () => {
        openDialog({
          title: 'Add Webhook',
          content: <AddWebhookDialog fleetId={id} closeDialog={closeDialog} />,
        })
      },
      text: 'Add Webhook',
    },
  }

  return buttonPerRoute[route]
}

type FleetIdParam = {
  id: string
}

export const Fleet = () => {
  const { closeDialog, openDialog } = useContext(DialogContext)

  const pathname = useLocation().pathname.split('/')[3]
  const { id } = useParams<FleetIdParam>()

  configurePageLayout({
    tabs: [
      { path: 'details', title: 'Details' },
      { path: 'api-keys', title: 'API Keys' },
      { path: 'webhooks', title: 'Webhooks' },
    ],
    button: getButtonForRoute(pathname, id, closeDialog, openDialog),
  })

  return (
    <Switch>
      <Redirect exact path="/fleets/:id" to="/fleets/:id/details" />
      <Route path="/fleets/:id/details">
        <FormView>
          <Card>
            <EditFleetContent />
          </Card>
        </FormView>
      </Route>
      <Route path="/fleets/:id/api-keys">
        <FormView>
          <Card>
            <ApiKeys />
          </Card>
        </FormView>
      </Route>
      <Route path="/fleets/:id/webhooks">
        <FormView>
          <Card>
            <Webhooks />
          </Card>
        </FormView>
      </Route>
    </Switch>
  )
}
