import { typedGql } from '../../../zeus/typedDocumentNode'
import { $ } from '../../../zeus'
import { platformSelector } from './platformSelector'

export const UPDATE_PLATFORM = typedGql('mutation')({
  updatePlatform: [
    {
      input: $('input', 'PlatformUpdateInput!'),
    },
    platformSelector,
  ],
})
