import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const ASSIGN_JOB_TO_DRIVER = typedGql('mutation')({
  assignJobToDriver: [
    {
      input: $('input', 'AssignJobToDriverInput!'),
    },
    {
      id: true,
      driverId: true,
      assignmentHistory: {
        id: true,
      },
    },
  ],
})
