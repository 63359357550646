import { useMutation } from '@apollo/client'
import React, { useContext, useRef, useState } from 'react'
import { DRIVER_BY_ID, DRIVER_BY_ID_RESPONSE } from './gql/driverById'
import { UPDATE_DRIVER } from './gql/updateDriver'
import { useFormik } from 'formik'
import { ToastType, useToaster } from '../../hooks/useToaster'
import {
  Alignment,
  Button,
  Card,
  Classes,
  Collapse,
  FormGroup,
  InputGroup,
  Intent,
  Popover,
  Switch,
} from '@blueprintjs/core'
import { DialogBody, DialogFooter } from '@blueprintjs/core'
import { FleetMultiSelect } from '../../components/Select/FleetMultiSelect'
import { StringParam, useQueryParam } from 'use-query-params'
import { DriverStatusOptionsSelect } from '../../components/Select/DriverStatusOptionsSelect'
import { DriverStatus, ModelTypes, UserRole } from '../../zeus'
import { LIST_DRIVERS } from './gql/drivers'
import { TitleSelect } from '../../components/Select/TitleSelect'
import { CurrentUserContext } from '../../providers/CurrentUserProvider'
import { DeleteDriverDialog } from '../../dialogs/DeleteDriverDialog'
import { DistanceUnitSelect } from '../../components/Select/DistanceUnitSelect'
import { NotifyDriverDialog } from '../../dialogs/NotifyDriverDialog'
import { QUERY_PARAMS } from '../../utils/queryParamsNames'
import { PhoneInput } from '../../components/Phone'
import { REMOVE_DRIVER_STATUS_OPTION } from './gql/removeDriverStatusOption'

export const DriverDetails = ({
  driver,
}: {
  driver: DRIVER_BY_ID_RESPONSE['driver']
}) => {
  if (!driver) return null

  // keep a reference to show warning
  const initialCost = useRef<string>(
    (driver.costPerDistanceUnit / 100).toFixed(2)
  )
  const [currentCost, setCurrentCost] = useState<string>(
    (driver.costPerDistanceUnit / 100).toFixed(2)
  )

  const { currentUser } = useContext(CurrentUserContext)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const [addOptionDialogOpen, setAddOptionDialogOpen] = useState<boolean>(false)
  const [isNotifyDialogOpen, setIsNotifyDialogOpen] = useState<boolean>(false)
  const [optionsExpanded, setOptionsExpanded] = useState<boolean>(false)
  const showSuccessToast = useToaster({ type: ToastType.SUCCESS })
  const showErrorToast = useToaster({ type: ToastType.ERROR })

  const [updateDriver] = useMutation(UPDATE_DRIVER, {
    refetchQueries: [
      LIST_DRIVERS,
      {
        query: DRIVER_BY_ID,
        variables: { driverId: driver.id },
      },
    ],
  })

  const [removeOption] = useMutation(REMOVE_DRIVER_STATUS_OPTION, {
    refetchQueries: [
      LIST_DRIVERS,
      {
        query: DRIVER_BY_ID,
        variables: { driverId: driver.id },
      },
    ],
  })

  const handleClick = (option: ModelTypes['OptionForDriverStatus']) => {
    removeOption({
      variables: {
        driverId: driver.id,
        option,
      },
      onError: err => {
        showErrorToast(err.message ?? 'Could not remove option')
      },
      onCompleted: () => {
        showSuccessToast('Option removed')
      },
    })
  }

  const [driverId, setDriverId] = useQueryParam(
    QUERY_PARAMS.driverId,
    StringParam
  )
  const fleets = driver?.fleetDrivers?.map(fd => fd.fleet) || []

  const { values, handleChange, handleSubmit, setFieldValue, isSubmitting } =
    useFormik({
      initialValues: {
        fleets: fleets || '',
        driverStatus: driver.status || DriverStatus.INACTIVE,
        title: driver.title || '',
        firstName: driver.firstName || '',
        middleNames: driver.middleNames || '',
        lastName: driver.lastName || '',
        phoneNumber: driver.phoneNumber || '',
        email: driver.email || '',
        completedSignUp: driver.completedSignUp,
        allowJobRejection: driver.allowJobRejection,
        allowStackingOrders: driver.allowStackingOrders,
        costPerDistanceUnit: driver.costPerDistanceUnit,
        distanceUnit: driver.distanceUnit,
      },
      onSubmit: async values => {
        const newCost = Math.round(parseFloat(currentCost) * 100)
        const isNewCostValid = !Number.isNaN(newCost)

        if (!isNewCostValid) {
          showErrorToast(
            'There was an error while updating the cost and will keep the old value. Please inform your friendly admin.'
          )
        }

        await updateDriver({
          variables: {
            input: {
              id: driver.id,
              status: values.driverStatus,
              title: values.title,
              firstName: values.firstName,
              middleNames: values.middleNames,
              lastName: values.lastName,
              email: values.email,
              phoneNumber: values.phoneNumber,
              completedSignUp: values.completedSignUp,
              allowJobRejection: values.allowJobRejection,
              allowStackingOrders: values.allowStackingOrders,
              costPerDistanceUnit: isNewCostValid
                ? newCost
                : driver.costPerDistanceUnit,
              fleetIds: values.fleets.map(fleet => fleet.id),
            },
          },
        })

        showSuccessToast('Driver successfully updated')
        setDriverId(null)
      },
    })

  const initialBreaks =
    (driver.breaks as unknown as ModelTypes['OptionForDriverStatus'][]) || []

  const clearPhoneNumber = () => {
    setFieldValue('phoneNumber', '+44')
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{ overflowY: 'scroll', paddingBottom: '20px' }}
    >
      <DialogBody useOverflowScrollContainer={false}>
        <FormGroup label="Fleet">
          <FleetMultiSelect
            selectedItems={values.fleets}
            fill
            onItemSelect={item => {
              if (item) {
                setFieldValue('fleets', [...values.fleets, item])
              }
            }}
            tagInputProps={{
              placeholder: 'Select fleet(s)',

              onRemove: (_tag, index) => {
                setFieldValue(
                  'fleets',
                  values.fleets.filter((_, i) => i !== index)
                )
              },
            }}
          />
        </FormGroup>

        <FormGroup label="Title">
          <TitleSelect
            name="title"
            onChange={handleChange}
            value={values.title}
            fill
          />
        </FormGroup>

        <FormGroup label="First Name">
          <InputGroup
            name="firstName"
            onChange={handleChange}
            value={values.firstName}
            fill
          />
        </FormGroup>

        <FormGroup label="Middle Names">
          <InputGroup
            name="middleNames"
            onChange={handleChange}
            value={values.middleNames}
            fill
          />
        </FormGroup>

        <FormGroup label="Last Name">
          <InputGroup
            name="lastName"
            onChange={handleChange}
            value={values.lastName}
            fill
          />
        </FormGroup>

        <FormGroup label="Email">
          <InputGroup
            name="email"
            onChange={handleChange}
            value={values.email}
            fill
          />
        </FormGroup>

        <FormGroup>
          <Card>
            <FormGroup
              label="Distance Unit"
              helperText="Can only be changed at the fleet level"
            >
              <DistanceUnitSelect
                name="distanceUnit"
                disabled
                onChange={handleChange}
                value={values.distanceUnit}
                fill
              />
            </FormGroup>

            <FormGroup
              label="Cost per Unit"
              labelFor="costPerDistanceUnit"
              helperText={
                currentCost !== initialCost.current
                  ? 'This change will only apply to future jobs'
                  : ''
              }
              intent={
                currentCost !== initialCost.current
                  ? Intent.WARNING
                  : Intent.NONE
              }
            >
              <InputGroup
                value={currentCost}
                min={0}
                name="costPerDistanceUnit"
                onChange={e => {
                  setCurrentCost(e.target.value)
                }}
                intent={
                  currentCost !== initialCost.current
                    ? Intent.WARNING
                    : Intent.NONE
                }
              />
            </FormGroup>
          </Card>
        </FormGroup>

        <FormGroup>
          <Card>
            <FormGroup label="Phone">
              <PhoneInput
                onChange={(number: string) => {
                  setFieldValue('phoneNumber', `${'+' + number}`)
                }}
                clearInput={clearPhoneNumber}
                value={values.phoneNumber}
              />
            </FormGroup>

            <Switch
              alignIndicator={Alignment.RIGHT}
              label="SignUp Complete"
              name="completedSignUp"
              defaultChecked={values.completedSignUp}
              onChange={handleChange}
            />
            <Switch
              alignIndicator={Alignment.RIGHT}
              label="Can accept / reject jobs"
              name="allowJobRejection"
              defaultChecked={values.allowJobRejection}
              onChange={handleChange}
            />
            <Switch
              alignIndicator={Alignment.RIGHT}
              label="Suitable for stacked jobs"
              name="allowStackingOrders"
              defaultChecked={values.allowStackingOrders}
              onChange={handleChange}
            />
          </Card>
        </FormGroup>
        <FormGroup>
          <Card>
            <Button
              minimal
              rightIcon={optionsExpanded ? 'caret-up' : 'caret-down'}
              text="Manage Breaks"
              onClick={e => {
                e.preventDefault()

                setOptionsExpanded(!optionsExpanded)
              }}
            />

            <Collapse isOpen={optionsExpanded}>
              <DriverStatusOptionsSelect />
            </Collapse>
          </Card>
        </FormGroup>
      </DialogBody>
      <DialogFooter>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {currentUser?.role === UserRole.ADMIN && (
            <>
              <Popover
                interactionKind="click"
                isOpen={isDeleteDialogOpen}
                canEscapeKeyClose
                onInteraction={state => setIsDeleteDialogOpen(state)}
                content={
                  <DeleteDriverDialog
                    closeContainer={() => setIsDeleteDialogOpen(false)}
                    driverId={driver!.id}
                  />
                }
              >
                <Button
                  minimal
                  intent={Intent.DANGER}
                  icon="trash"
                  onClick={() => setIsDeleteDialogOpen(true)}
                />
              </Popover>

              <Popover
                interactionKind="click"
                isOpen={isNotifyDialogOpen}
                canEscapeKeyClose
                onInteraction={state => setIsNotifyDialogOpen(state)}
                content={
                  <NotifyDriverDialog
                    closeContainer={() => setIsNotifyDialogOpen(false)}
                    driverId={driver!.id}
                  />
                }
              >
                <Button
                  minimal
                  intent={Intent.PRIMARY}
                  icon="notifications"
                  onClick={() => setIsNotifyDialogOpen(true)}
                />
              </Popover>
            </>
          )}
          <Button
            onClick={() => {
              setDriverId(null)
            }}
            disabled={isSubmitting}
            minimal
          >
            Cancel
          </Button>
          <Button type="submit" loading={isSubmitting} intent={Intent.PRIMARY}>
            Save
          </Button>
        </div>
      </DialogFooter>
    </form>
  )
}
