import { useMutation, useQuery } from '@apollo/client'
import { Popover, Menu, Button, MenuItem, MenuDivider } from '@blueprintjs/core'
import React from 'react'
import { useQueryParam } from 'use-query-params'
import { QUERY_PARAMS } from '../../../utils/queryParamsNames'
import { DriverStatus } from '../../../zeus'
import { LIST_DRIVERS } from '../gql/listDrivers'
import { useToaster, ToastType } from '../../../hooks/useToaster'
import { ASSIGN_JOB_TO_DRIVER } from '../../Jobs/gql/assignJobToDriver'
import { JOBS_WITH_EXCEPTIONS_BY_FLEET } from '../gql/jobsWithExceptionsByFleet'

interface reassignDriverProps {
  jobId: string
}

export const ReassignDriver = ({ jobId }: reassignDriverProps) => {
  const [status] = useQueryParam<DriverStatus>(QUERY_PARAMS.driverStatus)
  const [assignJob] = useMutation(ASSIGN_JOB_TO_DRIVER)
  const [fleetId] = useQueryParam<string>(QUERY_PARAMS.fleetId)
  const openErrorToast = useToaster({ type: ToastType.ERROR })
  const openSuccessToast = useToaster({ type: ToastType.SUCCESS })

  const { data: driverData } = useQuery(LIST_DRIVERS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        status,
        ...(fleetId && {
          fleetDrivers: {
            some: {
              fleetId: {
                equals: fleetId,
              },
            },
          },
        }),
        completedSignUp: { equals: true },
      },
    },
  })

  return (
    <Popover
      content={
        <Menu>
          {driverData?.drivers?.length === 0 ? (
            <MenuItem text="No available drivers" />
          ) : (
            driverData?.drivers?.map((item, index) => {
              return (
                <>
                  <MenuItem
                    text={`${item.firstName} ${item.lastName}`}
                    onClick={async () => {
                      await assignJob({
                        variables: {
                          input: {
                            jobId: jobId as string,
                            driverId: item.id as string,
                          },
                        },
                        onError: err => {
                          openErrorToast(
                            `Could not assign job to driver, ${err.message}`
                          )
                        },
                        onCompleted: () => {
                          openSuccessToast(
                            'Job assigned. If the driver rejects the job it will be put in the exception queue.'
                          )
                        },
                        refetchQueries: [
                          {
                            query: JOBS_WITH_EXCEPTIONS_BY_FLEET,
                            variables: { fleetId: fleetId },
                          },
                        ],
                      })
                    }}
                  />
                  <MenuDivider />
                </>
              )
            })
          )}
        </Menu>
      }
      placement="bottom"
    >
      <Button
        alignText="left"
        icon="drive-time"
        rightIcon="caret-down"
        text="Reassign"
      />
    </Popover>
  )
}
