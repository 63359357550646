import React from 'react'
import { useQuery } from '@apollo/client'
import { USER_BY_ID } from './gql/userById'
import {
  EmptyListState,
  ErrorState,
  LoadingState,
} from '../../../components/NonIdealState/NonIdealState'
import { EditUserForm } from './EditUserForm'

export const UserDrawer = ({ userId }: { userId: string }) => {
  const { data, loading, error } = useQuery(USER_BY_ID, {
    variables: {
      userId,
    },
    fetchPolicy: 'network-only',
  })

  if (loading) {
    return <LoadingState entityName="user" />
  }
  if (error) {
    return <ErrorState entityName="user" />
  }
  if (!data?.userById) {
    return <EmptyListState entityName="user" />
  }

  return <EditUserForm user={data.userById} />
}
