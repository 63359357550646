import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  Button,
  Classes,
  ControlGroup,
  FormGroup,
  H1,
  InputGroup,
  Intent,
  Spinner,
  Switch,
  TextArea,
} from '@blueprintjs/core'
import { DialogBody, DialogFooter } from '@blueprintjs/core'
import { IBaseDialogProps } from './types'
import { Formik } from 'formik'
import { MapSection } from '../components/MapSection'
import { Map, Marker } from 'maplibre-gl'
import { CREATE_JOB_AND_TASKS } from '../pages/Jobs/gql/createJob'
import { ToastType, useToaster } from '../hooks/useToaster'
import { LIST_JOBS } from '../pages/Jobs/gql/jobs'
import { FleetMultiSelect } from '../components/Select/FleetMultiSelect'
import { addMinutes } from 'date-fns'
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css'
import { nanoid } from 'nanoid'
import { pinPickup, pinDropOffB } from '../assets/features'

export const CreateJobDialog = ({ closeDialog }: IBaseDialogProps) => {
  const openErrorToast = useToaster({ type: ToastType.ERROR })
  const [pickupLocation, setPickupLocation] = useState({
    location: {
      longitude: -1.5356,
      latitude: 53.7934,
    },
    locationName: 'Raviolli Ross',
  })
  const [dropOffLocation, setDropOffLocation] = useState({
    location: {
      longitude: -1.5666,
      latitude: 53.7963,
    },
    locationName: 'Korelogic',
  })

  const [createJobFn, { loading, error }] = useMutation(CREATE_JOB_AND_TASKS, {
    refetchQueries: [LIST_JOBS],
  })

  const handleCreateJob = async ({
    orderId,
    orderIsAsap,
    orderReference,
    orderContents,
    fleetId,
    estimatedPickupTime,
  }: {
    orderId: string
    orderIsAsap: boolean
    orderReference: string
    orderContents: string[]
    fleetId: string
    estimatedPickupTime?: string
  }) =>
    await createJobFn({
      variables: {
        input: {
          fleetId,
          orderReference,
          estimatedPickupTime,
          orderContents,
          orderId,
          orderIsAsap,
          pickupTask: {
            pickupNotes: 'Knock at the door',
            pickupContact: {
              name: 'Some Contact',
              phone: '+447123456789',
            },
            location: {
              ...pickupLocation.location,
              address: {
                firstLine: '6 Mill Hill',
                secondLine: '',
                thirdLine: '',
                postcode: 'LS1 5DQ',
                city: 'Leeds',
              },
            },
            locationName: pickupLocation.locationName,
          },
          dropOffTask: {
            dropOffNotes: 'leave in the safe place',
            proofOfDelivery: 'path/to/picture',
            dropOffContact: {
              phone: '+447123456789',
              name: 'Some DropOff Contact',
            },
            location: {
              ...dropOffLocation.location,
              address: {
                firstLine: 'Castleton Mill',
                secondLine: '',
                thirdLine: '',
                postcode: 'LS12 2DS',
                city: 'Leeds',
              },
            },
            locationName: dropOffLocation.locationName,
          },
        },
      },
    })

  // callback passed to the MapSection component used for side effects
  const dragMarkersCallback = (map: Map) =>
    map.on('load', () => {
      const pickupEl = document.createElement('img')
      pickupEl.id = 'pickup-marker'
      pickupEl.src = pinPickup
      pickupEl.style.width = '26px'
      pickupEl.style.height = '33px'

      const dropOffEl = document.createElement('img')
      dropOffEl.id = 'dropoff-marker'
      dropOffEl.src = pinDropOffB
      dropOffEl.style.width = '26px'
      dropOffEl.style.height = '33px'

      const pickupMarker = new Marker(pickupEl)
        .setLngLat([
          pickupLocation.location.longitude,
          pickupLocation.location.latitude,
        ])
        .setDraggable(true)
        .addTo(map)
      const onPickupMarkerDragEnd = () => {
        const lngLat = pickupMarker.getLngLat()
        setPickupLocation({
          ...pickupLocation,
          location: {
            longitude: lngLat.lng,
            latitude: lngLat.lat,
          },
        })
      }
      pickupMarker.on('dragend', onPickupMarkerDragEnd)

      const dropOffMarker = new Marker(dropOffEl)
        .setLngLat([
          dropOffLocation.location.longitude,
          dropOffLocation.location.latitude,
        ])
        .setDraggable(true)
        .addTo(map)

      const onDropOffMarkerDragEnd = () => {
        const lngLat = dropOffMarker.getLngLat()
        setDropOffLocation({
          ...dropOffLocation,
          location: {
            longitude: lngLat.lng,
            latitude: lngLat.lat,
          },
        })
      }
      dropOffMarker.on('dragend', onDropOffMarkerDragEnd)
    })

  if (loading) return <Spinner />
  if (error) return <H1>{error.message}</H1>

  return (
    <Formik
      initialValues={{
        orderId: nanoid(10),
        orderReference: nanoid(10),
        orderContents: ['burgers x 2, 3ft pizza'],
        fleet: { id: '', name: '' },
        orderIsAsap: true,
        estimatedPickupTime: addMinutes(new Date(), 30).toISOString(),
      }}
      onSubmit={({
        orderId,
        orderIsAsap,
        orderReference,
        orderContents,
        fleet,
        estimatedPickupTime,
      }) => {
        handleCreateJob({
          orderId,
          orderIsAsap,
          orderReference,
          orderContents,
          fleetId: fleet.id,
          estimatedPickupTime,
        })
          .catch(error => {
            openErrorToast(error.message)
          })
          .finally(() => {
            closeDialog()
          })
      }}
    >
      {({ handleSubmit, handleChange, values, setFieldValue }) => (
        <DialogBody useOverflowScrollContainer={false}>
          <form onSubmit={handleSubmit}>
            <ControlGroup fill>
              <FormGroup label="Pickup time" labelFor="estimatedPickupTime">
                <InputGroup
                  name="estimatedPickupTime"
                  type="text"
                  onChange={handleChange}
                  value={values.estimatedPickupTime}
                  placeholder="Preorder time e.g: 2022-08-02T13:05:59.598Z"
                />
              </FormGroup>
              <FormGroup
                label="Order is ASAP"
                labelFor="orderIsAsap"
                className={Classes.FIXED}
                style={{ marginLeft: '10px' }}
              >
                <Switch
                  name="orderIsAsap"
                  checked={values.orderIsAsap}
                  onChange={handleChange}
                />
              </FormGroup>
            </ControlGroup>
            <FormGroup label="Fleet" labelFor="fleet">
              <FleetMultiSelect
                selectedItems={[values.fleet]}
                fill
                onItemSelect={item => {
                  if (item) {
                    setFieldValue('fleet', item)
                  }
                }}
                tagInputProps={{
                  placeholder: 'Select fleet(s)',

                  onRemove: (_tag, index) => {
                    setFieldValue('fleet', null)
                  },
                }}
              />
            </FormGroup>
            <FormGroup label="Order ID" labelFor="orderId">
              <InputGroup
                name="orderId"
                type="text"
                onChange={handleChange}
                value={values.orderId}
              />
            </FormGroup>
            <FormGroup label="Order Reference" labelFor="orderReference">
              <InputGroup
                name="orderReference"
                type="text"
                onChange={handleChange}
                value={values.orderReference}
              />
            </FormGroup>
            <FormGroup label="Order Contents" labelFor="orderContents">
              <TextArea
                name="orderContents"
                growVertically={true}
                onChange={handleChange}
                value={values.orderContents}
              />
            </FormGroup>
            <ControlGroup
              vertical={false}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <ControlGroup vertical={true}>
                <FormGroup
                  label="Pickup Location Name"
                  labelFor="pickupLocationName"
                >
                  <InputGroup
                    name="pickupLocationName"
                    placeholder={pickupLocation.locationName}
                    onChange={e => {
                      setPickupLocation({
                        ...pickupLocation,
                        locationName: e.target.value,
                      })
                    }}
                  />
                </FormGroup>
                <FormGroup
                  label="Pickup Location (A)"
                  labelFor="pickupLocation"
                >
                  <TextArea
                    rows={2}
                    name="pickupLocation"
                    placeholder="Click on the map"
                    value={Object.values(pickupLocation.location)
                      .toString()
                      .replace(',', ',\n')}
                    readOnly={true}
                  />
                </FormGroup>
              </ControlGroup>

              <ControlGroup vertical={true}>
                <FormGroup
                  label="DropOff Location Name"
                  labelFor="dropOffLocationName"
                >
                  <InputGroup
                    name="dropOffLocationName"
                    placeholder={dropOffLocation.locationName}
                    onChange={e => {
                      setDropOffLocation({
                        ...dropOffLocation,
                        locationName: e.target.value,
                      })
                    }}
                  />
                </FormGroup>
                <FormGroup
                  label="DropOff Location (B)"
                  labelFor="dropOffLocation"
                >
                  <TextArea
                    rows={2}
                    name="dropOffLocation"
                    placeholder="Click on the map"
                    value={Object.values(dropOffLocation.location)
                      .toString()
                      .replace(',', ',\n')}
                    readOnly={true}
                  />
                </FormGroup>
              </ControlGroup>
            </ControlGroup>
            <FormGroup>
              <MapSection
                mapFunctionCallback={dragMarkersCallback}
                showControls={true}
              />
            </FormGroup>
            <DialogFooter>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                  text="Create"
                  intent={Intent.PRIMARY}
                  type="submit"
                  disabled={!values.fleet.id}
                />
              </div>
            </DialogFooter>
          </form>
        </DialogBody>
      )}
    </Formik>
  )
}
