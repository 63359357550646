import React, { useContext } from 'react'
import { TableView } from '../../components/Views/Table'
import { AddPartnerDialog } from '../../dialogs/AddPartnerDialog'
import { DialogContext } from '../../providers/DialogProvider'
import { configurePageLayout } from '../../providers/PageLayoutProvider'
import { PartnerContent } from './PartnerContent'

export const Partners = () => {
  const { openDialog, closeDialog } = useContext(DialogContext)

  configurePageLayout({
    tabs: [],
    button: {
      onClick: () =>
        openDialog({
          title: 'Add Partner',
          content: <AddPartnerDialog closeDialog={closeDialog} />,
        }),
      text: 'Add Partner',
    },
  })

  return (
    <TableView>
      <PartnerContent />
    </TableView>
  )
}
