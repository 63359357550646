import 'react-phone-input-2/lib/style.css'
import { Icon } from '@blueprintjs/core'
import React, { useContext } from 'react'
import PhoneInputLib from 'react-phone-input-2'
import { ToastType, useToaster } from '../../hooks/useToaster'
import { PageLayoutContext } from '../../providers/PageLayoutProvider'

type Props = {
  onChange: (number: string) => void
  value: string
  clearInput: () => void
}
export const PhoneInput = ({ onChange, value, clearInput }: Props) => {
  const { darkMode } = useContext(PageLayoutContext)
  const openErrorToast = useToaster({ type: ToastType.ERROR })

  // Stand in for now. Improved form validation is needed
  const validatePhoneNumber = () => {
    if (value.length < 11) {
      openErrorToast('Please enter a valid phone number')
    }
  }

  return (
    <div style={{ alignItems: 'center', display: 'flex' }}>
      <PhoneInputLib
        disableSearchIcon={true}
        country={'gb'}
        countryCodeEditable={false}
        enableSearch
        copyNumbersOnly={false}
        inputStyle={{
          width: '100%',
          height: 35,
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          borderRightWidth: 0,
          backgroundColor: darkMode ? 'rgba(17, 20, 24, 0.3)' : undefined,
        }}
        buttonStyle={{
          backgroundColor: darkMode ? 'rgba(17, 20, 24, 0.3)' : undefined,
        }}
        searchStyle={{
          backgroundColor: darkMode ? 'rgba(17, 20, 24, 0.3)' : undefined,
          color: 'black',
        }}
        onChange={onChange}
        onBlur={validatePhoneNumber}
        onlyCountries={['gb']}
        value={value}
      />
      <button
        onClick={clearInput}
        className={darkMode ? 'bp5-button-dark' : 'bp5-button'}
        style={{
          height: 35,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        }}
        type="button"
      >
        <Icon icon="cross" />
      </button>
    </div>
  )
}
