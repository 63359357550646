import { useMutation } from '@apollo/client'
import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
  Spinner,
} from '@blueprintjs/core'
import { DialogBody, DialogFooter } from '@blueprintjs/core'
import { useFormik } from 'formik'
import React from 'react'
import { IBaseDialogProps } from './types'
import { JobStatusSelect } from '../components/Select/JobStatusSelect'
import { WEBHOOKS_BY_FLEET } from '../pages/Fleets/gql/fleetWithWebhooks'
import { CREATE_WEBHOOK } from '../pages/Fleets/gql/createWebhook'

export const AddWebhookDialog = ({
  fleetId,
  closeDialog,
}: { fleetId: string } & IBaseDialogProps) => {
  const [createWebhook, { loading }] = useMutation(CREATE_WEBHOOK, {
    refetchQueries: [{ query: WEBHOOKS_BY_FLEET, variables: { id: fleetId } }],
  })

  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      name: '',
      url: '',
      forJobStatus: null,
    },
    onSubmit: async values => {
      await createWebhook({
        variables: {
          input: {
            name: values.name,
            url: values.url,
            forJobStatus:
              values.forJobStatus === '' ? null : values.forJobStatus,
            fleet: { connect: { id: fleetId } },
          },
        },
      })

      closeDialog()
    },
  })

  return (
    <>
      <DialogBody>
        <form onSubmit={handleSubmit}>
          <FormGroup label="Name" labelFor="name">
            <InputGroup
              id="name"
              name="name"
              intent={errors.name ? 'danger' : 'none'}
              value={values.name}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup label="URL" labelFor="url">
            <InputGroup
              id="url"
              name="url"
              intent={errors.name ? 'danger' : 'none'}
              value={values.url}
              onChange={handleChange}
            />
          </FormGroup>
          <FormGroup label="For Job Status" labelFor="jobStatus">
            <JobStatusSelect
              name="jobStatus"
              onChange={handleChange}
              value={values.forJobStatus || ''}
              large
              fill
            />
          </FormGroup>
          <DialogFooter>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={closeDialog}>Cancel</Button>
              <Button
                icon={loading && <Spinner size={20} />}
                intent={Intent.PRIMARY}
                type="submit"
              >
                Save
              </Button>
            </div>
          </DialogFooter>
        </form>
      </DialogBody>
    </>
  )
}
