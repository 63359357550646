import { typedGql } from '../../../../zeus/typedDocumentNode'
import { $ } from '../../../../zeus'

export const REQUEST_RESET_PASSWORD = typedGql('mutation')({
  requestPasswordReset: [
    {
      email: $('email', 'String!'),
    },
    true,
  ],
})
