import React, { useContext } from 'react'
import GraphiQL from 'graphiql'
import { createGraphiQLFetcher } from '@graphiql/toolkit'
import 'graphiql/graphiql.min.css'
import { SessionTokenContext } from '../../../providers/SessionTokenProvider'
import { NonIdealState } from '@blueprintjs/core'

export const Playground = () => {
  const endpoint = process.env.REACT_APP_GRAPHQL_SERVER
  const subsEndpoint = process.env.REACT_APP_GRAPHQL_SERVER_WS
  const { token } = useContext(SessionTokenContext)

  if (!endpoint || !token) {
    console.warn({ endpoint, token })
    return (
      <NonIdealState
        title="No access to the playground"
        description="Double check the token and the graphql endpoint in the logs"
      />
    )
  }

  const fetcher = createGraphiQLFetcher({
    url: endpoint,
    subscriptionUrl: subsEndpoint,
  })

  return (
    <div
      style={{
        height: 'calc(100vh - 96px)',
      }}
    >
      <GraphiQL
        schemaDescription
        defaultHeaders={JSON.stringify({
          Authorization: `Bearer ${token}`,
        })}
        fetcher={fetcher}
      />
    </div>
  )
}
