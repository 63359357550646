import { useMutation } from '@apollo/client'
import { useFormik } from 'formik'
import React from 'react'
import { ToastType, useToaster } from '../../hooks/useToaster'
import { UPDATE_PASSWORD } from '../../pages/Admin/Users/gql/updatePassword'
import { Classes, DialogBody, DialogFooter } from '@blueprintjs/core'
import {
  Button,
  Card,
  FormGroup,
  H4,
  Icon,
  Intent,
  Popover,
  Text,
} from '@blueprintjs/core'
import { PasswordField } from './PasswordField'
import { RequestResetPasswordForm } from '../../pages/Login/RequestResetPasswordForm'

export const PasswordUpdate = ({
  closeContainer,
}: {
  closeContainer: Function
}) => {
  const openSuccessToast = useToaster({ type: ToastType.SUCCESS })
  const openErrorToast = useToaster({ type: ToastType.ERROR })

  const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD, {
    onError: error => {
      openErrorToast(error.message)
    },
    onCompleted: data => {
      if (data.updatePassword) {
        openSuccessToast('Successfully updated password')
        closeContainer()
        // maybe replace the stored token?
      } else {
        openErrorToast('There was an error updating the password')
      }
    },
  })

  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    onSubmit: async values => {
      await updatePassword({
        variables: {
          input: {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          },
        },
      })
    },
  })

  return (
    <DialogBody>
      <form onSubmit={handleSubmit}>
        <H4>Update Password</H4>
        <FormGroup label="Previous">
          <PasswordField
            large
            name="oldPassword"
            value={values.oldPassword}
            intent={errors.oldPassword ? Intent.DANGER : Intent.NONE}
            onChange={handleChange}
          />
        </FormGroup>

        <FormGroup label="New Password">
          <PasswordField
            large
            name="newPassword"
            onChange={handleChange}
            intent={errors.newPassword ? Intent.DANGER : Intent.NONE}
            value={values.newPassword}
          />
        </FormGroup>

        <DialogFooter>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Popover
              content={
                <Card>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      <Icon icon="warning-sign" intent={Intent.DANGER} />
                      &nbsp;Resetting the password will log you out.
                    </Text>
                  </div>
                  <RequestResetPasswordForm closeContainer={closeContainer} />
                </Card>
              }
            >
              <Button minimal intent={Intent.PRIMARY}>
                Forgot?
              </Button>
            </Popover>
            <Button
              onClick={() => {
                closeContainer()
              }}
            >
              Cancel
            </Button>
            <Button intent={Intent.PRIMARY} type="submit" disabled={loading}>
              Update
            </Button>
          </div>
        </DialogFooter>
      </form>
    </DialogBody>
  )
}
