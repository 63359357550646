/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	AddressInput:{

	},
	ApiImplementationCreateInput:{
		fleet:"FleetConnectOneInput"
	},
	ApiImplementationCreateNestedManyWithoutFleetInput:{
		connect:"WhereRequiredIdInput",
		create:"ApiImplementationCreateWithoutFleetInput"
	},
	ApiImplementationCreateWithoutFleetInput:{

	},
	ApiImplementationListRelationFilter:{
		every:"ApiImplementationWhereInput",
		none:"ApiImplementationWhereInput",
		some:"ApiImplementationWhereInput"
	},
	ApiImplementationOrderByInput:{
		apiKey:"SortOrder",
		createdAt:"SortOrder",
		fleet:"FleetOrderByInput",
		fleetId:"SortOrder",
		name:"SortOrder",
		updatedAt:"SortOrder"
	},
	ApiImplementationOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	ApiImplementationUpdateInput:{
		fleet:"FleetConnectOneInput"
	},
	ApiImplementationUpdateManyWithoutFleetInput:{
		connect:"WhereRequiredIdInput",
		create:"ApiImplementationCreateWithoutFleetInput",
		delete:"WhereRequiredIdInput",
		disconnect:"WhereRequiredIdInput",
		set:"WhereRequiredIdInput"
	},
	ApiImplementationWhereInput:{
		AND:"ApiImplementationWhereInput",
		NOT:"ApiImplementationWhereInput",
		OR:"ApiImplementationWhereInput",
		apiKey:"StringFilter",
		createdAt:"DateTimeFilter",
		fleet:"FleetWhereInput",
		fleetId:"StringFilter",
		id:"StringFilter",
		model:"StringFilter",
		name:"StringFilter",
		updatedAt:"DateTimeFilter"
	},
	AssignJobToDriverInput:{

	},
	AuthTokenType: "enum" as const,
	AvailableNodesByUserAndDesiredRoleInput:{
		desiredRole:"UserRole"
	},
	BaseCreateInput:{
		fleet:"FleetConnectOneInput"
	},
	BaseCreateNestedWithoutFleetInput:{
		connect:"WhereRequiredIdInput",
		create:"BaseCreateWithoutFleetInput"
	},
	BaseCreateWithoutFleetInput:{

	},
	BaseListRelationFilter:{
		every:"BaseWhereInput",
		none:"BaseWhereInput",
		some:"BaseWhereInput"
	},
	BaseOrderByInput:{
		address:"SortOrder",
		createdAt:"SortOrder",
		fleet:"FleetOrderByInput",
		fleetId:"SortOrder",
		geo:"SortOrder",
		name:"SortOrder",
		updatedAt:"SortOrder"
	},
	BaseOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	BaseUpdateInput:{
		fleet:"FleetConnectOneInput"
	},
	BaseUpdateManyWithoutFleetInput:{
		connect:"WhereRequiredIdInput",
		create:"BaseCreateWithoutFleetInput",
		delete:"WhereRequiredIdInput",
		disconnect:"WhereRequiredIdInput",
		set:"WhereRequiredIdInput"
	},
	BaseWhereInput:{
		AND:"BaseWhereInput",
		NOT:"BaseWhereInput",
		OR:"BaseWhereInput",
		address:"JsonFilter",
		createdAt:"DateTimeFilter",
		fleet:"FleetWhereInput",
		fleetId:"StringFilter",
		geo:"JsonFilter",
		id:"StringFilter",
		name:"StringFilter",
		updatedAt:"DateTimeFilter"
	},
	BooleanFilter:{
		not:"BooleanFilter"
	},
	CalculateRouteInput:{
		pointA:"CoordinatesInput",
		pointB:"CoordinatesInput"
	},
	CancelJobInput:{
		canceledBy:"CanceledBy"
	},
	CanceledBy: "enum" as const,
	CompleteUserProfileInput:{

	},
	ContactInput:{

	},
	CoordinatesInput:{

	},
	DateTime: `scalar.DateTime` as const,
	DateTimeFilter:{
		equals:"DateTime",
		gt:"DateTime",
		gte:"DateTime",
		in:"DateTime",
		lt:"DateTime",
		lte:"DateTime",
		not:"DateTimeFilter",
		notIn:"DateTime"
	},
	DeliveryEstimateInput:{
		dropoffCoordinates:"CoordinatesInput",
		pickupCoordinates:"CoordinatesInput"
	},
	DistanceUnit: "enum" as const,
	DistanceUnitFilter:{
		equals:"DistanceUnit",
		in:"DistanceUnit",
		not:"DistanceUnit",
		notIn:"DistanceUnit"
	},
	DriverConnectOneInput:{
		connect:"WhereRequiredIdInput"
	},
	DriverConnectOrDisconnectInput:{
		connect:"WhereRequiredIdInput"
	},
	DriverConnectOrDisconnectOneInput:{
		connect:"WhereRequiredIdInput"
	},
	DriverCreateInput:{
		distanceUnit:"DistanceUnit",
		status:"DriverStatus",
		vehicle:"VehicleCreateNestedOneWithoutDriverInput"
	},
	DriverCreateNestedOneWithoutFleetDriversInput:{
		connect:"WhereRequiredIdInput",
		create:"DriverCreateWithoutFleetDriversInput"
	},
	DriverCreateWithoutFleetDriversInput:{
		distanceUnit:"DistanceUnit",
		status:"DriverStatus",
		vehicle:"VehicleCreateNestedOneWithoutDriverInput"
	},
	DriverInviteInput:{

	},
	DriverJobAssignmentStatus: "enum" as const,
	DriverOrderByInput:{
		allowJobRejection:"SortOrder",
		allowStackingOrders:"SortOrder",
		breaks:"SortOrder",
		completedSignUp:"SortOrder",
		costPerDistanceUnit:"SortOrder",
		createdAt:"SortOrder",
		currentLocation:"SortOrder",
		distanceUnit:"SortOrder",
		email:"SortOrder",
		firstName:"SortOrder",
		fleetDrivers:"FleetDriverOrderByRelationAggregateInput",
		fleetGroups:"SortOrder",
		id:"SortOrder",
		inactiveStartingAt:"SortOrder",
		jobAssignments:"JobAssignmentHistoryOrderByRelationAggregateInput",
		jobs:"JobOrderByRelationAggregateInput",
		lastName:"SortOrder",
		middleNames:"SortOrder",
		phoneNumber:"SortOrder",
		pushToken:"SortOrder",
		status:"SortOrder",
		updatedAt:"SortOrder",
		vehicle:"VehicleOrderByInput"
	},
	DriverSetLocationInput:{
		currentLocation:"LocationInput"
	},
	DriverStatus: "enum" as const,
	DriverUpdateDetailsInput:{

	},
	DriverUpdateInput:{
		currentLocation:"LocationInput",
		distanceUnit:"DistanceUnit",
		status:"DriverStatus"
	},
	DriverUpdatePushTokenInput:{

	},
	DriverWhereInput:{
		AND:"DriverWhereInput",
		NOT:"DriverWhereInput",
		OR:"DriverWhereInput",
		allowJobRejection:"BooleanFilter",
		allowStackingOrders:"BooleanFilter",
		breaks:"JsonFilter",
		completedSignUp:"BooleanFilter",
		createdAt:"DateTimeFilter",
		currentLocation:"JsonFilter",
		distanceUnit:"DistanceUnit",
		email:"StringFilter",
		firstName:"StringFilter",
		fleetDrivers:"FleetDriverListRelationFilter",
		fleetGroups:"JsonFilter",
		id:"StringFilter",
		inactiveStartingAt:"DateTimeFilter",
		jobAssignments:"JobAssignmentHistoryListRelationFilter",
		jobs:"JobListRelationFilter",
		lastName:"StringFilter",
		middleNames:"StringFilter",
		pauseNotifications:"BooleanFilter",
		phoneNumber:"StringFilter",
		status:"DriverStatus",
		updatedAt:"DateTimeFilter",
		vehicle:"VehicleWhereInput"
	},
	DropOffTaskCreateInput:{
		dropOffContact:"ContactInput",
		job:"JobConnectOneInput",
		location:"LocationCoordsInput"
	},
	DropOffTaskInput:{
		dropOffContact:"ContactInput",
		location:"LocationCoordsInput"
	},
	DropOffTaskListRelationFilter:{
		every:"DropOffTaskWhereInput",
		none:"DropOffTaskWhereInput",
		some:"DropOffTaskWhereInput"
	},
	DropOffTaskOrderByInput:{
		ageVerification:"SortOrder",
		allocatedTime:"SortOrder",
		createdAt:"SortOrder",
		dropOffNotes:"SortOrder",
		job:"JobOrderByInput",
		locationName:"SortOrder",
		orderInStack:"SortOrder",
		updatedAt:"SortOrder"
	},
	DropOffTaskOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	DropOffTaskUpdateInput:{
		dropOffContact:"ContactInput",
		job:"JobConnectOneInput",
		location:"LocationCoordsInput"
	},
	DropOffTaskUpdateManyWithoutJobInput:{
		connect:"WhereRequiredIdInput",
		delete:"WhereRequiredIdInput",
		disconnect:"WhereRequiredIdInput",
		set:"WhereRequiredIdInput"
	},
	DropOffTaskUpdateProofOfDelivery:{

	},
	DropOffTaskWhereInput:{
		AND:"DropOffTaskWhereInput",
		NOT:"DropOffTaskWhereInput",
		OR:"DropOffTaskWhereInput",
		createdAt:"DateTimeFilter",
		job:"JobWhereInput",
		proofOfDelivery:"JsonFilter",
		updatedAt:"DateTimeFilter"
	},
	EligibleDriversForPickupInput:{

	},
	FleetConnectOneInput:{
		connect:"WhereRequiredIdInput"
	},
	FleetConnectOrDisconnectOneInput:{
		connect:"WhereRequiredIdInput"
	},
	FleetCreateInput:{
		bases:"BaseCreateNestedWithoutFleetInput",
		distanceUnit:"DistanceUnit",
		fleetDrivers:"FleetDriverCreateNestedWithoutFleetInput",
		implementations:"ApiImplementationCreateNestedManyWithoutFleetInput",
		partner:"PartnerCreateNestedOneWithoutFleetsInput",
		vehicles:"VehicleCreateNestedWithoutOwnerFleetInput",
		webhooks:"WebhookCreateNestedManyWithoutFleetInput"
	},
	FleetCreateNestedManyWithoutPartnerInput:{
		connect:"WhereRequiredIdInput",
		create:"FleetCreateWithoutPartnerInput"
	},
	FleetCreateWithoutPartnerInput:{
		distanceUnit:"DistanceUnit"
	},
	FleetDriverCreateInput:{
		driver:"DriverConnectOneInput",
		fleet:"FleetConnectOneInput"
	},
	FleetDriverCreateNestedWithoutFleetInput:{
		connect:"WhereRequiredIdInput",
		create:"FleetDriverCreateWithoutFleetInput"
	},
	FleetDriverCreateWithoutDriverInput:{
		fleet:"FleetConnectOneInput"
	},
	FleetDriverCreateWithoutFleetInput:{
		driver:"DriverConnectOneInput"
	},
	FleetDriverListRelationFilter:{
		every:"FleetDriverWhereInput",
		none:"FleetDriverWhereInput",
		some:"FleetDriverWhereInput"
	},
	FleetDriverOrderByInput:{
		createdAt:"SortOrder",
		driver:"DriverOrderByInput",
		driverId:"SortOrder",
		fleet:"FleetOrderByInput",
		fleetId:"SortOrder",
		updatedAt:"SortOrder"
	},
	FleetDriverOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	FleetDriverUpdateInput:{
		driver:"DriverConnectOrDisconnectOneInput",
		fleet:"FleetConnectOrDisconnectOneInput"
	},
	FleetDriverUpdateManyWithoutDriverInput:{
		connect:"WhereRequiredIdInput",
		create:"FleetDriverCreateWithoutDriverInput",
		delete:"WhereRequiredIdInput",
		disconnect:"WhereRequiredIdInput",
		set:"WhereRequiredIdInput"
	},
	FleetDriverUpdateManyWithoutFleetInput:{
		connect:"WhereRequiredIdInput",
		create:"FleetDriverCreateWithoutFleetInput",
		delete:"WhereRequiredIdInput",
		disconnect:"WhereRequiredIdInput",
		set:"WhereRequiredIdInput"
	},
	FleetDriverWhereInput:{
		AND:"FleetDriverWhereInput",
		NOT:"FleetDriverWhereInput",
		OR:"FleetDriverWhereInput",
		createdAt:"DateTimeFilter",
		driver:"DriverWhereInput",
		driverId:"StringFilter",
		fleet:"FleetWhereInput",
		fleetId:"StringFilter",
		id:"StringFilter",
		updatedAt:"DateTimeFilter"
	},
	FleetListRelationFilter:{
		every:"FleetWhereInput",
		none:"FleetWhereInput",
		some:"FleetWhereInput"
	},
	FleetOrderByInput:{
		bases:"BaseOrderByRelationAggregateInput",
		createdAt:"SortOrder",
		defaultCostPerDistanceUnit:"SortOrder",
		distanceUnit:"SortOrder",
		fleetDrivers:"FleetDriverOrderByRelationAggregateInput",
		geo:"SortOrder",
		id:"SortOrder",
		implementations:"ApiImplementationOrderByRelationAggregateInput",
		jobAcceptWaitTime:"SortOrder",
		jobDropOffAllocatedTime:"SortOrder",
		jobs:"JobOrderByRelationAggregateInput",
		leadTime:"SortOrder",
		legalCookies:"SortOrder",
		legalPrivacy:"SortOrder",
		legalTerms:"SortOrder",
		name:"SortOrder",
		partner:"PartnerOrderByInput",
		partnerId:"SortOrder",
		pricePerDistanceUnit:"SortOrder",
		updatedAt:"SortOrder",
		vehicles:"VehicleOrderByRelationAggregateInput",
		webhooks:"WebhookOrderByRelationAggregateInput"
	},
	FleetOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	FleetUpdateInput:{
		bases:"BaseUpdateManyWithoutFleetInput",
		distanceUnit:"DistanceUnit",
		fleetDrivers:"FleetDriverUpdateManyWithoutFleetInput",
		implementations:"ApiImplementationUpdateManyWithoutFleetInput",
		vehicles:"VehicleUpdateManyWithoutOwnerFleetInput",
		webhooks:"WebhookUpdateManyWithoutFleetInput"
	},
	FleetUpdateManyWithoutPartnerInput:{
		connect:"WhereRequiredIdInput",
		create:"FleetCreateWithoutPartnerInput",
		disconnect:"WhereRequiredIdInput"
	},
	FleetWhereInput:{
		AND:"FleetWhereInput",
		NOT:"FleetWhereInput",
		OR:"FleetWhereInput",
		bases:"BaseListRelationFilter",
		broadcastJob:"BooleanFilter",
		contactEmail:"StringFilter",
		contactFullAddress:"StringFilter",
		contactFullName:"StringFilter",
		contactPhone:"StringFilter",
		createdAt:"DateTimeFilter",
		defaultCostPerDistanceUnit:"IntFilter",
		distanceUnit:"DistanceUnitFilter",
		fleetDrivers:"FleetDriverListRelationFilter",
		geo:"JsonFilter",
		id:"StringFilter",
		implementations:"ApiImplementationListRelationFilter",
		jobAcceptWaitTime:"IntFilter",
		jobDropOffAllocatedTime:"IntFilter",
		jobs:"JobListRelationFilter",
		leadTime:"IntFilter",
		legalCookies:"StringFilter",
		legalPrivacy:"StringFilter",
		legalTerms:"StringFilter",
		name:"StringFilter",
		outrightRejectJob:"BooleanFilter",
		partner:"PartnerWhereInput",
		partnerId:"StringFilter",
		pricePerDistanceUnit:"IntFilter",
		updatedAt:"DateTimeFilter",
		vehicles:"VehicleListRelationFilter",
		webhooks:"WebhookListRelationFilter"
	},
	IntFilter:{
		not:"IntFilter"
	},
	JSON: `scalar.JSON` as const,
	JobAssignmentHistoryCreateInput:{
		driver:"DriverConnectOneInput",
		job:"JobConnectOneInput",
		status:"DriverJobAssignmentStatus"
	},
	JobAssignmentHistoryListRelationFilter:{
		every:"JobAssignmentHistoryWhereInput",
		none:"JobAssignmentHistoryWhereInput",
		some:"JobAssignmentHistoryWhereInput"
	},
	JobAssignmentHistoryOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	JobAssignmentHistoryUpdateInput:{
		driver:"DriverConnectOneInput",
		job:"JobConnectOneInput",
		status:"DriverJobAssignmentStatus"
	},
	JobAssignmentHistoryUpdateManyWithoutDriverInput:{
		connect:"WhereRequiredIdInput",
		disconnect:"WhereRequiredIdInput",
		set:"WhereRequiredIdInput"
	},
	JobAssignmentHistoryUpdateManyWithoutJobInput:{
		connect:"WhereRequiredIdInput",
		delete:"WhereRequiredIdInput",
		disconnect:"WhereRequiredIdInput",
		set:"WhereRequiredIdInput"
	},
	JobAssignmentHistoryWhereInput:{
		AND:"JobAssignmentHistoryWhereInput",
		NOT:"JobAssignmentHistoryWhereInput",
		OR:"JobAssignmentHistoryWhereInput",
		autoRejectAt:"DateTimeFilter",
		createdAt:"DateTimeFilter",
		driver:"DriverWhereInput",
		job:"JobWhereInput",
		status:"DriverJobAssignmentStatus",
		updatedAt:"DateTimeFilter"
	},
	JobAssignmentOrderByInput:{
		autoRejectAt:"SortOrder",
		createdAt:"SortOrder",
		driver:"DriverOrderByInput",
		job:"JobOrderByInput",
		status:"SortOrder",
		updatedAt:"SortOrder"
	},
	JobConnectOneInput:{
		connect:"WhereRequiredIdInput"
	},
	JobCreateDirectInput:{
		dropOffTask:"DropOffTaskInput",
		estimatedPickupTime:"DateTime",
		pickupTask:"PickupTaskInput"
	},
	JobListRelationFilter:{
		every:"JobWhereInput",
		none:"JobWhereInput",
		some:"JobWhereInput"
	},
	JobOrderByInput:{
		assignmentHistory:"JobAssignmentHistoryOrderByRelationAggregateInput",
		canceledBy:"SortOrder",
		completedByDriverFullName:"SortOrder",
		completedByDriverId:"SortOrder",
		completionTime:"SortOrder",
		cost:"SortOrder",
		createdAt:"SortOrder",
		distanceUnit:"SortOrder",
		driver:"DriverOrderByInput",
		dropOffTasks:"DropOffTaskOrderByRelationAggregateInput",
		estimatedDistanceMiles:"SortOrder",
		estimatedDurationMinutes:"SortOrder",
		estimatedPickupTime:"SortOrder",
		finishedAssignment:"SortOrder",
		fleet:"FleetOrderByInput",
		initialCost:"SortOrder",
		jobAcceptWaitTime:"SortOrder",
		jobCompleteNotes:"SortOrder",
		jobReference:"SortOrder",
		onHold:"SortOrder",
		orderContents:"SortOrder",
		orderId:"SortOrder",
		orderIsAsap:"SortOrder",
		orderReference:"SortOrder",
		pickupTasks:"PickupTaskOrderByRelationAggregateInput",
		pickupTime:"SortOrder",
		price:"SortOrder",
		stackId:"SortOrder",
		stackStatus:"SortOrder",
		status:"SortOrder",
		updatedAt:"SortOrder"
	},
	JobOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	JobStatus: "enum" as const,
	JobStatusFilter:{
		equals:"JobStatus",
		in:"JobStatus",
		not:"JobStatus",
		notIn:"JobStatus"
	},
	JobUpdateInput:{
		assignmentHistory:"JobAssignmentHistoryUpdateManyWithoutJobInput",
		canceledBy:"CanceledBy",
		completionTime:"DateTime",
		driver:"DriverConnectOrDisconnectInput",
		dropOffTasks:"DropOffTaskUpdateManyWithoutJobInput",
		estimatedPickupTime:"DateTime",
		fleet:"FleetConnectOneInput",
		pickupTasks:"PickupTaskUpdateManyWithoutJobInput",
		pickupTime:"DateTime",
		status:"JobStatus"
	},
	JobUpdateManyWithoutDriverInput:{
		connect:"WhereRequiredIdInput",
		disconnect:"WhereRequiredIdInput",
		set:"WhereRequiredIdInput"
	},
	JobWhereInput:{
		AND:"JobWhereInput",
		NOT:"JobWhereInput",
		OR:"JobWhereInput",
		assignmentHistory:"JobAssignmentHistoryListRelationFilter",
		canceledBy:"CanceledBy",
		completedByDriverFullName:"StringFilter",
		completedByDriverId:"StringFilter",
		completionTime:"DateTimeFilter",
		createdAt:"DateTimeFilter",
		distanceUnit:"DistanceUnitFilter",
		driver:"DriverWhereInput",
		driverId:"StringFilter",
		dropOffTasks:"DropOffTaskListRelationFilter",
		estimatedPickupTime:"DateTimeFilter",
		fleet:"FleetWhereInput",
		fleetId:"StringFilter",
		id:"StringFilter",
		jobCompleteNotes:"StringFilter",
		jobReference:"StringFilter",
		orderContents:"JsonFilter",
		orderId:"StringFilter",
		orderReference:"StringFilter",
		pickupTasks:"PickupTaskListRelationFilter",
		pickupTime:"DateTimeFilter",
		stackId:"StringFilter",
		stackStatus:"JobStatusFilter",
		status:"JobStatusFilter",
		updatedAt:"DateTimeFilter"
	},
	JsonFilter:{

	},
	LocationCoordsInput:{
		address:"AddressInput"
	},
	LocationInput:{

	},
	Mutation:{
		acceptJob:{

		},
		assignJobToDriver:{
			input:"AssignJobToDriverInput"
		},
		cancelJob:{
			input:"CancelJobInput"
		},
		completeJob:{

		},
		completeUserProfile:{
			input:"CompleteUserProfileInput"
		},
		createApiImplementation:{
			input:"ApiImplementationCreateInput"
		},
		createBase:{
			input:"BaseCreateInput"
		},
		createDropOffTask:{
			input:"DropOffTaskCreateInput"
		},
		createFleet:{
			input:"FleetCreateInput"
		},
		createFleetDriver:{
			input:"FleetDriverCreateInput"
		},
		createJob:{
			input:"JobCreateDirectInput"
		},
		createJobAssignmentHistory:{
			input:"JobAssignmentHistoryCreateInput"
		},
		createPartner:{
			input:"PartnerCreateInput"
		},
		createPickupTask:{
			input:"PickupTaskCreateInput"
		},
		createPlatform:{
			input:"PlatformCreateInput"
		},
		createUser:{
			input:"UserCreateInput"
		},
		createWebhook:{
			input:"WebhookCreateInput"
		},
		deleteApiImplementation:{

		},
		deleteDriver:{

		},
		deleteJob:{

		},
		deleteUser:{

		},
		deleteWebhook:{

		},
		driverLogin:{

		},
		inviteDriver:{
			input:"DriverInviteInput"
		},
		inviteUser:{
			input:"UserInviteInput"
		},
		login:{
			input:"UserLoginInput"
		},
		notifyDriver:{
			input:"NotifyDriverInput"
		},
		rejectJob:{

		},
		removeDriverStatusOption:{
			option:"JSON"
		},
		requestPasswordReset:{

		},
		resetPassword:{
			input:"UserResetPasswordInput"
		},
		setDriverStatusFromOption:{
			option:"JSON"
		},
		updateApiImplementation:{
			input:"ApiImplementationUpdateInput"
		},
		updateBase:{
			input:"BaseUpdateInput"
		},
		updateDriver:{
			input:"DriverUpdateInput"
		},
		updateDriverDetails:{
			input:"DriverUpdateDetailsInput"
		},
		updateDriverLocation:{
			input:"DriverSetLocationInput"
		},
		updateDropOffTask:{
			input:"DropOffTaskUpdateInput"
		},
		updateDropOffTaskProofOfDelivery:{
			input:"DropOffTaskUpdateProofOfDelivery"
		},
		updateFleet:{
			input:"FleetUpdateInput"
		},
		updateFleetDriver:{
			input:"FleetDriverUpdateInput"
		},
		updateJob:{
			input:"JobUpdateInput"
		},
		updateJobAssignmentHistory:{
			input:"JobAssignmentHistoryUpdateInput"
		},
		updateJobPickupTime:{
			input:"UpdateJobPickupTimeInput"
		},
		updateJobStatus:{
			input:"UpdateJobStatusInput"
		},
		updatePartner:{
			input:"PartnerUpdateInput"
		},
		updatePassword:{
			input:"UserUpdatePasswordInput"
		},
		updatePickupTask:{
			input:"PickupTaskUpdateInput"
		},
		updatePlatform:{
			input:"PlatformUpdateInput"
		},
		updatePushToken:{
			input:"DriverUpdatePushTokenInput"
		},
		updateUser:{
			input:"UserUpdateInput"
		},
		updateWebhook:{
			input:"WebhookUpdateInput"
		},
		uploadJobCompleteProof:{
			input:"UploadJobCompleteProofInput"
		},
		verifyPhone:{

		}
	},
	NotifyDriverInput:{
		data:"JSON"
	},
	PartnerConnectOrDisconnectInput:{
		connect:"WhereRequiredIdInput",
		disconnect:"WhereRequiredIdInput"
	},
	PartnerCreateInput:{
		platform:"PlatformConnectById"
	},
	PartnerCreateNestedOneWithoutFleetsInput:{
		connect:"WhereRequiredIdInput",
		create:"PartnerCreateInput"
	},
	PartnerCreateWithoutPlatformInput:{
		fleets:"FleetCreateNestedManyWithoutPartnerInput"
	},
	PartnerListRelationFilter:{
		every:"PartnerWhereInput",
		none:"PartnerWhereInput",
		some:"PartnerWhereInput"
	},
	PartnerOrderByInput:{
		createdAt:"SortOrder",
		fleets:"FleetOrderByRelationAggregateInput",
		id:"SortOrder",
		name:"SortOrder",
		partners:"PartnerOrderByRelationAggregateInput",
		platform:"PlatformOrderByInput",
		platformId:"SortOrder",
		updatedAt:"SortOrder"
	},
	PartnerOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	PartnerUpdateInput:{
		fleets:"FleetUpdateManyWithoutPartnerInput"
	},
	PartnerUpdatemanyWithoutPlatformInput:{
		connect:"PartnerWhereUniqueInput",
		delete:"PartnerWhereUniqueInput",
		disconnect:"PartnerWhereUniqueInput"
	},
	PartnerWhereInput:{
		AND:"PartnerWhereInput",
		NOT:"PartnerWhereInput",
		OR:"PartnerWhereInput",
		createdAt:"DateTimeFilter",
		fleets:"FleetListRelationFilter",
		id:"StringFilter",
		name:"StringFilter",
		platform:"PlatformWhereInput",
		platformId:"StringFilter",
		updatedAt:"DateTimeFilter"
	},
	PartnerWhereUniqueInput:{

	},
	PickupTaskCreateInput:{
		job:"JobConnectOneInput",
		location:"LocationCoordsInput",
		pickupContact:"ContactInput"
	},
	PickupTaskInput:{
		location:"LocationCoordsInput",
		pickupContact:"ContactInput"
	},
	PickupTaskListRelationFilter:{
		every:"PickupTaskWhereInput",
		none:"PickupTaskWhereInput",
		some:"PickupTaskWhereInput"
	},
	PickupTaskOrderByInput:{
		createdAt:"SortOrder",
		job:"JobOrderByInput",
		locationHashed:"SortOrder",
		locationName:"SortOrder",
		pickupNotes:"SortOrder",
		updatedAt:"SortOrder"
	},
	PickupTaskOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	PickupTaskUpdateInput:{
		job:"JobConnectOneInput",
		location:"LocationCoordsInput",
		pickupContact:"ContactInput"
	},
	PickupTaskUpdateManyWithoutJobInput:{
		connect:"WhereRequiredIdInput",
		delete:"WhereRequiredIdInput",
		disconnect:"WhereRequiredIdInput",
		set:"WhereRequiredIdInput"
	},
	PickupTaskWhereInput:{
		AND:"PickupTaskWhereInput",
		NOT:"PickupTaskWhereInput",
		OR:"PickupTaskWhereInput",
		createdAt:"DateTimeFilter",
		job:"JobWhereInput",
		updatedAt:"DateTimeFilter"
	},
	PlatformConnectById:{
		connect:"WhereRequiredIdInput"
	},
	PlatformCreateInput:{
		partners:"PlatformCreateOrConnectPartnerInput"
	},
	PlatformCreateOrConnectPartnerInput:{
		create:"PartnerCreateWithoutPlatformInput"
	},
	PlatformOrderByInput:{
		createdAt:"SortOrder",
		id:"SortOrder",
		name:"SortOrder",
		partners:"PartnerOrderByRelationAggregateInput",
		updatedAt:"SortOrder"
	},
	PlatformUpdateInput:{
		partners:"PartnerUpdatemanyWithoutPlatformInput"
	},
	PlatformWhereInput:{
		AND:"PlatformWhereInput",
		NOT:"PlatformWhereInput",
		OR:"PlatformWhereInput",
		createdAt:"DateTimeFilter",
		id:"StringFilter",
		name:"StringFilter",
		partners:"PartnerListRelationFilter",
		updatedAt:"DateTimeFilter"
	},
	Query:{
		apiImplementation:{

		},
		apiImplementations:{
			orderBy:"ApiImplementationOrderByInput",
			where:"ApiImplementationWhereInput"
		},
		availableNodesByUserAndDesiredRole:{
			input:"AvailableNodesByUserAndDesiredRoleInput"
		},
		base:{

		},
		bases:{
			orderBy:"BaseOrderByInput",
			where:"BaseWhereInput"
		},
		bookedAndAvailableOptionsForDriverStatus:{

		},
		calculateRoute:{
			input:"CalculateRouteInput"
		},
		collisionsOrStackability:{

		},
		deliveryEstimate:{
			input:"DeliveryEstimateInput"
		},
		driver:{

		},
		drivers:{
			orderBy:"DriverOrderByInput",
			where:"DriverWhereInput"
		},
		dropOffTask:{

		},
		dropOffTasks:{
			orderBy:"DropOffTaskOrderByInput",
			where:"DropOffTaskWhereInput"
		},
		eligibleDriversForPickup:{
			input:"EligibleDriversForPickupInput"
		},
		exceptionQueue:{

		},
		fleet:{

		},
		fleetDriver:{

		},
		fleetDrivers:{
			orderBy:"FleetDriverOrderByInput",
			where:"FleetDriverWhereInput"
		},
		fleets:{
			orderBy:"FleetOrderByInput",
			where:"FleetWhereInput"
		},
		job:{

		},
		jobAssignmentHistories:{
			orderBy:"JobAssignmentOrderByInput",
			where:"JobAssignmentHistoryWhereInput"
		},
		jobAssignmentHistory:{

		},
		jobs:{
			orderBy:"JobOrderByInput",
			where:"JobWhereInput"
		},
		paginatedJobs:{
			orderBy:"JobOrderByInput",
			where:"JobWhereInput"
		},
		partner:{

		},
		partners:{
			orderBy:"PartnerOrderByInput",
			where:"PartnerWhereInput"
		},
		pickupTask:{

		},
		pickupTasks:{
			orderBy:"PickupTaskOrderByInput",
			where:"PickupTaskWhereInput"
		},
		platform:{

		},
		platforms:{
			orderBy:"PlatformOrderByInput",
			where:"PlatformWhereInput"
		},
		timeConnectedInMs:{
			input:"TimeConnectedInMsInput"
		},
		userByEmail:{

		},
		userById:{

		},
		users:{
			orderBy:"UserOrderByInput",
			where:"UserWhereInput"
		},
		vehicle:{

		},
		vehicles:{
			orderBy:"VehicleOrderByInput",
			where:"VehicleWhereInput"
		},
		webhook:{

		},
		webhooks:{
			orderBy:"WebhookOrderByInput",
			where:"WebhookWhereInput"
		}
	},
	SortOrder: "enum" as const,
	StringFilter:{
		not:"StringFilter"
	},
	Subscription:{
		driver:{

		},
		drivers:{
			orderBy:"DriverOrderByInput",
			where:"DriverWhereInput"
		},
		job:{

		},
		jobs:{
			orderBy:"JobOrderByInput",
			where:"JobWhereInput"
		},
		paginatedJobs:{
			orderBy:"JobOrderByInput",
			where:"JobWhereInput"
		}
	},
	TimeConnectedInMsInput:{

	},
	UpdateJobPickupTimeInput:{
		estimatedPickupTime:"DateTime"
	},
	UpdateJobStatusInput:{
		status:"JobStatus"
	},
	UploadJobCompleteProofInput:{

	},
	UserConnectInput:{
		connect:"WhereRequiredIdInput"
	},
	UserCreateInput:{
		partners:"PartnerConnectOrDisconnectInput",
		role:"UserRole",
		userStatus:"UserStatus"
	},
	UserCreateNewPasswordInput:{

	},
	UserInviteInput:{
		role:"UserRole"
	},
	UserLoginInput:{

	},
	UserOrderByInput:{
		createdAt:"SortOrder",
		email:"SortOrder",
		enabled:"SortOrder",
		firstName:"SortOrder",
		id:"SortOrder",
		lastName:"SortOrder",
		phoneNumber:"SortOrder",
		role:"SortOrder",
		updatedAt:"SortOrder",
		userStatus:"SortOrder"
	},
	UserResetPasswordInput:{

	},
	UserRole: "enum" as const,
	UserRoleFilter:{
		equals:"UserRole",
		in:"UserRole",
		not:"UserRoleFilter",
		notIn:"UserRole"
	},
	UserStatus: "enum" as const,
	UserStatusFilter:{
		equals:"UserStatus",
		in:"UserStatus",
		not:"UserStatusFilter",
		notIn:"UserStatus"
	},
	UserUpdateInput:{
		role:"UserRole",
		userStatus:"UserStatus"
	},
	UserUpdatePasswordInput:{

	},
	UserWhereInput:{
		AND:"UserWhereInput",
		NOT:"UserWhereInput",
		OR:"UserWhereInput",
		createdAt:"DateTimeFilter",
		email:"StringFilter",
		enabled:"BooleanFilter",
		firstName:"StringFilter",
		id:"StringFilter",
		lastName:"StringFilter",
		phoneNumber:"StringFilter",
		role:"UserRoleFilter",
		updatedAt:"DateTimeFilter",
		userStatus:"UserStatusFilter"
	},
	VehicleConnectOrDisconnectOneInput:{
		connect:"WhereRequiredIdInput"
	},
	VehicleCreateInput:{
		driver:"DriverConnectOneInput",
		ownerFleet:"FleetConnectOneInput",
		type:"VehicleType"
	},
	VehicleCreateNestedOneWithoutDriverInput:{
		connect:"WhereRequiredIdInput",
		create:"VehicleCreateInput"
	},
	VehicleCreateNestedWithoutOwnerFleetInput:{
		connect:"WhereRequiredIdInput",
		create:"VehicleCreateWithoutOwnerFleetInput"
	},
	VehicleCreateWithoutOwnerFleetInput:{
		driver:"DriverConnectOneInput",
		type:"VehicleType"
	},
	VehicleListRelationFilter:{
		every:"VehicleWhereInput",
		none:"VehicleWhereInput",
		some:"VehicleWhereInput"
	},
	VehicleOrderByInput:{
		createdAt:"SortOrder",
		driver:"DriverOrderByInput",
		driverId:"SortOrder",
		id:"SortOrder",
		make:"SortOrder",
		model:"SortOrder",
		name:"SortOrder",
		ownerDriver:"SortOrder",
		ownerFleet:"FleetOrderByInput",
		ownerFleetId:"SortOrder",
		reg:"SortOrder",
		updatedAt:"SortOrder"
	},
	VehicleOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	VehicleType: "enum" as const,
	VehicleUpdateInput:{
		driver:"DriverConnectOrDisconnectOneInput",
		ownerFleet:"FleetConnectOrDisconnectOneInput",
		type:"VehicleType"
	},
	VehicleUpdateManyWithoutOwnerFleetInput:{
		connect:"WhereRequiredIdInput",
		create:"VehicleCreateWithoutOwnerFleetInput",
		delete:"WhereRequiredIdInput",
		disconnect:"WhereRequiredIdInput",
		set:"WhereRequiredIdInput"
	},
	VehicleWhereInput:{
		AND:"VehicleWhereInput",
		NOT:"VehicleWhereInput",
		OR:"VehicleWhereInput",
		createdAt:"DateTimeFilter",
		driver:"DriverWhereInput",
		driverId:"StringFilter",
		id:"StringFilter",
		make:"StringFilter",
		model:"StringFilter",
		ownerDriver:"BooleanFilter",
		ownerFleet:"FleetWhereInput",
		ownerFleetId:"StringFilter",
		reg:"StringFilter",
		type:"VehicleType",
		updatedAt:"DateTimeFilter"
	},
	WebhookCreateInput:{
		fleet:"FleetConnectOneInput",
		forJobStatus:"JobStatus"
	},
	WebhookCreateNestedManyWithoutFleetInput:{
		connect:"WhereRequiredIdInput",
		create:"WebhookCreateWithoutFleetInput"
	},
	WebhookCreateWithoutFleetInput:{
		forJobStatus:"JobStatus"
	},
	WebhookListRelationFilter:{
		every:"WebhookWhereInput",
		none:"WebhookWhereInput",
		some:"WebhookWhereInput"
	},
	WebhookOrderByInput:{
		apiKeyHeader:"SortOrder",
		createdAt:"SortOrder",
		fleet:"FleetOrderByInput",
		isRedboxApi:"SortOrder",
		name:"SortOrder",
		updatedAt:"SortOrder",
		url:"SortOrder"
	},
	WebhookOrderByRelationAggregateInput:{
		_count:"SortOrder"
	},
	WebhookUpdateInput:{
		fleet:"FleetConnectOneInput",
		forJobStatus:"JobStatus"
	},
	WebhookUpdateManyWithoutFleetInput:{
		connect:"WhereRequiredIdInput",
		create:"WebhookCreateWithoutFleetInput",
		delete:"WhereRequiredIdInput",
		disconnect:"WhereRequiredIdInput",
		set:"WhereRequiredIdInput"
	},
	WebhookWhereInput:{
		AND:"WebhookWhereInput",
		NOT:"WebhookWhereInput",
		OR:"WebhookWhereInput",
		apiKeyHeader:"StringFilter",
		createdAt:"DateTimeFilter",
		fleet:"FleetWhereInput",
		fleetId:"StringFilter",
		forJobStatus:"JobStatus",
		id:"StringFilter",
		isRedboxApi:"BooleanFilter",
		name:"StringFilter",
		updatedAt:"DateTimeFilter",
		url:"StringFilter"
	},
	WhereRequiredIdInput:{

	}
}

export const ReturnTypes: Record<string,any> = {
	ApiImplementation:{
		apiKey:"String",
		createdAt:"DateTime",
		fleet:"Fleet",
		fleetId:"ID",
		id:"ID",
		name:"String",
		updatedAt:"DateTime"
	},
	Base:{
		address:"JSON",
		createdAt:"DateTime",
		fleet:"Fleet",
		fleetId:"String",
		geo:"JSON",
		id:"ID",
		name:"String",
		updatedAt:"DateTime"
	},
	CollisionsOrStackability:{
		isPlausible:"Boolean",
		isStackable:"Boolean",
		jobsOverlap:"Boolean"
	},
	Coordinates:{
		latitude:"Float",
		longitude:"Float"
	},
	CreateUserResponse:{
		id:"String",
		jwtToken:"String"
	},
	DateTime: `scalar.DateTime` as const,
	DeliveryEstimate:{
		costInPennies:"Int",
		dropoffCoordinates:"Coordinates",
		pickupCoordinates:"Coordinates"
	},
	Driver:{
		allowJobRejection:"Boolean",
		allowStackingOrders:"Boolean",
		breaks:"JSON",
		completedSignUp:"Boolean",
		costPerDistanceUnit:"Int",
		createdAt:"DateTime",
		currentLocation:"JSON",
		distanceUnit:"DistanceUnit",
		email:"String",
		firstName:"String",
		fleetContact:"DriverFleetContact",
		fleetDrivers:"FleetDriver",
		fleetGroups:"JSON",
		heading:"Float",
		id:"ID",
		inactiveStartingAt:"DateTime",
		jobAssignments:"JobAssignmentHistory",
		jobs:"Job",
		lastName:"String",
		middleNames:"String",
		pauseNotifications:"Boolean",
		phoneNumber:"String",
		pushToken:"String",
		snsEndpointARN:"String",
		speed:"Float",
		status:"DriverStatus",
		title:"String",
		todaysJobsCounter:"Int",
		todaysMilesCounter:"Float",
		updatedAt:"DateTime",
		vehicle:"Vehicle"
	},
	DriverFleetContact:{
		fleetContactPhone:"String",
		fleetName:"String"
	},
	DriverLoginResponse:{
		expireAt:"DateTime",
		phoneNumber:"String",
		token:"String"
	},
	DropOffTask:{
		ageVerification:"Boolean",
		allocatedTime:"Int",
		createdAt:"DateTime",
		dropOffContact:"JSON",
		dropOffNotes:"String",
		id:"ID",
		job:"Job",
		jobId:"String",
		location:"JSON",
		locationName:"String",
		orderInStack:"Int",
		proofOfDelivery:"String",
		updatedAt:"DateTime"
	},
	EligibleDriversForPickup:{
		driverId:"String",
		driverLocation:"Coordinates",
		geographicalDistance:"Float",
		roadDistance:"Float"
	},
	Fleet:{
		bases:"Base",
		broadcastJob:"Boolean",
		contactEmail:"String",
		contactFullAddress:"String",
		contactFullName:"String",
		contactPhone:"String",
		createdAt:"DateTime",
		defaultCostPerDistanceUnit:"Int",
		distanceUnit:"DistanceUnit",
		fleetDrivers:"FleetDriver",
		geo:"JSON",
		id:"ID",
		implementations:"ApiImplementation",
		jobAcceptWaitTime:"Int",
		jobDropOffAllocatedTime:"Int",
		jobs:"Job",
		leadTime:"Int",
		legalCookies:"String",
		legalPrivacy:"String",
		legalTerms:"String",
		name:"String",
		outrightRejectJob:"Boolean",
		partner:"Partner",
		partnerId:"String",
		pricePerDistanceUnit:"Int",
		updatedAt:"DateTime",
		users:"User",
		vehicles:"Vehicle",
		webhooks:"Webhook"
	},
	FleetDriver:{
		createdAt:"DateTime",
		driver:"Driver",
		driverId:"ID",
		fleet:"Fleet",
		fleetId:"ID",
		id:"ID",
		updatedAt:"DateTime"
	},
	JSON: `scalar.JSON` as const,
	Job:{
		assignmentHistory:"JobAssignmentHistory",
		canceledBy:"CanceledBy",
		completedByDriverFullName:"String",
		completedByDriverId:"String",
		completionTime:"DateTime",
		cost:"Int",
		createdAt:"DateTime",
		distanceUnit:"DistanceUnit",
		driver:"Driver",
		driverId:"String",
		dropOffTasks:"DropOffTask",
		estimatedDistanceMiles:"Float",
		estimatedDurationMinutes:"Float",
		estimatedPickupTime:"DateTime",
		finishedAssignment:"Boolean",
		fleet:"Fleet",
		fleetContactEmail:"String",
		fleetContactFullAddress:"String",
		fleetContactFullName:"String",
		fleetContactPhone:"String",
		fleetId:"String",
		id:"ID",
		idealRoute:"JSON",
		initialCost:"Int",
		jobAcceptWaitTime:"Int",
		jobCompleteNotes:"String",
		jobCompletePhotoProof:"String",
		jobHasPhotoProof:"Boolean",
		jobReference:"String",
		onHold:"Boolean",
		orderContents:"JSON",
		orderId:"String",
		orderIsAsap:"Boolean",
		orderReference:"String",
		pickupTasks:"PickupTask",
		pickupTime:"DateTime",
		price:"Int",
		reasonForCancellation:"String",
		route:"JSON",
		stackId:"String",
		stackStatus:"JobStatus",
		status:"JobStatus",
		updatedAt:"DateTime"
	},
	JobAssignmentHistory:{
		autoRejectAt:"DateTime",
		createdAt:"DateTime",
		driver:"Driver",
		driverId:"String",
		id:"ID",
		job:"Job",
		jobId:"String",
		status:"DriverJobAssignmentStatus",
		updatedAt:"DateTime"
	},
	MinimalNode:{
		id:"String",
		name:"String"
	},
	Mutation:{
		acceptJob:"Job",
		assignJobToDriver:"Job",
		assignJobsScheduler:"String",
		autoTransitionDriversStatusScheduler:"String",
		autoTravelToPickupScheduler:"String",
		cancelJob:"Job",
		cancelJobsScheduler:"String",
		completeJob:"Job",
		completeUserProfile:"String",
		continueJobsAssignmentScheduler:"String",
		createApiImplementation:"ApiImplementation",
		createBase:"Base",
		createDropOffTask:"DropOffTask",
		createFleet:"Fleet",
		createFleetDriver:"FleetDriver",
		createJob:"Job",
		createJobAssignmentHistory:"JobAssignmentHistory",
		createPartner:"Partner",
		createPickupTask:"PickupTask",
		createPlatform:"Platform",
		createUser:"CreateUserResponse",
		createWebhook:"Webhook",
		deleteApiImplementation:"ApiImplementation",
		deleteDriver:"Driver",
		deleteJob:"Job",
		deleteUser:"User",
		deleteWebhook:"Webhook",
		driverLogin:"DriverLoginResponse",
		driverLogout:"String",
		inviteDriver:"Driver",
		inviteUser:"String",
		login:"String",
		notifyDriver:"Boolean",
		rejectJob:"Job",
		removeDriverStatusOption:"Boolean",
		requestPasswordReset:"String",
		resetPassword:"String",
		setDriverStatusFromOption:"Boolean",
		updateApiImplementation:"ApiImplementation",
		updateBase:"Base",
		updateDriver:"Driver",
		updateDriverDetails:"Driver",
		updateDriverLocation:"String",
		updateDropOffTask:"DropOffTask",
		updateDropOffTaskProofOfDelivery:"DropOffTask",
		updateFleet:"Fleet",
		updateFleetDriver:"FleetDriver",
		updateJob:"Job",
		updateJobAssignmentHistory:"JobAssignmentHistory",
		updateJobPickupTime:"Job",
		updateJobStatus:"Job",
		updatePartner:"Partner",
		updatePassword:"String",
		updatePickupTask:"PickupTask",
		updatePlatform:"Platform",
		updatePushToken:"Driver",
		updateUser:"User",
		updateWebhook:"Webhook",
		uploadJobCompleteProof:"Boolean",
		verifyPhone:"VerifyPhoneResponse"
	},
	OptionForDriverStatus:{
		booked:"Boolean",
		durationInMinutes:"Int",
		id:"ID",
		label:"String",
		maxStartTime:"String",
		minStartTime:"String",
		minimalLabel:"String",
		status:"String"
	},
	PaginatedJobs:{
		count:"Int",
		jobs:"Job"
	},
	Partner:{
		createdAt:"DateTime",
		fleets:"Fleet",
		id:"ID",
		name:"String",
		platform:"Platform",
		platformId:"String",
		updatedAt:"DateTime",
		users:"User"
	},
	PickupTask:{
		createdAt:"DateTime",
		id:"ID",
		job:"Job",
		jobId:"String",
		location:"JSON",
		locationHashed:"String",
		locationName:"String",
		pickupContact:"JSON",
		pickupNotes:"String",
		updatedAt:"DateTime"
	},
	Platform:{
		createdAt:"DateTime",
		id:"ID",
		name:"String",
		partners:"Partner",
		updatedAt:"DateTime"
	},
	Query:{
		apiImplementation:"ApiImplementation",
		apiImplementations:"ApiImplementation",
		assignedJobs:"Job",
		availableNodesByUserAndDesiredRole:"MinimalNode",
		availableRolesByUser:"UserRole",
		base:"Base",
		bases:"Base",
		bookedAndAvailableOptionsForDriverStatus:"OptionForDriverStatus",
		calculateRoute:"String",
		collisionsOrStackability:"CollisionsOrStackability",
		currentDriver:"Driver",
		currentJobs:"Job",
		currentUser:"User",
		deliveryEstimate:"DeliveryEstimate",
		driver:"Driver",
		drivers:"Driver",
		dropOffTask:"DropOffTask",
		dropOffTasks:"DropOffTask",
		eligibleDriversForPickup:"EligibleDriversForPickup",
		exceptionQueue:"Job",
		fleet:"Fleet",
		fleetDriver:"FleetDriver",
		fleetDrivers:"FleetDriver",
		fleets:"Fleet",
		job:"Job",
		jobAssignmentHistories:"JobAssignmentHistory",
		jobAssignmentHistory:"JobAssignmentHistory",
		jobs:"Job",
		paginatedJobs:"PaginatedJobs",
		partner:"Partner",
		partners:"Partner",
		pendingJobAndStack:"Job",
		pickupTask:"PickupTask",
		pickupTasks:"PickupTask",
		platform:"Platform",
		platforms:"Platform",
		timeConnectedInMs:"String",
		userByEmail:"User",
		userById:"User",
		users:"User",
		vehicle:"Vehicle",
		vehicles:"Vehicle",
		webhook:"Webhook",
		webhooks:"Webhook"
	},
	Subscription:{
		assignedJobs:"Job",
		currentDriver:"Driver",
		currentJobs:"Job",
		driver:"Driver",
		drivers:"Driver",
		job:"Job",
		jobs:"Job",
		paginatedJobs:"PaginatedJobs",
		pendingJobAndStack:"Job"
	},
	User:{
		createdAt:"DateTime",
		email:"String",
		enabled:"Boolean",
		firstName:"String",
		fleets:"Fleet",
		id:"ID",
		lastName:"String",
		partners:"Partner",
		password:"String",
		phoneNumber:"String",
		role:"String",
		updatedAt:"DateTime",
		userStatus:"String"
	},
	Vehicle:{
		createdAt:"DateTime",
		driver:"Driver",
		driverId:"String",
		id:"ID",
		make:"String",
		model:"String",
		ownerDriver:"Boolean",
		ownerFleet:"Fleet",
		ownerFleetId:"String",
		reg:"String",
		type:"VehicleType",
		updatedAt:"DateTime"
	},
	VerifyPhoneResponse:{
		completedSignUp:"Boolean",
		token:"String"
	},
	Webhook:{
		apiKeyHeader:"String",
		apiKeyValue:"String",
		createdAt:"DateTime",
		fleet:"Fleet",
		fleetId:"ID",
		forJobStatus:"JobStatus",
		id:"ID",
		isRedboxApi:"Boolean",
		name:"String",
		updatedAt:"DateTime",
		url:"String"
	}
}

export const Ops = {
mutation: "Mutation" as const,
	query: "Query" as const,
	subscription: "Subscription" as const
}