import { decode } from 'jsonwebtoken'
import { AuthTokenType, UserRole } from '../zeus'

export interface IPayload {
  userId: string
  userRole: UserRole
  tokenType: AuthTokenType
  iat?: number
  exp?: number
}

export const getPayloadFromToken = (token: string) =>
  decode(token) as IPayload | null
