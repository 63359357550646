import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const COLLISIONS_OR_STACKABILITY = typedGql('query')({
  collisionsOrStackability: [
    {
      destinationDriverId: $('destinationDriverId', 'String!'),
      jobId: $('jobId', 'String!'),
    },
    {
      jobsOverlap: true,
      isPlausible: true,
      isStackable: true,
    },
  ],
})
