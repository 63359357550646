import { typedGql } from '../../../zeus/typedDocumentNode'
import { $ } from '../../../zeus'

export const LIST_PARTNERS = typedGql('query')({
  partners: [
    {
      orderBy: {
        name: $('partnersOrderByNameDirection', 'SortOrder!'),
      },
    },
    {
      id: true,
      name: true,
      updatedAt: true,
      createdAt: true,
      platform: {
        id: true,
        name: true,
      },
    },
  ],
})
