import { Collapse, Icon, IconSize, Intent, Tag } from '@blueprintjs/core'
import React, { Fragment, VFC } from 'react'
import { EmptyListState } from '../../../components/NonIdealState/NonIdealState'
import { DASHBOARD_JOBS_RESPONSE } from '../gql/jobs'
import { TableView } from '../../../components/Views/Table'
import { StringParam, useQueryParam } from 'use-query-params'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { uniqueId } from 'lodash'
import { EllipsisText } from '../../../atomics/EllipsisText'
import { EstimatedTimeWarning } from '../../../atomics/EstimatedTimeWarning'
import { TasksTableEntry } from '../../../components/TasksTableEntry'
import { Explanation } from '../../../atomics/Explanation'
import { QUERY_PARAMS } from '../../../utils/queryParamsNames'
import { DATE_TIME_FORMAT_FOR_ESTIMATED_TIME } from '../../../atomics/EstimatedTimeActualAndDelta'
import { StatusTag, jobStatusColors } from '../../../styles/status'

export const JobsDetails: VFC<{
  jobs: DASHBOARD_JOBS_RESPONSE['jobs']
}> = ({ jobs }) => {
  if (!jobs || !jobs.length) {
    return <EmptyListState entityName="jobs" />
  }

  const [selectedJob, setSelectedJob] = useQueryParam(
    QUERY_PARAMS.jobId,
    StringParam
  )
  const [_stackId, setStackId] = useQueryParam(
    QUERY_PARAMS.stackId,
    StringParam
  )

  return (
    <TableView>
      <thead>
        <tr>
          <th></th>
          <th>Order</th>
          <th>Job</th>
          <th>Status</th>
          <th>Fleet</th>
          <th>Estimated Duration</th>
          <th>Pickup Time</th>
          <th>Driver</th>
        </tr>
      </thead>
      <tbody>
        {jobs.map(job => {
          const {
            id,
            jobReference,
            stackId,
            orderId,
            orderIsAsap,
            status,
            onHold,
            estimatedPickupTime,
            pickupTime,
            estimatedDurationMinutes,
            pickupTasks,
            fleet: { name: fleetName, id: fleetId },
            dropOffTasks,
            driver,
          } = job

          return (
            <Fragment key={id}>
              <tr>
                <td>
                  <Icon
                    style={{
                      cursor: 'pointer',
                    }}
                    icon={selectedJob === id ? 'chevron-up' : 'chevron-down'}
                    onClick={() => {
                      setSelectedJob(selectedJob === id ? undefined : id)
                      setStackId(selectedJob === id ? undefined : stackId)
                    }}
                  />
                </td>
                <td>
                  <div style={{ display: 'flex' }}>
                    <Explanation
                      icon="time"
                      intent={orderIsAsap ? Intent.PRIMARY : Intent.NONE}
                      size={IconSize.STANDARD}
                      content={`Order is ${!orderIsAsap ? 'NOT' : ''} ASAP`}
                      style={{
                        marginRight: 10,
                        opacity: orderIsAsap ? 1 : 0.2,
                      }}
                    />

                    <EllipsisText>{orderId}</EllipsisText>
                  </div>
                </td>
                <td>
                  <div style={{ display: 'flex' }}>
                    <>
                      <Explanation
                        icon="layers"
                        intent={stackId ? Intent.PRIMARY : Intent.NONE}
                        size={IconSize.STANDARD}
                        content={`Job is ${
                          !stackId ? 'NOT' : ''
                        } part of a stack`}
                        style={{
                          marginRight: 10,
                          opacity: stackId ? 1 : 0.2,
                        }}
                      />
                    </>
                    <Link to={`/jobs/${id}`}>{jobReference}</Link>
                  </div>
                </td>
                <td>
                  <StatusTag
                    intent={jobStatusColors[status].intent}
                    icon={
                      <Explanation
                        icon={onHold ? 'pause' : 'play'}
                        size={12}
                        content={`Job is ${!onHold ? 'NOT' : ''} on hold`}
                        style={{ marginBottom: 1.5 }}
                      />
                    }
                  >
                    {status.replaceAll('_', ' ')}
                  </StatusTag>
                </td>
                <td>
                  <Link to={`/fleets/${fleetId}`}>{fleetName}</Link>
                </td>
                <td>
                  {estimatedDurationMinutes
                    ? `${Math.floor(estimatedDurationMinutes)} min`
                    : '---'}
                </td>
                <td style={{ minWidth: 80 }}>
                  <EstimatedTimeWarning
                    pickupTime={(pickupTime ?? estimatedPickupTime) as string}
                  />{' '}
                  {format(
                    new Date(estimatedPickupTime as string),
                    DATE_TIME_FORMAT_FOR_ESTIMATED_TIME
                  )}
                </td>
                <td>
                  {driver?.id ? (
                    <Link to={`/drivers/${driver.id}`}>
                      <EllipsisText>
                        <Explanation
                          icon="pivot"
                          intent={
                            driver.allowJobRejection
                              ? Intent.PRIMARY
                              : Intent.NONE
                          }
                          size={IconSize.STANDARD}
                          content={`Driver can ${
                            !driver.allowJobRejection ? 'NOT' : ''
                          } accept / reject jobs`}
                          style={{
                            marginRight: 10,
                            opacity: driver.allowJobRejection ? 1 : 0.2,
                          }}
                        />
                        {driver.firstName
                          ? driver.firstName.concat(' ', driver.lastName || '')
                          : driver.phoneNumber}
                      </EllipsisText>
                    </Link>
                  ) : (
                    '---'
                  )}
                </td>
              </tr>
              <tr id={job.id || uniqueId()}>
                <td colSpan={8} style={{ padding: 0 }}>
                  <Collapse key={job.id} isOpen={selectedJob === job.id}>
                    <TasksTableEntry
                      pickupTasks={pickupTasks || []}
                      dropOffTasks={dropOffTasks || []}
                      showAddress
                      stacked={!!stackId}
                    />
                  </Collapse>
                </td>
              </tr>
            </Fragment>
          )
        })}
      </tbody>
    </TableView>
  )
}
