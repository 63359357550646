import styled, { css } from 'styled-components'

export const DialogWrapper = styled.div<{
  darkMode: boolean
}>`
  background-color: ${({ darkMode }) =>
    darkMode ? '#2f343c !important' : '#FFFFFF'};

  .bp5-dialog-footer-fixed {
    background-color: ${({ darkMode }) =>
      darkMode ? '#2f343c !important' : '#FFFFFF'};
  }

  .bp5-card {
    background-color: ${({ darkMode }) =>
      darkMode ? '#2f343c !important' : '#FFFFFF'};
  }
`
