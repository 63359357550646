import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'
import { ResultOf } from '@graphql-typed-document-node/core'

export const GET_JOB = typedGql('query')({
  job: [
    {
      id: $('jobId', 'String!'),
    },
    {
      id: true,
      jobReference: true,
      stackId: true,
      status: true,
      stackStatus: true,
      onHold: true,
      canceledBy: true,
      reasonForCancellation: true,
      orderReference: true,
      orderId: true,
      orderIsAsap: true,
      orderContents: true,
      createdAt: true,
      updatedAt: true,
      estimatedPickupTime: true,
      finishedAssignment: true,
      pickupTime: true,
      completionTime: true,
      completedByDriverId: true,
      completedByDriverFullName: true,
      jobCompleteNotes: true,
      jobCompletePhotoProof: true,
      cost: true,
      initialCost: true,
      price: true,
      distanceUnit: true,
      fleet: {
        id: true,
        name: true,
      },
      driver: {
        id: true,
        phoneNumber: true,
        firstName: true,
        lastName: true,
        currentLocation: true,
        status: true,
        breaks: true,
        pushToken: true,
        allowJobRejection: true,
        vehicle: {
          id: true,
          reg: true,
        },
      },
      estimatedDistanceMiles: true,
      estimatedDurationMinutes: true,
      route: true,
      idealRoute: true,
      pickupTasks: {
        __typename: true,
        id: true,
        pickupContact: true,
        pickupNotes: true,
        location: true,
        locationName: true,
        createdAt: true,
        updatedAt: true,
      },
      dropOffTasks: {
        __typename: true,
        id: true,
        ageVerification: true,
        dropOffContact: true,
        dropOffNotes: true,
        location: true,
        locationName: true,
        proofOfDelivery: true,
        orderInStack: true,
        allocatedTime: true,
        createdAt: true,
        updatedAt: true,
      },
      assignmentHistory: {
        id: true,
        driverId: true,
        status: true,
        autoRejectAt: true,
        createdAt: true,
        updatedAt: true,
      },
    },
  ],
  jobs: [
    {
      where: $('jobsWhere', 'JobWhereInput!'),
    },
    {
      id: true,
      status: true,
      stackStatus: true,
      dropOffTasks: {
        id: true,
        ageVerification: true,
        dropOffContact: true,
        dropOffNotes: true,
        location: true,
        locationName: true,
        proofOfDelivery: true,
        orderInStack: true,
        allocatedTime: true,
        createdAt: true,
        updatedAt: true,
        __typename: true,
      },
    },
  ],
})

export type GET_JOB_RESPONSE_TYPE = ResultOf<typeof GET_JOB>
