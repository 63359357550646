// TODO i wrote this but actually i hate it
// i dont like the idea of calling the hook in every route
// and if you don't then the tabs/button doesn't get wiped out
// i think it needs to have tighter integration with the routes
// but there's more important things to do
import React, { createContext, useContext, useEffect } from 'react'
import { Header } from '../components/Header/Header'
import { PageLayoutButton, PageLayoutTab } from '../components/Header/types'

type PageLayoutState = {
  button: PageLayoutButton | null
  tabs: PageLayoutTab[]
  darkMode?: boolean
}

type PageLayoutContextType = {
  configurePageLayout: (arg: PageLayoutState) => void
  setDarkMode: (arg: boolean) => void
  darkMode: boolean
}

export const PageLayoutContext = createContext<PageLayoutContextType>({
  configurePageLayout: () => {
    throw new Error('configurePageLayout not implemented')
  },
  setDarkMode: () => {},
  darkMode: false,
})

export const PageLayoutProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<PageLayoutState>({
    button: null,
    tabs: [],
  })
  const [darkMode, setDarkMode] = React.useState<boolean>(false)

  const configurePageLayout = (arg: PageLayoutState) => {
    setState(arg)
  }

  return (
    <PageLayoutContext.Provider
      value={{
        configurePageLayout,
        setDarkMode,
        darkMode,
      }}
    >
      <Header button={state.button || undefined} tabs={state.tabs} />
      <div className={darkMode ? 'bp5-dark' : ''}>{children}</div>
    </PageLayoutContext.Provider>
  )
}

export const configurePageLayout = (
  newState: PageLayoutState = { button: null, tabs: [] }
) => {
  const { configurePageLayout } = useContext(PageLayoutContext)

  // TODO pass children to Tabs once this is sorted
  useEffect(() => {
    configurePageLayout(newState)
  }, [JSON.stringify(newState)]) // i hate this so much
}
