import {
  Button,
  Card,
  Classes,
  Collapse,
  ControlGroup,
  FormGroup,
  InputGroup,
  Intent,
  Switch,
} from '@blueprintjs/core'
import { DialogBody, DialogFooter } from '@blueprintjs/core'
import React, { useState } from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { useMutation, useQuery } from '@apollo/client'
import { DELETE_WEBHOOK } from '../../gql/deleteWebhook'
import { UPDATE_WEBHOOK } from '../../gql/updateWebhook'
import { useFormik } from 'formik'
import {
  ErrorState,
  LoadingState,
} from '../../../../components/NonIdealState/NonIdealState'
import { JobStatusSelect } from '../../../../components/Select/JobStatusSelect'
import {
  WEBHOOKS_BY_FLEET,
  WEBHOOKS_BY_FLEET_RESPONSE,
} from '../../gql/fleetWithWebhooks'
import { ModelTypes } from '../../../../zeus'
import { QUERY_PARAMS } from '../../../../utils/queryParamsNames'

type Webhook = ModelTypes['Webhook']
type PartialWebhook = Pick<
  Webhook,
  | 'id'
  | 'name'
  | 'url'
  | 'forJobStatus'
  | 'createdAt'
  | 'updatedAt'
  | 'isRedboxApi'
  | 'apiKeyHeader'
  | 'apiKeyValue'
>

export const WebhookDetails = ({
  webhook,
  fleetId,
}: {
  webhook?: PartialWebhook
  fleetId: string
}) => {
  const {
    name,
    url,
    forJobStatus,
    id,
    isRedboxApi,
    apiKeyHeader,
    apiKeyValue,
  } = webhook!
  const [, setWebhookId] = useQueryParam(QUERY_PARAMS.webhookId, StringParam)
  const [credentialsExpanded, setCredentialsExpanded] = useState(false)
  const [deleteWebhookFn] = useMutation(DELETE_WEBHOOK, {
    refetchQueries: [
      {
        query: WEBHOOKS_BY_FLEET,
        variables: { id: fleetId },
      },
    ],
  })

  const [
    updateWebhookFn,
    { loading: updateWebhookFnLoading, error: updateWebhookFnError },
  ] = useMutation(UPDATE_WEBHOOK, {
    refetchQueries: [
      {
        query: WEBHOOKS_BY_FLEET,
        variables: { id: fleetId },
      },
    ],
  })

  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      name,
      url,
      isRedboxApi,
      forJobStatus,
      apiKeyHeader,
      apiKeyValue,
    },
    onSubmit: async values => {
      await updateWebhookFn({
        variables: {
          input: {
            id,
            name: values.name,
            url: values.url,
            isRedboxApi: values.isRedboxApi,
            forJobStatus: values.forJobStatus,
            apiKeyHeader: values.apiKeyHeader,
            apiKeyValue: values.apiKeyValue,
          },
        },
      })

      setWebhookId(null)
    },
  })

  return (
    <Card>
      <form onSubmit={handleSubmit}>
        <DialogBody>
          <FormGroup label="Name" labelFor="name">
            <InputGroup
              id="name"
              name="name"
              intent={errors.name ? 'danger' : 'none'}
              value={values.name}
              onChange={handleChange}
            />
          </FormGroup>
          <ControlGroup fill>
            <FormGroup label="URL" labelFor="url">
              <InputGroup
                id="url"
                name="url"
                intent={errors.url ? 'danger' : 'none'}
                value={values.url}
                onChange={handleChange}
              />
            </FormGroup>
            <FormGroup
              label="Redbox API"
              labelFor="isRedboxApi"
              className={Classes.FIXED}
              style={{ marginLeft: '10px' }}
            >
              <Switch
                name="isRedboxApi"
                checked={values.isRedboxApi}
                onChange={handleChange}
              />
            </FormGroup>
          </ControlGroup>

          <Button
            style={{
              padding: '0',
              margin: '0 0 16px 0',
            }}
            minimal
            rightIcon={credentialsExpanded ? 'caret-up' : 'caret-down'}
            text="Credentials"
            onClick={e => {
              e.preventDefault()
              setCredentialsExpanded(!credentialsExpanded)
            }}
          />
          <Collapse isOpen={credentialsExpanded}>
            <ControlGroup fill>
              <FormGroup label="Header" labelFor="apiKeyHeader">
                <InputGroup
                  id="apiKeyHeader"
                  name="apiKeyHeader"
                  placeholder="e.g. x-api-key"
                  value={values.apiKeyHeader}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup label="Value" labelFor="apiKeyValue">
                <InputGroup
                  id="apiKeyValue"
                  name="apiKeyValue"
                  placeholder="e.g. 1234567890"
                  value={values.apiKeyValue}
                  onChange={handleChange}
                />
              </FormGroup>
            </ControlGroup>
          </Collapse>

          <FormGroup label="For Job Status" labelFor="forJobStatus">
            <JobStatusSelect
              name="forJobStatus"
              onChange={handleChange}
              value={values.forJobStatus || ''}
              large
              fill
            />
          </FormGroup>
        </DialogBody>

        <DialogFooter>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              text="Delete"
              intent={Intent.DANGER}
              minimal
              onClick={async () => {
                await deleteWebhookFn({
                  variables: { id },
                })
                setWebhookId(null)
              }}
            />
            <Button
              text="Save"
              intent={Intent.PRIMARY}
              minimal
              outlined
              type="submit"
            />
          </div>
        </DialogFooter>
      </form>
    </Card>
  )
}
