import React, { useMemo } from 'react'
import { ButtonGroup, Button, Position, MenuItem } from '@blueprintjs/core'
import { Select } from '@blueprintjs/select'
import {
  add,
  clamp,
  Duration,
  endOfDay,
  format,
  getMonth,
  getYear,
  setMonth,
  setYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  sub,
} from 'date-fns'
import { MonthAndYearSelector } from './MonthAndYearSelector'
import { WeekSelector } from './WeekSelector'
import { DaySelector } from './DaySelector'
import { QuarterSelector } from './QuarterSelector'
import { CustomRangeSelector } from './CustomRangeSelector'
import { ISO_WEEK_DAY } from './datetime'
import { useQueryParam } from 'use-query-params'
import { QUERY_PARAMS } from '../../../utils/queryParamsNames'

/**
 * CONSTANTS
 */
const URL_DATE_FORMAT = 'yyyy-MM-dd'
export const DATE_FILTER_TYPES = {
  DAY: { key: 'DAY', name: 'Day' },
  WEEK: { key: 'WEEK', name: 'Week' },
  MONTH: { key: 'MONTH', name: 'Month' },
  QUARTER: { key: 'QUARTER', name: 'Quarter' },
  CUSTOM: { key: 'CUSTOM', name: 'Custom' },
} as const
type DateFilterInterval =
  (typeof DATE_FILTER_TYPES)[keyof typeof DATE_FILTER_TYPES]
export type DateFilterConstIntervals = DateFilterInterval['key']

/**
 * DATE UTILS
 */
const formatToDateOnly = (date: Date) => format(date, URL_DATE_FORMAT)
const startOfMostRecentMonday = (date = new Date()) =>
  startOfWeek(date, { weekStartsOn: ISO_WEEK_DAY.MONDAY })
const getDefaultStartOfRangeDate = (dateFilterTypeKey: string) => {
  if (dateFilterTypeKey === DATE_FILTER_TYPES.WEEK.key) {
    return startOfMostRecentMonday()
  }
  if (dateFilterTypeKey === DATE_FILTER_TYPES.MONTH.key) {
    return startOfMonth(new Date())
  }
  return startOfDay(new Date())
}

type CalculateEndFromStartOfRangeDateProps = {
  startOfRangeDate: Date
  dateFilterTypeKey: string
  maxCustomRangeInterval: Duration
}
export const calculateEndFromStartOfRangeDate = ({
  startOfRangeDate,
  dateFilterTypeKey,
  maxCustomRangeInterval,
}: CalculateEndFromStartOfRangeDateProps): Date =>
  endOfDay(
    clamp(
      add(startOfRangeDate, {
        ...(dateFilterTypeKey === DATE_FILTER_TYPES.WEEK.key && {
          weeks: 1,
          days: -1,
        }),
        ...(dateFilterTypeKey === DATE_FILTER_TYPES.MONTH.key && {
          months: 1,
          days: -1,
        }),
        ...(dateFilterTypeKey === DATE_FILTER_TYPES.QUARTER.key && {
          months: 3,
          days: -1,
        }),
      }),
      {
        start: startOfRangeDate,
        end: sub(add(startOfRangeDate, maxCustomRangeInterval), { days: 1 }),
      }
    )
  )

const calculateMaxIntervalBasedOnFilterTypes = (
  filterTypes: DateFilterInterval[]
): Duration => {
  const keyToDuration = {
    [DATE_FILTER_TYPES.DAY.key]: { days: 1 },
    [DATE_FILTER_TYPES.WEEK.key]: { days: 7 },
    [DATE_FILTER_TYPES.MONTH.key]: { days: 31 },
    [DATE_FILTER_TYPES.QUARTER.key]: { days: 93 },
  }

  const longestDuration = filterTypes.reduce((acc, filterType) => {
    if (filterType.key === 'CUSTOM') {
      return acc
    }

    const daysFromKey = keyToDuration[filterType.key].days
    const daysFromAcc = acc.days
    if (daysFromKey > daysFromAcc) {
      acc = keyToDuration[filterType.key]
    }
    return acc
  }, keyToDuration[DATE_FILTER_TYPES.DAY.key])

  return longestDuration
}

/**
 * COMPONENT
 */
type DateRangeFilterProps = {
  disabled?: boolean
  minimal?: boolean
  defaultFilterTypeKey?: string
  defaultStartOfRangeDate?: Date
  defaultEndOfRangeDate?: Date
  filterTypes?: DateFilterInterval[]
  offsetHours?: number
}

export const DateRangeFilter = ({
  filterTypes = Object.values(DATE_FILTER_TYPES),
  defaultFilterTypeKey = DATE_FILTER_TYPES.DAY.key,
  defaultStartOfRangeDate = getDefaultStartOfRangeDate(defaultFilterTypeKey),
  defaultEndOfRangeDate,
  disabled = false,
  minimal = false,
  offsetHours = 0,
}: DateRangeFilterProps) => {
  const maxCustomRangeInterval = useMemo(
    () => calculateMaxIntervalBasedOnFilterTypes(filterTypes),
    [filterTypes]
  )

  const [dateFilterTypeKey, setDateFilterTypeKey] = useQueryParam<
    string | undefined | null
  >(QUERY_PARAMS.dateFilterTypeKey)
  const [startOfRangeDate, setStartOfRangeDate] = useQueryParam<
    string | undefined | null
  >(QUERY_PARAMS.startOfRangeDate)
  const [endOfRangeDate, setEndOfRangeDate] = useQueryParam<
    string | undefined | null
  >(QUERY_PARAMS.endOfRangeDate)

  if (
    !dateFilterTypeKey ||
    !startOfRangeDate ||
    (dateFilterTypeKey === DATE_FILTER_TYPES.CUSTOM.key && !endOfRangeDate)
  ) {
    if (!dateFilterTypeKey) {
      setDateFilterTypeKey(defaultFilterTypeKey)
    }
    if (!startOfRangeDate) {
      setStartOfRangeDate(formatToDateOnly(defaultStartOfRangeDate))
    }
    if (dateFilterTypeKey === DATE_FILTER_TYPES.CUSTOM.key && !endOfRangeDate) {
      setEndOfRangeDate(
        formatToDateOnly(
          defaultEndOfRangeDate ??
            calculateEndFromStartOfRangeDate({
              startOfRangeDate: defaultStartOfRangeDate,
              dateFilterTypeKey: defaultFilterTypeKey,
              maxCustomRangeInterval,
            })
        )
      )
    }
    return null
  }

  const startOfRangeDateTime = startOfDay(new Date(startOfRangeDate))

  return (
    <ButtonGroup>
      <Select
        filterable={false}
        items={filterTypes}
        itemRenderer={(item: DateFilterInterval, { handleClick }) => (
          <MenuItem
            key={item.key}
            onClick={handleClick}
            text={item.name}
            icon={item.key === dateFilterTypeKey ? 'tick' : 'blank'}
            shouldDismissPopover={false}
          />
        )}
        onItemSelect={selectedDateFilterType => {
          setDateFilterTypeKey(selectedDateFilterType.key)

          // If the selected date filter type is custom
          // then we need to calculate the end date, as other filter types
          // only have a start date
          if (selectedDateFilterType.key === DATE_FILTER_TYPES.CUSTOM.key) {
            // calculate end of range date based on the start of range date
            // but clamp to the end of the current day, otherwise the custom end date picker can't handle the future date
            const calculatedEndOfRangeDate = clamp(
              calculateEndFromStartOfRangeDate({
                startOfRangeDate: startOfRangeDateTime,
                dateFilterTypeKey: dateFilterTypeKey,
                maxCustomRangeInterval,
              }),
              {
                start: startOfRangeDateTime,
                end: endOfDay(new Date()),
              }
            )
            setEndOfRangeDate(formatToDateOnly(calculatedEndOfRangeDate))
          } else {
            // clear endOfRangeDate as that's only used for the CUSTOM filter type
            setEndOfRangeDate(null)

            // WEEK and MONTH filter types need to be set to the start of the week/month
            // (QUARTER allows a custom startOfRangeDate so does not require a similar change)
            if (selectedDateFilterType.key === DATE_FILTER_TYPES.WEEK.key) {
              setStartOfRangeDate(
                formatToDateOnly(startOfMostRecentMonday(startOfRangeDateTime))
              )
            }
            if (selectedDateFilterType.key === DATE_FILTER_TYPES.MONTH.key) {
              setStartOfRangeDate(
                formatToDateOnly(startOfMonth(startOfRangeDateTime))
              )
            }
          }
        }}
        popoverProps={{
          minimal: false,
          position: Position.BOTTOM_LEFT,
          boundary: document.body,
        }}
      >
        <Button disabled={disabled} minimal={minimal} icon={'calendar'}>
          {
            DATE_FILTER_TYPES[dateFilterTypeKey as DateFilterConstIntervals]
              .name
          }
        </Button>
      </Select>
      {dateFilterTypeKey === DATE_FILTER_TYPES.DAY.key && (
        <DaySelector
          date={startOfRangeDateTime}
          setDate={(date: Date) => {
            setStartOfRangeDate(formatToDateOnly(date))
          }}
          offsetHours={offsetHours}
        />
      )}
      {dateFilterTypeKey === DATE_FILTER_TYPES.WEEK.key && (
        <WeekSelector
          startOfWeekDate={startOfRangeDateTime}
          setStartOfWeekDate={(date: Date) => {
            setStartOfRangeDate(formatToDateOnly(date))
          }}
          offsetHours={offsetHours}
        />
      )}
      {dateFilterTypeKey === DATE_FILTER_TYPES.MONTH.key && (
        <MonthAndYearSelector
          monthIndex={getMonth(startOfRangeDateTime)}
          setChangeMonth={(monthIndex: number) => {
            setStartOfRangeDate(
              formatToDateOnly(setMonth(startOfRangeDateTime, monthIndex))
            )
          }}
          year={getYear(startOfRangeDateTime)}
          setChangeYear={(year: number) => {
            setStartOfRangeDate(
              formatToDateOnly(setYear(startOfRangeDateTime, year))
            )
          }}
        />
      )}
      {dateFilterTypeKey === DATE_FILTER_TYPES.QUARTER.key && (
        <QuarterSelector
          startOfQuarterDate={startOfRangeDateTime}
          setStartOfQuarterDate={(date: Date) => {
            setStartOfRangeDate(formatToDateOnly(date))
          }}
          offsetHours={offsetHours}
        />
      )}
      {dateFilterTypeKey === DATE_FILTER_TYPES.CUSTOM.key && (
        <CustomRangeSelector
          startOfRangeDate={startOfRangeDateTime}
          setStartOfRangeDate={(date: Date) => {
            setStartOfRangeDate(formatToDateOnly(date))
          }}
          endOfRangeDate={endOfDay(
            new Date(endOfRangeDate ?? add(new Date(), maxCustomRangeInterval))
          )}
          setEndOfRangeDate={(date: Date) => {
            setEndOfRangeDate(formatToDateOnly(date))
          }}
          maxCustomRangeInterval={maxCustomRangeInterval}
          offsetHours={offsetHours}
        />
      )}
    </ButtonGroup>
  )
}
