import { typedGql } from '../../../zeus/typedDocumentNode'
import { $, SortOrder } from '../../../zeus'
import { ResultOf } from '@graphql-typed-document-node/core'

export const DRIVERS_AND_THEIR_JOBS = typedGql('query')({
  drivers: [
    {
      where: $('where', 'DriverWhereInput'),
      orderBy: {
        allowJobRejection: SortOrder['asc'], // prefer drivers that can't reject jobs
      },
    },
    {
      id: true,
      phoneNumber: true,
      firstName: true,
      lastName: true,
      completedSignUp: true,
      currentLocation: true,
      status: true,
      breaks: true,
      allowJobRejection: true,
      allowStackingOrders: true,
      jobs: {
        id: true,
        jobReference: true,
        stackId: true,
        estimatedPickupTime: true,
        estimatedDurationMinutes: true,
        price: true,
      },
    },
  ],
})

export type DRIVERS_AND_THEIR_JOBS_RESPONSE = ResultOf<
  typeof DRIVERS_AND_THEIR_JOBS
>
