import React from 'react'
import { Popup } from 'maplibre-gl'
import { renderToString } from 'react-dom/server'
import { FC } from 'react'
import { PopupInsideContainer } from '../styles'
import { Tag, HTMLTable } from '@blueprintjs/core'
import { ModelTypes } from '../../../zeus'
import { driverStatusColors } from '../../../styles/status'
import { OptionsForStatus } from '../../OptionsForStatus'

export type PartialDriver = Pick<
  ModelTypes['Driver'],
  | 'id'
  | 'currentLocation'
  | 'status'
  | 'breaks'
  | 'firstName'
  | 'lastName'
  | 'phoneNumber'
>

export const createDriverPopup = (driver: PartialDriver): Popup => {
  const html = renderToString(<DriverPopupContent driver={driver} />)

  const popup = new Popup({
    offset: 20,
    anchor: 'right',
  }).setHTML(html)

  return popup
}

export const DriverPopupContent: FC<{
  driver: PartialDriver
}> = ({ driver }) => {
  const { id, status, breaks, firstName, lastName, phoneNumber } = driver

  return (
    <PopupInsideContainer>
      <div style={{ padding: '10px' }}>
        <Tag intent={driverStatusColors[status].intent}>{status}</Tag>
      </div>
      <HTMLTable compact>
        <tr>
          <th>Driver</th>
          <td>
            <a href={`/drivers/${id}`}>
              {firstName ? firstName.concat(' ', lastName ?? '') : phoneNumber}
            </a>
          </td>
        </tr>

        {!!breaks?.length && (
          <tr>
            <th>Breaks</th>
            <td>
              <div>
                <OptionsForStatus
                  options={breaks as ModelTypes['OptionForDriverStatus'][]}
                  showInactiveOption
                  flexDirection="column"
                />
              </div>
            </td>
          </tr>
        )}

        <tr>
          <th>Contact</th>
          <td>{phoneNumber}</td>
        </tr>
      </HTMLTable>
    </PopupInsideContainer>
  )
}
