import React from 'react'
import { Button, MenuItem } from '@blueprintjs/core'
import { useQueryParam } from 'use-query-params'
import { JobStatus } from '../../zeus'
import { QUERY_PARAMS } from '../../utils/queryParamsNames'
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select'

export interface Status {
  value: string
  label: string
}

const filterStatus: ItemPredicate<Status> = (
  query,
  status,
  _index,
  exactMatch
) => {
  const normalizedTitle = status.label.toLowerCase()
  const normalizedQuery = query.toLowerCase()

  if (exactMatch) {
    return normalizedTitle === normalizedQuery
  } else {
    return `${normalizedTitle} ${status.label}`.includes(normalizedQuery)
  }
}

const renderStatus: ItemRenderer<Status> = (
  status,
  { handleClick, handleFocus, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      onClick={handleClick}
      key={status.value}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={status.label}
    />
  )
}

const statusesMap: Record<keyof typeof JobStatus, string> = {
  [JobStatus.ALMOST_AT_DROP_OFF]: 'Almost at drop off',
  [JobStatus.ALMOST_AT_PICKUP]: 'Almost at Pick Up',
  [JobStatus.ASSIGNED]: 'Assigned',
  [JobStatus.AWAITING_ASSIGNMENT]: 'Awaiting Assignment',
  [JobStatus.CANCELLED]: 'Cancelled',
  [JobStatus.COMPLETE]: 'Complete',
  [JobStatus.EXCEPTION]: 'Exception',
  [JobStatus.PENDING]: 'Pending',
  [JobStatus.TRAVELLING_TO_DROP_OFF]: 'Travelling to Drop Off',
  [JobStatus.TRAVELLING_TO_PICKUP]: 'Travelling to Pick Up',
  [JobStatus.WAITING_AT_DROP_OFF]: 'Waiting at Drop Off',
  [JobStatus.WAITING_AT_PICKUP]: 'Waiting at Pick Up',
}

export const FilterByJobStatus = () => {
  const [status, setStatus] = useQueryParam<undefined | JobStatus>(
    QUERY_PARAMS.jobStatus
  )
  const handleChange = (item: Status) => {
    setStatus(item.value ? (item.value as JobStatus) : undefined)
  }

  const statuses: Status[] = Object.keys(statusesMap).map(key => ({
    value: key,
    label: statusesMap[key as keyof typeof JobStatus],
    selected: status === key,
  }))

  const selectedStatus = statuses.find(item => item.value === status)

  return (
    <Select
      filterable={false}
      onItemSelect={handleChange}
      items={[{ value: '', label: 'Job Status' }, ...statuses]}
      itemPredicate={filterStatus}
      itemRenderer={renderStatus}
      noResults={
        <MenuItem
          disabled={true}
          text="No results."
          roleStructure="listoption"
        />
      }
    >
      <Button
        text={selectedStatus?.label ?? 'Job status'}
        rightIcon="double-caret-vertical"
      />
    </Select>
  )
}
