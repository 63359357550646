import { Card } from '@blueprintjs/core'
import styled from 'styled-components'
import loginBg from '../../assets/login-background.jpg'

export const LoginBackground = styled.div`
  display: flex;
  background-size: cover;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background-position: center;
  background-image: url(${loginBg});
  background-color: #ffcd00;
`

export const LoginContainer = styled.div`
  display: flex;
  margin: 10rem;
  justify-content: center;
  width: 100%;
  align-items: center;
  flex-direction: column;
`

export const LoginCard = styled(Card)`
  margin: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 340px;
  margin-bottom: 20px;
`

export const LoginButtonContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`

export const LoginLogo = styled.div`
  height: 60px;
  text-align: center;
`
