export const createElementForMarker = ({
  id,
  src,
  style,
  isDriverMarker,
}: {
  id: string
  src: string
  style?: {
    width: string
    height: string
  }
  isDriverMarker?: boolean
}): HTMLElement => {
  const el = document.createElement('div')
  const img = document.createElement('img')

  el.id = id
  el.appendChild(img)
  img.src = src
  el.style.width = style?.width || '26px'
  el.style.height = style?.height || '26px'
  if (isDriverMarker) {
    el.style.borderRadius = '50%'
    el.style.boxShadow = '0px 0px 1px 1px #0000001a'
  }

  return el
}
