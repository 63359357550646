import { $ } from '../../../../zeus'
import { typedGql } from '../../../../zeus/typedDocumentNode'

export const USER_BY_ID = typedGql('query')({
  userById: [
    {
      id: $('userId', 'String!'),
    },
    {
      id: true,
      firstName: true,
      lastName: true,
      email: true,
      phoneNumber: true,
      role: true,
      userStatus: true,
      partners: {
        id: true,
        name: true,
      },
      fleets: {
        id: true,
        name: true,
      },
    },
  ],
})
