import { useMutation } from '@apollo/client'
import {
  Button,
  Card,
  Checkbox,
  Classes,
  FormGroup,
  Icon,
  InputGroup,
  Intent,
  NumericInput,
  Popover,
  Spinner,
  Text,
  TextArea,
} from '@blueprintjs/core'
import { DialogFooter } from '@blueprintjs/core'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { DistanceUnitSelect } from '../../../../components/Select/DistanceUnitSelect'
import { ToastType, useToaster } from '../../../../hooks/useToaster'
import {
  oneKmToOneMiPrice,
  oneMiToOneKmPrice,
} from '../../../../utils/distanceConversion'
import { DistanceUnit, SortOrder } from '../../../../zeus'
import { FLEET_BY_ID_RESPONSE } from '../../gql/fleet'
import { LIST_FLEETS } from '../../gql/fleets'
import { UPDATE_FLEET } from '../../gql/updateFleet'
import { GridCell, GridContainer } from '../styles'
import { PhoneInput } from '../../../../components/Phone'

const distanceUnitToAbbreviationMap: Record<DistanceUnit, string> = {
  MILES: 'mi',
  KILOMETERS: 'km',
}

export const EditFleetForm = ({
  fleet,
}: {
  fleet: FLEET_BY_ID_RESPONSE['fleet']
}) => {
  if (!fleet) return null

  let history = useHistory()
  const showSuccessToast = useToaster({ type: ToastType.SUCCESS })
  const showErrorToast = useToaster({ type: ToastType.ERROR })

  // keep a reference so we don't get rounding losses
  // due to repeated conversions
  const initialUnitAndFees = {
    unit: fleet.distanceUnit,
    pricePerUnit: (fleet.pricePerDistanceUnit / 100).toFixed(2),
    costPerUnit: (fleet.defaultCostPerDistanceUnit / 100).toFixed(2),
  }

  const [currentUnitAndFees, setCurrentUnitAndFees] = useState<{
    unit: DistanceUnit
    pricePerUnit: string
    costPerUnit: string
  }>(initialUnitAndFees)
  const [confirmationVisible, setConfirmationVisible] = useState<boolean>(false)

  const [updateFleetMutation] = useMutation(UPDATE_FLEET, {
    onError: err => showErrorToast(err.message),
    onCompleted: () => {
      showSuccessToast('Successfully updated the fleet')
      history.push('/fleets')
    },
    refetchQueries: [
      {
        query: LIST_FLEETS,
        variables: {
          OrderFleetsByNameDirecton: SortOrder.asc,
        },
      },
    ],
  })

  const handleDistanceUnitChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newUnit =
      e.currentTarget.value === DistanceUnit.MILES
        ? DistanceUnit.MILES
        : DistanceUnit.KILOMETERS

    if (newUnit !== initialUnitAndFees.unit) {
      setConfirmationVisible(true)
      setCurrentUnitAndFees(currState => ({
        unit: newUnit,
        pricePerUnit:
          newUnit === DistanceUnit.MILES
            ? oneKmToOneMiPrice({
                pricePerKm: parseFloat(currState.pricePerUnit),
              }).toFixed(2)
            : oneMiToOneKmPrice({
                pricePerMi: parseFloat(currState.pricePerUnit),
              }).toFixed(2),
        costPerUnit:
          newUnit === DistanceUnit.MILES
            ? oneKmToOneMiPrice({
                pricePerKm: parseFloat(currState.costPerUnit),
              }).toFixed(2)
            : oneMiToOneKmPrice({
                pricePerMi: parseFloat(currState.costPerUnit),
              }).toFixed(2),
      }))
    } else {
      setCurrentUnitAndFees(initialUnitAndFees)
    }
  }

  const { values, handleChange, isSubmitting, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: fleet,
      onSubmit: async values => {
        // check all the conversions were successful
        const newPrice = Math.round(
          parseFloat(currentUnitAndFees.pricePerUnit) * 100
        )
        const newCost = Math.round(
          parseFloat(currentUnitAndFees.costPerUnit) * 100
        )
        const isNewPriceValid = !Number.isNaN(newPrice)
        const isNewCostValid = !Number.isNaN(newCost)

        // if not inform the user and keep the old values
        if (!isNewPriceValid || !isNewCostValid) {
          showErrorToast(
            'There was an error while updating the price/cost and will keep the same values. Please inform your friendly admin.'
          )
        }

        const variables = {
          ...values,
          distanceUnit:
            isNewPriceValid && isNewCostValid
              ? currentUnitAndFees.unit
              : fleet.distanceUnit,
          pricePerDistanceUnit: isNewPriceValid
            ? newPrice
            : fleet.pricePerDistanceUnit,
          defaultCostPerDistanceUnit: isNewCostValid
            ? newCost
            : fleet.defaultCostPerDistanceUnit,
        }

        await updateFleetMutation({ variables })
      },
    })

  const clearPhoneNumber = () => {
    setFieldValue('contactPhone', '+44')
  }

  return (
    <form onSubmit={handleSubmit}>
      <GridContainer>
        <GridCell>
          <FormGroup label="Name">
            <InputGroup
              value={values.name}
              onChange={handleChange}
              name="name"
            />
          </FormGroup>
          <FormGroup label="Lead Time (min)">
            <NumericInput
              value={values.leadTime}
              name="leadTime"
              onValueChange={number => setFieldValue('leadTime', number)}
            />
          </FormGroup>

          <FormGroup label="Wait Time (sec)">
            <NumericInput
              value={values.jobAcceptWaitTime}
              name="jobAcceptWaitTime"
              onValueChange={number =>
                setFieldValue('jobAcceptWaitTime', number)
              }
            />
          </FormGroup>

          <FormGroup label="Drop Off Allocated Time (sec)">
            <NumericInput
              value={values.jobDropOffAllocatedTime}
              name="jobDropOffAllocatedTime"
              onValueChange={number =>
                setFieldValue('jobDropOffAllocatedTime', number)
              }
            />
          </FormGroup>

          <FormGroup label="Distance Unit">
            <DistanceUnitSelect
              onChange={handleDistanceUnitChange}
              value={currentUnitAndFees.unit}
            />
          </FormGroup>

          <FormGroup
            label={`Price / ${
              distanceUnitToAbbreviationMap[currentUnitAndFees.unit]
            }`}
          >
            <InputGroup
              value={currentUnitAndFees.pricePerUnit}
              min={0}
              name="pricePerDistanceUnit"
              onChange={e => {
                setCurrentUnitAndFees(currentState => ({
                  ...currentState,
                  pricePerUnit: e.target.value,
                }))
                setConfirmationVisible(true)
              }}
            />
          </FormGroup>

          <FormGroup
            label={`Default Cost / ${
              distanceUnitToAbbreviationMap[currentUnitAndFees.unit]
            }`}
          >
            <InputGroup
              value={currentUnitAndFees.costPerUnit}
              min={0}
              name="defaultCostPerDistanceUnit"
              onChange={e => {
                setCurrentUnitAndFees(currentState => ({
                  ...currentState,
                  costPerUnit: e.target.value,
                }))
                setConfirmationVisible(true)
              }}
            />
          </FormGroup>

          <Checkbox
            label="Cancel Jobs in the exception queue"
            checked={values.outrightRejectJob}
            onChange={e =>
              setFieldValue('outrightRejectJob', e.currentTarget.checked)
            }
          />
        </GridCell>
        <GridCell>
          <FormGroup label="Full Name">
            <InputGroup
              value={values.contactFullName}
              onChange={handleChange}
              name="contactFullName"
            />
          </FormGroup>
          <FormGroup label="Email">
            <InputGroup
              value={values.contactEmail}
              onChange={handleChange}
              name="contactEmail"
            />
          </FormGroup>
          <FormGroup
            label="Phone"
            intent={values.contactPhone?.length ? Intent.NONE : Intent.WARNING}
            helperText={
              values.contactPhone
                ? null
                : 'Please provide a phone drivers can use to contact the fleet'
            }
          >
            <PhoneInput
              onChange={(number: string) => {
                setFieldValue('contactPhone', `${'+' + number}`)
              }}
              clearInput={clearPhoneNumber}
              value={values.contactPhone ?? ''}
            />
          </FormGroup>
          <FormGroup label="Address">
            <TextArea
              growVertically
              large
              value={values.contactFullAddress}
              onChange={handleChange}
              name="contactFullAddress"
            />
          </FormGroup>
        </GridCell>
      </GridContainer>
      <DialogFooter>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          {confirmationVisible ? (
            <Popover
              content={
                <Card style={{ maxWidth: '300px' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Text>
                      <Icon icon="warning-sign" intent={Intent.DANGER} />
                      &nbsp;Changing the price / cost will update all the
                      defaults for all the drivers that belong to this fleet.
                      Proceed?
                    </Text>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: '10px',
                    }}
                  >
                    <Button
                      minimal
                      onClick={() => {
                        setConfirmationVisible(false)
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      minimal
                      outlined
                      intent={Intent.DANGER}
                      onClick={() => handleSubmit()}
                    >
                      Proceed
                    </Button>
                  </div>
                </Card>
              }
            >
              <Button minimal outlined intent={Intent.PRIMARY}>
                Save
              </Button>
            </Popover>
          ) : (
            <Button
              icon={isSubmitting && <Spinner size={20} />}
              type="submit"
              minimal
              outlined
              intent={Intent.PRIMARY}
            >
              Save
            </Button>
          )}
        </div>
      </DialogFooter>
    </form>
  )
}
