import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const NOTIFY_DRIVER = typedGql('mutation')({
  notifyDriver: [
    {
      input: $('input', 'NotifyDriverInput!'),
    },
    true,
  ],
})
