import { NonIdealState, Spinner } from '@blueprintjs/core'
import React, {
  FunctionComponent,
  useReducer,
  createContext,
  useEffect,
  useContext,
} from 'react'
import { getPayloadFromToken } from '../auth/getPayloadFromToken'
import {
  CurrentUser,
  CurrentUserState,
  defaultState,
  reducerFunction,
  UserActionType,
} from './currentUserState'
import { SessionTokenContext } from './SessionTokenProvider'
import { typedGql } from '../zeus/typedDocumentNode'
import { $ } from '../zeus'
import { useApolloClient, useLazyQuery } from '@apollo/client'

const currentUserKey = process.env.REACT_APP_CURRENT_USER_KEY || 'currentUser'

const USER_BY_ID = typedGql('query')({
  userById: [
    { id: $('id', 'String!') },
    {
      id: true,
      firstName: true,
      lastName: true,
      role: true,
      email: true,
      phoneNumber: true,
      enabled: true,
      userStatus: true,
    },
  ],
})

export const CurrentUserContext = createContext<CurrentUserState>(defaultState)

export const CurrentUserProvider: FunctionComponent = ({ children }) => {
  const { token, deleteStoredTokens } = useContext(SessionTokenContext)
  const storedUser = localStorage.getItem(currentUserKey)
  const { resetStore } = useApolloClient()

  let parsedStoredUser: CurrentUser | undefined = undefined
  try {
    if (storedUser) {
      parsedStoredUser = JSON.parse(storedUser)
    }
  } catch (err) {
    console.warn('Error while parsing a stored user', err)
  }

  const [state, dispatch] = useReducer(reducerFunction, {
    ...defaultState,
    ...(parsedStoredUser && {
      currentUser: parsedStoredUser,
      loading: false,
      loggedIn: true,
    }),
  })

  const [getUserData, { loading }] = useLazyQuery(USER_BY_ID, {
    fetchPolicy: 'network-only',
  })

  const setCurrentUser = async (): Promise<void> => {
    try {
      const tokenPayload = getPayloadFromToken(token!)
      const currentUserId = tokenPayload?.userId

      // user already present and stored
      if (parsedStoredUser && parsedStoredUser.id === currentUserId) {
        dispatch({
          type: UserActionType.LOGIN,
          newState: {
            currentUser: parsedStoredUser,
            loading: false,
          },
        })
        return
      }

      // new successful login
      if (currentUserId) {
        await getUserData({
          variables: {
            id: currentUserId!,
          },
          onCompleted: ({ userById }) => {
            if (userById) {
              localStorage.setItem(currentUserKey, JSON.stringify(userById))
              dispatch({
                type: UserActionType.LOGIN,
                newState: {
                  currentUser: userById,
                  loading: false,
                },
              })
            }
          },
          onError: err => {
            console.error('Error getting data for user by id', err.message)
            dispatch({ type: UserActionType.LOGOUT })
            deleteStoredTokens()
          },
        })
      }
    } catch (e) {
      dispatch({ type: UserActionType.LOGOUT })
    }
  }

  useEffect(() => {
    const callSetCurrentUser = async () => {
      await setCurrentUser()
    }

    if (token && token.length) {
      callSetCurrentUser()
    }
  }, [token])

  // logout on the first render if the token expired
  useEffect(() => {
    const tokenPayload = getPayloadFromToken(token || '')
    const tokenExpiryTimestampInSeconds = tokenPayload?.exp

    if (
      tokenExpiryTimestampInSeconds &&
      tokenExpiryTimestampInSeconds * 1000 < new Date().getTime()
    ) {
      dispatch({ type: UserActionType.LOGOUT })
      deleteStoredTokens()
      resetStore()
    }
  }, [])

  return (
    <CurrentUserContext.Provider value={{ ...state, dispatch }}>
      {loading ? (
        <NonIdealState icon={<Spinner />} title="Logging you in..." />
      ) : (
        children
      )}
    </CurrentUserContext.Provider>
  )
}
