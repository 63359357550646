import { useMutation } from '@apollo/client'
import {
  Button,
  Classes,
  FormGroup,
  H4,
  InputGroup,
  Intent,
} from '@blueprintjs/core'
import { DialogBody, DialogFooter } from '@blueprintjs/core'
import { useFormik } from 'formik'
import React, { useContext } from 'react'
import { ToastType, useToaster } from '../../hooks/useToaster'
import { REQUEST_RESET_PASSWORD } from '../Admin/Users/gql/requestResetPassword'
import { CurrentUserContext } from '../../providers/CurrentUserProvider'
import { UserActionType } from '../../providers/currentUserState'
import { SessionTokenContext } from '../../providers/SessionTokenProvider'

export const RequestResetPasswordForm = ({
  closeContainer,
}: {
  closeContainer?: Function
}) => {
  const openErrorToast = useToaster({ type: ToastType.ERROR })
  const openSuccessToast = useToaster({ type: ToastType.SUCCESS })
  const { dispatch } = useContext(CurrentUserContext)
  const { deleteStoredTokens } = useContext(SessionTokenContext)

  const [requestPasswordReset, { loading }] = useMutation(
    REQUEST_RESET_PASSWORD,
    {
      onError: error => {
        openErrorToast(error.message)
      },
      onCompleted: data => {
        if (data.requestPasswordReset) {
          openSuccessToast(
            'If an account associated with the specified email is found, you will receive shortly a reset code'
          )
          deleteStoredTokens()
          dispatch({ type: UserActionType.RESET_PASSWORD })
          if (closeContainer) {
            closeContainer()
          }
        } else {
          openErrorToast('There was an error updating the password')
        }
      },
    }
  )

  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async values => {
      await requestPasswordReset({
        variables: {
          email: values.email,
        },
      })
    },
  })

  return (
    <DialogBody>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <InputGroup
            large
            minLength={7}
            name="email"
            value={values.email}
            intent={errors.email ? Intent.DANGER : Intent.NONE}
            onChange={handleChange}
            placeholder="The email used when registering"
          />
        </FormGroup>

        <DialogFooter>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              onClick={() => {
                if (closeContainer) {
                  closeContainer()
                } else {
                  dispatch({ type: UserActionType.LOGOUT })
                }
              }}
            >
              Cancel
            </Button>
            <Button intent={Intent.PRIMARY} type="submit" disabled={loading}>
              Reset
            </Button>
          </div>
        </DialogFooter>
      </form>
    </DialogBody>
  )
}
