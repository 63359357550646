import styled from 'styled-components'

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 1fr;
  gap: 0px 10px;
  grid-template-areas: '. .';
`

export const GridCell = styled.div`
  max-width: 240px;
`
