import React, { FC, useEffect, useRef } from 'react'
import { LngLatLike, Map, NavigationControl } from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import 'maplibre-gl-js-amplify/dist/public/amplify-map.css'
import { createMap } from 'maplibre-gl-js-amplify'
import { Amplify } from 'aws-amplify'
import { awsConfig } from '../../aws-exports'

Amplify.configure(awsConfig)

export type InitializeMapArgs = {
  centerCoords?: LngLatLike
  zoom?: number
  showControls?: boolean
  mapSize?: {
    height: string
    width: string
  }
  mapFunctionCallback?: (map: Map) => Map | void
}

/**
 * @param centerCoords: an array of numbers like [longitude, latitude]
 * @param zoom: an integer describing the zoom level. Defaults to 13
 * @param showControls: if true it will render navigation controls on the map
 * @param mapSize: A react style object setting the dimensions of the map. Defaults to {height: '20vh', width: '100%'}.
 * Don't use % unless all the parrents have a set height. Use vh or rem.
 * @param mapFunctionCallback: a function that takes a single argument of type maplibre-gl.Map and is executed on it.
 * @returns a map
 */
export const MapSection: FC<InitializeMapArgs> = ({
  centerCoords = [-1.5664, 53.7962], // [-3.2184, 54.1175] for Barrow
  zoom = 11,
  showControls = true,
  mapSize = {
    height: '20vh',
    width: '100%',
  },
  mapFunctionCallback: mapFunction,
}) => {
  const mapRef = useRef(null)

  useEffect(() => {
    let map: Map
    ;(async () => {
      if (mapRef.current != null) {
        map = await createMap({
          container: mapRef.current,
          center: centerCoords,
          zoom,
        })
        if (showControls) map.addControl(new NavigationControl(), 'top-right')
        if (mapFunction) mapFunction(map)
      }
    })()

    return () => {
      if (map) map.remove()
    }
  }, [])

  return <div ref={mapRef} style={mapSize} id="map" />
}
