import React from 'react'
import { useQuery } from '@apollo/client'
import { Button, MenuItem, Spinner } from '@blueprintjs/core'
import { StringParam, useQueryParam } from 'use-query-params'
import { typedGql } from '../../zeus/typedDocumentNode'
import { SortOrder, $ } from '../../zeus'
import { QUERY_PARAMS } from '../../utils/queryParamsNames'
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select'

export interface Driver {
  value: string
  label: string
}

const renderDrivers: ItemRenderer<Driver> = (
  driver,
  { handleClick, handleFocus, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      onClick={handleClick}
      key={driver.value}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={driver.label}
    />
  )
}

const filterDrivers: ItemPredicate<Driver> = (
  query,
  driver,
  _index,
  exactMatch
) => {
  const normalizedTitle = driver.label.toLowerCase()
  const normalizedQuery = query.toLowerCase()

  if (exactMatch) {
    return normalizedTitle === normalizedQuery
  } else {
    return `${normalizedTitle} ${driver.label}`.includes(normalizedQuery)
  }
}

const LIST_DRIVERS = typedGql('query')({
  drivers: [
    {
      where: {
        fleetDrivers: {
          some: {
            fleetId: {
              equals: $('fleetId', 'String'),
            },
          },
        },
      },
      orderBy: {
        firstName: SortOrder.asc,
      },
    },
    {
      id: true,
      firstName: true,
      lastName: true,
    },
  ],
})

export const FilterByDriver = () => {
  const [driverId, setDriverId] = useQueryParam(
    QUERY_PARAMS.driverId,
    StringParam
  )
  const [fleetId] = useQueryParam<undefined | string>(QUERY_PARAMS.fleetId)

  const { data, loading } = useQuery(LIST_DRIVERS, {
    variables: {
      fleetId: fleetId,
    },
  })

  const handleChange = (item: Driver) => {
    setDriverId(item.value ? item.value : undefined)
  }

  const drivers = data?.drivers?.map(({ id, firstName, lastName }) => ({
    value: id,
    label: firstName ? `${firstName} ${lastName}` : id,
    selected: id === driverId,
  }))

  const selectedDriver = drivers?.find(driver => driver.value === driverId)

  if (loading) {
    return <Spinner />
  }

  if (!drivers || !drivers.length) {
    return null
  }

  return (
    <Select
      onItemSelect={handleChange}
      items={[{ value: '', label: 'Filter by driver...' }, ...drivers]}
      itemPredicate={filterDrivers}
      itemRenderer={renderDrivers}
      noResults={
        <MenuItem
          disabled={true}
          text="No results."
          roleStructure="listoption"
        />
      }
    >
      <Button
        text={selectedDriver?.label ?? 'Filter by Driver'}
        rightIcon="double-caret-vertical"
      />
    </Select>
  )
}
