import { useQuery } from '@apollo/client'
import { HTMLSelect, Spinner } from '@blueprintjs/core'
import React from 'react'
import { ModelTypes, SortOrder } from '../../zeus'
import { LIST_PARTNERS } from '../../pages/Partners/gql/partners'
import { BaseSelectProps } from './types'

export const PartnerSelect = (props: BaseSelectProps) => {
  const { data, loading } = useQuery(LIST_PARTNERS, {
    variables: {
      partnersOrderByNameDirection: SortOrder.asc,
    },
  })

  if (loading) {
    return <Spinner />
  }

  const partners =
    data?.partners.map(
      ({ id, name }: Pick<ModelTypes['Partner'], 'id' | 'name'>) => ({
        value: id,
        label: name,
      })
    ) || []

  return (
    <HTMLSelect
      {...props}
      options={[{ value: '', label: 'Select a partner...' }, ...partners]}
    />
  )
}
