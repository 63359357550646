import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const DELETE_WEBHOOK = typedGql('mutation')({
  deleteWebhook: [
    {
      id: $('id', 'String!'),
    },
    {
      id: true,
    },
  ],
})
