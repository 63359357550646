export const colors = {
  mainBg: 'rgba(232, 237, 242, 0.42)',
  secondaryBg: '#fff',
  borderColor: 'rgba(115, 134, 148, 0.28)',
  globalLinkColor: '#106ba3',
  headerBgColor: '#ffcd00',
  headerTextColor: '#051624',
  headerTextHoverColor: '#051624',
  primaryButtonColor: '#ffcd00',
  primaryButtonText: '#000000',
  primaryButtonShadow: '0 0 0 1px rgba(0, 0, 0, 0.8)',
  primaryNavColor: '#738694',
  primaryNavHoverColor: '#181F26',
  primaryNavActiveColor: '#FF3B4C',
  disabledText: 'rgba(92, 112, 128, 0.5)',

  // ********************************
  // tabBar
  // ********************************
  tabBarBG: '#051624',
  tabBarTabText: '#738694',
  tabBarTabHoverText: '#8B9BA7',
  tabBarTabActiveText: '#ffffff',

  // ********************************
  // Menu
  // ********************************

  menuSelect: '#137CBD',
  menuSelectText: 'white',

  dropdownBg: '#fff',
  highlight: '#ffcd00',

  // ********************************
  // Order
  // ********************************
  orderHighlight: '#5c7080',
  orderTimeColor: '#43bf4d',

  // ********************************
  // Map
  // ********************************
  routeColor: '#222222',
  legRouteColor: '#c23030',
  idealRouteColor: '#0000ff',
  routeWidth: 4,
  alreadyDoneRouteColor: 'gray',
  alreadyDoneRouteWidth: 7,

  // ********************************
  // Filter bar
  // ********************************

  filterBarBackgroundLight: '#eef0f2',
  filterBarBorderLight: '#e0dce4',
  filterBarBackgroundDark: '#383e47',
  filterBarBorderDark: '#000000',

}
