import React from 'react'
import { Button, Popover, Position } from '@blueprintjs/core'
import { DateRangePicker } from '@blueprintjs/datetime'
import { add, clamp, isFuture, sub, endOfDay } from 'date-fns'
import { formatToCustomDayBoundary, YEAR_FORMAT } from './datetime'

type QuarterSelectorProps = {
  startOfQuarterDate: Date
  setStartOfQuarterDate: (date: Date) => void
  minimal?: boolean
  disabled?: boolean
  offsetHours?: number
}

export const QuarterSelector = ({
  startOfQuarterDate,
  setStartOfQuarterDate,
  minimal = false,
  disabled = false,
  offsetHours = 0,
}: QuarterSelectorProps) => {
  return (
    <>
      <Button
        rightIcon="caret-left"
        disabled={disabled}
        minimal={minimal}
        onClick={() => {
          setStartOfQuarterDate(sub(startOfQuarterDate, { months: 3 }))
        }}
      />
      <Popover
        position={Position.BOTTOM_LEFT}
        boundary={document.body}
        content={
          <DateRangePicker
            value={[
              startOfQuarterDate,
              clamp(
                add(endOfDay(startOfQuarterDate), { months: 3, days: -1 }),
                {
                  start: startOfQuarterDate,
                  end: endOfDay(new Date()),
                }
              ),
            ]}
            maxDate={endOfDay(new Date())}
            highlightCurrentDay
            singleMonthOnly
            shortcuts={false}
            onChange={([selectedDate]) => {
              if (selectedDate) {
                setStartOfQuarterDate(selectedDate)
              }
            }}
          />
        }
      >
        <Button
          disabled={disabled}
          minimal={minimal}
          rightIcon="double-caret-vertical"
        >
          {formatToCustomDayBoundary({
            date: startOfQuarterDate,
            formatStr: YEAR_FORMAT,
            offsetHours,
          })}
          {'  '}
          <span className="bp5-text-disabled">to</span>
          {'  '}
          {formatToCustomDayBoundary({
            date: endOfDay(add(startOfQuarterDate, { months: 3, days: -1 })),
            formatStr: YEAR_FORMAT,
            offsetHours,
          })}
        </Button>
      </Popover>
      <Button
        rightIcon="caret-right"
        minimal={minimal}
        onClick={() => {
          setStartOfQuarterDate(add(startOfQuarterDate, { months: 3 }))
        }}
        disabled={disabled || isFuture(add(startOfQuarterDate, { months: 3 }))}
      />
    </>
  )
}
