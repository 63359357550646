import React from 'react'
import { Button, MenuItem } from '@blueprintjs/core'
import { useQueryParam } from 'use-query-params'
import { DriverStatus } from '../../zeus'
import { QUERY_PARAMS } from '../../utils/queryParamsNames'
import { ItemPredicate, ItemRenderer, Select } from '@blueprintjs/select'

export interface Status {
  value: string
  label: string
}

const filterStatus: ItemPredicate<Status> = (
  query,
  status,
  _index,
  exactMatch
) => {
  const normalizedTitle = status.label.toLowerCase()
  const normalizedQuery = query.toLowerCase()

  if (exactMatch) {
    return normalizedTitle === normalizedQuery
  } else {
    return `${normalizedTitle} ${status.label}`.includes(normalizedQuery)
  }
}

const renderStatus: ItemRenderer<Status> = (
  status,
  { handleClick, handleFocus, modifiers }
) => {
  if (!modifiers.matchesPredicate) {
    return null
  }
  return (
    <MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      onClick={handleClick}
      key={status.value}
      onFocus={handleFocus}
      roleStructure="listoption"
      text={status.label}
    />
  )
}

const statusesMap: Record<string, DriverStatus> = {
  Active: DriverStatus.ACTIVE,
  Inactive: DriverStatus.INACTIVE,
  'On break': DriverStatus.ON_BREAK,
  'No longer receiving new jobs': DriverStatus.NO_LONGER_RECEIVING_NEW_JOBS,
}

export const FilterByDriverStatus = () => {
  const [status, setStatus] = useQueryParam<undefined | DriverStatus>(
    QUERY_PARAMS.driverStatus
  )

  const handleChange = (item: Status) => {
    setStatus(item.value ? (item.value as DriverStatus) : undefined)
  }

  const statuses: Status[] = Object.keys(statusesMap).map(key => ({
    value: statusesMap[key],
    label: key,
    selected: status === key,
  }))

  const selectedStatus = statuses.find(item => item.value === status)

  return (
    <Select
      filterable={false}
      onItemSelect={handleChange}
      items={[{ value: '', label: 'Driver Status' }, ...statuses]}
      itemPredicate={filterStatus}
      itemRenderer={renderStatus}
      noResults={
        <MenuItem
          disabled={true}
          text="No results."
          roleStructure="listoption"
        />
      }
    >
      <Button
        text={selectedStatus?.label ?? 'Driver status'}
        rightIcon="double-caret-vertical"
      />
    </Select>
  )
}
