import { uniqueId } from 'lodash'
import { LinePaint } from 'maplibre-gl'
import { RouteType } from './createRoute'
import { colors } from '../../styles/colors'

export const createLegRoute = ({
  routeJSON,
  legPosition,
  paint = {
    'line-color': colors.legRouteColor,
    'line-width': colors.routeWidth,
  },
}: {
  routeJSON: string
  legPosition: number
  paint?: LinePaint
}) => {
  const randomSource = uniqueId('source_')
  const randomLayer = uniqueId('legLayer_')
  const route: RouteType = JSON.parse(routeJSON)

  const coordinates = route.Legs[legPosition].Geometry.LineString

  const source = {
    type: 'geojson',
    data: {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates,
      },
    },
  }

  const layer = {
    id: randomLayer,
    type: 'line',
    source: randomSource,
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint,
  }

  return {
    id: randomSource,
    source: source as maplibregl.AnySourceData,
    layer: layer as maplibregl.AnyLayer,
  }
}
