import { Duration } from 'date-fns'

export const shortenedDuration = (duration: Duration | undefined) => {
  if (!duration) {
    return null
  }

  const formattedDuration =
    (duration.days ? `${duration.days}d ` : '') +
    (duration.hours ? `${duration.hours}h ` : '') +
    (duration.minutes ? `${duration.minutes}m` : '')

  return formattedDuration
}
