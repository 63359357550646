import { typedGql } from '../../../zeus/typedDocumentNode'
import { $ } from '../../../zeus'

export const POSTPONE_JOB = typedGql('mutation')({
  updateJobPickupTime: [
    {
      input: $('input', 'UpdateJobPickupTimeInput!'),
    },
    {
      id: true,
      estimatedPickupTime: true,
    },
  ],
})
