import React, { useContext } from 'react'
import { Drawer, DrawerSize } from '@blueprintjs/core'
import { useQueryParam, StringParam } from 'use-query-params'
import { useQuery } from '@apollo/client'
import { DRIVER_BY_ID } from './gql/driverById'
import {
  EmptyListState,
  ErrorState,
  LoadingState,
} from '../../components/NonIdealState/NonIdealState'
import { DriverDetails } from './DriverDetails'
import { QUERY_PARAMS } from '../../utils/queryParamsNames'
import { PageLayoutContext } from '../../providers/PageLayoutProvider'

export const DriverDrawer = () => {
  const { darkMode } = useContext(PageLayoutContext)
  const [driverId, setDriverId] = useQueryParam(
    QUERY_PARAMS.driverId,
    StringParam
  )
  const { data, loading, error } = useQuery(DRIVER_BY_ID, {
    variables: { driverId: driverId! },
    skip: !driverId,
    fetchPolicy: 'network-only',
  })

  return (
    <Drawer
      isOpen={!!driverId}
      title="Driver Details"
      onClose={() => setDriverId(null)}
      size={DrawerSize.SMALL}
      className={darkMode ? 'bp5-dark' : ''}
    >
      {loading && <LoadingState entityName="driver" />}
      {error && <ErrorState entityName="driver" />}
      {data && !data.driver && <EmptyListState entityName="driver" />}
      {data && <DriverDetails driver={data.driver} />}
    </Drawer>
  )
}
