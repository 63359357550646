import React, { useEffect } from 'react'
import { FilterBar } from '../../../components/Filters/FilterBar'
import { SearchBy } from '../../../components/Filters/SearchBy'
import { FilterByFleet } from '../../../components/Filters/FilterByFleet'
import { HorizontalContainer, VerticalContainer } from '../styles'
import { FilterByDriverStatus } from '../../../components/Filters/FilterByDriverStatus'
import { useQueryParam, StringParam } from 'use-query-params'
import { useQuery } from '@apollo/client'
import { LIST_DRIVERS, LIST_DRIVERS_SUBSCRIPTION } from '../gql/listDrivers'
import { Spinner } from '@blueprintjs/core'
import { DriversMapSubsection } from './DriversMapSubsection'
import { DriversDetails } from './DriversDetails'
import { DriverStatus } from '../../../zeus'
import { ErrorState } from '../../../components/NonIdealState/NonIdealState'
import { RightPanel } from '../styles'
import { QUERY_PARAMS } from '../../../utils/queryParamsNames'

export const DriversDashboard = () => {
  // const [driverPhone] = useQueryParam(paramName, StringParam) // fails atm due to rerendering
  const [fleetId] = useQueryParam(QUERY_PARAMS.fleetId, StringParam)
  const [status] = useQueryParam<DriverStatus>(QUERY_PARAMS.driverStatus)

  const { loading, error, data, subscribeToMore } = useQuery(LIST_DRIVERS, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        status,
        ...(fleetId && {
          fleetDrivers: {
            some: {
              fleetId: {
                equals: fleetId,
              },
            },
          },
        }),
        completedSignUp: { equals: true },
        // ...(driverPhone && {
        //   phoneNumber: { startsWith: driverPhone },
        // }),
      },
    },
  })

  useEffect(
    () =>
      subscribeToMore({
        document: LIST_DRIVERS_SUBSCRIPTION,
        variables: {
          where: {
            status,
            ...(fleetId && {
              fleetDrivers: {
                some: {
                  fleetId: {
                    equals: fleetId,
                  },
                },
              },
            }),
            completedSignUp: { equals: true },
            // ...(driverPhone && {
            //   phoneNumber: { startsWith: driverPhone },
            // }),
          },
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return prev
          }

          const { drivers } = subscriptionData.data

          if (!drivers) {
            return prev
          }

          return {
            ...prev,
            ...drivers,
          }
        },
      }),
    [subscribeToMore]
  )

  if (loading) return <Spinner />
  if (error) return <ErrorState entityName="drivers" />

  return (
    <VerticalContainer>
      <HorizontalContainer>
        <DriversMapSubsection drivers={data?.drivers ?? []} />
        <RightPanel>
          <FilterBar justifyEnd>
            {/* <SearchBy
              paramName={paramName}
              placeholder="Search driver by phone"
            /> */}
            <FilterByDriverStatus />
            <FilterByFleet />
          </FilterBar>
          <DriversDetails drivers={data?.drivers} />
        </RightPanel>
      </HorizontalContainer>
    </VerticalContainer>
  )
}
