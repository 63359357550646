import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const CREATE_FLEET = typedGql('mutation')({
  createFleet: [
    {
      input: {
        name: $('name', 'String!'),
        leadTime: $('leadTime', 'Int!'),
        geo: 'aaaa', // TODO wtf, why is this required on server
        partner: {
          connect: {
            id: $('partnerId', 'String!'),
          },
        },
      },
    },
    {
      id: true,
      name: true,
      leadTime: true,
      partner: {
        name: true,
      },
    },
  ],
})
