import { useQuery } from '@apollo/client'
import React from 'react'
import { SortOrder } from '../../zeus'
import {
  EmptyListState,
  ErrorState,
  LoadingState,
} from '../../components/NonIdealState/NonIdealState'
import { LIST_PARTNERS } from './gql/partners'

export const PartnerContent = () => {
  const { data, loading, error } = useQuery(LIST_PARTNERS, {
    variables: {
      partnersOrderByNameDirection: SortOrder.asc,
    },
  })

  if (loading) {
    return <LoadingState entityName="partners" />
  }

  if (error) {
    return <ErrorState entityName="partners" />
  }

  const partners = data?.partners || []

  if (!partners.length) {
    return <EmptyListState entityName="partners" />
  }

  return (
    <>
      <thead>
        <tr>
          <th>Name</th>
          <th>Platform</th>
        </tr>
      </thead>
      <tbody>
        {partners.map(({ name, id, platform }) => (
          <tr key={id}>
            <td>{name}</td>
            <td>{platform?.name ?? '---'}</td>
          </tr>
        ))}
      </tbody>
    </>
  )
}
