import { HTMLSelect } from '@blueprintjs/core'
import React from 'react'

import { BaseSelectProps } from './types'

export const TitleSelect = (props: BaseSelectProps) => {
  const titles = ['Mr', 'Mrs', 'Miss', 'Other']
  const titleEntries = titles.map(title => ({
    value: title,
    label: title,
  }))
  return (
    <HTMLSelect
      {...props}
      options={[{ value: '', label: 'Choose...' }, ...titleEntries]}
    />
  )
}
