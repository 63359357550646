import styled from 'styled-components'
import { theme } from '../../styles/theme';

type FilterBarContainerProps = {
  flexDirection?: 'row' | 'column'
  marginBottom?: string
  justifyEnd?: boolean
  darkMode? : boolean
}

export const FilterBarContainer = styled.div<FilterBarContainerProps>`
  flex-direction: ${props => props.flexDirection ?? 'row'};
  margin-bottom: ${props => props.marginBottom ?? 'none'};
  justify-content: ${props => (props.justifyEnd ? 'flex-end' : 'flex-start')};
  background-color: ${({ darkMode, theme }) => (darkMode ? theme.colors.filterBarBackgroundDark : theme.colors.filterBarBackgroundLight)};
  display: flex;
  height: 50px;
  align-items: center;
  padding: 10px;
  flex-basis: 100%;
  border-bottom: 1px solid ${({ darkMode, theme }) => (darkMode ? theme.colors.filterBarBorderDark : theme.colors.filterBarBorderLight)};
  flex: 1;
  
  .bp5-html-select select {
    border-radius: 0px;
    flex-shrink: 1;
  }

  .bp5-input {
    border-radius: 0px;
  }
`

export const FilterBarPager = styled.div`
display: flex;
align-items: center;
margin-left: auto; 
`