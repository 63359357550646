import { Marker } from 'maplibre-gl'
import { createTaskPopup, PartialDropOffTask } from './popupUtils'
import { Location } from '../../utils/location'
import { createElementForMarker } from './createElementForMarker'
import { DROPOFF_TASK_INDEX_TO_PIN } from './createTasksToBeDrawn'

export type TasksMarkersForStackedJobsType = {
  tasksMarkers: Marker[] | undefined
}

export const createStackedTasksToBeDrawn = ({
  dropOffTasks,
  preserveMarkerOriginalSize = false,
}: {
  dropOffTasks: PartialDropOffTask[]
  preserveMarkerOriginalSize?: boolean
}): TasksMarkersForStackedJobsType => {
  const response: TasksMarkersForStackedJobsType = {
    tasksMarkers: undefined,
  }

  // DropOff Tasks
  dropOffTasks.forEach(dropOffTask => {
    if (!dropOffTask || !dropOffTask.location) return

    const dropOffTaskLocation = dropOffTask.location
    const { longitude, latitude } = dropOffTaskLocation as unknown as Location
    const el = createElementForMarker({
      id: dropOffTask.id,
      src: DROPOFF_TASK_INDEX_TO_PIN[dropOffTask.orderInStack],
      style: {
        width: preserveMarkerOriginalSize ? '26px' : '16px',
        height: preserveMarkerOriginalSize ? '33px' : '23px',
      },
    })

    const popup = createTaskPopup(dropOffTask)
    const marker = new Marker(el)
      .setLngLat([longitude, latitude])
      .setPopup(popup)

    response.tasksMarkers
      ? response.tasksMarkers.push(marker)
      : (response.tasksMarkers = [marker])
  })

  return response
}
