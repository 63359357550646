import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './App'

// css
import './index.css'
// TODD move to css ??? https://github.com/vitejs/vite/issues/132
import 'normalize.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import '@blueprintjs/icons/lib/css/blueprint-icons.css'

ReactDOM.render(
  // <React.StrictMode>
  <App />,
  // </React.StrictMode>,
  document.getElementById('root')
)

if ((module as any).hot) {
  ;(module as any).hot.accept()
}
