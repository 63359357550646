import styled from 'styled-components'
import { Navbar as BPNavbar } from '@blueprintjs/core'

export const NavContainer = styled.nav`
  display: flex;
  position: fixed;
  left: 0px;
  top: 96px;
  height: calc(100% - 96px);
  z-index: 1;
`

export const Navbar = styled(BPNavbar)<{ darkMode?: boolean }>`
  width: 48px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.secondaryBg};
  a,
  a:active,
  a:visited {
    color: #738694;
  }
  a:hover {
    color: #181f26;
  }
  a.active {
    color: ${({ darkMode }) => (darkMode ? '#ffcd00' : '#051624')} 
  }
`

export const NavMainSection = styled.div`
  display: flex;
  // width: 100%; don't even
  flex-direction: column;
`

export const NavBottomSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const NavIconNavContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

export const NavIconIconContainer = styled.div`
  padding: 12px 0px;
  cursor: pointer;
  width: 46px;
  display: flex;
  justify-content: center;
`
