import { typedGql } from '../../../zeus/typedDocumentNode'
import { $ } from '../../../zeus'

export const SET_DRIVER_STATUS_FROM_OPTION = typedGql('mutation')({
  setDriverStatusFromOption: [
    {
      driverId: $('driverId', 'String!'),
      option: $('option', 'JSON!'),
    },
    true,
  ],
})
