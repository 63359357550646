import { useMutation } from '@apollo/client'
import {
  Button,
  Classes,
  Icon,
  Intent,
  Popover,
  Radio,
  RadioGroup,
} from '@blueprintjs/core'
import { Dialog, DialogBody, DialogFooter } from '@blueprintjs/core'
import { addMinutes } from 'date-fns'
import React, { useState } from 'react'
import { Explanation } from '../../../atomics/Explanation'
import { ToastType, useToaster } from '../../../hooks/useToaster'
import { COLLISIONS_OR_STACKABILITY } from '../gql/collisions'
import { DRIVERS_AND_THEIR_JOBS } from '../gql/listDriversWithJobs'
import { POSTPONE_JOB } from '../gql/postponeJob'

type PostponeJobProps = {
  id: string
  estimatedPickupTime: string | Date
  isExceptionJob?: boolean
  isLastInQueue?: boolean
}

export const PostponeJob = ({
  id,
  estimatedPickupTime,
  isExceptionJob = false,
  isLastInQueue = false,
}: PostponeJobProps) => {
  const openSuccessToast = useToaster({ type: ToastType.SUCCESS })
  const openErrorToast = useToaster({ type: ToastType.ERROR })
  const [postponeDuration, setPostponeDuration] = useState<number | undefined>(
    undefined
  )
  const canPostpone = isExceptionJob || isLastInQueue

  const [postponeJob, { loading }] = useMutation(POSTPONE_JOB, {
    onError: err => {
      openErrorToast(err.message || 'There was an error postponing this job')
    },
    onCompleted: ({ updateJobPickupTime }) => {
      if (updateJobPickupTime?.estimatedPickupTime) {
        openSuccessToast(
          `New estimated pickup time is ${new Date(
            updateJobPickupTime.estimatedPickupTime as string
          ).toLocaleTimeString()}`
        )
      }
    },
    refetchQueries: [COLLISIONS_OR_STACKABILITY, DRIVERS_AND_THEIR_JOBS],
  })

  return (
    <Popover
      children={
        <Icon icon="double-caret-horizontal" style={{ cursor: 'pointer' }} />
      }
      content={
        <div style={{ margin: 0, maxWidth: '300px' }}>
          <DialogBody>
            <p>
              Postpone the estimated pickup time for this job. Note that this
              can not be undone.
            </p>
            <RadioGroup
              onChange={e => {
                setPostponeDuration(parseInt(e.currentTarget.value))
              }}
              selectedValue={postponeDuration}
            >
              <Radio label="+5 min" value={5} />
              <Radio label="+10 min" value={10} />
              <Radio label="+15 min" value={15} />
              <Radio label="+30 min" value={30} />
            </RadioGroup>

            <DialogFooter>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                  disabled={!canPostpone || loading || !postponeDuration}
                  minimal
                  outlined
                  intent={canPostpone ? Intent.WARNING : Intent.NONE}
                  onClick={async () => {
                    await postponeJob({
                      variables: {
                        input: {
                          id,
                          estimatedPickupTime: addMinutes(
                            new Date(estimatedPickupTime || new Date()),
                            postponeDuration || 0
                          ),
                        },
                      },
                    })
                  }}
                >
                  Postpone
                  {!canPostpone && (
                    <>
                      &nbsp; &nbsp;
                      <Explanation
                        icon="issue"
                        sup
                        content="Can only postpone a job if it's in the exception queue or is the driver's last"
                      />
                    </>
                  )}
                </Button>
              </div>
            </DialogFooter>
          </DialogBody>
        </div>
      }
    />
  )
}
