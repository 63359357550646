import { Card } from '@blueprintjs/core'
import styled from 'styled-components'

export const DetailsContainer = styled(Card)`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`

export const ButtonsFooterContainer = styled.div`
  margin-top: 1rem;
`

export const OrderRefAndStatusAndDriverContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`

export const StatusAndDriverContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`
export const AllocatedTimeContainer = styled.span`
  color: grey;
  font-size: smaller;
`
