import React from 'react'
import { ToastType, useToaster } from '../../../hooks/useToaster'
import { ModelTypes } from '../../../zeus'
import { UnstyledParagraph, Shadow } from './styles'
import { SCALING_FACTOR } from './Timeline'
import { Spinner } from '@blueprintjs/core'

type ShadowJobProp = Pick<
  ModelTypes['Job'],
  'id' | 'estimatedPickupTime' | 'estimatedDurationMinutes'
> & {
  startingTime?: Date
  onlyChild: boolean
  collisions?: ModelTypes['CollisionsOrStackability']
}

export const ShadowJob = ({
  estimatedDurationMinutes,
  estimatedPickupTime,
  startingTime,
  onlyChild,
  collisions,
}: ShadowJobProp) => {
  const openErrorToast = useToaster({ type: ToastType.ERROR })
  if (!collisions) return <Spinner />

  const { jobsOverlap, isPlausible } = collisions

  if (jobsOverlap) {
    openErrorToast(
      'The job overlaps with another one. Maybe try and postpone the latter one?'
    )
  }

  if (!isPlausible) {
    openErrorToast(
      "The driver can't make it in time from this job's drop off location to the next one. Maybe you can postpone the latter one?"
    )
  }

  // size based on duration
  const width = estimatedDurationMinutes * SCALING_FACTOR

  let left: number | undefined = undefined
  let pickupInMillisec = 0
  if (startingTime) {
    // conditionally position based on start time
    // a negative diff -> pickup was in the past
    pickupInMillisec = new Date(estimatedPickupTime || 0).getTime()
    const diffInMillisec = pickupInMillisec - startingTime.getTime()
    left = Math.floor(diffInMillisec / 1000 / 60) * SCALING_FACTOR
  }

  return (
    <Shadow
      width={width}
      cannotAssign={jobsOverlap || !isPlausible}
      left={left}
      onlyChild={onlyChild}
    >
      <UnstyledParagraph>
        {Math.round(estimatedDurationMinutes)} min
      </UnstyledParagraph>
    </Shadow>
  )
}
