import { Dialog } from '@blueprintjs/core'
import React, {
  createContext,
  FunctionComponent,
  useContext,
  useState,
} from 'react'
import { DialogWrapper } from '../dialogs/styles'
import { PageLayoutContext } from './PageLayoutProvider'

export type DialogConfig = {
  content: JSX.Element | null
  title: string | null
}

type DialogContext = {
  isOpen: boolean
  closeDialog: () => void
  openDialog: (dialog: DialogConfig) => void
}

export const DialogContext = createContext<DialogContext>({
  isOpen: false,
  closeDialog: () => void 0,
  openDialog: (_: DialogConfig) => void 0,
})

export const DialogProvider: FunctionComponent = ({ children }) => {
  const { darkMode } = useContext(PageLayoutContext)
  const [isOpen, setIsOpen] = useState(false)
  const [dialog, setDialog] = useState<DialogConfig | null>(null)

  return (
    <DialogContext.Provider
      value={{
        isOpen,
        closeDialog: () => setIsOpen(false),
        openDialog: ({ title, content }: DialogConfig) => {
          setDialog({ content, title })
          setIsOpen(true)
        },
      }}
    >
      {dialog && (
        <Dialog
          title={dialog?.title}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <DialogWrapper
            className={darkMode ? 'bp5-dark' : ''}
            darkMode={darkMode}
          >
            {dialog?.content}
          </DialogWrapper>
        </Dialog>
      )}

      {children}
    </DialogContext.Provider>
  )
}
