import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const TOGGLE_ON_HOLD_SWITCH = typedGql('mutation')({
  updateJob: [
    {
      input: {
        id: $('id', 'String!'),
        onHold: $('onHold', 'Boolean!'),
      },
    },
    {
      id: true,
      onHold: true,
    },
  ],
})
