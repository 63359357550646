import React, { useContext, useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  Button,
  Classes,
  Drawer,
  DrawerSize,
  FormGroup,
  InputGroup,
  Intent,
  Popover,
} from '@blueprintjs/core'
import { DialogBody, DialogFooter } from '@blueprintjs/core'
import { useFormik } from 'formik'
import { RoleSelect } from '../../../components/Select/RoleSelect'
import { UserStatusSelect } from '../../../components/Select/UserStatusSelect'
import { ToastType, useToaster } from '../../../hooks/useToaster'
import { UPDATE_USER } from './gql/updateUser'
import { LIST_USERS } from './gql/users'
import { ModelTypes, UserRole, UserStatus } from '../../../zeus'
import { StringParam, useQueryParam } from 'use-query-params'
import { PasswordUpdate } from '../../../components/FormElements/PasswordUpdate'
import { CurrentUserContext } from '../../../providers/CurrentUserProvider'
import { DeleteUserDialog } from '../../../dialogs/DeleteUserDialog'
import { QUERY_PARAMS } from '../../../utils/queryParamsNames'
import { PhoneInput } from '../../../components/Phone'
import { NodesMultiSelect } from '../../../components/Select/NodesMultiSelect'
import capitalize from 'lodash/capitalize'

type IncomingUser = Pick<
  ModelTypes['User'],
  | 'id'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'phoneNumber'
  | 'role'
  | 'userStatus'
> & {
  partners: Pick<ModelTypes['Partner'], 'id' | 'name'>[]
} & {
  fleets: Pick<ModelTypes['Fleet'], 'id' | 'name'>[]
}

export const EditUserForm = ({ user }: { user: IncomingUser }) => {
  const { currentUser } = useContext(CurrentUserContext)
  const openSuccessToast = useToaster({ type: ToastType.SUCCESS })
  const openErrorToast = useToaster({ type: ToastType.ERROR })
  const [userId, setUserId] = useQueryParam(QUERY_PARAMS.userId, StringParam)
  const [roleState, setRoleState] = useState<UserRole>(user.role as UserRole)
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false)
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)

  // permissions
  const partnerNodes = user.partners.map(({ id, name }) => ({ id, name })) || []
  const fleetNodes = user.fleets.map(({ id, name }) => ({ id, name })) || []
  const [nodes, setNodes] = useState<ModelTypes['MinimalNode'][] | null>(null)
  // end permissions

  useEffect(() => {
    // when the role changes we need to update the nodes
    if (roleState === UserRole.PARTNER_USER) {
      setNodes(partnerNodes)
    } else if (roleState === UserRole.FLEET_USER) {
      setNodes(fleetNodes)
    }
  }, [roleState, setNodes])

  const [updateUser, { loading }] = useMutation(UPDATE_USER, {
    refetchQueries: [LIST_USERS],
    onCompleted: data => {
      if (data?.updateUser) {
        openSuccessToast('User successfully updated')
        setUserId(null)
      }
    },
    onError: error => {
      openErrorToast(error.message || 'Error updating user')
    },
  })
  const { id, email, phoneNumber, firstName, lastName, userStatus } = user

  const { values, handleChange, handleSubmit, errors, setFieldValue } =
    useFormik({
      initialValues: {
        email,
        phoneNumber,
        firstName,
        lastName,
        role: roleState as UserRole,
        userStatus: userStatus as UserStatus,
      },
      onSubmit: async values => {
        await updateUser({
          variables: {
            input: {
              id,
              ...values,
              ...(nodes && { nodes: nodes.map(({ id }) => id) }),
            },
          },
        })
      },
    })

  const clearPhoneNumber = () => {
    setFieldValue('phoneNumber', '+44')
  }

  return (
    <DialogBody>
      <form onSubmit={handleSubmit}>
        <FormGroup label="Email">
          <InputGroup
            name="email"
            onChange={handleChange}
            intent={errors.email ? Intent.DANGER : Intent.NONE}
            value={values.email}
          />
        </FormGroup>
        <FormGroup label="Phone">
          <PhoneInput
            onChange={(number: string) => {
              setFieldValue('phoneNumber', `${'+' + number}`)
            }}
            clearInput={clearPhoneNumber}
            value={values.phoneNumber}
          />
        </FormGroup>

        <FormGroup label="First Name">
          <InputGroup
            name="firstName"
            onChange={handleChange}
            intent={errors.firstName ? Intent.DANGER : Intent.NONE}
            value={values.firstName}
          />
        </FormGroup>
        <FormGroup label="Last Name">
          <InputGroup
            name="lastName"
            onChange={handleChange}
            intent={errors.lastName ? Intent.DANGER : Intent.NONE}
            value={values.lastName}
          />
        </FormGroup>

        <FormGroup label="Role">
          <RoleSelect
            name="role"
            onChange={e => {
              handleChange(e)
              setRoleState(e.target.value as UserRole)
            }}
            value={values.role}
            fill
          />
        </FormGroup>

        {values.role !== UserRole.ADMIN && (
          <FormGroup label={capitalize(roleState.split('_')[0] + 's')}>
            <NodesMultiSelect
              desiredRole={values.role}
              fill
              selectedItems={nodes || []}
              onItemSelect={node => {
                setNodes(nodes => {
                  if (!nodes) return [node]
                  if (nodes.find(n => n.id === node.id)) return nodes
                  return [...nodes, node]
                })
              }}
              tagInputProps={{
                placeholder: 'Select at least one node',
                onRemove: node => {
                  setNodes(nodes => {
                    if (!nodes) return []
                    return nodes.filter(n => n.name !== node)
                  })
                },
              }}
            />
          </FormGroup>
        )}

        <FormGroup label="Status">
          <UserStatusSelect
            name="userStatus"
            onChange={handleChange}
            value={values.userStatus}
            fill
          />
        </FormGroup>

        <DialogFooter>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            {currentUser?.id && currentUser.id === userId && (
              <Button
                intent={Intent.DANGER}
                minimal
                outlined
                icon="key"
                onClick={() => {
                  setIsDrawerOpen(prevState => !prevState)
                }}
              />
            )}
            {currentUser?.role === UserRole.ADMIN && (
              <Popover
                interactionKind="click"
                isOpen={isDeleteDialogOpen}
                canEscapeKeyClose
                onInteraction={state => setIsDeleteDialogOpen(state)}
                content={
                  <DeleteUserDialog
                    closeContainer={() => setIsDeleteDialogOpen(false)}
                  />
                }
              >
                <Button
                  minimal
                  intent={Intent.DANGER}
                  icon="trash"
                  onClick={() => setIsDeleteDialogOpen(true)}
                />
              </Popover>
            )}
            <Button
              onClick={() => {
                setUserId(null)
              }}
            >
              Cancel
            </Button>
            <Button intent={Intent.PRIMARY} type="submit" disabled={loading}>
              Save
            </Button>
          </div>
        </DialogFooter>
        <Drawer
          isOpen={isDrawerOpen}
          title="Authentication"
          size={DrawerSize.SMALL}
          onClose={() => setIsDrawerOpen(false)}
        >
          <PasswordUpdate closeContainer={() => setIsDrawerOpen(false)} />
        </Drawer>
      </form>
    </DialogBody>
  )
}
