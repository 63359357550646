import { typedGql } from '../../../../zeus/typedDocumentNode'
import { $ } from '../../../../zeus'

export const RESET_PASSWORD = typedGql('mutation')({
  resetPassword: [
    {
      input: $('input', 'UserResetPasswordInput!'),
    },
    true,
  ],
})
