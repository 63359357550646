import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const CREATE_JOB_AND_TASKS = typedGql('mutation')({
  createJob: [
    {
      input: $('input', 'JobCreateDirectInput!'),
    },
    {
      id: true,
      estimatedPickupTime: true,
    },
  ],
})
