import { $, SortOrder } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const JOBS_BY_DRIVER_ID = typedGql('query')({
  jobs: [
    {
      where: $('where', 'JobWhereInput!'),
      orderBy: {
        estimatedPickupTime: SortOrder.asc,
      },
    },
    {
      id: true,
      jobReference: true,
      stackId: true,
      status: true,
      onHold: true,
      canceledBy: true,
      orderReference: true,
      orderContents: true,
      driverId: true,
      estimatedDistanceMiles: true,
      estimatedDurationMinutes: true,
      route: true,
      pickupTasks: {
        __typename: true,
        id: true,
        location: true,
        locationName: true,
        pickupContact: true,
        pickupNotes: true,
      },
      dropOffTasks: {
        __typename: true,
        id: true,
        location: true,
        locationName: true,
        dropOffContact: true,
        dropOffNotes: true,
        orderInStack: true,
        allocatedTime: true,
      },
      createdAt: true,
      updatedAt: true,
    },
  ],
})
