import { Position, Tooltip } from '@blueprintjs/core'
import React, { SyntheticEvent, useContext } from 'react'
import { ReactSVG } from 'react-svg'

import { NavIconIconContainer, NavIconNavContainer } from './Nav.styles'
import { UserRole } from '../../zeus'
import { CurrentUserContext } from '../../providers/CurrentUserProvider'

type IINavIconProps = {
  imagePath: string
  clickAction?: (e?: SyntheticEvent) => void
  tooltipContent: string
  restrictToRoles?: UserRole[]
}

export const NavIcon = ({
  imagePath,
  clickAction,
  tooltipContent,
  restrictToRoles,
}: IINavIconProps) => {
  const { currentUser } = useContext(CurrentUserContext)
  if (
    restrictToRoles &&
    !restrictToRoles.includes(currentUser?.role as UserRole)
  ) {
    return null
  }

  return (
    <Tooltip content={tooltipContent} position={Position.RIGHT}>
      <NavIconNavContainer>
        <NavIconIconContainer onClick={clickAction}>
          <ReactSVG src={imagePath} />
        </NavIconIconContainer>
      </NavIconNavContainer>
    </Tooltip>
  )
}
