import { typedGql } from '../../../../zeus/typedDocumentNode'
import { ResultOf } from '@graphql-typed-document-node/core'
import { $, SortOrder } from '../../../../zeus'

export const LIST_USERS = typedGql('query')({
  users: [
    {
      where: $('where', 'UserWhereInput'),
      orderBy: {
        createdAt: SortOrder.asc,
      },
    },
    {
      id: true,
      createdAt: true,
      email: true,
      enabled: true,
      firstName: true,
      lastName: true,
      phoneNumber: true,
      role: true,
      userStatus: true,
    },
  ],
})

export type LIST_USERS_RESPONSE = ResultOf<typeof LIST_USERS>
