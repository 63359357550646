import React from 'react'
import { HTMLSelect } from '@blueprintjs/core'
import { BaseSelectProps } from './types'
import capitalize from 'lodash/capitalize'
import { useQuery } from '@apollo/client'
import { AVAILABLE_ROLES_BY_USER } from '../../pages/Admin/Users/gql/availableRolesByUser'
import { ToastType, useToaster } from '../../hooks/useToaster'

export const RoleSelect = (props: BaseSelectProps) => {
  const openErrorToast = useToaster({ type: ToastType.ERROR })
  const { data, loading, error } = useQuery(AVAILABLE_ROLES_BY_USER)

  if (error) {
    openErrorToast(error.message || 'Failed to fetch available roles')
    return null
  }
  if (!data?.availableRolesByUser || loading) return null

  const roleEntries = data.availableRolesByUser.map(role => ({
    value: role,
    label: capitalize(role.replaceAll('_', ' ')),
  }))

  return (
    <HTMLSelect
      {...props}
      options={[{ value: '', label: 'Select a role' }, ...roleEntries]}
    />
  )
}
