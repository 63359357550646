import { useMutation } from '@apollo/client'
import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
  Spinner,
} from '@blueprintjs/core'
import { DialogBody, DialogFooter } from '@blueprintjs/core'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { RoleSelect } from '../components/Select/RoleSelect'
import { ToastType, useToaster } from '../hooks/useToaster'
import { INVITE_USER } from '../pages/Admin/Users/gql/inviteUser'
import { LIST_USERS } from '../pages/Admin/Users/gql/users'
import { ModelTypes, UserRole } from '../zeus'
import { IBaseDialogProps } from './types'
import { NodesMultiSelect } from '../components/Select/NodesMultiSelect'
import { capitalize } from 'lodash'

export const InviteUserDialog = ({ closeDialog }: IBaseDialogProps) => {
  const openSuccessToast = useToaster({ type: ToastType.SUCCESS })
  const openErrorToast = useToaster({ type: ToastType.ERROR })

  const [nodes, setNodes] = useState<ModelTypes['MinimalNode'][] | null>(null)

  const [inviteUser, { loading }] = useMutation(INVITE_USER, {
    refetchQueries: [LIST_USERS],
    onError: err => {
      openErrorToast(err.message || 'Failed to create user')
    },
    onCompleted: data => {
      if (data.inviteUser) {
        openSuccessToast(`Created user with id ${data.inviteUser}`)
        closeDialog()
      } else {
        openErrorToast('Failed to create user')
      }
    },
  })

  const { values, handleChange, handleSubmit, errors } = useFormik({
    initialValues: {
      email: '',
      role: UserRole.FLEET_USER,
      nodes: [],
    },
    onSubmit: async values => {
      await inviteUser({
        variables: {
          input: {
            ...values,
            ...(nodes &&
              nodes.length > 0 && {
                nodes: nodes.map(node => node.id),
              }),
          },
        },
      })
    },
  })

  return (
    <>
      <DialogBody>
        <form onSubmit={handleSubmit}>
          <FormGroup label="Email">
            <InputGroup
              name="email"
              onChange={handleChange}
              intent={errors.email ? Intent.DANGER : Intent.NONE}
              value={values.email}
              data-testid="email-input"
            />
          </FormGroup>
          <FormGroup label="Role">
            <RoleSelect
              name="role"
              onChange={handleChange}
              value={values.role}
              fill
            />
          </FormGroup>

          {values.role && values.role !== UserRole.ADMIN && (
            <FormGroup
              label={capitalize(values.role.split('_')[0]?.concat('s'))}
            >
              <NodesMultiSelect
                desiredRole={values.role}
                fill
                selectedItems={nodes || []}
                onItemSelect={node => {
                  setNodes(nodes => {
                    if (!nodes) return [node]
                    if (nodes.find(n => n.id === node.id)) return nodes
                    return [...nodes, node]
                  })
                }}
                tagInputProps={{
                  placeholder: 'Select at least one',
                  onRemove: node => {
                    setNodes(nodes => {
                      if (!nodes) return []
                      return nodes.filter(n => n.name !== node)
                    })
                  },
                }}
              />
            </FormGroup>
          )}

          <DialogFooter>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button onClick={closeDialog}>Cancel</Button>
              <Button
                icon={loading && <Spinner size={20} />}
                intent={Intent.PRIMARY}
                type="submit"
              >
                Save
              </Button>
            </div>
          </DialogFooter>
        </form>
      </DialogBody>
    </>
  )
}
