import {
  Button,
  Classes,
  FormGroup,
  InputGroup,
  Intent,
} from '@blueprintjs/core'
import { useFormik } from 'formik'
import React, { useContext, useEffect } from 'react'
import { ToastType, useToaster } from '../../hooks/useToaster'
import { CurrentUserContext } from '../../providers/CurrentUserProvider'
import { UserActionType } from '../../providers/currentUserState'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { COMPLETE_USER_PROFILE } from '../Admin/Users/gql/completeRegistration'
import { DialogBody, DialogFooter } from '@blueprintjs/core'
import { PasswordField } from '../../components/FormElements/PasswordField'
import { SessionTokenContext } from '../../providers/SessionTokenProvider'
import { PhoneInput } from '../../components/Phone'

export const CompleteSignUpForm = () => {
  const { dispatch } = useContext(CurrentUserContext)
  const openSuccessToast = useToaster({ type: ToastType.SUCCESS })
  const { key } = useParams<{ key?: string }>()
  const { deleteStoredTokens } = useContext(SessionTokenContext)

  // clear all existing user data on load
  useEffect(() => {
    deleteStoredTokens()
  }, [])

  const [completeUserProfile, { loading }] = useMutation(
    COMPLETE_USER_PROFILE,
    {
      onCompleted: data => {
        if (data.completeUserProfile) {
          openSuccessToast('Profile sucessfully updated')
          location.pathname = '/login'
        }
      },
    }
  )

  const { values, handleChange, handleSubmit, errors, setFieldValue } =
    useFormik({
      initialValues: {
        key: key || '',
        firstName: '',
        lastName: '',
        password: '',
        phoneNumber: '',
      },
      onSubmit: async values => {
        await completeUserProfile({
          variables: {
            input: values,
          },
        })
      },
    })

  const clearPhoneNumber = () => {
    setFieldValue('phoneNumber', '+44')
  }

  return (
    <DialogBody>
      <form onSubmit={handleSubmit}>
        {!key && (
          <FormGroup>
            <InputGroup
              minLength={7}
              name="key"
              value={values.key}
              intent={errors.key ? Intent.DANGER : Intent.NONE}
              onChange={handleChange}
              placeholder="The reset code received"
            />
          </FormGroup>
        )}
        <FormGroup label="First name">
          <InputGroup
            value={values.firstName || ''}
            onChange={handleChange}
            name="firstName"
          />
        </FormGroup>
        <FormGroup label="Last name">
          <InputGroup
            value={values.lastName}
            onChange={handleChange}
            name="lastName"
          />
        </FormGroup>
        <FormGroup label="Phone Number">
          <PhoneInput
            onChange={(number: string) => {
              setFieldValue('phoneNumber', `${'+' + number}`)
            }}
            clearInput={clearPhoneNumber}
            value={values.phoneNumber}
          />
        </FormGroup>
        <FormGroup label="Password">
          <PasswordField
            name="password"
            value={values.password}
            intent={errors.password ? Intent.DANGER : Intent.NONE}
            onChange={handleChange}
          />
        </FormGroup>

        <DialogFooter>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Button
              minimal
              onClick={() => {
                deleteStoredTokens()
                if (key) {
                  location.pathname = '/login'
                } else {
                  dispatch({ type: UserActionType.LOGOUT })
                }
              }}
            >
              Cancel
            </Button>
            <Button intent={Intent.PRIMARY} type="submit" disabled={loading}>
              Register
            </Button>
          </div>
        </DialogFooter>
      </form>
    </DialogBody>
  )
}
