import { useQuery } from '@apollo/client'
import { Intent, Tooltip, Tag, IconSize, Icon } from '@blueprintjs/core'
import React, { useContext } from 'react'
import {
  EmptyListState,
  ErrorState,
  LoadingState,
} from '../../components/NonIdealState/NonIdealState'
import { LIST_DRIVERS } from './gql/drivers'
import startCase from 'lodash.startcase'
import { StringParam, useQueryParam } from 'use-query-params'
import { DriverDrawer } from './DriverDrawer'
import { TableView } from '../../components/Views/Table'
import { configurePageLayout } from '../../providers/PageLayoutProvider'
import { InviteDriverDialog } from '../../dialogs/InviteDriverDialog'
import { DialogContext } from '../../providers/DialogProvider'
import { Explanation } from '../../atomics/Explanation'
import { QUERY_PARAMS } from '../../utils/queryParamsNames'
import { ModelTypes } from '../../zeus'
import { driverStatusColors, StatusTag } from '../../styles/status'
import format from 'date-fns/format'
import { OptionsForStatus } from '../../components/OptionsForStatus'

export const Drivers = () => {
  const { openDialog, closeDialog } = useContext(DialogContext)

  configurePageLayout({
    tabs: [],
    button: {
      onClick: () =>
        openDialog({
          title: 'Invite Driver',
          content: <InviteDriverDialog closeDialog={closeDialog} />,
        }),
      text: 'Invite Driver',
    },
  })

  const { data, loading, error } = useQuery(LIST_DRIVERS)
  const [_driverId, setDriverId] = useQueryParam(
    QUERY_PARAMS.driverId,
    StringParam
  )

  const icon = <Icon size={12} icon="symbol-circle" />

  if (loading) {
    return <LoadingState entityName="drivers" />
  }

  if (error) {
    return <ErrorState entityName="drivers" />
  }

  if (!data?.drivers?.length) {
    return <EmptyListState entityName="drivers" />
  }

  // TODO fleet needs fixing due to many->many
  return (
    <>
      <TableView>
        <>
          <thead>
            <tr>
              <th />
              <th>Phone</th>
              <th>Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>Breaks</th>
              <th>Fleet name</th>
            </tr>
          </thead>
          <tbody>
            {data.drivers.map(
              ({
                id,
                status,
                breaks,
                firstName,
                lastName,
                email,
                completedSignUp,
                phoneNumber,
                fleetDrivers,
                allowJobRejection,
                allowStackingOrders,
              }) => (
                <tr key={id} onClick={() => setDriverId(id)}>
                  <td style={{ width: '1rem' }}>
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Explanation
                        icon="layers"
                        intent={
                          allowStackingOrders ? Intent.PRIMARY : Intent.NONE
                        }
                        size={IconSize.STANDARD}
                        content={`Driver is ${
                          !allowStackingOrders ? 'NOT' : ''
                        } suitable to stack orders`}
                        style={{
                          marginRight: 10,
                          opacity: allowStackingOrders ? 1 : 0.2,
                        }}
                      />
                      <Explanation
                        icon="pivot"
                        intent={allowJobRejection ? Intent.DANGER : Intent.NONE}
                        size={IconSize.STANDARD}
                        content={`Driver can ${
                          !allowJobRejection ? 'NOT' : ''
                        } accept / reject jobs`}
                        style={{ opacity: allowJobRejection ? 1 : 0.2 }}
                      />
                    </div>
                  </td>
                  <td style={{ maxWidth: 120 }}>
                    <a>{phoneNumber}</a>
                    {'   '}
                    {!completedSignUp ? (
                      <Explanation
                        sup
                        intent={Intent.WARNING}
                        icon="warning-sign"
                        content="Driver has not completed registration"
                      />
                    ) : null}
                  </td>
                  <td>
                    {firstName ? firstName.concat(' ', lastName || '') : '---'}
                  </td>
                  <td>{email || '---'}</td>
                  <td>
                    <StatusTag
                      intent={driverStatusColors[status].intent}
                      icon={icon}
                    >
                      {status.replaceAll('_', ' ')}
                    </StatusTag>
                  </td>
                  <td>
                    <OptionsForStatus
                      options={breaks as ModelTypes['OptionForDriverStatus'][]}
                      showInactiveOption
                      showMinimalLabel
                    />
                  </td>
                  <td>
                    {(fleetDrivers || [])
                      .map(({ fleet }) => fleet.name)
                      .join(' ')}
                  </td>
                </tr>
              )
            )}
          </tbody>
        </>
      </TableView>
      <DriverDrawer />
    </>
  )
}
