import { useMutation } from '@apollo/client'
import { Button, Card, Classes, H4, Intent } from '@blueprintjs/core'
import { DialogFooter } from '@blueprintjs/core'
import React from 'react'
import { StringParam, useQueryParam } from 'use-query-params'
import { ToastType, useToaster } from '../hooks/useToaster'
import { DELETE_DRIVER } from '../pages/Drivers/gql/deleteDriver'
import { LIST_DRIVERS } from '../pages/Drivers/gql/drivers'
import { QUERY_PARAMS } from '../utils/queryParamsNames'

export const DeleteDriverDialog = ({
  driverId,
  closeContainer,
}: {
  driverId: string
  closeContainer: Function
}) => {
  const openSuccessToast = useToaster({ type: ToastType.SUCCESS })
  const openErrorToast = useToaster({ type: ToastType.ERROR })
  const [_driverId, setDriverId] = useQueryParam(
    QUERY_PARAMS.driverId,
    StringParam
  )
  const [deleteDriver, { loading }] = useMutation(DELETE_DRIVER, {
    refetchQueries: [LIST_DRIVERS],
  })

  return (
    <Card style={{ width: '300px' }}>
      <H4>Delete Driver</H4>
      <p>
        Are you sure you want to delete the driver? This <strong>cannot</strong>{' '}
        be undone. Additionally all his own vehicles will be deleted and all
        jobs will need to be reassigned.
      </p>
      <DialogFooter>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button text="Cancel" minimal onClick={() => closeContainer()} />
          <Button
            text="Delete"
            intent={Intent.DANGER}
            disabled={loading}
            onClick={async () => {
              await deleteDriver({
                variables: { driverId },
                onError: err => {
                  openErrorToast(err.message)
                },
                onCompleted: () => {
                  openSuccessToast('Driver deleted')
                  closeContainer()
                  setDriverId(null)
                },
              })
            }}
          />
        </div>
      </DialogFooter>
    </Card>
  )
}
