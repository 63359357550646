import { Icon, Intent, Tag } from '@blueprintjs/core'
import React, { FC } from 'react'
import { JobStatus } from '../../zeus'
import { StatusTag } from '../../styles/status'
import { Explanation } from '../../atomics/Explanation'

const jobStatusIntentMap = (status: JobStatus): Intent => {
  switch (status) {
    case JobStatus.CANCELLED:
      return Intent.DANGER
    case JobStatus.EXCEPTION:
      return Intent.DANGER
    case JobStatus.PENDING:
      return Intent.WARNING
    case JobStatus.COMPLETE:
      return Intent.SUCCESS
    default:
      return Intent.PRIMARY
  }
}

export const JobStatusSection: FC<{ status: JobStatus; onHold: boolean }> = ({
  status,
  onHold,
}) => (
  <div>
    <StatusTag
      icon={
        <Explanation
          icon={onHold ? 'pause' : 'play'}
          size={12}
          content={`Job is ${!onHold ? 'NOT' : ''} on hold`}
          style={{ marginBottom: 1.5 }}
        />
      }
      intent={jobStatusIntentMap(status)}
    >
      {status}
    </StatusTag>
  </div>
)
