import { $ } from '../../../zeus'
import { typedGql } from '../../../zeus/typedDocumentNode'

export const DELETE_JOB_AND_TASKS = typedGql('mutation')({
  deleteJob: [
    {
      id: $('jobId', 'String!'),
    },
    {
      id: true,
    },
  ],
})
