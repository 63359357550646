import { Card } from '@blueprintjs/core'
import React from 'react'
import { Route } from 'react-router-dom'
import { DistanceUnit, ModelTypes } from '../../zeus'

type FeesProps = Pick<
  ModelTypes['Job'],
  | 'id'
  | 'distanceUnit'
  | 'price'
  | 'cost'
  | 'initialCost'
  | 'estimatedDistanceMiles'
  | 'estimatedDurationMinutes'
>

//  TODO proper currency https://korelogic.atlassian.net/browse/DIS-184
export const Fees = ({
  id,
  distanceUnit,
  price,
  cost,
  initialCost,
  estimatedDistanceMiles,
  estimatedDurationMinutes,
}: FeesProps) => {
  return (
    <Card>
      <p>
        Price £
        <span style={{ fontWeight: 'bold' }}>{(price / 100).toFixed(2)}</span>
      </p>
      <p>
        Initial Cost £
        <span style={{ fontWeight: 'bold' }}>
          {(initialCost / 100).toFixed(2)}
        </span>
      </p>
      <p>
        Cost £
        <span style={{ fontWeight: 'bold' }}>{(cost / 100).toFixed(2)}</span>
      </p>
      <p>
        Distance in miles{' '}
        <span style={{ fontWeight: 'bold' }}>
          {estimatedDistanceMiles.toFixed(2)}
        </span>
      </p>
      <p>
        Duration in minutes{' '}
        <span style={{ fontWeight: 'bold' }}>
          {Math.round(estimatedDurationMinutes)}
        </span>
      </p>
    </Card>
  )
}
